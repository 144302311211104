var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gd-users-list" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gd-users-list__body" },
      _vm._l(_vm.users, function(user, index) {
        return _c("ul", { key: index, staticClass: "gd-table-row" }, [
          _c("li", { staticClass: "gd-table-cell" }, [
            _vm._v(_vm._s(user.firstName) + " " + _vm._s(user.lastName))
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "gd-table-cell" }, [
            _vm._v(_vm._s(user.username))
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "gd-table-cell gd-table-cell--role" }, [
            _vm._v(_vm._s(user.role))
          ]),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "gd-table-cell gd-table-cell--last" },
            [
              _vm.isSimpleUser(user.role)
                ? _c(
                    "gdButton",
                    {
                      attrs: { theme: "ghost", loading: _vm.loading },
                      nativeOn: {
                        click: function($event) {
                          _vm.deleteCompanysUser(user.id)
                        }
                      }
                    },
                    [_vm._v("\n          Usuń\n        ")]
                  )
                : _vm._e()
            ],
            1
          )
        ])
      })
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gd-table-header" }, [
      _c("ul", { staticClass: "gd-users-list__header gd-table-row" }, [
        _c("li", { staticClass: "gd-table-cell" }, [_vm._v("Imię i nazwisko")]),
        _vm._v(" "),
        _c("li", { staticClass: "gd-table-cell gd-table-cell--email" }, [
          _vm._v("Adres email")
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "gd-table-cell gd-table-cell--role" }, [
          _vm._v("Rola")
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "gd-table-cell gd-table-cell--last" }, [
          _vm._v("Akcje")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }