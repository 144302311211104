<template>
  <div :class="gdDashboardBrickClass">
    <gdBrick
      :thin="thin"
      :disabled="disabled"
    >
      <div
        v-if="iconName"
        class="icon-wrapper"
      >
        <gdIcon
          class="icon-main"
          :name="iconName"
          :large="true"
        />
      </div>
      <h2 class="brick__title">{{ title }}</h2>
      <p class="brick__content">{{ content }}</p>
      <div
        class="brick__disabled-info"
        v-if="disabled"
      >
        <span class="info-label">niedostępny</span>
        <gdTooltip
          v-if="disabledTooltipMessage"
          :content="disabledTooltipMessage"
          iconName="question"
        />
      </div>
      <div
        class="brick__buttons"
        v-if="!disabled"
      >
        <slot name="buttons"/>
      </div>
    </gdBrick>
  </div>
</template>

<script>
  import gdBrick from '@/components/bricks/gd-brick'

  export default {

    props: {
      iconName: String,
      title: String,
      content: String,
      disabledTooltipMessage: String,
      thin: Boolean,
      disabled: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      gdDashboardBrickClass () {
        return {
          'gd-brick-dashboard': true,
          'gd-brick-dashboard--thin': this.thin
        }
      }
    },

    components: {
      gdBrick
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .gd-brick-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 48px;
    }

    .gd-brick {
      display: flex;
      height: 100%;

      &--disabled {
        .icon-main {
          color: $color-dark;
        }
      }
    }

    .brick {
      &__title {
        margin: 35px 0 10px;
        font-size: 20px;
      }

      &__content {
        margin-bottom: auto;
        font-size: 16px;
        line-height: 20px;
        color: $color-gray-light;
      }

      &__disabled-info {
        display: flex;
        align-items: center;

        .info-label {
          font-family: $font-bold;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1.7px;
        }

        .gd-tooltip {
          position: relative;
          top: -2px;
          right: -5px;

          svg.icon {
            display: block;
          }
        }
      }

      &__buttons {
        width: 100%;
        display: flex;
        justify-content: center;

        .gd-button-wrapper:not(:first-child) {
          margin-left: 10px;
        }
      }
    }

    &--thin {
      width: calc((100% / 2) - 5px);
    }
  }
</style>
