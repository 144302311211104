var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-edit-areas section--wide" },
    [
      _c(
        "gdPageHeader",
        { attrs: { title: "Rejestr Czynności Przetwarzania Danych (RCPD)" } },
        [
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "gdButton",
                {
                  staticClass: "btn-activity-register-edit",
                  nativeOn: {
                    click: function($event) {
                      return _vm.cancel($event)
                    }
                  }
                },
                [_vm._v("\n        Anuluj\n      ")]
              ),
              _vm._v(" "),
              _c(
                "gdButton",
                {
                  staticClass: "btn-activity-register-edit",
                  attrs: { loading: _vm.loading },
                  nativeOn: {
                    click: function($event) {
                      return _vm.saveAreas($event)
                    }
                  }
                },
                [_vm._v("\n        Zapisz obszary\n      ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _vm._l(_vm.areas, function(area, index) {
        return _c("gdEditAreaRow", {
          key: index,
          attrs: { title: area.title, id: area.id, mode: area.mode }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }