var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-activity-register section--wide" },
    [
      _c(
        "gdPageHeader",
        {
          attrs: {
            title: "Rejestr Czynności Przetwarzania Danych (RCPD)",
            description:
              "To mapa czynności w Twojej firmie, w których wykorzystujesz dane osobowe. Dzięki niej wiesz co robisz z danymi w firmie, np. wykonywanie przelewów, prowadzenie listy płac, rekrutacja."
          }
        },
        [
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "gdButton",
                {
                  nativeOn: {
                    click: function($event) {
                      return _vm.downloadRegister($event)
                    }
                  }
                },
                [_vm._v("\n        Pobierz\n      ")]
              ),
              _vm._v(" "),
              _c(
                "gdButton",
                {
                  attrs: { link: { name: "app.activity-register.edit-areas" } }
                },
                [_vm._v("\n        Edytuj obszary\n      ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _vm._l(_vm.userAreas, function(area) {
        return _c("gdTableActivityArea", {
          key: area.id,
          attrs: {
            areaId: area.id,
            title: area.name,
            headers: area.headers,
            activities: area.activities
          }
        })
      }),
      _vm._v(" "),
      !_vm.user.company.metadata.steps[5].completed
        ? _c("gdContinueImplementation")
        : _vm._e(),
      _vm._v(" "),
      _c("gdModalInfo", {
        attrs: {
          iconName: "table",
          title: "Rejestr Czynności Przetwarzania wegenerowany!",
          content:
            "Rejestr, który wygenerowaliśmy jest dokładnie dopasowany do profilu Twojej działalności, nadal masz możliwość edycji, usuwania bądź dodawania czynności",
          buttonLabel: "Zobacz rejestr"
        },
        on: { buttonClick: _vm.hideModal }
      }),
      _vm._v(" "),
      _c("gdModalRcpdCustomValues", {
        attrs: { title: "TITLE", content: "CONTENT", iconName: "table" }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }