const initialState = () => ({
  documents: [],
  compatibilities: []
})

const state = () => (initialState())

const actions = {
  async GET_MATRIX({ commit, dispatch }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'user/compatibility/view'
      }, { root: true })
      console.log('reportOfCompatibility/GET_MATRIX', data)
      const { compatibilities, documents } = (data || {})
      commit('SET_DOCUMENTS', { documents })
      commit('SET_COMPATIBILITIES', { compatibilities })
    } catch(error){
      console.error('reportOfCompatibility/GET_MATRIX', { error })
      throw error
    }
  }
}

const mutations = {
  SET_DOCUMENTS(state, { documents }){
    state.documents = documents
  },

  SET_COMPATIBILITIES(state, { compatibilities }){
    state.compatibilities = compatibilities
  }
}

const getters = {
  documents: state => {
    if(!state.documents || !state.documents.length){
      return
    }

    return state.documents
  },
  compatibilities: state => {
    if(!state.compatibilities || !state.compatibilities.length){
      return
    }

    return state.compatibilities
  }
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
