var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-help" },
    [
      _c("gdPageHeader", {
        attrs: {
          title: "Pomoc",
          description:
            "Maecenas sed diam eget risus varius blandit sit amet non magna."
        }
      }),
      _vm._v(" "),
      _vm.questions
        ? _c("gdAccordeon", {
            attrs: {
              items: _vm.questions,
              visibleField: "title",
              expandableField: "answer",
              commentField: "comment"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }