<template>
  <div :class="brickClass">
    <gdBrick
      :thin="thin"
      :disabled="disabled"
    >
      <div class="brick__image">
        <slot name="image" />
      </div>
      <div class="brick__content">
        <slot name="icon" />
        <h2 class="content-title">{{ title }}</h2>
        <p class="content-about">{{ about }}</p>
        <slot name="buttons" />
      </div>
    </gdBrick>
  </div>
</template>

<script>
  import gdBrick from '@/components/bricks/gd-brick'

  export default {
    props: {
      title: String,
      about: String,
      wide: Boolean,
      disabled: {
        type: Boolean,
        defualt: false
      },
      thin: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      brickClass() {
        return {
          'gd-brick-horizontal': true,
          'gd-brick-horizontal--wide': this.wide
        }
      }
    },
    components: {
      gdBrick
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-brick-horizontal {
    width: 100%;

    .gd-brick__inner {
      flex-flow: row nowrap;
      padding: 20px;
    }

    .brick {
      &__image {
        flex-shrink: 0;
        height: 260px;
        width: 360px;
        border-radius: 3px;

        .gd-image {
          border-radius: 2px;
          overflow: hidden;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      &__content {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 5%;

        .icon {
          margin-bottom: 25px;
          color: $color-blue;
        }
      }
    }

    .content-title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .content-about {
      font-size: 16px;
      line-height: 20px;
      color: $color-gray-light;
    }

    .gd-button-wrapper {
      margin-top: 15px;
    }

    &--wide {
      .brick {
        &__image {
          height: 280px;
          width: 520px;
        }
      }
    }
  }
</style>
