<template>
  <header
    class="gd-rodo-step-header"
    :class="{ 'gd-rodo-step-header--active': active, 'gd-rodo-step-header--completed': completed }"
  >
    <gdBulletStep
      :stepActive="active"
      :stepSuccess="completed"
    />

    <div class="gd-rodo-step-menu">
      <gdButton
        v-if="rollbackable"
        title="Powrót do poprzedniego etapu"
        @click.native="rollbackStepModal"
        :loading="loading"
      >
        <gdImg src="/assets/icons/icon-arrow-up.png" />
      </gdButton>
      <gdButton
        v-if="resettable"
        title="Reset bieżącego etapu"
        @click.native="resetStepModal"
        :loading="loading"
      >
        <gdImg src="/assets/icons/icon-reset.png" />
      </gdButton>
    </div>

    <h1 class="gd-rodo-step-header__title">{{ completed ? completedTitle : title }}</h1>
    <h2
      :style="{ color: sideTitle.color }"
      class="gd-rodo-step-header__sidetitle"
      v-if="sideTitle"
    >{{ sideTitle.text }}</h2>

    <gdIcon
      v-if="iconName"
      class="gd-rodo-step-header__icon"
      :name="iconName"
      :large="true"
    />
  </header>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      completedTitle: {
        type: String,
        required: true
      },
      sideTitle: {
        type: Object
      },
      iconName: {
        type: String
      },
      active: {
        type: Boolean
      },
      completed: {
        type: Boolean
      },
      resettable: {
        type: Boolean
      },
      rollbackable: {
        type: Boolean
      }
    },

    data() {
      return {
        loading: false
      }
    },

    methods: {
      resetStepModal() {
        this.$store.dispatch('prompt/SHOW', {
          title: 'Potwierdzenie zresetowania bieżącego etapu wdrożenia',
          content: 'Zresetowanie bieżącego etapu wdrożenia spowoduje usunięcie wszystkich danych dodanych w tym etapie. Czy na pewno zresetować bieżący etap wdrożenia?',
          onAccept: async() => {
            try {
              if ( ! this.loading ) {
                this.loading = true
                await this.$store.dispatch('rodoSteps/RESET_STEP')
              }
            } catch (error) {
              this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
              console.error({ error })
            } finally {
              this.loading = false
            }
          }
        })
      },

      rollbackStepModal() {
        this.$store.dispatch('prompt/SHOW', {
          title: 'Potwierdzenie powrotu do poprzedniego etapu wdrożenia',
          content: 'Powrót do poprzedniego etapu wdrożenia spowoduje usunięcie wszystkich danych dodanych w bieżącym oraz poprzednim etapie wdrożenia. Czy na pewno powrócić do poprzedniego etapu wdrożenia?',
          onAccept: async() => {
            try {
              if ( ! this.loading ) {
                this.loading = true
                await this.$store.dispatch('rodoSteps/ROLLBACK_STEP')
              }
            } catch (error) {
              this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
              console.error({ error })
            } finally {
              this.loading = false
            }
          }
        })
      }
    },
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/mixins/breakpoints.scss";

  .gd-rodo-step-header {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__title {
      font-size: 20px;
      line-height: 48px;
      text-align: left;
      transition: .15s;

      @include media(1300px) {
        font-size: 18px;
      }
    }

    &__sidetitle {
      font-size: 16px;
      line-height: 20px;
      color: $color-gray-light;
    }

    &__icon {
      color: lighten($color-gray-light, 40);
    }

    &__description {
      width: 100%;
    }

    .gd-bullet-step {
      position: absolute;
      top: 10px;
      left: -100px;
    }

    .gd-rodo-step-menu {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 70px;
      left: -108px;

      .gd-btn--active {
        padding: 12px;
      }
    }
    .gd-rodo-step-menu > * {
      margin-bottom: 10px;
    }
  }

  .gd-rodo-step-header--active {
    .gd-rodo-step-header__title {
      font-size: 25px;
      text-align: left;
      color: $color-blue;
    }

    .gd-rodo-step-header__icon {
      color: $color-dark;
    }
  }

  .gd-rodo-step-header--completed {
    .gd-rodo-step-header__icon {
      color: $color-dark;
    }
  }
</style>
