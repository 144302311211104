<template>
  <gdSignUp/>
</template>

<script>
  import gdSignUp from '@/pages/gd-sign-up'
  import Store from '@/stores'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    async beforeRouteEnter(to, from, next){
      try {
        await Store.dispatch('payments/GET_PAYMENT_PLANS')
        next()
      } catch(error) {
        console.error(`Couldn't resolve gd-signup beforeRouteEnter requests`, { error })
        next({
          name: 'app.error',
          query: {
            type: 'API'
          }
        })
      }
    },

    components: {
      gdSignUp
    }
  }
</script>
