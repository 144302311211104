var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-data-processing container" },
    [
      _c(
        "gdLink",
        { attrs: { to: { name: "home" } } },
        [_c("gdImg", { attrs: { src: "/assets/images/logo.svg" } })],
        1
      ),
      _vm._v(" "),
      _c("h1", { staticClass: "gd-data-processing__heading" }, [
        _vm._v("Jak przetwarzamy dane")
      ]),
      _vm._v(" "),
      _vm._l(_vm.dataProcessing, function(el, index) {
        return _c("gdInfoTextList", { key: index, attrs: { element: el } })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }