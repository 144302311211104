export default function interpretRiskResult ({ result, mode = 'primary' }) {

  if (mode === 'primary') {
    if ([1, 2, 3].includes(result)) {
      return 1
    } else if ([4, 6].includes(result)) {
      return 2
    } else if (9 === result) {
      return 3
    }
  }

  else if (mode === 'secondary') {
    if ([1, 2].includes(result)) {
      return 1
    } else if ([3, 4].includes(result)) {
      return 2
    } else if ([6, 9].includes(result)) {
      return 3
    }
  }
}
