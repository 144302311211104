const initialState = () => ({})

const state = () => (initialState())

const actions = {
  async DOWNLOAD_FILE ({ commit, getters, dispatch }, { fileId }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/file/${fileId}/download`,
        fileDownload: true
      }, { root: true })

      console.log('file/DOWNLOAD_FILE', { data })
    } catch(error){
      console.error('file/DOWNLOAD_FILE', { error })
      throw error
    }
  },

  async GET_FILE ({ commit, getters, dispatch }, { fileId }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/file/${fileId}`
      }, { root: true })

      console.log('file/GET_FILE', { data })
    } catch(error){
      console.error('file/GET_FILE', { error })
      throw error
    }
  },

  async DELETE_FILE ({ commit, getters, dispatch }, { fileId, fileType }) {
    // function create for future use with different types of files
    const createEndpoint = (id, type) => {
      switch (type) {
      case 'uar':
        return `user/uar/${id}`
      default:
        return `user/file/${id}`
      }
    }

    try {
      const { data } = await dispatch('api/REQUEST', {
        method: 'DELETE',
        endpoint: createEndpoint(fileId, fileType)
      }, { root: true })

      console.log('file/DELETE_FILE', { data })
    } catch(error){
      console.error('file/DELETE_FILE', { error })
      throw error
    }
  },

  async ADD_FILE ({ commit, getters, dispatch }, { name, handle, recommendationId, categoryId }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        method: 'POST',
        endpoint: 'user/file',
        data: { name, handle, recommendationId, categoryId }
      }, { root: true })

      console.log('file/ADD_FILE', { data })
    } catch(error){
      console.error('file/ADD_FILE', { error })
      throw error
    }
  }

}

const mutations = {}

const getters = {}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
