var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "gd-footer-app section--wide" }, [
      _c("div", { staticClass: "gd-footer-app__inner" }, [
        _c("div", { staticClass: "gd-footer-app__info info-hero" }, [
          _c("span", [_vm._v("Potrzebujesz pomocy?")]),
          _vm._v(" "),
          _c("a", { attrs: { href: "tel:178700350‬" } }, [
            _vm._v("Telefon 791 764 754")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gd-footer-app__info gd-footer-app__info--hero" },
          [
            _c("span", [
              _vm._v(
                "Nasz dział Wsparcia i Obsługi Klienta jest do Twojej dyspozycji od poniedziałku do piatku w godzinach od 8:30 do 17:00"
              )
            ]),
            _vm._v(" "),
            _c("a", { attrs: { href: "mailto:kontakt@gdpsystem.pl" } }, [
              _vm._v("kontakt@gdpsystem.pl")
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }