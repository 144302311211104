<template>
  <section class="gd-table-row">
    <slot />
  </section>
</template>
<script>
  export default {
  }
</script>
<style lang="scss">

</style>
