<template>
  <section :class="rootCssClasses">
    <slot />
  </section>
</template>

<script>
  import { isElementInViewport } from '@/helpers'

  export default {

    props: {
      standalone: {
        type: Boolean,
        default: true
      },
      stickyMode: {
        type: Boolean,
        default: false
      }
    },

    data(){
      return {
        sticky: false,
        prevScrollY: 0
      }
    },

    computed: {
      rootCssClasses(){
        const base = 'gd-table-footer'
        const classes = [
          base,
          this.standalone ? 'gd-table-footer--standalone' : null,
          (this.stickyMode && this.sticky) ? 'gd-table-footer--sticky' : null
        ]

        return classes.filter(item => !!item).join(' ')
      }
    },

    destroyed () {
      if(this.stickyMode){
        window.removeEventListener('scroll', this.handleScroll)
      }
    },

    methods: {
      getScrollDiretion(scrollY){
        const result = (scrollY > this.prevScrollY) ? 'down' : 'up'
        this.prevScrollY = scrollY
        return result
      },

      handleScroll (event) {
        const scrollDirection = this.getScrollDiretion(window.scrollY)
        const { previousElementSibling } = this.$el

        if(!previousElementSibling || !previousElementSibling.clientHeight || !isElementInViewport({ el: previousElementSibling })){
          return
        }

        const prevElementSiblingOffsetTop = previousElementSibling.getBoundingClientRect().top * -1

        switch(scrollDirection){
        case 'down':

          if(window.innerHeight + prevElementSiblingOffsetTop - previousElementSibling.clientHeight - this.$el.clientHeight > 0){
            this.sticky = false
            previousElementSibling.style.marginBottom = 0
            this.$el.style.bottom = 0
          } else if(window.innerHeight + prevElementSiblingOffsetTop > prevElementSiblingOffsetTop){

            this.sticky = true
            previousElementSibling.style.marginBottom = `${this.$el.clientHeight}px`
            this.$el.style.bottom = `${previousElementSibling.clientHeight - prevElementSiblingOffsetTop - window.innerHeight}px`
          }
          break

        case 'up':
          if(window.innerHeight + prevElementSiblingOffsetTop - this.$el.clientHeight < previousElementSibling.clientHeight){
            this.sticky = true
            previousElementSibling.style.marginBottom = `${this.$el.clientHeight}px`
            this.$el.style.bottom = `${previousElementSibling.clientHeight - prevElementSiblingOffsetTop - window.innerHeight}px`
          }
          break
        }
      }
    },

    watch: {
      stickyMode(newVal, oldVal){
        console.log('gd-table-footer watch stickyMode', { newVal, oldVal })
        if(newVal && !oldVal){
          window.addEventListener('scroll', this.handleScroll)
        }
        if(!newVal && oldVal){
          window.removeEventListener('scroll', this.handleScroll)
        }
      }
    }
  }
</script>
<style lang="scss">

  .gd-table-footer {
    &--no-padding {
      padding: 0;
      flex-flow: column;

      .gd-horizontal-scroll-bar {
        padding: 16px 40px 16px 20px;
      }
    }

    &--sticky {
      position: absolute;
      bottom: 0;
      left: 1px;
      z-index: 9999;
      width: 100%;
      overflow: hidden;

      .gd-activity-add-row {
        width: 100%;
      }
    }
  }
</style>
