const initialState = () => ({
  introSlides: [
    {
      title: 'Profil klienta',
      content: 'Określamy obszar i zakres Twojej działalności oraz ilość przetwarzanych danych w Twojej firmie.'
    },
    {
      title: 'RCPD',
      content: 'Na podstawie Twojego profilu tworzymy dla Ciebie RCPD, a następnie pomagamy zweryfikować jego zakres.'
    },
    {
      title: 'Uzupełnienie profilu',
      content: 'Doprecyzowujemy Twój profil w celu ustalenia konieczności powołania w Twojej firmie Inspektora Danych Osobowych.'
    },
    {
      title: 'Raport Zaleceń',
      content: 'Tworzymy dla Ciebie specjalny raport zaleceń wraz ze wskazówkami jak je skutecznie wdrożyć w Twojej firmie. Dodatkowo służymy pomocą i wsparciem w ich realizacji.'
    },
    {
      title: 'Certyfikat',
      content: 'Po wykonaniu zaleceń udostępnimy Ci Raport z Wdrożenia oraz umożliwimy pobranie Certyfikatu zgodności ze standardami GDPS.'
    },
    {
      title: 'Optymalizacja Twoich działań',
      content: 'Udostępniamy Ci platformę GDPSystem do codziennej pracy jako narzędzie wsparcia i zarządzania obszarem danych osobowych w Twojej firmie.'
    }
  ],
  documents: [],
  certicate: {}
})

const state = () => (initialState())

const actions = {
  async GET_DASHBOARD({ commit, dispatch }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'user/dashboard/view'
      }, { root: true })
      const { certicate, documents } = (data || {})

      commit('SET_CERTIFICATE', { certicate })
      commit('SET_DOCUMENTS', { documents })

      console.log('documents/GET_DASHBOARD', data)
    } catch(error){
      console.error('documents/GET_DASHBOARD', { error })
      throw error
    }
  },
}

const mutations = {
  SET_CERTIFICATE(state, { certicate }){
    state.certicate = certicate
  },

  SET_DOCUMENTS(state, { documents }){
    state.documents = documents
  }
}

const getters = {
  introSlides: state => state.introSlides,
  certicate: state => state.certicate,
  documents: state => {
    if(!state.documents || !state.documents.length){
      return
    }

    return state.documents
  }
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
