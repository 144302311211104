<template>
  <gdDashboard />
</template>
<script>
  import gdDashboard from '@/pages/app/gd-dashboard'
  import Store from '@/stores'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    async beforeRouteEnter(to, from, next){
      try {
        await Store.dispatch('rodoSteps/GET_QUESTIONS')
        await Store.dispatch('dashboard/GET_DASHBOARD')
        await Store.dispatch('user/GET_ALLOWED_RESOURCES')
        next()
      } catch(error) {
        console.error(`Couldn't resolve gd-dashboard beforeRouteEnter requests`, { error })
        next({
          name: 'app.error',
          query: {
            type: 'API'
          }
        })
      }
    },

    components: {
      gdDashboard
    }
  }
</script>
