var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gdTable",
    {
      staticClass: "gd-table-activity-area",
      attrs: { id: _vm.rootId, title: _vm.title, cellsOverflow: true }
    },
    [
      _vm.activities || _vm.isAddActivityRowVisible
        ? _c(
            "gdTableHeader",
            {
              attrs: {
                bordered: !!(this.isAddActivityRowVisible || this.activities),
                underlined: !_vm.headers || _vm.headers.length === 0,
                stickyMode: _vm.stickyMode
              }
            },
            [
              _c(
                "gdTableRow",
                [
                  _c("gdTableCell", { ref: "gdTableCellHeaderHeroCell" }, [
                    _vm._v("\n        Czynność\n      ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "gdTableCellHeaderWrapper",
                      staticClass: "cells-overflow"
                    },
                    [
                      _vm._l(_vm.headers, function(cell, cellIndex) {
                        return _c(
                          "gdTableCell",
                          { key: cellIndex },
                          [
                            cell.info
                              ? _c("gdTooltip", {
                                  attrs: {
                                    content: cell.info,
                                    iconName: "question"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n          " + _vm._s(cell.name) + "\n        "
                            )
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c("gdTableCell", [
                        _vm._v("\n          Data aktualizacji\n        ")
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "gdTableBody",
        [
          _vm._l(_vm.activities, function(activity, activityIndex) {
            return _vm.activities
              ? [
                  activity
                    ? _c("pre", { attrs: { hidden: "" } }, [
                        _vm._v(_vm._s(activity.id))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  activity && !_vm.editActivityRowVisible.includes(activity.id)
                    ? _c(
                        "gdTableRow",
                        {
                          key: activityIndex,
                          ref: "gdTableRow",
                          refInFor: true
                        },
                        [
                          _c(
                            "gdTableCell",
                            [
                              _c(
                                "span",
                                { ref: "gdTableHeroCell", refInFor: true },
                                [_vm._v(_vm._s(activity.name))]
                              ),
                              _vm._v(" "),
                              activity.info
                                ? _c("gdTooltip", {
                                    attrs: {
                                      content: activity.info,
                                      iconName: "question"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.editActivityRowVisible.length &&
                              !_vm.isAddActivityRowVisible
                                ? _c(
                                    "gdButton",
                                    {
                                      attrs: { theme: "ghost" },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.showEditActivityRow({
                                            activity: activity
                                          })
                                        }
                                      }
                                    },
                                    [_vm._v("\n            Edytuj\n          ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "cells-overflow" },
                            [
                              _vm._l(_vm.headers, function(cell, cellIndex) {
                                return _c(
                                  "gdTableCell",
                                  { key: cellIndex },
                                  [
                                    _c("pre", { attrs: { hidden: "" } }, [
                                      _vm._v(_vm._s(activity))
                                    ]),
                                    _vm._v(" "),
                                    activity[
                                      _vm._activityColumnsKey(activity)
                                    ] &&
                                    activity[_vm._activityColumnsKey(activity)][
                                      cell.id
                                    ]
                                      ? [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                activity[
                                                  _vm._activityColumnsKey(
                                                    activity
                                                  )
                                                ][cell.id]
                                                  ? _vm.cellLabel(
                                                      activity[
                                                        _vm._activityColumnsKey(
                                                          activity
                                                        )
                                                      ][cell.id]
                                                    )
                                                  : null
                                              )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm.cellInfo(
                                            activity[
                                              _vm._activityColumnsKey(activity)
                                            ][cell.id]
                                          )
                                            ? _c("gdTooltip", {
                                                attrs: {
                                                  content: _vm.cellInfo(
                                                    activity[
                                                      _vm._activityColumnsKey(
                                                        activity
                                                      )
                                                    ][cell.id]
                                                  ),
                                                  iconName: "question"
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              }),
                              _vm._v(" "),
                              _c("gdTableCell", [
                                _c("pre", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("moment")(
                                        activity.updatedAt,
                                        "DD/MM/YYYY hh:mm"
                                      )
                                    )
                                  )
                                ])
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _c("gdActivityEditRow", {
                        attrs: { activityId: activity.id, headers: _vm.headers }
                      })
                ]
              : _vm._e()
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "gdTableFooter",
        {
          class: { "gd-table-footer--no-padding": _vm.isAddActivityRowVisible },
          attrs: {
            standalone: !this.isAddActivityRowVisible && !this.activities,
            stickyMode: _vm.stickyMode
          }
        },
        [
          _vm.isAddActivityRowVisible
            ? _c("gdActivityAddRow", {
                attrs: { headers: _vm.headers, areaId: _vm.areaId }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAddActivityRowVisible
            ? _c(
                "gdButton",
                {
                  style: {
                    visibility: _vm.editActivityRowVisible.length
                      ? "hidden"
                      : ""
                  },
                  attrs: { theme: "ghost" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.showAddActivityRow($event)
                    }
                  }
                },
                [_vm._v("\n      Dodaj czynność\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activities || _vm.isAddActivityRowVisible
            ? _c("gdHorizontalScrollBar", {
                attrs: { scrollableElements: _vm.scrollableElements }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }