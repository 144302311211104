var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { ref: "rodoStepsHolder", staticClass: "gd-uar-steps" },
    [
      !_vm.allStepsCompleted
        ? _c("span", { staticClass: "gd-uar-steps__progress-bg" })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.groups, function(group, index) {
        return _c("gdUarSingleStep", {
          key: group.id,
          attrs: { group: group, groupIndex: index }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }