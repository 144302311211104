import { formatRodoStep } from '@/helpers'

const initialState = () => ({
  activeStepId: 1,
  stepsProgress: 0,
  questions: null,
  steps: {
    1: {
      id: 1,
      active: true,
      completed: false,
      description: null,
      descriptionExtra: 'Pozwól nam się bliżej poznać odpowiadając na proste pytania.',
      questions: null,
      affectedDescription: null
    },
    2: {
      id: 2,
      active: false,
      completed: false,
      description: 'Zweryfikuj i potwierdź czy prawidłowo wskazaliśmy czynności przetwarzania danych - przypisaliśmy je do poszczególnych obszarów - pracowniczego, księgowego, administracyjne etc.',
      descriptionExtra: 'Czas uzupełnić najważniejszy element RODO.',
      questions: null,
      affectedDescription: null
    },
    3: {
      id: 3,
      active: false,
      completed: false,
      description: null,
      descriptionExtra: 'Aby być w pełni zgodnym z RODO zadamy jeszcze kilka pytań.',
      questions: null,
      affectedDescription: null
    },
    4: {
      id: 4,
      active: false,
      completed: false,
      description: 'Jeśli przetwarzasz dane osobowe na zlecenie innych podmiotów, pamiętaj o konieczności uzupełnienia i prowadzenia Rejestru Kategorii Przetwarzań. Możesz to zrobić teraz lub uzupełnić w innym czasie.',
      descriptionExtra: 'Uzupełnij Rejestr procesora jeśli jesteś do tego zobligowany.',
      questions: null,
      affectedDescription: 'Nie przetwarzasz danych osobowych w imieniu innych firm w związku z czym nie masz obowiązek prowadzenia Rejestru Procesora.'
    },
    5: {
      id: 5,
      active: false,
      completed: false,
      description: 'W tym miejscu znajdziesz wytyczne i zalecenia do wykonania, które mają na celu dostosowanie Twojej działalności do zgodności z RODO. Po wykonaniu poniższych zaleceń będziesz mógł wygenerować Raport z Wdrożenia oraz przejść do pobrania Certyfikatu.',
      descriptionExtra: 'Zastosuj się do zaleceń, które przygotowaliśmy.',
      questions: null,
      affectedDescription: null
    },
    6: {
      id: 6,
      active: false,
      completed: false,
      description: 'Gratulujemy zakończenia wdrożenia. Możesz posługiwać się Certyfikatem GDPStandard, budując wiarygodność i zaufanie wśród swoich Klientów i Partnerów biznesowych.',
      descriptionExtra: 'Ostatni krok! Pobierz Certyfikat zgodności GDPS.',
      questions: null,
      affectedDescription: null
    }
  }
})

const state = () => (initialState())

const actions = {
  async GET_QUESTIONS({ commit, dispatch, rootGetters, }){
    try {
      const { data } = await dispatch('api/REQUEST', { endpoint: 'question' }, { root: true })
      commit('SET_QUESTIONS', { questions: data })

      const userMeta = rootGetters['user/userMeta']
      if(userMeta.steps){
        dispatch('SET_STEPS_STATUS', { steps: userMeta.steps })
      }
    } catch(error){
      console.error('ACTION rodoSteps/GET_QUESTIONS', { error })
      throw error
    }
  },

  async NEXT_QUESTION({ commit, getters }, { stepId, question }){
    try {
      commit('NEXT_QUESTION', { stepId, question })
      const onlyLastStepToComplete = getters.step5.completed &&  !getters.step6.completed
      const progressValue = onlyLastStepToComplete ? (100 - getters.stepsProgress) : getters.stepsProgressValue
      commit('UPDATE_PROGRESS', { progressValue })
    } catch(error){
      console.error('ACTION rodoSteps/SKIP_QUESTION', { error })
      throw error
    }
  },

  async PREVIOUS_QUESTION({ commit, getters }, { stepId, question }){
    try {
      commit('PREVIOUS_QUESTION', { stepId, question })
      commit('UPDATE_PROGRESS', { progressValue: getters.stepsProgressValue * -1 })
    } catch(error){
      console.error('ACTION rodoSteps/SKIP_QUESTION', { error })
      throw error
    }
  },

  async SKIP_QUESTION({ commit }, { stepId, question, answer }){
    try {
      console.log('ACTION rodoSteps/SKIP_QUESTION', { answer, question, stepId })
      commit('SET_QUESTION_SKIPPED', { stepId, question, answer })
      const onlyLastStepToComplete = getters.step5.completed &&  !getters.step6.completed
      const progressValue = onlyLastStepToComplete ? (100 - getters.stepsProgress) : getters.stepsProgressValue
      commit('UPDATE_PROGRESS', { progressValue })
    } catch(error){
      console.error('ACTION rodoSteps/SKIP_QUESTION', { error })
      throw error
    }
  },

  async ANSWER_QUESTIONS({ commit, dispatch, state }, { stepId, answers }){
    try {
      if(answers && answers.length){
        const { data } = await dispatch('api/REQUEST', {
          endpoint: 'user/answer',
          method: 'POST',
          data: answers
        }, { root: true })

        const stepsMeta = Object.keys(state.steps).reduce((res, stepKey) => {
          const step = state.steps[stepKey]
          const { completed, id, active, questionsAnswered } = step
          res[id] = {
            completed,
            active,
            questionsAnswered: (id === stepId) ? true : questionsAnswered
          }

          return res
        }, {})

        await dispatch('user/SET_META', {
          steps: stepsMeta
        }, { root: true })

        commit('SET_STEPS_STATUS', { steps: stepsMeta })
      }
    } catch (error){
      console.error('ACTION rodoSteps/ANSWER_QUESTIONS', { error })
      throw error
    }
  },

  async COMPLETE_STEP({ commit, getters, dispatch, state }, { stepId }){
    try {
      const nextStepId = stepId + 1

      commit('SET_STEP_SUCCEED', { stepId })
      commit('SET_STEP_ACTIVE', { stepId: nextStepId })
      const onlyLastStepToComplete = getters.step5.completed &&  !getters.step6.completed
      const progressValue = onlyLastStepToComplete ? (100 - getters.stepsProgress) : getters.stepsProgressValue
      commit('UPDATE_PROGRESS', { progressValue })

      const stepsMeta = Object.keys(state.steps).reduce((res, stepKey) => {
        const { id, completed, active } = state.steps[stepKey]
        res[id] = {
          completed: completed,
          active: active
        }
        return res
      }, {})

      await dispatch('user/SET_META', {
        steps: stepsMeta
      }, { root: true })

      await dispatch('dashboard/GET_DASHBOARD', {}, { root: true })
      await dispatch('user/GET_ALLOWED_RESOURCES', {}, { root: true })

      console.log('ACTION rodoSteps/COMPLETE_STEP', { stepId })
    } catch (error){
      console.error('ACTION rodoSteps/COMPLETE_STEP', { error })
      throw error
    }
  },

  SET_STEPS_STATUS({ commit, getters, state }, { steps }){
    console.log('ACTION rodoSteps/SET_STEPS_STATUS', { steps })

    commit('SET_STEPS_STATUS', { steps })

    let progressValue = 0
    Object.keys(state.steps).forEach(stepKey => {
      const step = state.steps[stepKey]
      if(step.completed){
        progressValue += getters.stepsProgressValue

        if(step.questions && step.questions.length){
          progressValue += (getters.stepsProgressValue * step.questions.length)
        }
      }
    })

    commit('UPDATE_PROGRESS', { progressValue })
  },

  async RESET_STEP({ commit, getters, dispatch, state }){
    try {
      console.log('ACTION rodoSteps/RESET_STEP')

      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'rodoStep/reset',
        method: 'POST'
      }, { root: true })

      await dispatch('user/GET_USER', {}, { root: true })
      commit('RESET_STATE')
      await dispatch('rodoSteps/GET_QUESTIONS', {}, { root: true })
      await dispatch('dashboard/GET_DASHBOARD', {}, { root: true })
    } catch (error){
      console.error('ACTION rodoSteps/RESET_STEP', { error })
      throw error
    }
  },

  async ROLLBACK_STEP({ commit, getters, dispatch, state }){
    try {
      console.log('ACTION rodoSteps/ROLLBACK_STEP')

      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'rodoStep/rollback',
        method: 'POST'
      }, { root: true })

      await dispatch('user/GET_USER', {}, { root: true })
      commit('RESET_STATE')
      await dispatch('rodoSteps/GET_QUESTIONS', {}, { root: true })
      await dispatch('dashboard/GET_DASHBOARD', {}, { root: true })
    } catch (error){
      console.error('ACTION rodoSteps/ROLLBACK_STEP', { error })
      throw error
    }
  }
}

const mutations = {

  SET_STEPS_STATUS(state, { steps }){
    console.log('rodoSteps/SET_STEPS_STATUS', { steps })

    Object.keys(state.steps).forEach(stepKey => {
      const step = state.steps[stepKey]
      const externalStep = steps[stepKey]

      if(step && externalStep){
        if(externalStep.active){
          state.activeStepId = parseInt(stepKey)
        }
        state.steps[stepKey] = Object.assign({}, state.steps[stepKey], externalStep)
      }
    })
  },

  SET_QUESTIONS(state, { questions }){
    console.log('rodoSteps/SET_STEP_QUESTIONS', { questions })

    const stepsQuestions = (questions || []).reduce((res, item) => {

      const question = { ...item }
      switch(item.questionGroup){

      case 0:
        // Step 1 questions
        if(!res[1]){
          res[1] = []
        }
        res[1].push(item)
        break

      case 1:
        // Step 3 questions
        if(!res[3]){
          res[3] = []
        }
        res[3].push(item)
        break
      }

      return res
    }, {})

    Object.keys(stepsQuestions).forEach(stepId => {

      const questions = stepsQuestions[stepId]
        .sort((a,b) => a.sortOrder - b.sortOrder)

      questions[0].active = true

      state.steps[stepId] = Object.assign({}, state.steps[stepId], {
        questions,
        activeQuestionIndex: 0
      })
    })
  },

  SET_STEP_SUCCEED(state, { stepId }){
    console.log('rodoSteps/SET_STEP_SUCCEED', { stepId })
    state.steps[stepId].completed = true
  },

  SET_STEP_ACTIVE(state, { stepId }){
    console.log('rodoSteps/SET_STEP_ACTIVE', { stepId })
    state.activeStepId = stepId
    Object.keys(state.steps).forEach(_stepId => {
      state.steps[_stepId].active = _stepId === stepId.toString()
    })
  },

  NEXT_QUESTION(state, { stepId, question }){
    console.log('rodoSteps/SET_QUESTION_ANSWERED', { stepId, question })


    state.steps[stepId].questions.forEach((item, index) => {
      if(item.id === question.id){

        item.active = false

        const nextQuestionIndex = (index + 1)
        const nextQuestion = state.steps[stepId].questions[nextQuestionIndex]

        if(nextQuestion){
          nextQuestion.active = true
          state.steps[stepId].activeQuestionIndex = nextQuestionIndex
        }
      }
    })

    const nextStep = state.steps[stepId + 1]

    if(nextStep && question.affectNextStep){
      nextStep.prevStepAffect = true
    }
  },

  PREVIOUS_QUESTION(state, { stepId, question }){
    console.log('rodoSteps/PREVIOUS_QUESTION')

    state.steps[stepId].questions.forEach((item, index) => {
      if(item.id === question.id){
        item.active = false

        const prevQuestionIndex = index - 1
        const prevQuestion = state.steps[stepId].questions[prevQuestionIndex]

        if(prevQuestion){
          prevQuestion.active = true
          state.steps[stepId].activeQuestionIndex = prevQuestionIndex
        }
      }
    })
  },

  UPDATE_PROGRESS(state, { progressValue }){
    const parsedProgressValue = parseFloat(progressValue.toFixed(2))

    const progress = (state.stepsProgress + parsedProgressValue >= 100) ? 100 : (state.stepsProgress + parsedProgressValue)
    state.stepsProgress = parseFloat(progress.toFixed(2))
  },

  RESET_STATE(state){
    Object.assign(state, initialState())
  }
}

const getters = {
  step1: (state, getters) => formatRodoStep(state.steps[1]),
  step2: (state, getters) => formatRodoStep(state.steps[2]),
  step3: (state, getters) => formatRodoStep(state.steps[3]),
  step4: (state, getters) => formatRodoStep(state.steps[4]),
  step5: (state, getters) => formatRodoStep(state.steps[5]),
  step6: (state, getters) => formatRodoStep(state.steps[6]),
  allStepsCompleted: state => {
    return Object.keys(state.steps)
      .every(stepKey => state.steps[stepKey].completed)
  },
  succeedSteps: state => {
    return Object.keys(state.steps)
      .filter(stepKey => state.steps[stepKey].completed)
  },
  stepsProgress: state => state.stepsProgress,
  stepsProgressValue: (state, getters) => {
    const totalSteps = Object.keys(state.steps).length
    const totalQuestions = Object.keys(state.steps)
      .reduce((res, stepKey) => {
        const step = state.steps[stepKey]

        if(step.questions){
          res = res + step.questions.length
        }

        return res
      }, 0)

    const allStepsCompleted = getters.allStepsCompleted

    let result;
    const baseValue = (100 / (totalSteps + totalQuestions))

    if(allStepsCompleted){
      result = baseValue
    } else {
      result = parseFloat(baseValue.toFixed(2))
    }

    return result
  },
  activeStepId: state => state.activeStepId,
  activeStep: state => {
    return state.steps[state.activeStepId]
  }
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
