var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gdModal",
    {
      staticClass: "gd-modal-rcpd-custom-values",
      attrs: {
        name: _vm.modalName,
        beforeClose: _vm.beforeClose,
        width: 710,
        updateQuery: false
      },
      on: { show: _vm.showModal }
    },
    [
      _c("gdIcon", {
        staticClass: "gd-modal-rcpd-custom-values__icon",
        attrs: { name: "table", large: true }
      }),
      _vm._v(" "),
      _c("h1", { staticClass: "gd-modal-rcpd-custom-values__title" }, [
        _vm._v("\n    Własne wartości\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gd-modal-rcpd-custom-values__content" },
        [
          _vm.modalData
            ? _c(
                "ul",
                { staticClass: "gd-modal-rcpd-custom-values__values-list" },
                _vm._l(_vm.customValues, function(value, index) {
                  return !_vm.removedValues.includes(value.value)
                    ? _c(
                        "li",
                        { key: index },
                        [
                          _vm._v(
                            "\n        " + _vm._s(value.label) + "\n        "
                          ),
                          _c("gdIcon", {
                            attrs: { name: "close" },
                            nativeOn: {
                              click: function($event) {
                                _vm.removeValue({ value: value })
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                })
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.modalData
            ? _c("gdFormAddRcpdCustomValue", {
                attrs: {
                  activityId:
                    _vm.modalData.mode === "EDIT"
                      ? _vm.modalData.originalActivityId
                      : _vm.modalData.activityId,
                  mode: _vm.modalData.mode,
                  columnId: _vm.modalData.columnId
                },
                on: { submitted: _vm.handleFormSubmit }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }