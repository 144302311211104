<template>
  <gdPaymentAwait/>
</template>

<script>
  import gdPaymentAwait from '@/pages/gd-payment-await'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    async mounted() {
      try {
        await this.$store.dispatch('payments/GET_PAYMENT_STATUS')
        await this.$store.dispatch('user/GET_USER')
        await this.$store.dispatch('registers/GET_REGISTERS')
      } catch(error) {
        console.error(`Couldn't resolve gd-payment-await Mounted requests`, { error })
      }
    },
    components: {
      gdPaymentAwait
    }
  }
</script>
