var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("gdAccordeon", {
    staticClass: "gd-accordeon-recommendations",
    attrs: { items: _vm.items },
    scopedSlots: _vm._u([
      {
        key: "visible-content",
        fn: function(props) {
          return [_vm._v("\n    " + _vm._s(props.item.title) + "\n  ")]
        }
      },
      {
        key: "expandable-content",
        fn: function(props) {
          return [
            props.item.content
              ? _c(
                  "div",
                  {
                    staticClass:
                      "recommendation-description recommendation-description--main"
                  },
                  [
                    _c("gdRichText", [
                      _vm._v(
                        "\n        " + _vm._s(props.item.content) + "\n      "
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            props.item.comment
              ? _c(
                  "div",
                  {
                    staticClass:
                      "recommendation-description recommendation-description--detail"
                  },
                  [
                    _c("gdRichText", [
                      _vm._v(
                        "\n        " + _vm._s(props.item.comment) + "\n      "
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "files-list" },
              _vm._l(props.item.files, function(file, fileIndex) {
                return _c(
                  "li",
                  { key: fileIndex },
                  [
                    props.item.files
                      ? _c(
                          "gdFileLink",
                          {
                            attrs: { file: file, allowRemove: true },
                            on: {
                              fileDeleted: function($event) {
                                _vm.$emit("fileDeleted")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(file.name) + "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ),
            _vm._v(" "),
            props.item.requireAttachment
              ? _c(
                  "gdButton",
                  {
                    attrs: { theme: "secondary" },
                    nativeOn: {
                      click: function($event) {
                        _vm.uploadFile({ recommendation: props.item })
                      }
                    }
                  },
                  [_vm._v("\n      Wgraj plik\n    ")]
                )
              : _vm._e()
          ]
        }
      },
      {
        key: "right",
        fn: function(props) {
          return [
            _c("gdCheckbox", {
              on: {
                input: function($event) {
                  _vm.setRecommendationStatus({ recommendation: props.item })
                }
              },
              model: {
                value: props.item.isCompleted,
                callback: function($$v) {
                  _vm.$set(props.item, "isCompleted", $$v)
                },
                expression: "props.item.isCompleted"
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }