const initialState = () => ({
  services: [
    {
      title: 'Obsługa praw jednostki',
      icon: 'user',
      about: 'Pomagamy obsłużyć wnioski osób, których dane Państwo przetwarzają.',
      buttonLabel: 'Więcej',
      modalTitle: 'Obsługa praw jednostki',
      modalContent: 'Wspieramy w obsłudze wniosków i żądań osób realizujących przysługujące im prawa jednostki.',
      modalBtnLabel: 'Skontaktuj się'
    },
    {
      title: "Wsparcie IOD",
      icon: 'chat',
      about: 'Zapewniamy wsparcie merytoryczne i organizacyjne dla Inspektora Ochrony Danych',
      buttonLabel: 'Więcej',
      modalTitle: "Wdrożenie IOD",
      modalContent: 'Zapewniamy wsparcie merytoryczne i organizacyjne dla funkcjonującego Inspektora Ochrony lub przejmujemy na siebie pełnienie funkcji IOD. Dbamy, abyś na co dzień działał zgodnie z przepisami o ochronie danych osobowych. A wszystko przy wykorzystaniu GDPSystem.',
      modalBtnLabel: 'Skontaktuj się'
    },
    {
      title: "Outsourcing IOD",
      icon: 'docs',
      about: 'Przejmujemy całość obowiązków delegując Inspektora Ochrony Danych Osobowych.',
      buttonLabel: 'Więcej',
      modalTitle: "Outsourcing IOD",
      modalContent: 'Zapewniamy wsparcie merytoryczne i organizacyjne dla funkcjonującego Inspektora Ochrony lub przejmujemy na siebie pełnienie funkcji IOD. Dbamy, abyś na co dzień działał zgodnie z przepisami o ochronie danych osobowych. A wszystko przy wykorzystaniu GDPSystem.',
      modalBtnLabel: 'Skontaktuj się'
    },
    {
      title: 'Wsparcie kryzysowe',
      icon: 'time',
      about: 'W razie incydentu bezpieczeństwa lub kontroli zapewniamy obsługę prawną.',
      buttonLabel: 'Więcej',
      modalTitle: 'Wsparcie kryzysowe',
      modalContent: 'W razie incydentu bezpieczeństwa lub kontroli zapewniamy obsługę i pomoc prawną. Wspieramy Klienta w sytuacji nagłego incydentu wycieku danych poprzez udzielenie wytycznych co do możliwych scenariuszy postępowania.',
      modalBtnLabel: 'Skontaktuj się'
    },
    {
      title: 'SODO',
      icon: 'folder',
      about: 'Dedykowana osoba odpowiedzialna za obszar przetwarzania danych osobowych.',
      buttonLabel: 'Więcej',
      modalTitle: 'SODO',
      modalContent: 'Dla tych, którzy świadomie zarządzają ryzykiem i chcą mieć dedykowaną osobę odpowiedzialną za obszar przetwarzania danych osobowych, czyli Specjalistę Ochrony Danych Osobowych.',
      modalBtnLabel: 'Skontaktuj się'
    },
    {
      title: 'Wsparcie IT & Testy Bezpieczeństwa',
      icon: 'laptop',
      about: 'We współpracy z Naszymi Partnerami oferujemy zdalne wsparcie w zapewnieniu środków bezpieczeństwa oraz w przeprowadzaniu testów penetracyjnych.',
      buttonLabel: 'Więcej',
      modalTitle: 'Wsparcie IT & Testy Bezpieczeństwa',
      modalContent: 'Korzystając ze wsparcia Naszych Partnerów pomogamy zapewnić odpowiednie środki bezpieczeństwa oraz zweryfikować podatności (słabości) Twoich systemów w celu przeciwdziałania potencjalnym atakom.',
      modalBtnLabel: 'Skontaktuj się'
    }
  ]
})

const state = () => (initialState())

const getters = {
  services: state => state.services
}

export default {
  namespaced: true,
  state, getters
}
