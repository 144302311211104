const initialState = () => ({
  regions: [
    'Dolnośląskie',
    'Kujawsko-pomorskie',
    'Lubelskie',
    'Lubuskie',
    'Łódzkie',
    'Małopolskie',
    'Mazowieckie',
    'Opolskie',
    'Podkarpackie',
    'Podlaskie',
    'Pomorskie',
    'Śląskie',
    'Świętokrzyskie',
    'Warmińsko-mazurskie',
    'Wielkopolskie',
    'Zachodniopomorskie'

  ]
})

const state = () => (initialState())

const actions = {}

const mutations = {}

const getters = {
  regions: state => {
    return state.regions.map(item => ({ label: item, value: item }))
  }
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
