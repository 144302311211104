// Mock
const questions = [{
  title: 'Co oznacza RODO?',
  answer: `Przepisy RODO zastępują obowiązującą obecnie ustawę z 29 sierpnia 1997 r. o ochronie danych osobowych.

Ale: w dniu 9 lutego br., po zakończeniu szerokich konsultacji społecznych, opiniowania, a także zorganizowanej konferencji uzgodnieniowej, Ministerstwo Cyfryzacji w dniu 9 lutego br. skierowało na Komitet do Spraw Europejskich Rady Ministrów projekt ustawy o ochronie danych osobowych zapewniającej skuteczne stosowanie w Polsce unijnego rozporządzenia 2016/679 (RODO).

Projekt ustawy ustanawia nowy organ właściwy w sprawie ochrony danych osobowych – Prezesa Urzędu Ochrony Danych Osobowych – oraz reguluje warunki i tryb udzielania certyfikacji i akredytacji, postępowania w sprawie naruszenia przepisów o ochronie danych i odpowiedzialności cywilnej za naruszenie przepisów o ochronie danych osobowych.

Projekt zawiera również propozycja zmian ponad 40 ustaw sektorowych. Zmiany co do zasady pełnią rolę techniczno-legislacyjną.

W polskich przepisach o ochronie danych osobowych znajdzie się także regulacja tego fragmentu zasad ochrony danych osobowych, który nie został uregulowany w RODO, czyli niektórych zasad przetwarzania danych kadrowych.

Więcej o projekcie: https://www.gov.pl/cyfryzacja/projekt-ustawy-o-ochronie-danych-osobowych-skierowany-na-komitet-do-spraw-europejskich-rady-ministrow

Nie ma jednak absolutnie sensu czekać z wdrożeniem RODO na nową ustawę: RODO zawiera już kompletną regulację prawa ochrony danych osobowych i na jej podstawie należy i trzeba się przygotować do stosowania i egzekucji przepisów.`,
  comment: 'Komentarz: Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco'
}, {
  title: 'Co z dotychczasową UOD? (polska Ustawa o ochronie danych osobowych z 29 sierpnia 1997 r.)',
  answer: `Przepisy RODO zastępują obowiązującą obecnie ustawę z 29 sierpnia 1997 r. o ochronie danych osobowych.

Ale: w dniu 9 lutego br., po zakończeniu szerokich konsultacji społecznych, opiniowania, a także zorganizowanej konferencji uzgodnieniowej, Ministerstwo Cyfryzacji w dniu 9 lutego br. skierowało na Komitet do Spraw Europejskich Rady Ministrów projekt ustawy o ochronie danych osobowych zapewniającej skuteczne stosowanie w Polsce unijnego rozporządzenia 2016/679 (RODO).

Projekt ustawy ustanawia nowy organ właściwy w sprawie ochrony danych osobowych – Prezesa Urzędu Ochrony Danych Osobowych – oraz reguluje warunki i tryb udzielania certyfikacji i akredytacji, postępowania w sprawie naruszenia przepisów o ochronie danych i odpowiedzialności cywilnej za naruszenie przepisów o ochronie danych osobowych.

Projekt zawiera również propozycja zmian ponad 40 ustaw sektorowych. Zmiany co do zasady pełnią rolę techniczno-legislacyjną.

W polskich przepisach o ochronie danych osobowych znajdzie się także regulacja tego fragmentu zasad ochrony danych osobowych, który nie został uregulowany w RODO, czyli niektórych zasad przetwarzania danych kadrowych.

Więcej o projekcie: https://www.gov.pl/cyfryzacja/projekt-ustawy-o-ochronie-danych-osobowych-skierowany-na-komitet-do-spraw-europejskich-rady-ministrow

Nie ma jednak absolutnie sensu czekać z wdrożeniem RODO na nową ustawę: RODO zawiera już kompletną regulację prawa ochrony danych osobowych i na jej podstawie należy i trzeba się przygotować do stosowania i egzekucji przepisów.`
}, {
  title: 'Co stanie się z GIODO? (Generalny Inspektor Danych Osobowych)',
  answer: `Przepisy RODO zastępują obowiązującą obecnie ustawę z 29 sierpnia 1997 r. o ochronie danych osobowych.

Ale: w dniu 9 lutego br., po zakończeniu szerokich konsultacji społecznych, opiniowania, a także zorganizowanej konferencji uzgodnieniowej, Ministerstwo Cyfryzacji w dniu 9 lutego br. skierowało na Komitet do Spraw Europejskich Rady Ministrów projekt ustawy o ochronie danych osobowych zapewniającej skuteczne stosowanie w Polsce unijnego rozporządzenia 2016/679 (RODO).

Projekt ustawy ustanawia nowy organ właściwy w sprawie ochrony danych osobowych – Prezesa Urzędu Ochrony Danych Osobowych – oraz reguluje warunki i tryb udzielania certyfikacji i akredytacji, postępowania w sprawie naruszenia przepisów o ochronie danych i odpowiedzialności cywilnej za naruszenie przepisów o ochronie danych osobowych.

Projekt zawiera również propozycja zmian ponad 40 ustaw sektorowych. Zmiany co do zasady pełnią rolę techniczno-legislacyjną.

W polskich przepisach o ochronie danych osobowych znajdzie się także regulacja tego fragmentu zasad ochrony danych osobowych, który nie został uregulowany w RODO, czyli niektórych zasad przetwarzania danych kadrowych.

Więcej o projekcie: https://www.gov.pl/cyfryzacja/projekt-ustawy-o-ochronie-danych-osobowych-skierowany-na-komitet-do-spraw-europejskich-rady-ministrow

Nie ma jednak absolutnie sensu czekać z wdrożeniem RODO na nową ustawę: RODO zawiera już kompletną regulację prawa ochrony danych osobowych i na jej podstawie należy i trzeba się przygotować do stosowania i egzekucji przepisów.`
}, {
  title: 'Czy podlegam RODO?',
  answer: `Przepisy RODO zastępują obowiązującą obecnie ustawę z 29 sierpnia 1997 r. o ochronie danych osobowych.

Ale: w dniu 9 lutego br., po zakończeniu szerokich konsultacji społecznych, opiniowania, a także zorganizowanej konferencji uzgodnieniowej, Ministerstwo Cyfryzacji w dniu 9 lutego br. skierowało na Komitet do Spraw Europejskich Rady Ministrów projekt ustawy o ochronie danych osobowych zapewniającej skuteczne stosowanie w Polsce unijnego rozporządzenia 2016/679 (RODO).

Projekt ustawy ustanawia nowy organ właściwy w sprawie ochrony danych osobowych – Prezesa Urzędu Ochrony Danych Osobowych – oraz reguluje warunki i tryb udzielania certyfikacji i akredytacji, postępowania w sprawie naruszenia przepisów o ochronie danych i odpowiedzialności cywilnej za naruszenie przepisów o ochronie danych osobowych.

Projekt zawiera również propozycja zmian ponad 40 ustaw sektorowych. Zmiany co do zasady pełnią rolę techniczno-legislacyjną.

W polskich przepisach o ochronie danych osobowych znajdzie się także regulacja tego fragmentu zasad ochrony danych osobowych, który nie został uregulowany w RODO, czyli niektórych zasad przetwarzania danych kadrowych.

Więcej o projekcie: https://www.gov.pl/cyfryzacja/projekt-ustawy-o-ochronie-danych-osobowych-skierowany-na-komitet-do-spraw-europejskich-rady-ministrow

Nie ma jednak absolutnie sensu czekać z wdrożeniem RODO na nową ustawę: RODO zawiera już kompletną regulację prawa ochrony danych osobowych i na jej podstawie należy i trzeba się przygotować do stosowania i egzekucji przepisów.`
}, {
  title: 'Co to są dane osobowe?',
  answer: `Przepisy RODO zastępują obowiązującą obecnie ustawę z 29 sierpnia 1997 r. o ochronie danych osobowych.

Ale: w dniu 9 lutego br., po zakończeniu szerokich konsultacji społecznych, opiniowania, a także zorganizowanej konferencji uzgodnieniowej, Ministerstwo Cyfryzacji w dniu 9 lutego br. skierowało na Komitet do Spraw Europejskich Rady Ministrów projekt ustawy o ochronie danych osobowych zapewniającej skuteczne stosowanie w Polsce unijnego rozporządzenia 2016/679 (RODO).

Projekt ustawy ustanawia nowy organ właściwy w sprawie ochrony danych osobowych – Prezesa Urzędu Ochrony Danych Osobowych – oraz reguluje warunki i tryb udzielania certyfikacji i akredytacji, postępowania w sprawie naruszenia przepisów o ochronie danych i odpowiedzialności cywilnej za naruszenie przepisów o ochronie danych osobowych.

Projekt zawiera również propozycja zmian ponad 40 ustaw sektorowych. Zmiany co do zasady pełnią rolę techniczno-legislacyjną.

W polskich przepisach o ochronie danych osobowych znajdzie się także regulacja tego fragmentu zasad ochrony danych osobowych, który nie został uregulowany w RODO, czyli niektórych zasad przetwarzania danych kadrowych.

Więcej o projekcie: https://www.gov.pl/cyfryzacja/projekt-ustawy-o-ochronie-danych-osobowych-skierowany-na-komitet-do-spraw-europejskich-rady-ministrow

Nie ma jednak absolutnie sensu czekać z wdrożeniem RODO na nową ustawę: RODO zawiera już kompletną regulację prawa ochrony danych osobowych i na jej podstawie należy i trzeba się przygotować do stosowania i egzekucji przepisów.`
}]
//

const initialState = () => ({
  questions: null
})

const state = () => (initialState())

const actions = {
  async GET_FAQ({ commit, getters }){
    try {
      await console.log('help/GET_FAQ')
      commit('SET_QUESTIONS', { questions })
      commit
    } catch(error){
      console.error('help/GET_FAQ', { error })
      throw error
    }
  }
}

const mutations = {
  SET_QUESTIONS(state, { questions }){
    state.questions = questions
  }
}

const getters = {
  questions: state => state.questions
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
