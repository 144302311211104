<template>
  <div class="gd-page-header">
    <div class="gd-page-header__info">
      <h1 class="info__heading">{{ title }}</h1>
      <p
        v-if="description"
        class="info__sub-heading"
      >
        {{ description }}
      </p>
    </div>
    <div class="gd-page-header__actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      description: String
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/fonts.scss";
  @import "./src/assets/styles/vars/colors.scss";

  .gd-page-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    &__actions {
      display: flex;
      justify-content: flex-end;
      width: 40%;

      .gd-button-wrapper:not(:first-child) {
        margin-left: 10px;
      }
    }

    .info__heading {
      margin-bottom: 5px;
      font-size: 28px;
      font-family: $font-medium;
    }

    .info__sub-heading {
      font-size: 16px;
      line-height: 20px;
      color: $color-gray-light;
    }
  }
</style>
