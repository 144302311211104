const initialState = () => ({
  navItems: []
})

const state = () => (initialState())

const actions = {}

const mutations = {}

const getters = {
  navItems(state, getters, rootState, rootGetters) {

    const registers = (rootGetters['registers/registersCollection'] || [])
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map(register => {

        return {
          label: register.name,
          iconName: 'sheet',
          to: {
            name: 'app.register-single',
            params: { id: register.id }
          }
        }
      })

    const navItems = [
      {
        label: 'Panel Startowy',
        iconName: 'dashboard',
        to: { name: 'app.dashboard' }
      },
      {
        label: 'Rejestr Czynności Przetwarzania',
        iconName: 'table',
        to: { name: 'app.activity-register' }
      },
      ...registers,
      {
        label: 'Uproszczona Analiza Ryzyka',
        iconName: 'table',
        to: { name: 'app.simplified-risk-analysis' }
      },
      {
        label: 'Risk Tracker',
        iconName: 'table',
        to: { name: 'app.risk-tracker' }
      },
      {
        label: 'Certyfikat',
        iconName: 'certificate',
        to: { name: 'app.certificate' }
      },
      {
        label: 'Raport Zaleceń',
        iconName: 'list',
        to: { name: 'app.recommendations-report' }
      },
      {
        label: 'Raport Zgodności',
        iconName: 'docs',
        to: { name: 'app.report-of-compatibility' }
      },
      {
        label: 'Dokumenty',
        iconName: 'folder',
        to: { name: 'app.documents' }
      },
      // {
      //   label: 'Usługi',
      //   iconName: 'subcase',
      //   to: { name: 'app.services' }
      // },
      // {
      //   label: 'Ubezpieczenia',
      //   iconName: 'subcase',
      //   to: { name: 'app.insurances' }
      // },
      // {
      //   label: 'Szkolenia',
      //   iconName: 'student',
      //   to: { name: 'app.courses' }
      // },
      // {
      //   label: 'Pomoc',
      //   iconName: 'question',
      //   to: { name: 'app.help' }
      // }
    ]

    const disabledRoutes = rootGetters['user/disabledRoutes']

    return navItems.map(item => {
      const disabled = disabledRoutes[item.to.name]

      if(typeof disabled === 'function' && (item.to.params && typeof item.to.params.id !== 'undefined')){
        return Object.assign({ disabled: disabled({ id: item.to.params.id }) }, item)
      }

      return Object.assign({ disabled }, item)
    })
  }
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
