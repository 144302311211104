var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-activity-edit-row" },
    [
      _vm.activity
        ? _c(
            "gdTableRow",
            { staticClass: "gd-table-row--edit" },
            [
              _c("gdTableCell", [
                _vm._v("\n      " + _vm._s(_vm.activity.name) + "\n    ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cells-overflow" },
                [
                  _vm._l(_vm.headers, function(cell, cellIndex) {
                    return _c(
                      "gdTableCell",
                      { key: cellIndex },
                      [
                        _c("pre", { attrs: { hidden: "" } }, [
                          _vm._v(_vm._s(_vm.activity))
                        ]),
                        _vm._v(" "),
                        _vm.activity.columns[cell.id]
                          ? [
                              cell.type === "list" &&
                              _vm.activity.columns[cell.id].availableValues
                                ? _c("gdSelect", {
                                    attrs: {
                                      name: "activity-edit-" + cellIndex,
                                      items:
                                        _vm.activity.columns[cell.id]
                                          .availableValues,
                                      multiselect:
                                        _vm.activity.columns[cell.id]
                                          .isMultiselect,
                                      required:
                                        _vm.activity.columns[cell.id]
                                          .isMultiselect,
                                      tabindex: "-1"
                                    },
                                    on: {
                                      "error-forbidden-close": _vm.lockSave,
                                      onCustomClick: function() {
                                        return _vm.handleCustomClick({
                                          value:
                                            _vm.activity.columns[cell.id]
                                              .currentValue,
                                          items:
                                            _vm.activity.columns[cell.id]
                                              .availableValues,
                                          columnId: cell.id
                                        })
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.activity.columns[cell.id]
                                          .currentValue,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.activity.columns[cell.id],
                                          "currentValue",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "activity.columns[cell.id].currentValue"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.activity.columns[cell.id].type === "input"
                                ? _c("gdInput", {
                                    attrs: {
                                      name: "activity-edit-" + cellIndex,
                                      tabindex: "-1"
                                    },
                                    model: {
                                      value:
                                        _vm.activity.columns[cell.id]
                                          .currentValue.value,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.activity.columns[cell.id]
                                            .currentValue,
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "activity.columns[cell.id].currentValue.value"
                                    }
                                  })
                                : _vm._e()
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  _vm._v(" "),
                  _c("gdTableCell")
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "gdTableRow",
        { staticClass: "gd-table-row--actions" },
        [
          _c(
            "gdButton",
            {
              attrs: { loading: _vm.isSaving, disabled: !_vm.allowToSave },
              nativeOn: {
                click: function($event) {
                  return _vm.updateActivity($event)
                }
              }
            },
            [_vm._v("\n      Zapisz czynność\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdButton",
            {
              attrs: { theme: "ghost" },
              nativeOn: {
                click: function($event) {
                  return _vm.closeActivityEdit($event)
                }
              }
            },
            [_vm._v("\n      Anuluj zmiany\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdButton",
            {
              attrs: { theme: "warning", loading: _vm.isRemoving },
              nativeOn: {
                click: function($event) {
                  return _vm.removeActivity($event)
                }
              }
            },
            [_vm._v("\n      Usuń czynność\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }