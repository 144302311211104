<template>
  <div class="gd-account-divider">
    <div class="gd-account-divider__info">
      <h2 class="info__heading">{{ title }}</h2>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: String
    }
  };
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/fonts.scss";
  @import "./src/assets/styles/vars/colors.scss";

  .gd-account-divider {
    display: flex;
    justify-content: space-between;
    margin: 65px 0 15px;

    .info__heading {
      margin-bottom: 5px;
      font-size: 28px;
      font-family: $font-medium;
    }
  }
</style>
