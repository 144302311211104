var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gdBrick",
    { staticClass: "gd-brick-certificate-download" },
    [
      _c("gdIcon", {
        staticClass: "certificate-download__icon",
        attrs: { name: "docs", large: true }
      }),
      _vm._v(" "),
      _c("h1", { staticClass: "certificate-download__title" }, [
        _vm._v("Pobierz certyfikat")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "certificate-download__description" }, [
        _vm._v(
          "Certyfikat potwierdza spełnianie standardów Good Data Protection Standard."
        )
      ]),
      _vm._v(" "),
      _c(
        "figure",
        { staticClass: "certificate-download__image" },
        [
          _c("gdImg", {
            attrs: {
              srcset:
                "/assets/images/certyfikat-hp.png 1x, /assets/images/certyfikat-hp@2x.png 2x"
            }
          }),
          _vm._v(" "),
          _c("p", { staticClass: "certificate-download__name" }, [
            _vm._v(_vm._s(_vm.companyName))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "certificate-download__date" }, [
            _vm._v(_vm._s(_vm.certificateDate))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "gdButton",
        { attrs: { link: { name: "app.certificate" }, theme: "secondary" } },
        [_vm._v("\n    Pobierz certyfikat\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }