var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gd-bricks-wrapper" }, [
    _c("div", { staticClass: "gd-bricks-wrapper__inner" }, [
      _c("div", { staticClass: "col col--wide" }, [_vm._t("wide")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [_vm._t("thin")], 2)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }