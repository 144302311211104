<template>
  <section class="gd-footer-app section--wide">
    <div class="gd-footer-app__inner">
      <div class="gd-footer-app__info info-hero">
        <span>Potrzebujesz pomocy?</span>
        <a href="tel:178700350‬">Telefon 791 764 754</a>
      </div>
      <div class="gd-footer-app__info gd-footer-app__info--hero">
        <span>Nasz dział Wsparcia i Obsługi Klienta jest do Twojej dyspozycji od poniedziałku do piatku w godzinach od 8:30 do 17:00</span>
        <a href="mailto:kontakt@gdpsystem.pl">kontakt@gdpsystem.pl</a>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-footer-app {
    margin-top: auto;

    &__inner {
      padding-top: 20px;
      border-top: 1px solid rgba($color-black, .1);
      margin-top: 50px;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      color: rgba($color-dark, .6);

      &--hero {
        margin-top: 8px;
        font-size: 16px;
        color: rgba($color-gray-light, .6);
      }
    }
  }
</style>
