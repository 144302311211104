<template>
  <gdError />
</template>
<script>
  import gdError from '@/pages/app/gd-error'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    components: {
      gdError
    }
  }
</script>
