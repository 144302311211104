var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gd-account-divider" }, [
    _c("div", { staticClass: "gd-account-divider__info" }, [
      _c("h2", { staticClass: "info__heading" }, [_vm._v(_vm._s(_vm.title))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }