<template>
  <gdDataProcessing/>
</template>

<script>
  import gdDataProcessing from '@/pages/gd-data-processing'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    components: {
      gdDataProcessing
    }
  }
</script>
