<template>
  <section class="gd-error">
    <gdPageHeader
      title="Błąd"
      :description="errorMessage"
    />
  </section>
</template>
<script>
  import gdPageHeader from '@/components/page/gd-page-header'

  export default {

    computed: {
      errorType(){
        return this.$route.query.type
      },
      errorMessage(){
        switch(this.errorType){
        case 'NOT_ALLOWED':
          return 'Moduł będzie dostepny po uzupełnieniu niezbednych danych.'
        default:
          return 'Coś poszło nie tak. Skontaktuj się z administratorem serwisu.'
        }
      }
    },

    components: {
      gdPageHeader
    }
  }
</script>
<style lang="scss">

</style>
