import { formatQuestionType } from '@/helpers'

export default function formatRodoStep(step){
  if(!step){
    return
  }

  let mappedStep = {
    activeQuestionIndex: null
  }

  if(step.questions){

    mappedStep.questions = step.questions
      .map((item, index) => {

        if(item.active){
          mappedStep.activeQuestionIndex = index
        }

        const question = Object.assign({}, item)

        question.answers = (question.answers || []).slice()
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map(singleAnswer => {
            return { label: singleAnswer.answer, value: singleAnswer.id }
          })

        question.questionType = formatQuestionType({ questionType: question.questionType })

        return question
      })
  }

  const result = Object.assign({}, step, mappedStep)
  return result
}
