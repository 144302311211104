var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-risk-tracker" },
    [
      _c("gdPageHeader", { attrs: { title: "Risk Tracker" } }),
      _vm._v(" "),
      _c(
        "gdBricksWrapper",
        [
          _vm.allowRegister
            ? _c("gdBrick", { attrs: { slot: "wide" }, slot: "wide" }, [
                _c("p", { staticClass: "brick__content" }, [
                  _vm._v(
                    "Do założenia konta w Risk Tracker zostanie użyty Twój email " +
                      _vm._s(this.user.email) +
                      " oraz hasło pdane poniżej."
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "brick__content" }, [
                  _vm._v(
                    "\n        Hasło musi mieć co najmniej 8 znaków,\n        co najmniej jedną dużą literę,\n        co najmniej jedną małą literę,\n        co najmniej jeden znak specjalny.\n      "
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.allowRegister
            ? _c(
                "gdBrick",
                { attrs: { slot: "wide" }, slot: "wide" },
                [_c("gdFormRtPasswordSet")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.allowRegister
            ? _c(
                "gdBrick",
                { attrs: { slot: "wide" }, slot: "wide" },
                [
                  _c("p", {
                    staticClass: "brick__content",
                    domProps: { innerHTML: _vm._s(_vm.headerMessage) }
                  }),
                  _vm._v(" "),
                  _vm.user.company.rtIntegrated
                    ? _c("gdButton", { attrs: { link: _vm.rtUrl } }, [
                        _vm._v("\n        Przejdź do Risk Tracker\n      ")
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }