var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gd-form-sign-up gd-brick" }, [
    _c("div", { staticClass: "gd-brick__inner" }, [
      _c("header", { staticClass: "gd-form-sign-up__plan-header" }, [
        _c(
          "div",
          { staticClass: "plan-header__icon" },
          [_c("gdIcon", { attrs: { name: "user" } })],
          1
        ),
        _vm._v(" "),
        _vm.paymentPlans[_vm.planIndex]
          ? _c("h1", { staticClass: "plan-header__title" }, [
              _vm._v(
                "\n        Rejestracja Klient " +
                  _vm._s(_vm.paymentPlans[_vm.planIndex].name) +
                  "\n      "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.paymentPlans[_vm.planIndex]
          ? _c("p", { staticClass: "plan-header__price" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.paymentPlans[_vm.planIndex].price) +
                  " "
              ),
              _c("span", [_vm._v("/ rok")])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "gd-form",
          attrs: { novalidate: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit($event)
            }
          }
        },
        [
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: { name: "firstName", "data-vv-as": "imię" },
              model: {
                value: _vm.form.firstName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "firstName", $$v)
                },
                expression: "form.firstName"
              }
            },
            [_vm._v("\n        Imię\n      ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: { name: "lastName", "data-vv-as": "nazwisko" },
              model: {
                value: _vm.form.lastName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "lastName", $$v)
                },
                expression: "form.lastName"
              }
            },
            [_vm._v("\n        Nazwisko\n      ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'"
                }
              ],
              attrs: { name: "email", "data-vv-as": "email" },
              model: {
                value: _vm.form.email,
                callback: function($$v) {
                  _vm.$set(_vm.form, "email", $$v)
                },
                expression: "form.email"
              }
            },
            [_vm._v("\n        Email\n      ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|phoneNumber",
                  expression: "'required|phoneNumber'"
                }
              ],
              attrs: { name: "phone", "data-vv-as": "telefon" },
              model: {
                value: _vm.form.phone,
                callback: function($$v) {
                  _vm.$set(_vm.form, "phone", $$v)
                },
                expression: "form.phone"
              }
            },
            [_vm._v("\n        Telefon\n      ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|passEnoughLength",
                  expression: "'required|passEnoughLength'"
                }
              ],
              ref: "password",
              attrs: {
                name: "password",
                type: "password",
                "data-vv-as": "hasło"
              },
              model: {
                value: _vm.form.password,
                callback: function($$v) {
                  _vm.$set(_vm.form, "password", $$v)
                },
                expression: "form.password"
              }
            },
            [_vm._v("\n        Hasło\n      ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|matchPasswords:password",
                  expression: "'required|matchPasswords:password'"
                }
              ],
              attrs: {
                name: "passwordRepeat",
                type: "password",
                "data-vv-as": "powtórz hasło"
              },
              model: {
                value: _vm.form.passwordRepeat,
                callback: function($$v) {
                  _vm.$set(_vm.form, "passwordRepeat", $$v)
                },
                expression: "form.passwordRepeat"
              }
            },
            [_vm._v("\n        Powtórz hasło\n      ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: { name: "companyName", "data-vv-as": "z nazwą firmy" },
              model: {
                value: _vm.form.company.name,
                callback: function($$v) {
                  _vm.$set(_vm.form.company, "name", $$v)
                },
                expression: "form.company.name"
              }
            },
            [_vm._v("\n        Pełna nazwa firmy\n      ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    required: true,
                    regex: /^\d+$/
                  },
                  expression:
                    "{\n          required: true,\n          regex: /^\\d+$/\n        }"
                }
              ],
              attrs: {
                name: "companyNip",
                placeholder: "Dokładnie 10 cyfr",
                "data-vv-as": "z NIPem"
              },
              model: {
                value: _vm.form.company.nip,
                callback: function($$v) {
                  _vm.$set(_vm.form.company, "nip", $$v)
                },
                expression: "form.company.nip"
              }
            },
            [_vm._v("\n        NIP\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gd-input" },
            [
              _c("span", { staticClass: "gd-input__label" }, [
                _vm._v("Województwo")
              ]),
              _vm._v(" "),
              _c("gdSelect", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                attrs: {
                  placeholder: "Wybierz z listy...",
                  name: "companyProvince",
                  items: _vm.regions,
                  "data-vv-as": "województwo"
                },
                model: {
                  value: _vm.form.company.province,
                  callback: function($$v) {
                    _vm.$set(_vm.form.company, "province", $$v)
                  },
                  expression: "form.company.province"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|onlyLetters",
                  expression: "'required|onlyLetters'"
                }
              ],
              attrs: { name: "companyCity", "data-vv-as": "miejscowość" },
              model: {
                value: _vm.form.company.city,
                callback: function($$v) {
                  _vm.$set(_vm.form.company, "city", $$v)
                },
                expression: "form.company.city"
              }
            },
            [_vm._v("\n        Miejscowość\n      ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|atLeastOneNumber|atLeastOneLetter",
                  expression: "'required|atLeastOneNumber|atLeastOneLetter'"
                }
              ],
              attrs: { name: "companyAddress", "data-vv-as": "adres" },
              model: {
                value: _vm.form.company.address,
                callback: function($$v) {
                  _vm.$set(_vm.form.company, "address", $$v)
                },
                expression: "form.company.address"
              }
            },
            [_vm._v("\n        Adres\n      ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    required: true,
                    regex: /[0-9]/
                  },
                  expression:
                    "{\n          required: true,\n          regex: /[0-9]/\n        }"
                }
              ],
              attrs: {
                name: "companyZipCode",
                maxLength: true,
                maxLengthValue: 6,
                "data-vv-as": "z kodem pocztowym"
              },
              nativeOn: {
                input: function($event) {
                  _vm.zipCode(_vm.form.company.zipcode)
                }
              },
              model: {
                value: _vm.form.company.zipcode,
                callback: function($$v) {
                  _vm.$set(_vm.form.company, "zipcode", $$v)
                },
                expression: "form.company.zipcode"
              }
            },
            [_vm._v("\n        Kod pocztowy\n      ")]
          ),
          _vm._v(" "),
          _c(
            "gdCheckbox",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "no-selection",
              attrs: {
                name: "legalAccept",
                "data-vv-as": "z akceptacją regulaminu"
              },
              model: {
                value: _vm.form.legalAccept,
                callback: function($$v) {
                  _vm.$set(_vm.form, "legalAccept", $$v)
                },
                expression: "form.legalAccept"
              }
            },
            [
              _c("p", [
                _vm._v("Oświadczem, że zapoznałem się z treścią\n          "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/assets/regulations/regulamin_korzystania_z_gdpsystem.pdf",
                      target: "_blank"
                    }
                  },
                  [_vm._v("\n            Regulaminu\n          ")]
                ),
                _vm._v(
                  ",\n          akceptuję jego treść oraz zobowiązuję się do jego przestrzegania.\n        "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "gdCheckbox",
            {
              staticClass: "no-selection",
              attrs: { name: "legalAcceptEmail" },
              model: {
                value: _vm.form.legalAcceptEmail,
                callback: function($$v) {
                  _vm.$set(_vm.form, "legalAcceptEmail", $$v)
                },
                expression: "form.legalAcceptEmail"
              }
            },
            [
              _vm._v("\n        Wyrażam zgodę na otrzymywanie "),
              _c("span", { staticClass: "bold" }, [_vm._v("emailem")]),
              _vm._v(
                " od Good Data Protection Standard Sp. z o.o. z siedzibą w Warszawie marketingowych informacji handlowych.\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "gdCheckbox",
            {
              staticClass: "no-selection",
              attrs: { name: "legalAcceptSms" },
              model: {
                value: _vm.form.legalAcceptSms,
                callback: function($$v) {
                  _vm.$set(_vm.form, "legalAcceptSms", $$v)
                },
                expression: "form.legalAcceptSms"
              }
            },
            [
              _vm._v("\n        Wyrażam zgodę na otrzymywanie "),
              _c("span", { staticClass: "bold" }, [_vm._v("SMSem")]),
              _vm._v(
                " od Good Data Protection Standard Sp. z o.o. z siedzibą w Warszawie marketingowych informacji handlowych.\n      "
              )
            ]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gd-form-sign-up__submit" },
            [
              _c(
                "gdLink",
                { attrs: { to: _vm.landingUrl } },
                [_c("gdIcon", { attrs: { name: "arrow" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "gdButton",
                { attrs: { type: "submit", loading: _vm.loading } },
                [_vm._v("\n          Zarejestruj i opłać\n        ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gd-form-sign-up__pay-u" },
        [
          _c("p", { staticClass: "pay-u__info" }, [
            _vm._v("Nad bezpieczeństwem płatności czuwa")
          ]),
          _vm._v(" "),
          _c("gdImg", { attrs: { src: "/assets/images/pay_u_logo.svg" } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "gd-checkbox__label label--alone" }, [
      _vm._v(
        "\n        Administratorem Twoich danych osobowych będzie Good Data Protection Standard Sp. z o.o. W\n        "
      ),
      _c("a", { attrs: { href: "/przetwarzanie-danych", target: "_blank" } }, [
        _vm._v("\n          polityce prywatności\n        ")
      ]),
      _vm._v(
        "\n        dowiesz się jak będziemy przetwarzać Twoje dane.\n      "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "gd-checkbox__label label--alone" }, [
      _vm._v("\n        Klauzula informacyjna, czyli\n        "),
      _c("a", { attrs: { href: "/przetwarzanie-danych", target: "_blank" } }, [
        _vm._v("\n          jak przetwarzamy\n        ")
      ]),
      _vm._v("\n        Twoje dane.\n      ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }