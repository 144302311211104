<template>
  <section class="gd-risk-tracker">
    <gdPageHeader
      title="Risk Tracker"
    />
    <gdBricksWrapper>
      <gdBrick 
        slot="wide" 
        v-if="allowRegister"
      >
        <p class="brick__content">Do założenia konta w Risk Tracker zostanie użyty Twój email {{ this.user.email }} oraz hasło pdane poniżej.</p>
        <p class="brick__content">
          Hasło musi mieć co najmniej 8 znaków,
          co najmniej jedną dużą literę,
          co najmniej jedną małą literę,
          co najmniej jeden znak specjalny.
        </p>
      </gdBrick>
      <gdBrick 
        slot="wide" 
        v-if="allowRegister"
      >
        <gdFormRtPasswordSet />
      </gdBrick>
      <gdBrick 
        slot="wide" 
        v-if="!allowRegister"
      >
        <p 
          class="brick__content" 
          v-html="headerMessage"
        /> 
        <gdButton 
          v-if="user.company.rtIntegrated"
          :link="rtUrl"
        >
          Przejdź do Risk Tracker
        </gdButton>
      </gdBrick>
    </gdBricksWrapper>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import gdPageHeader from '@/components/page/gd-page-header'
  import gdFormRtPasswordSet from '@/components/forms/gd-form-rt-password-set'
  import gdBricksWrapper from '@/components/bricks/gd-bricks-wrapper'
  import gdBrick from "@/components/bricks/gd-brick";

  export default {
    components: {
      gdPageHeader,
      gdFormRtPasswordSet,
      gdBricksWrapper,
      gdBrick
    },

    data () {
      return {
        loading: false
      }
    },

    computed: {
      ...mapGetters("user", ["user", "companysUsers"]),
      ...mapGetters("riskTracker", ["rtUrl"]),
      allowRegister() {
        return this.user.isAdmin && !this.user.company.rtIntegrated
      },
      registeredAdmin() {
        return this.user.isAdmin && this.user.company.rtIntegrated
      },
      registeredSubuser() {
        return !this.user.isAdmin
      },
      headerMessage() {
        if (this.registeredAdmin) {
          return 'Przejdź do Risk Tracker i zaloguj się używając swojego adresu email i podanego wcześniej hasła.'
        }

        if (this.registeredSubuser) {
          return 'Zapytaj swojego administratora firmy o dane dostępu do Risk Tracker. Twoim administratorem jest: ' + this.companyAdministratorEmail
        }
      },
      companyAdministrator() {
        if ( !this.companysUsers ) {
          return null
        }

        let admin = this.companysUsers.find(item => 'Admin' === item.role) 

        if ( admin ) {
          return admin
        }

        return null
      },
      companyAdministratorEmail() {
        if ( !this.companyAdministrator ) {
          return ''
        }

        return this.companyAdministrator.username
      }
    },

    methods: {},

    beforeDestroy () {
      //this.$store.commit('simplifiedRiskAnalysis/RESET_STATE')
    }
  }
</script>

<style lang="scss">
  .gd-risk-tracker {
    width: 100%;
    
    form {
      width: 100%;
      text-align: left;
    }

    .gd-brick {
      min-height: 200px;
      
      &__inner {
        text-align: left;
        min-height: 200px;
      }

      .brick {
        &__title {
          width: 100%;
          margin: 30px 0 9px;
          font-size: 20px;
        }

        &__content {
          width: 100%;
          margin-bottom: auto;
          font-size: 16px;
          line-height: 20px;
          color: #737373;
          text-align: center;
        }
      }
    }
  }
</style>
