var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gdBrick",
    { class: _vm.rodoStepClass },
    [
      _c("gdRodoStepHeader", {
        attrs: {
          title:
            "Stwórz profil" +
            (!_vm.step1.questionsAnswered && _vm.step1.questions
              ? " - pytanie " +
                (_vm.step1.activeQuestionIndex + 1) +
                " z " +
                _vm.step1.questions.length
              : ""),
          completedTitle: "Profil utworzony",
          iconName: "user",
          active: _vm.step1.active,
          completed: _vm.step1.completed,
          resettable: _vm.step1.active && !_vm.step1.completed,
          rollbackable: _vm.step1.active && !_vm.step1.completed
        }
      }),
      _vm._v(" "),
      _c(
        "gdGrowTransition",
        [
          _vm.step1.active
            ? _c("gdGrowTransitionContent", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return _vm.step1.active
                        ? [
                            _c(
                              "section",
                              { staticClass: "gd-rodo-step__content" },
                              [
                                _vm.step1.questions
                                  ? _c("gdRodoStepQuestions", {
                                      attrs: {
                                        items: _vm.step1.questions,
                                        questionsAnswered:
                                          _vm.step1.questionsAnswered,
                                        questionsAnsweredMessage:
                                          "Dziękujemy za udzielenie odpowiedzi, przygotowaliśmy dla Ciebie Rejestr Czynności do wypełnienia.",
                                        stepId: _vm.stepId
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "section",
                                  {
                                    staticClass: "gd-rodo-step__content-extra"
                                  },
                                  [
                                    _vm.step1.activeQuestionIndex > 0
                                      ? _c(
                                          "gdButton",
                                          {
                                            attrs: { theme: "ghost" },
                                            nativeOn: {
                                              click: function($event) {
                                                _vm.previousQuestion()
                                              }
                                            }
                                          },
                                          [
                                            _c("gdImg", {
                                              attrs: {
                                                src:
                                                  "/assets/icons/icon-arrow-purple.svg"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : undefined
                    }
                  }
                ])
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }