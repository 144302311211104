<template>
  <section class="gd-log-in">
    <gdLink :to="landingUrl">
      <gdImg src="/assets/images/logo.svg" />
    </gdLink>
    <gdFormLogIn/>
    <p class="gd-log-in__info"><gdLink :to="{ name: 'password-reset' }">Nie pamiętasz hasła?</gdLink></p>
    <p class="gd-log-in__info">Jeśli nie posiadasz konta,
      <gdLink
        class="gd-log-in__link"
        :to="landingUrl"
      >
        zarejestruj się.
      </gdLink>
    </p>
  </section>
</template>

<script>
  import gdpsComponents from 'gdps-components'
  import gdFormLogIn from '@/components/forms/gd-form-log-in'

  export default {

    computed: {
      landingUrl(){
        return gdpsComponents.helpers.gdpsUrls({ environment: process.env.ENVIRONMENT }).LANDING
      }
    },

    components: {
      gdFormLogIn
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-log-in {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 490px;
    padding: 40px 40px 45px;
    border: 1px solid $color-brick-border;
    border-radius: 3px;
    margin: 160px auto 0;
    background-color: $color-white;
    box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);

    &__info {
      margin-top: 40px;
      text-align: center;

      span {
        color: $color-blue;
        cursor: pointer;
      }
    }

    .gd-image {
      margin-bottom: 30px;
    }
  }
</style>
