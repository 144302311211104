<template>
  <div class="gd-brick-logout">
    <gdBrick
      :thin="thin"
      :disabled="false"
    >
      <gdButton
        @click.native="logOut"
      >
        Wyloguj
      </gdButton>
      <p
        class="gd-brick-logout__delete-account"
        @click="handleWarningModal"
      >
        Usuń konto
      </p>
    </gdBrick>
    <!-- MODAL -->
    <gdModalWarning
      title="Uwaga!"
      content='Kliknięcie "usuń konto" jako administrator usuwa <b>wszystkie sub konta firmy, firmę</b> i konto admina.'
      buttonLabel="Usuń konto"
      @buttonClick="deleteAccount"
    />
  </div>
</template>

<script>
  import gdBrick from '@/components/bricks/gd-brick'

  export default {
    props: {
      thin: Boolean
    },

    methods: {
      logOut(){
        this.$store.dispatch('user/LOG_OUT')
        this.$router.push({ name: 'login'})
      },

      handleWarningModal() {
        this.$store.commit('modals/SHOW_MODAL', { modalName: 'warning'})
      },

      async deleteAccount() {
        try {
          await this.$store.dispatch('user/DELETE_ACCOUNT')
          window.location = '/logowanie'
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error})
        }
      }
    },

    components: {
      gdBrick
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-brick-logout {
    &__delete-account {
      margin-top: 40px;
      font-size: 16px;
      color: $color-red;
      cursor: pointer;
    }

    .gd-brick {
      min-height: unset;

      &__inner {
        min-height: unset;
        padding: 47px;
      }
    }

    .gd-btn {
      min-width: 180px;
    }
  }
</style>
