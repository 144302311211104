<template>
  <form
    class="gd-form gd-form-user-password-change"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <div class="gd-form__section">
      <gdInput
        name="user-password"
        v-model="password.old"
        type="password"
        data-vv-as="obecne hasło"
        v-validate="'required'"
      >
        Obecne hasło
      </gdInput>
    </div>
    <div class="gd-form__section">
      <gdInput
        name="user-new-password"
        v-model="password.new"
        data-vv-as="nowe hasło"
        type="password"
        ref="password"
        v-validate="'required|passEnoughLength'"
      >
        Nowe hasło
      </gdInput>
      <gdInput
        name="user-new-password-repeat"
        v-model="password.newRepeat"
        data-vv-as="potwierdź nowe hasło"
        v-validate="'required|matchPasswords:password|passEnoughLength'"
        ref="password_confirm"
        type="password"
      >
        Potwierdź nowe hasło
      </gdInput>
    </div>
    <div class="gd-form__submit">
      <gdButton
        @click.native="handleSubmit"
        :loading="loading"
      >
        Zmień hasło
      </gdButton>
    </div>
  </form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import objectPath from 'object-path'

  const initialFormState = () => ({ old: null, new: null, newRepeat: null })

  export default {
    data() {
      return {
        password: initialFormState(),
        loading: false
      }
    },

    methods: {
      async handleSubmit() {
        try {
          const isValid = await this.$validator.validateAll()

          if(!isValid){
            return
          }
          this.loading = true
          await this.$store.dispatch('user/CHANGE_PASSWORD', { old: this.password.old, newPass: this.password.new, newPassRepeat: this.password.newRepeat })
          await this.$store.dispatch('toasts/ADD_TOAST', { toast: { message: 'Hasło zmienione' }})
        } catch (error){
          this.loading = false
          let message = 'Wystąpił błąd poczas próby zmiany hasła.'
          const errorCode = objectPath.get(error, 'response.data.error.code')
          if (errorCode === 'wrong_password') {
            message = 'Obecne hasło jest nieprawidłowe'
          }
          this.$store.dispatch('toasts/ADD_TOAST', { toast: { message: `${message}`, type: 'error' }})
        } finally {
          this.clearForm()
          this.loading = false
        }
      },

      clearForm () {
        this.password = initialFormState()
      }
    }
  }
</script>

<style lang="scss">
  .gd-form-user-password-change {
    .gd-form__submit {
      width: 180px;
    }
  }
</style>
