var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-recommendations-report" },
    [
      _c("gdPageHeader", {
        attrs: {
          title: "Raport Zaleceń",
          description:
            "W tym miejscu znajdziesz wytyczne i zalecenia do wykonania, które mają na celu dostosowanie Twojej działalności do zgodności z RODO. Po wykonaniu poniższych zaleceń będziesz mógł wygenerować Raport z Wdrożenia oraz przejść do pobrania Certyfikatu."
        }
      }),
      _vm._v(" "),
      _vm.recommendations
        ? _c("gdAccordeonRecommendations", {
            attrs: { items: _vm.recommendations },
            on: { fileDeleted: _vm.reloadRecommendations }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "gdBricksWrapper",
        _vm._l(_vm.documents, function(doc, docIndex) {
          return _c(
            "gdBrickDashboard",
            {
              key: docIndex,
              attrs: {
                slot: (docIndex + 1) % 3 ? "wide" : "thin",
                thin: !!((docIndex + 1) % 3),
                iconName: "list",
                title: doc.name,
                content: doc.shortDescription,
                disabled: !doc.available
              },
              slot: (docIndex + 1) % 3 ? "wide" : "thin"
            },
            [
              _c(
                "template",
                { slot: "buttons" },
                [
                  _c(
                    "gdButton",
                    {
                      nativeOn: {
                        click: function($event) {
                          _vm.downloadDocument({
                            documentId: doc.id,
                            specialType: doc.specialType
                          })
                        }
                      }
                    },
                    [_vm._v("\n          Pobierz\n        ")]
                  )
                ],
                1
              )
            ],
            2
          )
        })
      ),
      _vm._v(" "),
      !_vm.user.metadata.steps[5].completed
        ? _c("gdContinueImplementation")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }