import axios from 'axios'
import objectPath from 'object-path'
import { routes } from '@/router'

const apiBaseUrl = `${process.env.API_HOST}/${process.env.API_BASE}/`

const initialState = () => ({
  apiBaseUrl
})

const state = () => (initialState())

const actions = {
  async REQUEST({ state, rootGetters, commit }, { endpoint, data, method, headers, timeout, fileDownload, useToken, params }){

    try {
      const storedToken = rootGetters['user/token']
      const Authorization = `Bearer ${storedToken}`
      const _useToken = (typeof useToken === 'undefined') ? true : useToken

      const request = {
        method: method || 'GET',
        data,
        url: state.apiBaseUrl + endpoint,
        headers,
        timeout,
        params
      }

      if (fileDownload) {
        request.responseType = 'arraybuffer'
        request.crossdomain
      }

      if (storedToken && _useToken) {
        request.headers = Object.assign({ Authorization }, headers)
      }

      const response = await axios(request)

      if (fileDownload) {
        /**
         * DEV NOTE:
         * This is hack for downloading streamed file
         * by ajax call on clientside only.
         * If you know better sollution feel free to edit.
         *
         * There is custom header with proposed filename on backend:
         * X-Suggested-Filename
         *
         * please refer to: https://stackoverflow.com/questions/43432892/force-download-get-request-using-axios
         * @Bart
         */
        let filename = response.headers['x-suggested-filename']
        let type = response.headers['content-type']
        let blob = new Blob([response.data], { type })
        let link = document.createElement('a')
        let url = window.URL.createObjectURL(blob)
        link.href = url
        link.download = filename
        //link.click()
        link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
      }

      return response

    } catch (error) {

      const errorCode = objectPath.get(error, 'response.data.error.code')

      switch(errorCode){

      case 'invalid_token':

        const loginRoute = routes.find(item => 'login' === item.name)

        if(loginRoute){
          commit('user/SET_TOKEN', { token: null }, { root: true})
          window.location.href = `${window.location.origin}${loginRoute.path}`
        }

        break
      default:
        throw error
      }
    }
  }
}

const mutations = {}

const getters = {}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
