<template>
  <gdDocuments />
</template>
<script>
  import gdDocuments from '@/pages/app/gd-documents'
  import Store from '@/stores'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    async beforeRouteEnter(to, from, next){
      try {
        await Store.dispatch('documents/GET_DOCUMENTS')
        await Store.dispatch('user/GET_UAR_DOCS')
        next()
      } catch(error) {
        console.error(`Couldn't resolve gd-dashboard beforeRouteEnter requests`, { error })
        next({
          name: 'app.error',
          query: {
            type: 'API'
          }
        })
      }
    },

    components: {
      gdDocuments
    }
  }
</script>
