<template>
  <section class="gd-simplified-risk-analysis">
    <gdPageHeader
      title="Uproszczona Analiza Ryzyka"
      description="Poziom ryzyka dla przetwarzania danych osobowych"
    />
    <gdBricksWrapper v-if="initLoading">

      <!-- Intro sider -->
      <transition
        name="global-slide"
        mode="out-in"
        slot="wide"
      >
        <gdSliderAppIntro
          class="gd-brick--large"
          :items="introSlides"
          v-if="introSlides && introSlides.length && !lastAnalysis && !hideIntro"
          startBtnTitle="Rozpocznij"
        />
      </transition>

      <!-- RODO Steps -->
      <transition
        name="global-slide"
        mode="out-in"
        slot="wide"
      >
        <gdUarSteps
          v-if="lastAnalysis || hideIntro"
          :groups="groups"
        />
      </transition>


      <gdBrick slot="thin">
        <gdChartProgress
          :title="lastAnalysis || hideIntro ? 'Postęp analizy' : 'Rozpocznij Analizę'"
          :content="lastAnalysis || hideIntro ? progressDescription : ''"
          :value="stepsProgress"
        />
      </gdBrick>

      <gdBrickDashboard
        v-if="documents.length"
        v-for="doc in documents"
        :key="doc.id"
        slot="thin"
        iconName="docs"
        :title="doc.name"
        :content="doc.shortDescription"
        disabledTooltipMessage="Moduł będzie dostepny po uzupełnieniu niezbednych danych"
        :disabled="!doc.available"
      >
        <template slot="buttons">
          <gdButton
            @click.native="downloadDocument({ documentId: doc.id, specialType: doc.specialType })"
            :theme="'secondary'"
          >
            Pobierz
          </gdButton>
        </template>
      </gdBrickDashboard>

      <gdBrickDashboard
        slot="thin"
        iconName="docs"
        title="Raport Uproszczonej Analizy Ryzyka"
        content="Wynik przeprowadzonej analizy."
        disabledTooltipMessage="Moduł będzie dostepny po uzupełnieniu niezbednych danych"
        :disabled="!allStepsCompleted && !raportLoading"
      >
        <template slot="buttons">
          <gdButton
            @click.native="downloadDocument({ documentId: lastAnalysis.id, specialType: lastAnalysis.specialType })"
            :theme="'secondary'"
          >
            Pobierz
          </gdButton>
        </template>
      </gdBrickDashboard>

    </gdBricksWrapper>

    <!-- Modal -->
    <gdModalInfo
      iconName="dashboard"
      title="Rozpoczynasz Uproszczoną Analizę Ryzyka"
      content="Celem analizy ryzyka jest określenie jego wysokości i w konsekwencji wskazanie rekomendowanych środków bezpieczeństwa technicznego i organizacyjnego właściwych dla ustalonego poziomu ryzyka."
      buttonLabel="Rozpocznij"
      @beforeClose="setIntroModalAsSeen"
      @buttonClick="hideModal"
    />
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import gdSliderAppIntro from '@/components/sliders/gd-slider-app-intro'
  import gdUarSteps from '@/components/uar-steps/gd-uar-steps'
  import gdPageHeader from '@/components/page/gd-page-header'
  import gdBrick from '@/components/bricks/gd-brick'
  import gdBrickDashboard from '@/components/bricks/gd-brick-dashboard'
  import gdBricksWrapper from '@/components/bricks/gd-bricks-wrapper'
  import gdChartProgress from '@/components/charts/gd-chart-progress'

  export default {
    components: {
      gdChartProgress,
      gdSliderAppIntro,
      gdUarSteps,
      gdPageHeader,
      gdBrickDashboard,
      gdBricksWrapper,
      gdBrick
    },

    data () {
      return {
        hideIntro: false
      }
    },

    computed: {
      ...mapGetters('simplifiedRiskAnalysis', [
        'introSlides',
        'groups',
        'documents',
        'allStepsCompleted',
        'stepsProgress',
        'progressDescription',
        'lastAnalysis',
        'initLoading',
        'raportLoading'
      ]),
    },

    methods: {

      async downloadDocument({ documentId, specialType }){
        try {
          await this.$store.dispatch('documents/DOWNLOAD_SINGLE_DOCUMENT', { documentId, specialType })
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },

      hideModal(){
        this.$store.commit('modals/HIDE_MODAL')
        this.hideIntro = true
      },

      async setIntroModalAsSeen(){
        this.isIntroClosed = true
      }
    },

    beforeDestroy () {
      this.$store.commit('simplifiedRiskAnalysis/RESET_STATE')
    }
  }
</script>

<style lang="scss">
  .gd-simplified-risk-analysis {
    width: 100%;
  }
</style>
