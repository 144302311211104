var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("gdBrick", { staticClass: "gd-slider-app-intro" }, [
    _c(
      "figure",
      [
        _c("gdImg", {
          attrs: {
            srcset:
              "/assets/images/app-intro.jpg 1x, /assets/images/app-intro@2x.jpg 2x"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("nav", { staticClass: "gd-slider-app-intro__nav" }, [
      _c(
        "ul",
        { staticClass: "nav-list", style: _vm.navListWidth(_vm.items) },
        [
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "li",
              { key: index, class: _vm.bulletClass(index, _vm.activeIndex) },
              [_c("gdIcon", { attrs: { name: "check" } })],
              1
            )
          }),
          _vm._v(" "),
          _c("span", { ref: "swiperProgress", staticClass: "swiper-progress" })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "gd-slider-app-intro__slider" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "swiper",
              rawName: "v-swiper:introductionCarousel",
              value: _vm.swiperOption,
              expression: "swiperOption",
              arg: "introductionCarousel"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "article",
                {
                  key: index,
                  staticClass: "swiper-slide gd-slider-app-intro__slide"
                },
                [
                  _c("h1", { staticClass: "slide-title" }, [
                    _vm._v(_vm._s(item.title))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "slide-content" }, [
                    _vm._v(_vm._s(item.content))
                  ])
                ]
              )
            })
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "slider-buttons" },
            [
              _vm.activeIndex > 0
                ? _c(
                    "gdButton",
                    {
                      staticClass: "prev-slide",
                      attrs: { theme: "ghost" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.prevSlide($event)
                        }
                      }
                    },
                    [_c("gdIcon", { attrs: { name: "arrow" } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeIndex !== _vm.items.length - 1
                ? _c(
                    "gdButton",
                    {
                      nativeOn: {
                        click: function($event) {
                          return _vm.nextSlide($event)
                        }
                      }
                    },
                    [
                      _vm._v("\n          Dalej\n          "),
                      _vm.activeIndex <= _vm.items.length - 1
                        ? _c("gdIcon", { attrs: { name: "arrow" } })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeIndex === _vm.items.length - 1 && _vm.isLoggedIn
                ? _c(
                    "gdButton",
                    {
                      nativeOn: {
                        click: function($event) {
                          return _vm.startImplementation($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.startBtnTitle) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }