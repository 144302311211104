var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gd-info-text-list" }, [
    _vm.element.title
      ? _c("h2", { staticClass: "gd-info-text-list__heading" }, [
          _vm._v("\n    " + _vm._s(_vm.element.title) + "\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.element.info
      ? _c("p", { staticClass: "gd-info-text-list__info" }, [
          _vm._v("\n    " + _vm._s(_vm.element.info) + "\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ol",
      _vm._l(_vm.element.list, function(item, index) {
        return _c("li", { key: index }, [
          item.listHeading
            ? _c("div", [
                _c("span", {
                  staticClass: "nested-list__heading",
                  domProps: { innerHTML: _vm._s(item.listHeading) }
                }),
                _vm._v(" "),
                _c(
                  "ol",
                  _vm._l(item.nestedList, function(el, index) {
                    return _c(
                      "li",
                      { key: index, staticClass: "nested-list__el" },
                      [
                        _vm._v("\n            " + _vm._s(index + 1) + ". "),
                        _c("span", { domProps: { innerHTML: _vm._s(el.text) } })
                      ]
                    )
                  })
                )
              ])
            : _c("span", { domProps: { innerHTML: _vm._s(item.text) } }, [
                item.heading
                  ? _c("span", { staticClass: "nested-list__el--bold" }, [
                      _vm._v(
                        "\n          " + _vm._s(item.heading) + " -\n        "
                      )
                    ])
                  : _vm._e()
              ])
        ])
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }