var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-log-in" },
    [
      _c(
        "gdLink",
        { attrs: { to: _vm.landingUrl } },
        [_c("gdImg", { attrs: { src: "/assets/images/logo.svg" } })],
        1
      ),
      _vm._v(" "),
      _c("gdFormLogIn"),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "gd-log-in__info" },
        [
          _c("gdLink", { attrs: { to: { name: "password-reset" } } }, [
            _vm._v("Nie pamiętasz hasła?")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "gd-log-in__info" },
        [
          _vm._v("Jeśli nie posiadasz konta,\n    "),
          _c(
            "gdLink",
            { staticClass: "gd-log-in__link", attrs: { to: _vm.landingUrl } },
            [_vm._v("\n      zarejestruj się.\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }