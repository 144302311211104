const initialState = () => ({
  areas: {
    active: null,
    available: null
  },
  changes: [],
  changesCopy: null
})

const state = () => (initialState())

const actions = {
  async GET_AREAS({ commit, getters, dispatch, state }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'user/rcpd/activityarea'
      }, { root: true })
      const { active, available } = data

      commit('SET_AREAS', { active, available })

      console.log('editAreas/GET_AREAS', { data })
    } catch(error){
      console.error('editAreas/GET_AREAS', { error })
      throw error
    }
  },

  async REMOVE_AREA({ commit, getters, dispatch }, { id }){
    try {

      const response = await dispatch('api/REQUEST', {
        endpoint: `user/rcpd/activityarea/${id}`,
        method: 'DELETE'
      }, { root: true })

      console.log('editAreas/REMOVE_AREA', { id, response })
    } catch(error){
      console.error('editAreas/REMOVE_AREA', { error })
      throw error
    }
  },

  async ADD_AREA({ commit, getters, dispatch }, { id }){
    try {

      const response = await dispatch('api/REQUEST', {
        endpoint: `user/rcpd/activityarea`,
        method: 'POST',
        data: {
          activityAreaId: id
        }
      }, { root: true })

      console.log('editAreas/ADD_AREA', { id, response })
    } catch(error){
      console.error('editAreas/ADD_AREA', { error })
      throw error
    }
  },

  async SAVE_AREAS({ commit, getters, state, dispatch }){
    try {
      if(!state.changes.length){
        return
      }

      const requests = state.changes.map(item => {
        switch(item.changeType){
        case 'ADD':
          return dispatch('ADD_AREA', { id: item.id })
        case 'REMOVE':
          return dispatch('REMOVE_AREA', { id: item.id })
        }
      })

      await Promise.all(requests)
      commit('CLEAR_CHANGES')

      console.log('editAreas/SAVE_AREAS')
    } catch(error){
      console.error('editAreas/SAVE_AREAS', { error })
      throw error
    }
  }
}

const mutations = {
  SET_AREAS(state, { active, available }) {
    state.areas.active = active
    state.areas.available = available
  },

  SET_AREA_CHANGE(state, { id, changeType }){
    const changeIndex = state.changes.findIndex(item => item.id === id)

    if(changeIndex > -1){
      state.changes.splice(changeIndex, 1)
      return
    }

    state.changes.push({ id, changeType })
  },

  CLEAR_CHANGES(state){
    state.changes = []
  },

  RESET_STATE(state){
    console.log('editAreas/RESET_STATE')
    Object.assign(state, initialState())
  }
}

const getters = {
  areas: state => {
    if(!state.areas || !state.areas.active && !state.areas.available){
      return
    }

    const removable = []
    const active = []

    if(state.areas.active && state.areas.active.length){

      state.areas.active.forEach(area => {
        const { name, id, activitiesCount } = area

        if (activitiesCount > 0) {
          active.push({
            title: name,
            id
          })
        } else {
          removable.push({
            title: name,
            id,
            mode: 'REMOVABLE'
          })
        }
      })
    }

    const available = (state.areas.available || []).map(area => {
      const { name, id, activitiesCount } = area

      return {
        title: name,
        id,
        mode: 'AVAILABLE'
      }
    })

    return active.concat(removable, available)
  },
  changesCopy: (state, getters, rootState, rootGetters) => {
    if(!state.changes.length){
      return ''
    }

    const changes = state.changes
    const areas = getters.areas

    const lines = changes
      .sort(item => item.changeType !== 'ADD')
      .map(item => {
        const changedArea = areas.find(singleAreas => singleAreas.id === item.id)

        switch(item.changeType){
        case 'ADD':
          return `Dodałeś obszar ${changedArea.title}`
        case 'REMOVE':
          return `Usunąłeś obszar <span style="text-decoration: line-through;">${changedArea.title}</span>`
        }
      })

    lines.push('\n \n Czy na pewno anulować wprowadzone zmiany?')

    return lines.join('\n')
  }
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
