<template>
  <div class="gd-brick-account-plan">
    <gdBrick
      :thin="thin"
      :disabled="false"
    >
      <section class="gd-brick-account-plan__section">
        <p class="brick__title">Twój plan to</p>
        <h3 class="brick__plan-name">"{{ planName }}"</h3>
        <p class="brick__content">Płatność jednorazowa</p>
        <p class="brick__content brick__content--space-bottom">{{ oneTimePrice }} netto rocznie</p>
        <p
          v-if="startDate"
          class="brick__content brick__date"
        >
          Rozpoczęcie usługi: {{ startDate }}
        </p>
        <p
          v-if="endDate"
          class="brick__content brick__content--space-bottom brick__date"
        >
          Koniec usługi: {{ endDate }}
        </p>
      </section>
    </gdBrick>
  </div>
</template>

<script>
  import gdBrick from '@/components/bricks/gd-brick'

  export default {
    props: {
      thin: Boolean,
      planName: String,
      planPrice: String,
      oneTimePrice: String,
      startDate: String,
      endDate: String,
      planInvoices: Array
    },

    components: {
      gdBrick
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/fonts.scss";
  @import "./src/assets/styles/vars/colors.scss";

  .gd-brick-account-plan {
    &__section:not(:last-child) {
      margin-bottom: 40px;
    }

    .gd-brick__inner {
      min-height: 302px;
    }

    .brick {
      &__content {
        font-size: 16px;
        line-height: 20px;
        color: $color-gray-light;

        &--space-bottom {
          margin-bottom: 20px;
        }
      }

      &__title {
        margin-bottom: 14px;
        font-size: 20px;
      }

      &__plan-name {
        margin-bottom: 10px;
        font-size: 28px;
        font-family: $font-medium;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__invoices {
        .gd-link {
          display: inline-block;
          margin-bottom: 14px;
          font-size: 16px;
          color: $color-blue;

          .invoice-date {
            color: $color-gray-light;
          }
        }
      }
    }
  }
</style>
