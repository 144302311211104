<template>
  <aside class="gd-sidebar-app">
    <gdNavAppMain :items="navItems" />
  </aside>
</template>

<script>
  import { mapGetters } from 'vuex'
  import gdNavAppMain from '@/components/navs/gd-nav-app-main'

  export default {

    computed: {
      ...mapGetters('sidebarApp', ['navItems'])
    },

    components: {
      gdNavAppMain
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";
  @import "./src/assets/styles/vars/layout.scss";

  .gd-sidebar-app {
    position: relative;
    z-index: -1;
    display: inline-block;
    width: $aside-app-width;
    min-height: $aside-app-min-height;
    background-color: $color-dark;
  }
</style>
