<template>
  <form
    class="gd-form gd-form-user-invite"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <div class="gd-form__section">
      <gdInput
        name="user-name"
        v-model="form.firstName"
        data-vv-as="imię"
        type="text"
        ref="name"
        v-validate="'required|min:3'"
      >
        Imię
      </gdInput>
      <gdInput
        name="user-surname"
        v-model="form.lastName"
        data-vv-as="nazwisko"
        type="text"
        ref="surname"
        v-validate="'required|min:3'"
      >
        Nazwisko
      </gdInput>
    </div>
    <div class="gd-form__section">
      <gdInput
        name="user-email"
        v-model="form.email"
        type="email"
        data-vv-as="email"
        v-validate="'required|email'"
      >
        Email
      </gdInput>
      <gdInput
        name="user-password"
        v-model="form.password"
        type="password"
        data-vv-as="password"
        v-validate="'required'"
      >
        Hasło
      </gdInput>
    </div>
    <div class="gd-form__submit">
      <gdButton
        @click.native="handleSubmit"
        :loading="loading"
      >
        Dodaj użytkownika
      </gdButton>
    </div>
  </form>
</template>

<script>
  import { mapGetters } from "vuex";
  import objectPath from "object-path";

  const initialFormState = () => ({
    email: null,
    password: null,
    firstName: null,
    lastName: null,
    phone: "666666666",
    legalAccept: true,
    legalAcceptSms: false,
    legalAcceptEmail: false
  });

  export default {
    data() {
      return {
        form: initialFormState(),
        loading: false
      };
    },

    mounted() {
      setTimeout(() => {
        this.clearForm();
      }, 500);
    },

    methods: {
      async handleSubmit() {
        try {
          const isValid = await this.$validator.validateAll();

          if (!isValid) {
            return;
          }

          this.loading = true;

          await this.$store.dispatch("user/CREATE_COMPANYS_USER", {
            ...this.form
          });
          await this.$store.dispatch("toasts/ADD_TOAST", {
            toast: { message: "Użytkownik dodany" }
          });
        } catch (error) {
          this.loading = false;
          let message = "Wystąpił błąd poczas próby dodania użytkownika.";
          const errorCode = objectPath.get(error, "response.data.error.code");

          this.$store.dispatch("toasts/ADD_TOAST", {
            toast: { message: `${message}`, type: "error" }
          });
        } finally {
          this.clearForm();
          this.loading = false;
        }
      },

      clearForm() {
        this.form = initialFormState();
      }
    }
  };
</script>

<style lang="scss">
  .gd-form-user-password-change {
    .gd-form__submit {
      width: 180px;
    }
  }
</style>
