var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.gdDashboardBrickClass },
    [
      _c("gdBrick", { attrs: { thin: _vm.thin, disabled: _vm.disabled } }, [
        _vm.iconName
          ? _c(
              "div",
              { staticClass: "icon-wrapper" },
              [
                _c("gdIcon", {
                  staticClass: "icon-main",
                  attrs: { name: _vm.iconName, large: true }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("h2", { staticClass: "brick__title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("p", { staticClass: "brick__content" }, [
          _vm._v(_vm._s(_vm.content))
        ]),
        _vm._v(" "),
        _vm.disabled
          ? _c(
              "div",
              { staticClass: "brick__disabled-info" },
              [
                _c("span", { staticClass: "info-label" }, [
                  _vm._v("niedostępny")
                ]),
                _vm._v(" "),
                _vm.disabledTooltipMessage
                  ? _c("gdTooltip", {
                      attrs: {
                        content: _vm.disabledTooltipMessage,
                        iconName: "question"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.disabled
          ? _c("div", { staticClass: "brick__buttons" }, [_vm._t("buttons")], 2)
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }