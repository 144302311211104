var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "gd-header-app" }, [
    _c("ul", { staticClass: "gd-header-app__list" }, [
      _c(
        "li",
        [
          _c("gdLink", { attrs: { to: "/" } }, [
            _c("img", {
              attrs: { src: "/assets/images/logo.svg", alt: "GDPS logo" }
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        { staticClass: "list__user" },
        [
          _vm.user && _vm.user.company
            ? _c("span", [
                _vm._v(
                  "\n        " + _vm._s(_vm.user.company.name) + "\n      "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.user
            ? _c(
                "gdLink",
                { staticClass: "user-link", attrs: { name: "app.account" } },
                [
                  _vm._v("\n        Twoje konto\n        "),
                  _c("gdIcon", { attrs: { name: "user" } })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }