var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gd-brick-vertical" },
    [
      _c(
        "gdBrick",
        { attrs: { thin: _vm.thin, disabled: false } },
        [
          _c("h2", { staticClass: "brick__title" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "brick__content" }, [
            _vm._v(_vm._s(_vm.content))
          ]),
          _vm._v(" "),
          _vm._t("image"),
          _vm._v(" "),
          _vm._t("buttons")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }