var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: _vm.rootCssClasses }, [
    _vm.title
      ? _c("h3", { staticClass: "gd-table__title" }, [
          _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "gd-table__wrapper" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }