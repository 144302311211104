<template>
  <gdBrick class="gd-brick-certificate-download">
    <gdIcon
      name="docs"
      :large="true"
      class="certificate-download__icon"
    />
    <h1 class="certificate-download__title">Pobierz certyfikat</h1>
    <p class="certificate-download__description">Certyfikat potwierdza spełnianie standardów Good Data Protection Standard.</p>
    <figure class="certificate-download__image">
      <gdImg
        srcset="/assets/images/certyfikat-hp.png 1x, /assets/images/certyfikat-hp@2x.png 2x"
      />
      <p class="certificate-download__name">{{ companyName }}</p>
      <p class="certificate-download__date">{{ certificateDate }}</p>
    </figure>
    <gdButton
      :link="{ name: 'app.certificate' }"
      theme="secondary"
    >
      Pobierz certyfikat
    </gdButton>
  </gdBrick>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdBrick from '@/components/bricks/gd-brick'

  export default {
    data () {
      return {
        companyName: null,
        certificateDate: null
      }
    },

    mounted() {
      this.certificateDate = this.formatDate()
      this.companyName = this.user.company.name
    },

    computed: {
      ...mapGetters('certificate', ['startDateFormat', 'endDateFormat']),
      ...mapGetters('user', ['user'])
    },

    methods: {
      formatDate () {
        return this.startDateFormat.replace(/[/]/g, '.')
      }
    },

    components: {
      gdBrick
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .gd-brick-certificate-download {
    figure {
      position: relative;
      font-family: $font-medium;
      color: $color-blue-dark;

      .certificate-download__name {
        position: absolute;
        top: calc(50% + 12px);
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
      }

      .certificate-download__date {
        position: absolute;
        bottom: 65px;
        left: 85px;
        font-size: 10px;
        letter-spacing: .9px;
      }
    }

    .gd-brick__inner {
      position: relative;
      align-items: flex-start;
      min-height: 590px;
      padding-left: 120px;
    }

    .certificate-download {
      &__image {
        margin-bottom: 16px;
      }

      &__icon {
        position: absolute;
        top: 40px;
        left: 50px;
      }

      &__title {
        font-size: 20px;
        margin-bottom: 15px;
      }

      &__description {
        margin-bottom: 25px;
        font-size: 16px;
        color: $color-gray-light;
      }
    }

    .gd-btn {
      margin-top: 10px;
    }
  }
</style>
