<template>
  <section class="gd-uar-step__questions">
    <div
      class="questions"
    >
      <ul
        class="questions_list"
        v-for="(question, questionIndex) in items"
        :key="questionIndex"
        v-if="questionIndex === group.activeQuestion"
      >
        <li :class="questionClasses(question)">
          <p class="question__title">
            {{ question.question }}
          </p>
          <p
            class="question__description"
            v-if="question.info"
            v-html="question.info"
          />
          <div class="question__answers">

            <!-- Back button -->
            <section class="gd-rodo-step__content-extra">
              <gdButton
                v-if="question.questionType !== '$UAR-RESULT' && groupIndex !== 0 "
                theme="ghost"
                @click.native="group.activeQuestion > 0 ? previousQuestion() : previousGroup()"
              >
                <gdImg
                  src="/assets/icons/icon-arrow-purple.svg"
                />
              </gdButton>
            </section>

            <!-- Textarea - user comment -->
            <template v-if="question.requireUserComment">
              <gdTextArea
                v-model="data[questionIndex].userComment"
                name="userComment"
                data-vv-as="uzasadnienie"
                v-validate="'required'"
              >
                Uzasadnienie
              </gdTextArea>
            </template>

            <!-- Boolean / Buttons -->
            <template v-if="question.questionType === 'boolean'">
              <div class="question__buttons">
                <gdButton
                  v-for="(answer, answerIndex) in question.answers"
                  :key="answerIndex"
                  @click.native="setAnswer({ answer, questionId: question.id, questionIndex }) + nextQuestion()"
                >
                  {{ answer.label }}
                </gdButton>
              </div>
            </template>

            <!-- Select -->
            <template v-if="question.questionType === 'select'">
              <gdSelect
                :value="data[questionIndex].answer"
                @input="setAnswer({ answer: $event, questionId: question.id, questionIndex })"
                :name="'question' + questionIndex"
                placeholder="Wybierz z listy..."
                :items="question.answers"
              />
              <div class="question__buttons">
                <gdButton
                  @click.native="nextQuestion()"
                  :disabled="!data[questionIndex].answer"
                >
                  Dalej
                </gdButton>
              </div>
            </template>

            <!-- Checkbox  -->
            <template v-if="question.questionType === 'checkbox'">
              <p style="color: red;">MUTLI CHECKBOX FOR UAR HAVE NO SENSE. CHANGE IT IN CMS.</p>
            </template>

            <!-- FAKED QUESTIONS -->
            <template v-if="question.questionType === '$UAR-RESULT'">
              <gdButton
                class="question__buttons"
                theme="secondary"
                @click.native="startNewAnalysis"
              >
                Przeprowadź ponowną analizę
              </gdButton>
            </template>

            <template v-if="question.questionType === '$CONTINUE'">
              <gdButton
                class="question__buttons"
                @click.native="nextQuestion(true)"
              >
                Kontynuuj
              </gdButton>
            </template>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
  import { interpretRiskResult } from '@/helpers'
  import { mapGetters, mapState } from 'vuex'

  export default {
    props: {
      items: Array,
      group: Object,
      groupIndex: Number
    },

    data(){
      return {
        data: this.items.map(val => ({
          answer: '',
          questionId: '',
          userComment: ''
        }))
      }
    },

    computed: {
      ...mapState('simplifiedRiskAnalysis', [
        'currentAnalysisGroupIndexMap'
      ]),
      ...mapGetters('simplifiedRiskAnalysis', [
        'currentAnalysis'
      ])
    },

    methods: {
      questionClasses(question){
        return `question question--type-${question.questionType}`
      },
      setAnswer ({ answer, questionId, questionIndex }) {
        this.$set(this.data[questionIndex], 'answer', answer)
        this.$set(this.data[questionIndex], 'questionId', questionId)
      },
      calcRiskForAnswers (answers) {
        let res = 1
        answers.forEach(ans => {
          res *= ans.value
        })

        if (answers.length > 1) {
          return interpretRiskResult({
            result: res,
            mode: this.groupIndex === 3 ? 'secondary' : 'primary'
          })
        } else {
          return res
        }
      },
      previousQuestion() {
        this.$store.commit('simplifiedRiskAnalysis/CHANGE_ACTIVE_QUESTION', { forGroup: this.group, method: 'decrease' })
        this.$store.commit('simplifiedRiskAnalysis/PREVIOUS_QUESTION')
      },
      async nextQuestion(justNext){
        const isValid = await this.$validator.validateAll()

        if (!isValid) {
          return
        }

        this.$store.commit('simplifiedRiskAnalysis/COMPLETE_QUESTION')

        if (this.group.activeQuestion === this.items.length - 1) {
          let groupAnalysis = {}

          if (!justNext) {
            groupAnalysis = {
              groupId: this.group.id,
              answersRiskValue: this.calcRiskForAnswers(this.data.map(item => item.answer)),
              riskValue: null,
              answers: this.data.map(item => ({
                questionId: item.questionId,
                answerId: item.answer.id,
                userComment: item.userComment || ''
              }))
            }
          }

          this.$store.commit('simplifiedRiskAnalysis/COMPLETE_GROUP', { groupIndex: this.groupIndex, groupAnalysis })

          const isReadyToSend = this.$store.getters['simplifiedRiskAnalysis/isReadyToSend']

          if(isReadyToSend) {
            await this.$store.dispatch('simplifiedRiskAnalysis/SEND_ANALYSIS')
            this.$store.dispatch('simplifiedRiskAnalysis/GET_DOCUMENTS')
          }
        } else {
          this.$store.commit('simplifiedRiskAnalysis/CHANGE_ACTIVE_QUESTION', { forGroup: this.group, method: 'increase' })
        }
      },
      previousGroup() {
        this.$store.commit('simplifiedRiskAnalysis/PREVIOUS_GROUP', { groupIndex: this.groupIndex })
      },
      startNewAnalysis() {
        this.$store.commit('simplifiedRiskAnalysis/START_NEW_ANALYSIS')
        this.$store.commit('simplifiedRiskAnalysis/RESET_STATE_INTERNAL')
      }
    },

    created () {
      const previousAnswers = this.currentAnalysis.groups[this.currentAnalysisGroupIndexMap[this.groupIndex]]

      if (previousAnswers && !this.group.fakedQuestions) {
        this.data = previousAnswers.answers.map((item, i) => ({
          answer: this.group.questions[i].answers.find(ans => ans.id === item.answerId),
          questionId: item.questionId,
          userComment: item.userComment
        }))
      }
    },
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .questions_list {
    width: 100%;
  }

  .question {
    &__title {
      text-align: left;
      font-family: $font-regular;
      font-size: 20px;
      color: $color-dark;
      line-height: 25px;
    }

    &__description {
      text-align: left;
      margin: 10px 0;
      font-family: $font-regular;
      font-size: 16px;
      color: $color-gray-light;
      line-height: 20px;
    }

    &__answers {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 20px;
      margin-left: -5px;

      .gd-select, .gd-text-area {
        position: relative;
        left: 5px;
        max-width: 425px;
      }

      .gd-select {
        order: -1;
        margin-bottom: 40px;
      }
    }

    .gd-btn {
      min-width: 100px;
      margin: 0 5px;
    }

    &--type-boolean {
      .question__buttons {
        display: flex;
        margin-top: auto;

        button {
          min-width: unset;
        }
      }
    }

    &--type-select {
      .question__answers {
        flex-flow: column nowrap;
      }

      .question__buttons {
        margin-top: auto;
      }
    }

    &--type-checkbox {
      .question__answers {
        flex-direction: column;
      }

      .question__buttons {
        margin-top: auto;
      }
    }
  }
</style>
