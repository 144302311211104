var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "gd-sidebar-app" },
    [_c("gdNavAppMain", { attrs: { items: _vm.navItems } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }