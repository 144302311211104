var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gd-brick-files-list" },
    [
      _c("gdBrick", [
        _c("h5", { staticClass: "gd-brick-files-list__title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "gd-brick-files-list__list" },
          _vm._l(_vm.filesList, function(file, index) {
            return _c(
              "li",
              { key: index },
              [
                _vm.isUAR()
                  ? _c("gdIcon", {
                      attrs: { name: "close" },
                      nativeOn: {
                        click: function($event) {
                          _vm.deleteFile({ file: file })
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    class: _vm.labelClasses(),
                    on: {
                      click: function($event) {
                        _vm.downloadDocument({
                          documentId: file.id,
                          specialType: file.specialType
                            ? file.specialType
                            : null
                        })
                      }
                    }
                  },
                  [_vm._v("\n          " + _vm._s(file.name) + "\n        ")]
                )
              ],
              1
            )
          })
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }