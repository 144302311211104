var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-password-reset" },
    [
      _c(
        "gdLink",
        { attrs: { to: { name: "home" } } },
        [_c("gdImg", { attrs: { src: "/assets/images/logo.svg" } })],
        1
      ),
      _vm._v(" "),
      _vm.token
        ? _c("gdFormPasswordSet", { attrs: { token: _vm.token } })
        : _c("gdFormPasswordReset"),
      _vm._v(" "),
      _c(
        "gdLink",
        {
          staticClass: "gd-password-reset__link",
          attrs: { to: { name: "login" } }
        },
        [_vm._v("\n    Powrót do logowania\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }