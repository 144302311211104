<template>
  <gdApp />
</template>
<script>
  import gdApp from '@/pages/app/gd-app'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    components: {
      gdApp
    }
  }
</script>
