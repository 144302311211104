<template>
  <gdActivityRegister />
</template>
<script>
  import gdActivityRegister from '@/pages/app/activity-register/gd-activity-register'
  import Store from '@/stores'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    async beforeRouteEnter(to, from, next){
      try {
        await Store.dispatch('activityRegister/GET_USER_AREAS')
        next()
      } catch(error) {
        console.error(`Couldn't resolve gd-activity-register beforeRouteEnter requests`, { error })
        next({
          name: 'app.error',
          query: {
            type: 'API'
          }
        })
      }
    },

    beforeDestroy(){
      this.$store.commit('activityRegister/RESET_STATE')
    },

    components: {
      gdActivityRegister
    }
  }
</script>
