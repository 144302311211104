<template>
  <div class="gd-brick-files-upload">
    <gdBrick>
      <div class="brick-content">
        <p class="content__title">{{ title }}</p>
        <template v-if="fileList && fileList.length">
          <ul>
            <li
              v-for="(file, index) in fileList"
              :key="index"
            >
              <gdIcon
                name="close"
                @click.native="deleteFile({ file })"
              />
              <span
                v-if="file.name"
                @click="downloadFile({ file })"
              >
                {{ file.name }}
              </span>
            </li>
          </ul>
        </template>
        <template v-else>
          <span class="no-files">Brak plików.</span>
        </template>
      </div>
      <div class="brick-actions">
        <gdButton
          @click.native="uploadFile"
          theme="secondary"
        >
          Wgraj plik
        </gdButton>
      </div>
    </gdBrick>
  </div>
</template>

<script>
  import gdBrick from '@/components/bricks/gd-brick'

  export default {
    props: {
      title: String,
      categoryId: Number,
      fileList: Array
    },
    methods: {
      async uploadFile(){
        try {
          const req = await this.$store.dispatch('filestack/UPLOAD_FILE')
          const file = (req.filesUploaded || [])[0]
          if(file){
            const { filename: name, handle } = file
            await this.$store.dispatch('file/ADD_FILE', { name, handle, categoryId: this.categoryId })
            this.$emit('fileUploaded')
          }
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },

      async deleteFile({ file }) {
        try {
          await this.$store.dispatch('file/DELETE_FILE', { fileId: file.id })
          this.$emit('fileDeleted')
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },

      async downloadFile({ file }) {
        try {
          await this.$store.dispatch('file/DOWNLOAD_FILE', { fileId: file.id })
          this.$emit('fileDownloaded')
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      }
    },
    components: {
      gdBrick
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-brick-files-upload {
    .gd-brick {
      min-height: unset;
    }

    .gd-brick__inner {
      flex-flow: row nowrap;
      min-height: unset;
      padding: 20px;
    }

    .brick {
      &-content {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        ul {
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-start;
          align-items: flex-start;

          span {
            padding-left: 8px;
            color: $color-blue;
            user-select: none;
            cursor: pointer;
          }

          li {
            margin-bottom: 12px;
          }
        }

        .no-files {
          color: $color-gray-light;
        }

        .icon {
          height: 8px;
          width: 8px;
          color: $color-red;
          cursor: pointer;
        }

        .content__title {
          margin-bottom: 10px;
          font-size: 20px;
        }
      }

      &-actions {
        justify-content: flex-end;
        align-self: flex-start;
        height: 100%;
        margin-left: auto;

        .gd-btn {
          min-width: 200px;
        }
      }
    }
  }
</style>
