<template>
  <div class="gd-users-list">
    <div class="gd-table-header">
      <ul class="gd-users-list__header gd-table-row">
        <li class="gd-table-cell">Imię i nazwisko</li>
        <li class="gd-table-cell gd-table-cell--email">Adres email</li>
        <li class="gd-table-cell gd-table-cell--role">Rola</li>
        <li class="gd-table-cell gd-table-cell--last">Akcje</li>
      </ul>
    </div>
    <div class="gd-users-list__body">
      <ul
        v-for="(user, index) in users"
        :key="index"
        class="gd-table-row"
      >
        <li class="gd-table-cell">{{ user.firstName }} {{ user.lastName }}</li>
        <li class="gd-table-cell">{{ user.username }}</li>
        <li class="gd-table-cell gd-table-cell--role">{{ user.role }}</li>
        <li class="gd-table-cell gd-table-cell--last">
          <gdButton
            v-if="isSimpleUser(user.role)"
            theme="ghost"
            :loading="loading"
            @click.native="deleteCompanysUser(user.id)"
          >
            Usuń
          </gdButton>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      users: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        loading: false
      };
    },

    methods: {
      deleteCompanysUser(id){
        let self = this
        this.$store.dispatch('prompt/SHOW', {
          content: 'Tej czynności nie da się cofnąć',
          title: 'Czy na pewno chcesz usunąć konto tego użytkownika?',
          cancelLabel: 'Anuluj',
          acceptLabel: 'Usuń konto',
          async onAccept() {
            try{
              self.$store.dispatch("user/DELETE_COMPANYS_USER", { id });
            } catch (error){
              console.error({ error})
            }
          },
        })
      },

      isSimpleUser(role) {
        return role.toLowerCase() === "admin" ? false : true;
      }
    }
  };
  </script>

<style lang="scss">
  @import "./src/assets/styles/vars/fonts.scss";
  @import "./src/assets/styles/vars/colors.scss";

  .gd-users-list {
    .gd-table-cell {
      &--last {
        max-width: 120px;
        margin-left: auto;
      }

      &--role {
        max-width: 100%;
      }

      &--email {
        width: 270px;
      }
    }

    .gd-button-wrapper {
      position: relative !important;
      left: 0 !important;
      opacity: 1 !important;

      .gd-btn {
        letter-spacing: 0;
        text-transform: unset;
        font-size: 16px;
      }
    }

    &__header {
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;

      .gd-table-cell:first-of-type {
        border-top-left-radius: 3px;
      }

      .gd-table-cell:last-of-type {
        border-top-right-radius: 3px;
      }
    }

    &__body .gd-table-row:last-of-type {
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;

      .gd-table-cell:first-of-type {
        border-bottom-left-radius: 3px;
      }

      .gd-table-cell:last-of-type {
        border-bottom-right-radius: 3px;
      }
    }
  }
</style>
