<template>
  <div class="gd-brick-data-processing-info">
    <gdBrick
      :thin="thin"
      :disabled="false"
    >
      <section class="gd-brick-data-processing-info__section">
        <p class="brick__title">Informacje przetwarzane</p>
        <p
          v-for="(data, index) in dataList"
          :key="index"
          class="brick__content brick__data-list"
        >
          {{ data.label }}
        </p>
      </section>
      <gdButton
        theme="warning"
        @click.native="deleteAccount"
      >
        Usuń konto
      </gdButton>
      <p class="brick__content brick__links">
        <gdLink to="#">Polityka prywatności,</gdLink>
        <!-- <gdLink to="#">Regulamin</gdLink> -->
        <a
          href="/assets/regulations/regulamin_korzystania_z_gdpsystem.pdf"
          target="_blank"
        >
          Regulamin
        </a>
      </p>
    </gdBrick>
  </div>
</template>

<script>
  import gdBrick from '@/components/bricks/gd-brick'

  export default {
    props: {
      thin: Boolean,
      dataList: Array
    },

    methods: {
      deleteAccount(){
        let self = this
        this.$store.dispatch('prompt/SHOW', {
          content: 'Tej czynności nie da się cofnąć',
          title: 'Usuń konto',
          cancelLabel: 'Anuluj',
          acceptLabel: 'Usuń konto',
          async onAccept() {
            try{
              await self.$store.dispatch('user/DELETE_ACCOUNT')
              await self.$store.dispatch('user/LOG_OUT')
              self.$router.push({ name: 'login' })
            } catch (error){
              self.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
              console.error({ error})
            }
          },
        })
      }
    },

    components: {
      gdBrick
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/fonts.scss";
  @import "./src/assets/styles/vars/colors.scss";

  .gd-brick-data-processing-info {
    &__section {
      margin-bottom: 25px;
    }

    .brick {
      &__content {
        font-size: 16px;
        line-height: 20px;
        color: $color-gray-light;
      }

      &__title {
        margin-bottom: 20px;
        font-size: 20px;
      }

      &__data-list {
        margin-bottom: 10px;
      }

      &__links {
        margin-top: 25px;
        color: $color-blue;
      }
    }
  }
</style>
