var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "gd-form gd-form-user-company-switch",
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c("section", { staticClass: "gd-form__section" }, [
        _c(
          "div",
          { staticClass: "gd-input" },
          [
            _c("span", { staticClass: "gd-input__label" }, [
              _vm._v("Aktywna firma")
            ]),
            _vm._v(" "),
            _c("gdSelect", {
              attrs: {
                placeholder: "Wybierz z listy...",
                name: "currentCompanyId",
                items: _vm.userCompanies
              },
              model: {
                value: _vm.form.currentCompany,
                callback: function($$v) {
                  _vm.$set(_vm.form, "currentCompany", $$v)
                },
                expression: "form.currentCompany"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gd-form__submit" },
        [
          _c(
            "gdButton",
            {
              attrs: { loading: _vm.loading },
              nativeOn: {
                click: function($event) {
                  return _vm.handleSubmit($event)
                }
              }
            },
            [_vm._v("\n      Przełącz\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }