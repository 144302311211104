var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "gd-form-service-contact gd-form",
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c(
        "gdTextArea",
        {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required",
              expression: "'required'"
            }
          ],
          attrs: { name: "message", "data-vv-as": "wiadomość" },
          model: {
            value: _vm.form.message,
            callback: function($$v) {
              _vm.$set(_vm.form, "message", $$v)
            },
            expression: "form.message"
          }
        },
        [_vm._v("\n    Wiadomość\n  ")]
      ),
      _vm._v(" "),
      _vm.formSent
        ? _c("p", { staticClass: "gd-form-service-contact__confirm" }, [
            _vm._v("\n    Twoja wiadomość została wysłana, dziękujemy.\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("gdButton", { attrs: { type: "submit", loading: _vm.loading } }, [
        _vm._v("\n    Wyślij\n  ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }