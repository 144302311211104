<template>
  <gdEditAreas />
</template>
<script>
  import gdEditAreas from '@/pages/app/activity-register/gd-edit-areas'
  import Store from '@/stores'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    async beforeRouteEnter(to, from, next){
      try {
        await Store.dispatch('editAreas/GET_AREAS')
        next()
      } catch(error) {
        console.error(`Couldn't resolve gd-edit-areas beforeRouteEnter requests`, { error })
        next({
          name: 'app.error',
          query: {
            type: 'API'
          }
        })
      }
    },
    beforeRouteLeave(to, from, next){
      const unsavedChanges = Store.state.editAreas.changes.length > 0
      const self = this

      if(unsavedChanges){

        this.$store.dispatch('prompt/SHOW', {
          content: this.$store.getters['editAreas/changesCopy'],
          title: 'Masz niezapisane zmiany w obszarach',
          cancelLabel: 'NIE',
          acceptLabel: 'TAK',
          onAccept(){
            self.$store.commit('editAreas/CLEAR_CHANGES')
            next()
          },
        })

        return
      }

      return next()
    },
    beforeDestroy(){
      this.$store.commit('editAreas/RESET_STATE')
    },

    components: {
      gdEditAreas
    }
  }
</script>
