const initialState = () => ({
  userCompanies: []
})

const state = () => (initialState())

const actions = {
  async GET_USER_COMPANIES ({ commit, dispatch }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'company/all'
      }, { root: true })

      console.log('company/GET_USER_COMPANIES', { data })
      commit('SET_USER_COMPANIES', { userCompanies: data })
    } catch(error){
      console.error('company/GET_USER_COMPANIES', { error })
      throw error
    }
  },

  async SET_CURRENT_COMPANY({ commit, dispatch }, companyId){
    try {
      const { data: response } = await dispatch('api/REQUEST', {
        endpoint: `company/${companyId}`,
        method: 'PUT'
      }, { root: true })

      console.log('company/SET_CURRENT_COMPANY', { response })
    } catch (error) {
      console.error('company/SET_CURRENT_COMPANY', { error })
      throw error
    }
  },
}

const mutations = {
  SET_USER_COMPANIES(state, { userCompanies }) {
    state.userCompanies = userCompanies
  }
}

const getters = {
  userCompanies: state => {
    return state.userCompanies.map(item => ({ label: item.name, value: item.id }))
  }
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
