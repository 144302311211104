var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-documents" },
    [
      _c("gdPageHeader", { attrs: { title: "Twoje dokumenty" } }),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "gd-documents__base" },
        _vm._l(_vm.filesCategories, function(fileCategory, index) {
          return _vm.filesCategories
            ? _c("gdBrickFilesUpload", {
                key: index,
                attrs: {
                  title: fileCategory.name,
                  fileList: fileCategory.files,
                  categoryId: fileCategory.id
                },
                on: {
                  fileUploaded: _vm.reloadDocuments,
                  fileDeleted: _vm.reloadDocuments
                }
              })
            : _vm._e()
        })
      ),
      _vm._v(" "),
      _vm.userUARDocs
        ? _c("gdBrickFilesList", {
            attrs: {
              title: "Raporty z Uproszczonej Analizy Ryzyka",
              filesList: _vm.userUARDocs,
              fileType: "uar"
            },
            on: { fileDeleted: _vm.reloadDocuments }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("gdPageHeader", { attrs: { title: "Wzory dokumentów" } }),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "gd-documents__examples" },
        [
          _c(
            "gdBricksWrapper",
            _vm._l(_vm.featuredDocuments, function(doc, docIndex) {
              return _vm.featuredDocuments
                ? _c(
                    "gdBrickDashboard",
                    {
                      key: docIndex,
                      attrs: {
                        slot: (docIndex + 1) % 3 ? "wide" : "thin",
                        disabled: false,
                        thin: !!((docIndex + 1) % 3),
                        iconName: "docs",
                        title: doc.name,
                        content: doc.shortDescritpion
                      },
                      slot: (docIndex + 1) % 3 ? "wide" : "thin"
                    },
                    [
                      _c(
                        "template",
                        { slot: "buttons" },
                        [
                          _c(
                            "gdButton",
                            {
                              attrs: { theme: "secondary" },
                              nativeOn: {
                                click: function($event) {
                                  _vm.downloadDocument({ documentId: doc.id })
                                }
                              }
                            },
                            [_vm._v("\n            Pobierz\n          ")]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                : _vm._e()
            })
          ),
          _vm._v(" "),
          _vm._l(_vm.documentsCategories, function(list, index) {
            return _vm.documentsCategories
              ? _c("gdBrickFilesList", {
                  key: index,
                  attrs: {
                    title: list.name,
                    filesList: list.documents,
                    fileType: "user-docs"
                  }
                })
              : _vm._e()
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }