<template>
  <section
    class="gd-uar-steps"
    ref="rodoStepsHolder"
  >
    <span
      v-if="!allStepsCompleted"
      class="gd-uar-steps__progress-bg"
    />

    <gdUarSingleStep
      v-for="(group, index) in groups"
      :group="group"
      :key="group.id"
      :groupIndex="index"
    />

  </section>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdUarSingleStep from '@/components/uar-steps/gd-uar-single-step'

  export default {
    props: {
      groups: Array
    },

    computed: {
      ...mapGetters('simplifiedRiskAnalysis', [
        'allStepsCompleted'
      ]),
    },

    components: {
      gdUarSingleStep
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/mixins/breakpoints.scss";

  .gd-uar-steps {
    position: relative;
    width: 100%;

    .gd-brick__inner {
      padding: 22px 40px 18px 140px;
      min-height: unset;

      .gd-rodo-step-header + div {
        position: relative;
        left: -50px;
        width: calc(100% + 50px);
        padding-left: 77px;
        margin-left: -5px;
        text-align: left;
        overflow: hidden;
      }
    }

    &__progress-bg {
      position: absolute;
      top: 43px;
      left: 51px;
      width: 4px;
      height: calc(100% - 76px);
      background-color: $color-blue-light;
    }

    .gd-image {
      height: unset;
      margin-bottom: auto;
    }
  }

  .gd-uar-step {
    min-height: unset !important;

    &__content {
      width: 100%;
      height: 0;
      margin-top: 20px;
      font-size: 16px;
      line-height: 20px;
      color: $color-gray-light;
      transition: height .15s;
      position: relative;

      p {
        max-width: 500px;
      }
    }

    &--active {
      .gd-brick__inner {
        padding-bottom: 38px;
      }

      .gd-uar-step__content {
        display: flex;
        flex-flow: column nowrap;
        height: 530px;
      }

      .gd-uar-step__content-extra {
        position: absolute;
        top: 0;
        left: -60px;
        bottom: 0;
        height: 100%;
        width: 60px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }

      .gd-uar-step__questions,
      .questions_list,
      .question__answers,
      .question {
        height: 100%;
      }

      .gd-uar-step__questions > div {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .question-answered-message {
        margin-bottom: auto;
      }
    }

    &--completed {
      .gd-uar-step__content {
        overflow: hidden;
      }
    }

    .question {
      display: flex;
      flex-flow: column nowrap;

      &s {
        height: 100%;
      }

      &s_list {
        position: relative;

        .gd-btn--ghost {
          position: absolute;
          left: -55px;
          bottom: 0;
          min-width: unset;
        }
      }

      .question__buttons {
        display: flex;
      }

      .gd-button-wrapper {
        justify-content: flex-start;
      }
    }

    .question__buttons {
      display: flex;
      margin-top: auto;

      .gd-btn {
        margin: 0 5px;

        @include media(1300px) {
          font-size: 10px;
        }
      }
    }

    .question__answers {
      height: 100%;
    }
  }
</style>
