<template>
  <div class="gd-continue-implementation">
    <p class="gd-continue-implementation__heading">Kontynuuj</p>
    <p class="gd-continue-implementation__text">Jeśli uzupełniłeś już {{ title }} możesz wrócić i kontynuować wdrożenie.</p>
    <gdButton
      :link="{ name: 'app' }"
    >
      Wróć do wdrożenia
    </gdButton>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        title: 'rejestr'
      }
    },

    mounted() {
      this.getPageTitle()
    },

    methods: {
      getPageTitle () {
        const pageTitle = document.getElementsByClassName('gd-page-header__info')[0].children[0].innerText
        if (pageTitle) {
          this.title = pageTitle
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .gd-continue-implementation {
    margin-top: auto;
    padding-top: 60px;

    &__heading {
      font: {
        size: 28px;
        family: $font-medium;
      }
      line-height: 33px;
    }

    &__text {
      margin: 5px 0 16px;
      font-size: 16px;
      line-height: 20px;
      color: $color-gray-light;
    }
  }
</style>
