<template>
  <div class="gd-brick-vertical">
    <gdBrick
      :thin="thin"
      :disabled="false"
    >
      <h2 class="brick__title">{{ title }}</h2>
      <p class="brick__content">{{ content }}</p>
      <slot name="image" />
      <slot name="buttons" />
    </gdBrick>
  </div>
</template>

<script>
  import gdBrick from '@/components/bricks/gd-brick'

  export default {
    props: {
      title: String,
      content: String,
      thin: {
        type: Boolean,
        default: false
      }
    },
    components: {
      gdBrick
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-brick-vertical {
    width: 100%;

    .brick {
      &__title {
        font-size: 20px;
        margin-bottom: 10px;
      }

      &__content {
        font-size: 16px;
        margin-bottom: 30px;
        color: $color-gray-light;
      }
    }

    .gd-button-wrapper {
      margin-top: 50px;
    }
  }
</style>
