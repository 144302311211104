var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: _vm.rootCssClasses },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("span", { staticClass: "gd-table-header__border-right" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }