import filestack from 'filestack-js'

const API_KEY = 'A4Zceoh4uQAq6HWBqS6dEz'
const SECURITY = {
  policy: 'eyJjYWxsIjpbInBpY2siLCJzdG9yZSJdLCJleHBpcnkiOjE4OTM1NDI0MDB9',
  signature: '6ea6060ac9ae2b4eeda69cae683f4e007b665f2b2ebee001277271b5f6fdf5fc'
};

const client = filestack.init(API_KEY, SECURITY)

const initialState = () => ({})

const state = () => (initialState())

const actions = {
  async UPLOAD_FILE({ dispatch }){
    try {
      const upload = await client.pick({
        fromSources: ['local_file_system'],
        accept: ['image/*', '.pdf','.doc', '.docx', '.docm', '.xlsx'],
        lang:'pl',
        disableTransformer: true,
      })

      const fails = upload && upload.filesFailed && upload.filesFailed.length
      const successes = upload && upload.filesUploaded && upload.filesUploaded.length
      if(!fails && successes){
        dispatch('toasts/ADD_TOAST', {
          toast : {
            message: 'Przesyłanie pliku zakończono pomyślnie.'
          }
        }, { root: true })
      }

      return upload
    } catch (error){
      console.error('filestack/UPLOAD failed', { error })
      throw error
    }
  }
}

const mutations = {}

const getters = {}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
