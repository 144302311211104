var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section--wide" },
    [
      _c("gdPageHeader", { attrs: { title: "Wybierz plan płatności" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gd-payment-plans" },
        [
          _c(
            "gdBrick",
            { staticClass: "gd-payment-plans__block block-info gd-brick" },
            [
              _c(
                "ul",
                { staticClass: "gd-payment-plans__benefits" },
                _vm._l(_vm.benefits, function(benefit, index) {
                  return _c("li", { key: index }, [
                    _vm._v("\n          " + _vm._s(benefit.name) + "\n        ")
                  ])
                })
              )
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.plans, function(plan, index) {
            return _c(
              "gdBrick",
              {
                directives: [
                  {
                    name: "match-heights",
                    rawName: "v-match-heights",
                    value: {
                      el: [
                        ".gd-payment-plans__benefits li",
                        ".block-plan__title"
                      ]
                    },
                    expression:
                      "{\n        el: ['.gd-payment-plans__benefits li', '.block-plan__title']\n      }"
                  }
                ],
                key: index,
                staticClass: "gd-payment-plans__block block-plan"
              },
              [
                _c("p", { staticClass: "block-plan__title" }, [
                  _vm._v(_vm._s(plan.name))
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "gd-payment-plans__benefits" },
                  _vm._l(plan.benefitValues, function(item, index) {
                    return _c("li", { key: index }, [_vm._v(_vm._s(item.info))])
                  })
                ),
                _vm._v(" "),
                _c("p", { staticClass: "block-plan__price" }, [
                  _vm._v(_vm._s(plan.price))
                ]),
                _vm._v(" "),
                _c(
                  "gdButton",
                  {
                    nativeOn: {
                      click: function($event) {
                        _vm.manageSignUpForm(plan.id, index)
                      }
                    }
                  },
                  [_vm._v("Wybierz")]
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }