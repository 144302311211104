<template>
  <div :class="rootCssClasses">
    <p class="row__title">{{ title }}</p>
    <div class="row__content">
      <gdButton
        v-if="innerMode === 'REMOVABLE'"
        theme="ghost"
        @click.native="deleteArea"
      >
        Usuń obszar
      </gdButton>
      <gdButton
        v-else-if="innerMode === 'AVAILABLE'"
        theme="ghost"
        @click.native="addArea"
      >
        Dodaj obszar
      </gdButton>
      <p v-else>Nie możesz usunąć tego Obszaru, ponieważ przetwarzasz czynności z jego zakresu.</p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      mode: String,
      title: String,
      id: [Number, String]
    },

    data(){
      return {
        innerMode: this.mode
      }
    },

    computed: {
      rootCssClasses(){
        const base = 'gd-edit-area-row'
        const mode = this.innerMode
        const classes = [
          base,
          mode !== 'REMOVABLE' && mode !== 'AVAILABLE' ? `${base}--disabled` : null,
          mode === 'REMOVABLE' ? `${base}--active` : null,
          mode === 'AVAILABLE' ? `${base}--available` : null
        ]
          .filter(item => !!item)

        return classes.join(' ')
      }
    },

    methods: {
      addArea() {
        this.$store.commit('editAreas/SET_AREA_CHANGE', { id: this.id, changeType: 'ADD' })
        this.innerMode = 'REMOVABLE'
      },

      deleteArea() {
        this.$store.commit('editAreas/SET_AREA_CHANGE', { id: this.id, changeType: 'REMOVE' })
        this.innerMode = 'AVAILABLE'
      }
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-edit-area-row {
    .row {
      &__title {
        font-size: 20px;
        margin-bottom: 10px;
        cursor: default;
      }

      &__content {
        height: 50px;
        padding: 17px 20px 15px;
        border: 1px solid $color-brick-border;
        border-radius: 3px;
        margin: 10px 0 20px;
        background-color: $color-white;
        box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);

        .gd-button-wrapper,
        .gd-btn {
          height: unset;
        }

        p {
          font-size: 16px;
          color: $color-gray-light;
        }
      }
    }

    &--active {
      .gd-btn {
        color: $color-red;
      }

      .row__content {
        background-color: transparent;
      }
    }

    &--available {
      .row__content {
        background-color: transparent;
      }
    }

    &--disabled {
      .row__content {
        cursor: default;
      }
    }

    &--active,
    &--available,
    &--disabled {
      &:last-of-type {
        padding-bottom: 15px;
        border-bottom: 1px solid $color-brick-border;
        margin-bottom: 25px;
      }
    }
  }
</style>
