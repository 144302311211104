var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gd-brick-data-processing-info" },
    [
      _c(
        "gdBrick",
        { attrs: { thin: _vm.thin, disabled: false } },
        [
          _c(
            "section",
            { staticClass: "gd-brick-data-processing-info__section" },
            [
              _c("p", { staticClass: "brick__title" }, [
                _vm._v("Informacje przetwarzane")
              ]),
              _vm._v(" "),
              _vm._l(_vm.dataList, function(data, index) {
                return _c(
                  "p",
                  {
                    key: index,
                    staticClass: "brick__content brick__data-list"
                  },
                  [_vm._v("\n        " + _vm._s(data.label) + "\n      ")]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "gdButton",
            {
              attrs: { theme: "warning" },
              nativeOn: {
                click: function($event) {
                  return _vm.deleteAccount($event)
                }
              }
            },
            [_vm._v("\n      Usuń konto\n    ")]
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "brick__content brick__links" },
            [
              _c("gdLink", { attrs: { to: "#" } }, [
                _vm._v("Polityka prywatności,")
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "/assets/regulations/regulamin_korzystania_z_gdpsystem.pdf",
                    target: "_blank"
                  }
                },
                [_vm._v("\n        Regulamin\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }