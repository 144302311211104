var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "gd-form gd-form-add-rcpd-custom-value",
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c("p", { staticStyle: { "font-weight": "600" } }, [
        _vm._v("Dodaj własną wartość")
      ]),
      _vm._v(" "),
      _c("gdInput", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'"
          }
        ],
        attrs: { name: "value", "data-vv-as": "wartość" },
        model: {
          value: _vm.form.valueName,
          callback: function($$v) {
            _vm.$set(_vm.form, "valueName", $$v)
          },
          expression: "form.valueName"
        }
      }),
      _vm._v(" "),
      _c(
        "gdButton",
        {
          attrs: { loading: _vm.loading },
          nativeOn: {
            click: function($event) {
              return _vm.handleSubmit($event)
            }
          }
        },
        [_vm._v("\n    Zapisz\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }