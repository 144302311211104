var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "gd-rodo-step-header",
      class: {
        "gd-rodo-step-header--active": _vm.active,
        "gd-rodo-step-header--completed": _vm.completed
      }
    },
    [
      _c("gdBulletStep", {
        attrs: { stepActive: _vm.active, stepSuccess: _vm.completed }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gd-rodo-step-menu" },
        [
          _vm.rollbackable
            ? _c(
                "gdButton",
                {
                  attrs: {
                    title: "Powrót do poprzedniego etapu",
                    loading: _vm.loading
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.rollbackStepModal($event)
                    }
                  }
                },
                [
                  _c("gdImg", {
                    attrs: { src: "/assets/icons/icon-arrow-up.png" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.resettable
            ? _c(
                "gdButton",
                {
                  attrs: {
                    title: "Reset bieżącego etapu",
                    loading: _vm.loading
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.resetStepModal($event)
                    }
                  }
                },
                [
                  _c("gdImg", {
                    attrs: { src: "/assets/icons/icon-reset.png" }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("h1", { staticClass: "gd-rodo-step-header__title" }, [
        _vm._v(_vm._s(_vm.completed ? _vm.completedTitle : _vm.title))
      ]),
      _vm._v(" "),
      _vm.sideTitle
        ? _c(
            "h2",
            {
              staticClass: "gd-rodo-step-header__sidetitle",
              style: { color: _vm.sideTitle.color }
            },
            [_vm._v(_vm._s(_vm.sideTitle.text))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.iconName
        ? _c("gdIcon", {
            staticClass: "gd-rodo-step-header__icon",
            attrs: { name: _vm.iconName, large: true }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }