var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-simplified-risk-analysis" },
    [
      _c("gdPageHeader", {
        attrs: {
          title: "Uproszczona Analiza Ryzyka",
          description: "Poziom ryzyka dla przetwarzania danych osobowych"
        }
      }),
      _vm._v(" "),
      _vm.initLoading
        ? _c(
            "gdBricksWrapper",
            [
              _c(
                "transition",
                {
                  attrs: { slot: "wide", name: "global-slide", mode: "out-in" },
                  slot: "wide"
                },
                [
                  _vm.introSlides &&
                  _vm.introSlides.length &&
                  !_vm.lastAnalysis &&
                  !_vm.hideIntro
                    ? _c("gdSliderAppIntro", {
                        staticClass: "gd-brick--large",
                        attrs: {
                          items: _vm.introSlides,
                          startBtnTitle: "Rozpocznij"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "transition",
                {
                  attrs: { slot: "wide", name: "global-slide", mode: "out-in" },
                  slot: "wide"
                },
                [
                  _vm.lastAnalysis || _vm.hideIntro
                    ? _c("gdUarSteps", { attrs: { groups: _vm.groups } })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "gdBrick",
                { attrs: { slot: "thin" }, slot: "thin" },
                [
                  _c("gdChartProgress", {
                    attrs: {
                      title:
                        _vm.lastAnalysis || _vm.hideIntro
                          ? "Postęp analizy"
                          : "Rozpocznij Analizę",
                      content:
                        _vm.lastAnalysis || _vm.hideIntro
                          ? _vm.progressDescription
                          : "",
                      value: _vm.stepsProgress
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.documents, function(doc) {
                return _vm.documents.length
                  ? _c(
                      "gdBrickDashboard",
                      {
                        key: doc.id,
                        attrs: {
                          slot: "thin",
                          iconName: "docs",
                          title: doc.name,
                          content: doc.shortDescription,
                          disabledTooltipMessage:
                            "Moduł będzie dostepny po uzupełnieniu niezbednych danych",
                          disabled: !doc.available
                        },
                        slot: "thin"
                      },
                      [
                        _c(
                          "template",
                          { slot: "buttons" },
                          [
                            _c(
                              "gdButton",
                              {
                                attrs: { theme: "secondary" },
                                nativeOn: {
                                  click: function($event) {
                                    _vm.downloadDocument({
                                      documentId: doc.id,
                                      specialType: doc.specialType
                                    })
                                  }
                                }
                              },
                              [_vm._v("\n          Pobierz\n        ")]
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _c(
                "gdBrickDashboard",
                {
                  attrs: {
                    slot: "thin",
                    iconName: "docs",
                    title: "Raport Uproszczonej Analizy Ryzyka",
                    content: "Wynik przeprowadzonej analizy.",
                    disabledTooltipMessage:
                      "Moduł będzie dostepny po uzupełnieniu niezbednych danych",
                    disabled: !_vm.allStepsCompleted && !_vm.raportLoading
                  },
                  slot: "thin"
                },
                [
                  _c(
                    "template",
                    { slot: "buttons" },
                    [
                      _c(
                        "gdButton",
                        {
                          attrs: { theme: "secondary" },
                          nativeOn: {
                            click: function($event) {
                              _vm.downloadDocument({
                                documentId: _vm.lastAnalysis.id,
                                specialType: _vm.lastAnalysis.specialType
                              })
                            }
                          }
                        },
                        [_vm._v("\n          Pobierz\n        ")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("gdModalInfo", {
        attrs: {
          iconName: "dashboard",
          title: "Rozpoczynasz Uproszczoną Analizę Ryzyka",
          content:
            "Celem analizy ryzyka jest określenie jego wysokości i w konsekwencji wskazanie rekomendowanych środków bezpieczeństwa technicznego i organizacyjnego właściwych dla ustalonego poziomu ryzyka.",
          buttonLabel: "Rozpocznij"
        },
        on: { beforeClose: _vm.setIntroModalAsSeen, buttonClick: _vm.hideModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }