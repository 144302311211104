<template>
  <div class="gd-info-text-list">
    <h2
      v-if="element.title"
      class="gd-info-text-list__heading"
    >
      {{ element.title }}
    </h2>
    <p
      v-if="element.info"
      class="gd-info-text-list__info"
    >
      {{ element.info }}
    </p>
    <ol>
      <li
        v-for="(item, index) in element.list"
        :key="index"
      >
        <div
          v-if="item.listHeading"
        >
          <span
            class="nested-list__heading"
            v-html="item.listHeading"
          />
          <ol>
            <li
              v-for="(el, index) in item.nestedList"
              :key="index"
              class="nested-list__el"
            >
              {{ index + 1 }}. <span v-html="el.text"/>
            </li>
          </ol>
        </div>
        <span
          v-else
          v-html="item.text"
        >
          <span
            v-if="item.heading"
            class="nested-list__el--bold"
          >
            {{ item.heading }} -
          </span>
        </span>
      </li>
    </ol>
  </div>
</template>

<script>
  export default {
    props: {
      element: Object
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .gd-info-text-list {
    ol {
      padding-left: 20px;
      list-style-type: decimal;
    }

    &__heading {
      margin-bottom: 10px;
      font: {
        size: 20px;
        family: $font-bold;
      }
    }

    &__info {
      margin-bottom: 10px;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    li {
      position: relative;
      margin-bottom: 10px;
      line-height: 20px;
    }

    .nested-list {
      &__heading {
        display: block;
        margin-bottom: 10px;
      }

      &__el {
        position: relative;
        display: block;
        margin-bottom: 10px;
        color: $color-gray-dark;

        a {
          color: $color-blue;
        }

        &--bold {
          font-family: $font-medium;
        }
      }
    }

    .bold {
      font-family: $font-bold;
    }

    a {
      color: $color-blue;
    }
  }
</style>
