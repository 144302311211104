<template>
  <form
    class="gd-form-service-contact gd-form"
    @submit.prevent="handleSubmit"
    novalidate
  >
    <gdTextArea
      v-model="form.message"
      name="message"
      data-vv-as="wiadomość"
      v-validate="'required'"
    >
      Wiadomość
    </gdTextArea>
    <p
      v-if="formSent"
      class="gd-form-service-contact__confirm"
    >
      Twoja wiadomość została wysłana, dziękujemy.
    </p>
    <gdButton
      type="submit"
      :loading="loading"
    >
      Wyślij
    </gdButton>
  </form>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      serviceTitle: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        form: {
          message: null
        },
        loading: false,
        formSent: false
      }
    },

    methods: {
      async handleSubmit(){
        try {
          const isValid = await this.$validator.validateAll()

          if(!isValid){
            return
          }

          this.loading = true
          this.formSent = false
          await this.$store.dispatch('contact/SEND_CONTACT_FORM', {
            type: 'service',
            data: {
              service: this.serviceTitle,
              message: this.form.message
            }
          })
          this.loading = false
          this.clearForm()
          this.formSent = true

        } catch (error){
          console.error('gd-form-service-contact', {error})
          this.$store.dispatch('toasts/ADD_TOAST', { toast: { message: 'Wystąpił błąd podczas wysyłki formularza.', type: 'error' }})
          this.loading = false
        }
      },

      clearForm () {
        Object.keys(this.form).forEach((key) => {
          this.form[key] = null
        })
      }
    },

    computed: {
      ...mapGetters('modals', ['modalPlanName'])
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-form-service-contact {
    .gd-button-wrapper {
      width: 100%;
      margin-top: 35px !important;
      justify-content: center;
    }

    .gd-input__label {
      text-align: left !important;
    }

    .gd-btn {
      width: 200px;
    }

    &__confirm {
      margin-top: 10px;
      color: darken($color-green, 5);
    }
  }
</style>
