<template>
  <gdCertificate />
</template>
<script>
  import Store from '@/stores'
  import gdCertificate from '@/pages/app/gd-certificate'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    async beforeRouteEnter(to, from, next){
      try {
        const userMeta = Store.getters['user/userMeta']
        const isStep6Completed = userMeta && userMeta.steps && userMeta.steps[6] && userMeta.steps[6].completed
        if(!isStep6Completed){

          const stepsMeta = Object.keys(userMeta.steps).reduce((res, stepKey) => {
            const step = { ...userMeta.steps[stepKey] }
            if(stepKey === '6'){
              step.completed = true
            }
            res[stepKey] = step
            return res
          }, {})

          await Store.dispatch('user/SET_META', {
            steps: stepsMeta
          }, { root: true })

        }
      } catch(error) {
        console.error({ error })
      }

      try {
        await Store.dispatch('certificate/GET_DATES')
        next()
      } catch(error) {
        console.error(`Couldn't resolve gd-certificate beforeRouteEnter requests`, { error })
        next({
          name: 'app.error',
          query: {
            type: 'API'
          }
        })
      }
    },

    components: {
      gdCertificate
    }
  }
</script>
