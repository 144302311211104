var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gdBrick",
    { class: _vm.rodoStepClass },
    [
      _c("gdRodoStepHeader", {
        attrs: {
          title:
            "Uzupełnij profil" +
            (_vm.step3.active &&
            !_vm.step3.questionsAnswered &&
            _vm.step3.questions
              ? " - pytanie " +
                (_vm.step3.activeQuestionIndex + 1) +
                " z " +
                _vm.step3.questions.length
              : ""),
          completedTitle: "Profil uzupełniony",
          iconName: "user",
          active: _vm.step3.active,
          completed: _vm.step3.completed,
          resettable: _vm.step3.active && !_vm.step3.completed,
          rollbackable: _vm.step3.active && !_vm.step3.completed
        }
      }),
      _vm._v(" "),
      _c(
        "gdGrowTransition",
        [
          _vm.step3.active
            ? _c("gdGrowTransitionContent", [
                _c(
                  "div",
                  { staticClass: "gd-rodo-step__content" },
                  [
                    _vm.step3.questions
                      ? _c("gdRodoStepQuestions", {
                          attrs: {
                            items: _vm.step3.questions,
                            questionsAnswered: _vm.step3.questionsAnswered,
                            questionsAnsweredMessage:
                              "Dziękujemy za udzielenie odpowiedzi, przygotowaliśmy dla Ciebie Rejestr Kategorii Przetwarzań do wypełnienia.",
                            stepId: _vm.stepId
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "section",
                      { staticClass: "gd-rodo-step__content-extra" },
                      [
                        _vm.step3.activeQuestionIndex > 0
                          ? _c(
                              "gdButton",
                              {
                                attrs: { theme: "ghost" },
                                nativeOn: {
                                  click: function($event) {
                                    _vm.previousQuestion()
                                  }
                                }
                              },
                              [
                                _c("gdImg", {
                                  attrs: {
                                    src: "/assets/icons/icon-arrow-purple.svg"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }