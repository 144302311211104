<template>
  <form
    class="gd-form gd-form-log-in"
    @submit.prevent="handleSubmit"
    novalidate
  >
    <gdInput
      v-model="form.username"
      name="email"
      data-vv-as="adres email"
      v-validate="'required|email'"
    >
      Adres Email
    </gdInput>
    <gdInput
      v-model="form.password"
      name="password"
      type="password"
      data-vv-as="hasło"
      v-validate="'required'"
    >
      Hasło
    </gdInput>
    <gdButton
      type="submit"
      :loading="loading"
    >
      Zaloguj
    </gdButton>
  </form>
</template>
<script>
  import objectPath from "object-path";

  export default {
    data() {
      return {
        form: {
          username: null,
          password: null
        },
        loading: false
      };
    },

    methods: {
      async handleSubmit() {
        try {
          const isValid = await this.$validator.validateAll();

          if (!isValid) {
            return;
          }

          this.loading = true;
          await this.$store.dispatch("user/LOG_IN", this.form);
          this.$router.push({ name: "app.dashboard" });
        } catch (error) {
          console.error("gd-form-log-in", { error });
          let message = "Użytkownik z tymi danymi nie istnieje";
          const errorCode = objectPath.get(error, "response.data.error.code");
          console.log(errorCode, "errorCode");

          switch (errorCode) {
          case "invalid_password":
            message = "Podane hasło jest nieprawidłowe";
            break;
          case "invalid_username":
            message = "Nieprawidłowa nazwa użytkownika";
            break;
          case "user_plan_payment_pending":
            message = "Oczekujemy na płatność od tego użytkownika";
            break;
          case "company_locked":
            message = "W tym momencie zalogowany jest inny użytkownik";
            break;
          }

          this.$store.dispatch("toasts/ADD_TOAST", {
            toast: { message: `${message}`, type: "error" }
          });
          this.loading = false;
        }
      }
    }
  };
</script>

<style lang="scss">
  .gd-form-log-in {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0 auto;
    width: 400px;

    div.gd-input {
      margin-right: 0;
    }

    .gd-btn {
      width: 180px;
    }
  }
</style>
