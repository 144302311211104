import { formatColumnValues } from '@/helpers'

export default function formatActivities({ activities }) {

  if (!Object.keys(activities).length) {
    return
  }

  return Object.keys(activities).reduce((res, key) => {
    const item = activities[key]
    const { columnValues, id, info, name, originalActivityId, isCustom } = item
    console.log({ columnValues, id, info, name, originalActivityId })
    const columns = formatColumnValues({ columnValues })

    res[key] = {
      columns,
      isCustom,
      id,
      info,
      name,
      originalActivityId
    }

    return res
  }, {})
}
