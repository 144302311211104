var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-certificate" },
    [
      _c("gdPageHeader", {
        attrs: {
          title: "Certyfikat",
          description:
            "Potwierdzamy możliwość posługiwania się certyfikatem Good Data Protection Standard."
        }
      }),
      _vm._v(" "),
      _c(
        "gdBricksWrapper",
        [
          _c(
            "gdBrickVertical",
            {
              attrs: {
                slot: "wide",
                title: "Pobierz certyfikat",
                content: "Good Data Protection Standard"
              },
              slot: "wide"
            },
            [
              _c(
                "figure",
                {
                  staticClass: "certificate-image",
                  attrs: { slot: "image" },
                  slot: "image"
                },
                [
                  _c("gdImg", {
                    attrs: {
                      srcset:
                        "/assets/images/certyfikat.png 1x, /assets/images/certyfikat@2x.png 2x"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "gd-certificate__name" }, [
                    _vm._v(_vm._s(_vm.companyName))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "gd-certificate__date" }, [
                    _vm._v(_vm._s(_vm.certificateDate))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "gdButton",
                {
                  attrs: { slot: "buttons" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.downloadCertificate($event)
                    }
                  },
                  slot: "buttons"
                },
                [_vm._v("\n        Pobierz certyfikat\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("gdBrickCertificateInfo", {
            attrs: {
              slot: "thin",
              disabled: false,
              title: "Informacje o certyfikacie",
              content:
                "podmiot certyfikujący: Good Data Protection Standard Sp. Z.o.o. z siedzibą w Warszawie",
              startDate: _vm.startDateFormat,
              endDate: _vm.endDateFormat
            },
            slot: "thin"
          }),
          _vm._v(" "),
          _c(
            "gdBrickVertical",
            {
              attrs: { slot: "thin", title: "Mini certyfikat na stronę" },
              slot: "thin"
            },
            [
              _c(
                "div",
                {
                  staticClass: "mini-cerificates",
                  attrs: { slot: "image" },
                  slot: "image"
                },
                [
                  _c(
                    "figure",
                    [
                      _c("gdImg", {
                        attrs: {
                          srcset:
                            "/assets/images/mini-certyfikat-pionowy@1x.png 1x, /assets/images/mini-certyfikat-pionowy@2x.png 2x"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "figure",
                    [
                      _c("gdImg", {
                        attrs: {
                          srcset:
                            "/assets/images/mini-certyfikat-poziomy@1x.png 1x, /assets/images/mini-certyfikat-poziomy@2x.png 2x"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "gdButton",
                {
                  attrs: { slot: "buttons" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.downloadCertificateImages($event)
                    }
                  },
                  slot: "buttons"
                },
                [_vm._v("\n        Pobierz\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }