<template>
  <section class="gd-certificate">
    <gdPageHeader
      title="Certyfikat"
      description="Potwierdzamy możliwość posługiwania się certyfikatem Good Data Protection Standard."
    />

    <gdBricksWrapper>
      <gdBrickVertical
        slot="wide"
        title="Pobierz certyfikat"
        content="Good Data Protection Standard"
      >
        <figure
          class="certificate-image"
          slot="image"
        >
          <gdImg
            srcset="/assets/images/certyfikat.png 1x, /assets/images/certyfikat@2x.png 2x"
          />
          <p class="gd-certificate__name">{{ companyName }}</p>
          <p class="gd-certificate__date">{{ certificateDate }}</p>
        </figure>
        <gdButton
          slot="buttons"
          @click.native="downloadCertificate"
        >
          Pobierz certyfikat
        </gdButton>
      </gdBrickVertical>
      <gdBrickCertificateInfo
        slot="thin"
        :disabled="false"
        title="Informacje o certyfikacie"
        content="podmiot certyfikujący: Good Data Protection Standard Sp. Z.o.o. z siedzibą w Warszawie"
        :startDate="startDateFormat"
        :endDate="endDateFormat"
      />
      <gdBrickVertical
        slot="thin"
        title="Mini certyfikat na stronę"
      >
        <div
          class="mini-cerificates"
          slot="image"
        >
          <figure>
            <gdImg
              srcset="/assets/images/mini-certyfikat-pionowy@1x.png 1x, /assets/images/mini-certyfikat-pionowy@2x.png 2x"
            />
          </figure>
          <figure>
            <gdImg
              srcset="/assets/images/mini-certyfikat-poziomy@1x.png 1x, /assets/images/mini-certyfikat-poziomy@2x.png 2x"
            />
          </figure>
        </div>
        <gdButton
          slot="buttons"
          @click.native="downloadCertificateImages"
        >
          Pobierz
        </gdButton>
      </gdBrickVertical>
    </gdBricksWrapper>
  </section>
</template>
<script>
  import gdBrickVertical from '@/components/bricks/gd-brick-vertical'
  import gdBrickCertificateInfo from '@/components/bricks/gd-brick-certificate-info'
  import gdPageHeader from '@/components/page/gd-page-header'
  import gdBricksWrapper from '@/components/bricks/gd-bricks-wrapper'
  import { mapGetters } from 'vuex'

  export default {
    data () {
      return {
        companyName: null,
        certificateDate: null
      }
    },

    mounted() {
      this.certificateDate = this.formatDate()
      this.companyName = this.user.company.name
    },

    computed: {
      ...mapGetters('certificate', ['startDateFormat', 'endDateFormat']),
      ...mapGetters('user', ['user'])
    },

    methods: {
      async downloadCertificate(){
        try {
          await this.$store.dispatch('certificate/DOWNLOAD_CERTIFICATE')
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },

      async downloadCertificateImages(){
        window.open(`${window.location.origin}/downloads/mini-certyfikaty.zip`)
      },

      formatDate () {
        return this.startDateFormat.replace(/[/]/g, '.')
      }
    },

    components: {
      gdBrickVertical,
      gdPageHeader,
      gdBricksWrapper,
      gdBrickCertificateInfo
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .gd-certificate {
    .mini-cerificates {
      display: flex;
      justify-content: space-between;

      figure {
        padding: 0 10px;
      }
    }

    figure.certificate-image {
      position: relative;
      margin-bottom: 1px;
      font-family: $font-medium;
      color: $color-blue-dark;

      .gd-image > img {
        margin: 45px 0;
      }

      .gd-certificate__name {
        position: absolute;
        top: calc(50% + 17px);
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        font-size: 25px;
      }

      .gd-certificate__date {
        position: absolute;
        bottom: 102px;
        left: 100px;
        z-index: 1000;
        letter-spacing: .9px;
        font-size: 13px;
      }
    }
  }
</style>
