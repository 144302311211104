var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "gd-form gd-form-log-in",
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c(
        "gdInput",
        {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|email",
              expression: "'required|email'"
            }
          ],
          attrs: { name: "email", "data-vv-as": "adres email" },
          model: {
            value: _vm.form.username,
            callback: function($$v) {
              _vm.$set(_vm.form, "username", $$v)
            },
            expression: "form.username"
          }
        },
        [_vm._v("\n    Adres Email\n  ")]
      ),
      _vm._v(" "),
      _c(
        "gdInput",
        {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required",
              expression: "'required'"
            }
          ],
          attrs: { name: "password", type: "password", "data-vv-as": "hasło" },
          model: {
            value: _vm.form.password,
            callback: function($$v) {
              _vm.$set(_vm.form, "password", $$v)
            },
            expression: "form.password"
          }
        },
        [_vm._v("\n    Hasło\n  ")]
      ),
      _vm._v(" "),
      _c("gdButton", { attrs: { type: "submit", loading: _vm.loading } }, [
        _vm._v("\n    Zaloguj\n  ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }