<template>
  <gdBrick class="gd-slider-app-intro">
    <figure>
      <gdImg
        srcset="/assets/images/app-intro.jpg 1x, /assets/images/app-intro@2x.jpg 2x"
      />
    </figure>
    <nav class="gd-slider-app-intro__nav">
      <ul
        class="nav-list"
        :style="navListWidth(items)"
      >
        <li
          :class="bulletClass(index, activeIndex)"
          v-for="(item, index) in items"
          :key="index"
        >
          <gdIcon name="check"/>
        </li>
        <span
          class="swiper-progress"
          ref="swiperProgress"
        />
      </ul>
    </nav>
    <section class="gd-slider-app-intro__slider">
      <div v-swiper:introductionCarousel="swiperOption">
        <div class="swiper-wrapper">
          <article
            class="swiper-slide gd-slider-app-intro__slide"
            v-for="(item, index) in items"
            :key="index"
          >
            <h1 class="slide-title">{{ item.title }}</h1>
            <p class="slide-content">{{ item.content }}</p>
          </article>
        </div>
        <div class="slider-buttons">
          <gdButton
            theme="ghost"
            v-if="activeIndex > 0"
            @click.native="prevSlide"
            class="prev-slide"
          >
            <gdIcon name="arrow"/>
          </gdButton>
          <gdButton
            v-if="activeIndex !== items.length - 1"
            @click.native="nextSlide"
          >
            Dalej
            <gdIcon
              name="arrow"
              v-if="activeIndex <= items.length - 1"
            />
          </gdButton>
          <gdButton
            v-if="activeIndex === items.length - 1 && isLoggedIn"
            @click.native="startImplementation"
          >
            {{ startBtnTitle }}
          </gdButton>
        </div>
      </div>
    </section>
  </gdBrick>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdBrick from '@/components/bricks/gd-brick'

  export default {

    props: {
      items: Array,
      startBtnTitle: { type: String, default: 'Rozpocznij wdrożenie' }
    },

    data () {
      return {
        progress: 0,
        activeIndex: null,
        swiperOption: {
          slidesPerView: 1,
          simulateTouch: false,
          effect: 'slide',
          speed: 400,
          on: {
            slideChangeTransitionStart: this.slideChangeTransitionStart
          }
        }
      }
    },

    mounted() {
      this.activeIndex = this.introductionCarousel.activeIndex
    },

    computed: {
      ...mapGetters('user', ['isLoggedIn']),
      ...mapGetters('ui', ['isMobile']),

      activeSlideIndex(){
        return this.introductionCarousel ? this.introductionCarousel.activeIndex : 0
      }
    },

    methods: {
      bulletClass (a, b) {
        return {
          'nav-list__bullet': true,
          'nav-list__bullet--completed': a < b,
          'nav-list__bullet--active': a === b
        }
      },

      swiperProgress (list) {
        const maxWidth = (list.length * (this.isMobile ? 36.666 : 56.666))

        console.log({
          'this.progress': this.progress,
          maxWidth
        })

        if (this.progress <= 0) {
          this.progress = 0
        }
        if (this.progress >= maxWidth) {
          this.progress = maxWidth
        }

        this.$refs.swiperProgress.style.width = `${this.progress}px`
      },

      prevSlide(){
        this.progress = this.progress - (this.isMobile ? 45 : 65)
        this.swiperProgress(this.items)
        setTimeout(() => {
          this.introductionCarousel.slidePrev()
        }, 150);
      },

      nextSlide(){
        this.progress = this.progress + (this.isMobile ? 45 : 65)
        this.swiperProgress(this.items)
        setTimeout(() => {
          this.introductionCarousel.slideNext()
        }, 150);
      },

      slideChangeTransitionStart(){
        this.activeIndex = this.introductionCarousel.activeIndex
      },

      startImplementation(){
        if(this.isLoggedIn){
          this.$store.dispatch('user/SET_META', { sawIntroSlider: true })
          this.$store.commit('modals/SHOW_MODAL', { modalName: 'info' })
        }
      },

      navListWidth (list) {
        return `width: ${list.length * (this.isMobile ? 36.666 : 56.666)}px`
      }
    },
    components: {
      gdBrick
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/fonts.scss";
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/mixins/breakpoints.scss";

  .gd-slider-app-intro {
    width: 100%;
    background-color: $color-white;

    @include media(tablet) {
      min-height: unset !important;
    }

    .swiper-container {
      display: flex;
      flex-flow: column nowrap;
    }

    &__slider {
      width: 100%;

      .slider-buttons {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 310px;
        height: 40px;
        margin: 27px auto 0;
        max-width: 100%;

        .gd-btn--active {
          position: relative;
          width: 220px;
          padding: 12px 0 10px;
          margin-left: 10px;

          @include media(tablet) {
            width: 150px;
          }

          .icon {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
          }
        }

        .gd-btn--ghost {
          position: absolute;
        }

        .prev-slide .gd-btn--ghost {
          top: 1px;
          left: 10px;
        }
      }
    }

    .swiper-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $color-white;

      .slide-title {
        font-family: $font-medium;
        font-size: 25px;
        color: $color-blue;
      }

      .slide-content {
        width: 500px;
        margin-top: 10px;
        font-size: 16px;
        line-height: 20px;
        color: $color-gray-light;

        @include media(tablet) {
          width: 100%;
        }
      }
    }

    &__nav {
      @include media(tablet) {
        width: 100%;
      }
    }

    .nav-list {
      position: relative;
      z-index: 0;
      display: flex;
      height: 24px;
      min-width: 280px;
      margin: 55px 0 30px;

      @include media(tablet) {
        margin: 30px auto;
        max-width: 100%;
        min-width: unset;
      }

      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 2px);
        left: 20px;
        z-index: -1;
        display: block;
        height: 4px;
        width: calc(100% - 40px);
        background-color: $color-blue-semi-light;

        @include media(tablet) {
          // width: calc(100% - 95px);
        }
      }

      &__bullet {
        position: relative;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        margin: 0 20px;
        background-color: $color-blue-light;
        border: 3px solid $color-blue-semi-light;

        @include media(tablet) {
          margin: 0 8px;
        }

        .icon-check {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -10%);
          height: 10px;
          width: 10px;
          color: $color-white;
          opacity: 0;
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          transition: none;
        }

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &--completed {
          border: 0;
          background-color: $color-blue;

          .icon-check {
            transform: translate(-50%, -50%);
            opacity: 1;
            transition: .3s;
          }
        }

        &--active {
          border: 0;
          background-color: $color-blue;

          .icon-check {
            transition: none;
          }
        }

        &--active::before {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background-color: $color-white;
        }

        &--active::after {
          height: 20px;
          width: 20px;
          z-index: -1;
          border: 1px solid $color-blue-semi-light;
          border-radius: 50%;
          background-color: $color-blue-light;
          animation: fadePulse 2.2s infinite;
        }
      }
    }

    .swiper-progress {
      display: block;
      position: absolute;
      top: calc(50% - 2px);
      left: 0;
      z-index: -1;
      height: 4px;
      width: 0;
      background-color: $color-blue;
      transition: width .45s cubic-bezier(0.63, 0.45, 0.15, 1.03);
    }

    @keyframes fadePulse {
      from {
        transform: translate(-50%, -50%) scale(1.2);
      }

      to {
        transform: translate(-50%, -50%) scale(2.6);
        opacity: 0;
      }
    }
  }
</style>
