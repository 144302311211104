<template>
  <header class="gd-header-app">
    <ul class="gd-header-app__list">
      <li>
        <gdLink to="/">
          <img
            src="/assets/images/logo.svg"
            alt="GDPS logo"
          >
        </gdLink>
      </li>
      <li class="list__user">
        <span
          v-if="user && user.company"
        >
          {{ user.company.name }}
        </span>
        <gdLink
          class="user-link"
          name="app.account"
          v-if="user"
        >
          Twoje konto
          <gdIcon name="user" />
        </gdLink>
      </li>
    </ul>
  </header>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters('user', ['user'])
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .gd-header-app {
    padding: 17px 22px;
    background-color: $color-white;
    box-shadow: 0 2px 0 0 rgba(0,0,0,0.05), 0 1px 0 0 rgba(0,0,0,0.05);

    &__list {
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
        font-size: 16px;
        color: $color-gray-light;

        span,
        a {
          display: flex;
          height: 100%;
        }
      }
    }

    .list {
      &__user {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;

        span:first-of-type {
          font-size: 16px;
          margin-right: 20px;
        }
      }
    }

    .user-link a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-family: $font-bold;
      letter-spacing: 1.1px;
      text-transform: uppercase;
      color: $color-blue;

      .icon {
        position: relative;
        top: -2px;
        margin-left: 15px;
      }
    }
  }
</style>
