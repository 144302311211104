import Vue from 'vue'
import Vuex from 'vuex'

import activityRegister from './activity-register'
import api from './api'
import certificate from './certificate'
import courses from './courses'
import company from './company'
import contact from './contact'
import dataProcessing from './data-processing'
import dashboard from './dashboard'
import documents from './documents'
import file from './file'
import filestack from './filestack'
import help from './help'
import insurances from './insurances'
import payments from './payments'
import recommendationsReport from './recommendations-report'
import registers from './registers'
import registerSingle from './register-single'
import reportOfCompatibility from './report-of-compatibility'
import rodoSteps from './rodo-steps'
import editAreas from './edit-areas'
import services from './services'
import sidebarApp from './sidebar-app'
import staticData from './static-data'
import user from './user'
import simplifiedRiskAnalysis from "./simplified-risk-analysis"
import riskTracker from "./risk-tracker"

Vue.use(Vuex)

const modules = {
  activityRegister,
  api,
  certificate,
  company,
  contact,
  courses,
  dashboard,
  dataProcessing,
  documents,
  file,
  filestack,
  editAreas,
  help,
  insurances,
  payments,
  recommendationsReport,
  registers,
  registerSingle,
  reportOfCompatibility,
  rodoSteps,
  services,
  sidebarApp,
  staticData,
  user,
  simplifiedRiskAnalysis,
  riskTracker
}

export default new Vuex.Store({
  modules
})
