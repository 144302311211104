var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gdBrick",
    { class: _vm.rodoStepClass },
    [
      _c("gdRodoStepHeader", {
        attrs: {
          title: "Rejestr Kategorii Przetwarzań",
          completedTitle: "Rejestr Kategorii Przetwarzań",
          iconName: "sheet",
          active: _vm.step4.active,
          completed: _vm.step4.completed,
          resettable: _vm.step4.active && !_vm.step4.completed,
          rollbackable: _vm.step4.active && !_vm.step4.completed
        }
      }),
      _vm._v(" "),
      _c(
        "gdGrowTransition",
        [
          _vm.step4.active
            ? _c("gdGrowTransitionContent", [
                _c("div", { staticClass: "gd-rodo-step__content" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.step4.prevStepAffect
                          ? _vm.step4.affectedDescription
                          : _vm.step4.description
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _vm.step4.prevStepAffect
                    ? _c(
                        "div",
                        { staticClass: "question__buttons" },
                        [
                          _c(
                            "gdButton",
                            {
                              nativeOn: {
                                click: function($event) {
                                  return _vm.completeStep($event)
                                }
                              }
                            },
                            [_vm._v("\n            Pomiń\n          ")]
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "question__buttons" },
                        [
                          _c(
                            "gdButton",
                            {
                              attrs: {
                                link: {
                                  name: "app.register-single",
                                  params: { id: _vm.processorRegisterId }
                                }
                              }
                            },
                            [_vm._v("Uzupełnij Rejestr Kategorii Przetwarzań")]
                          ),
                          _vm._v(" "),
                          _c(
                            "gdButton",
                            {
                              nativeOn: {
                                click: function($event) {
                                  return _vm.completeStep($event)
                                }
                              }
                            },
                            [_vm._v("Kontynuuj")]
                          )
                        ],
                        1
                      )
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }