const initialState = () => ({
  registersCollection: [],
  register: null
})

const state = () => (initialState())

const actions = {
  async GET_REGISTERS ({ commit, dispatch }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'registry'
      }, { root: true })

      console.log('registers/GET_REGISTERS', { data })
      commit('SET_REGISTERS', { registersCollection: data })
    } catch(error){
      console.error('registers/GET_REGISTERS', { error })
      throw error
    }
  }
}

const mutations = {
  SET_REGISTERS(state, { registersCollection }) {
    state.registersCollection = registersCollection
  }
}

const getters = {
  registersCollection: state => state.registersCollection
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
