var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-app" },
    [
      _c("gdHeaderApp"),
      _vm._v(" "),
      _c("gdSidebarApp"),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "gd-app__main" },
        [
          _c(
            "transition",
            { attrs: { name: "global-slide", mode: "out-in" } },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }