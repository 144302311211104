<template>
  <section :class="rootCssClasses">
    <slot />
    <span class="gd-table-header__border-right"/>
  </section>
</template>

<script>
  export default {

    props: {
      underlined: {
        type: Boolean,
        default: false
      },
      stickyMode: {
        type: Boolean,
        default: false
      }
    },

    data(){
      return {
        sticky: false,
        prevScrollY: 0
      }
    },

    computed: {
      rootCssClasses(){
        const base = 'gd-table-header'
        const classes = [
          base,
          this.underlined ? 'gd-table-header--underlined' : null,
          (this.stickyMode && this.sticky) ? 'gd-table-header--sticky' : null
        ]

        return classes.filter(item => !!item).join(' ')
      }
    },

    destroyed () {
      if(this.stickyMode){
        window.removeEventListener('scroll', this.handleScroll)
      }
    },

    methods: {
      getScrollDiretion(scrollY){
        const result = (scrollY > this.prevScrollY) ? 'down' : 'up'
        this.prevScrollY = scrollY
        return result
      },

      handleScroll (event) {

        const scrollDirection = this.getScrollDiretion(window.scrollY)
        const { nextElementSibling } = this.$el

        if(!nextElementSibling){
          return
        }

        const nextElementSiblingOffsetTop = nextElementSibling.getBoundingClientRect().top * -1

        switch(scrollDirection){
        case 'down':

          if(nextElementSibling.getBoundingClientRect().top - this.$el.clientHeight < 0){
            this.sticky = true
            nextElementSibling.style.marginTop = `${this.$el.clientHeight}px`
            this.$el.style.transform = `translateY(${nextElementSiblingOffsetTop + this.$el.clientHeight + 25}px)`
          }

          if(nextElementSiblingOffsetTop > nextElementSibling.clientHeight - 150){
            this.sticky = false
            nextElementSibling.style.marginTop = 0
            this.$el.style.transform = 'translateY(0)'
          }

          break

        case 'up':

          console.log({
            'nextElementSibling.clientHeight': nextElementSibling.clientHeight,
            'nextElementSiblingOffsetTop': nextElementSiblingOffsetTop,
            'window.innerHeight': window.innerHeight,
            'this.$el.clientHeight': this.$el.clientHeight
          })

          if(nextElementSiblingOffsetTop + this.$el.clientHeight < 0){
            this.sticky = false
            nextElementSibling.style.marginTop = 0
            this.$el.style.transform = `translateY(0)`
          } else {
            this.sticky = true
          }

          if(this.sticky){
            nextElementSibling.style.marginTop = `${this.$el.clientHeight}px`
            this.$el.style.transform = `translateY(${nextElementSiblingOffsetTop + this.$el.clientHeight + 25}px)`
          }
          break
        }
      }
    },

    watch: {
      stickyMode(newVal, oldVal){
        console.log('gd-table-header watch stickyMode', { newVal, oldVal })
        if(newVal && !oldVal){
          window.addEventListener('scroll', this.handleScroll)
        }
        if(!newVal && oldVal){
          window.removeEventListener('scroll', this.handleScroll)
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-table-header {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      border-bottom: 2px solid transparent;
    }

    &__border-right {
      position: absolute;
      top: -1px;
      right: 0;
      z-index: 1000;
      display: block;
      height: calc(100% + 2px);
      width: 4px;
      border-right: 1px solid $color-gray-semi-light;
      border-top: 1px solid $color-gray-semi-light;
      border-top-right-radius: 3px;
    }

    &--underlined::before {
      border-bottom: 2px solid $color-gray-semi-light;
    }

    &--sticky {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9999;
      width: 100%;
      overflow: hidden;
      border-left: 1px solid #D3D7DF;
    }

    .gd-table-row {
      background-color: $color-gray-semi-dark;
    }
  }
</style>
