<template>
  <gdTable
    class="gd-table-register-single"
    :title="title"
    :id="rootId"
    :cellsOverflow="false"
    ref="gdTable"
  >
    <gdTableHeader
      :underlined="!columns || columns.length === 0"
    >
      <gdTableRow>
        <div
          class="cells-overflow"
          ref="headerCells"
        >
          <gdTableCell
            v-for="(cell, cellIndex) in columns"
            :key="cellIndex"
          >
            <gdTooltip
              v-if="cell.info"
              :content="cell.info"
              iconName="question"
            />
            {{ cell.name }}
          </gdTableCell>
        </div>
      </gdTableRow>
    </gdTableHeader>
    <gdTableBody>
      <!-- eslint-disable -->
      <template
        v-for="(row, rowIndex) in rows"
      >
        <gdTableRow v-if="!editRowsVisible.includes(row.id)">
          <div class="cells-overflow">
            <gdTableCell
              v-for="(cell, cellIndex) in columns"
              :key="cellIndex"
            >
              <template v-if="row.selectedColumnValues && row.selectedColumnValues[cell.id]">
                <span v-html="row.selectedColumnValues[cell.id] ? cellLabel(row.selectedColumnValues[cell.id]) : null"></span>
                <gdTooltip
                  iconName="question"
                  v-if="cellInfo(row.selectedColumnValues[cell.id])"
                  :content="cellInfo(row.selectedColumnValues[cell.id])"
                />
                <gdButton
                  v-if="cellIndex === 0"
                  theme="ghost"
                  @click.native="showEditRow({ row })"
                >
                  Edytuj
                </gdButton>
              </template>
            </gdTableCell>
          </div>
        </gdTableRow>

        <!-- Edit activity row -->
        <gdRowEditRow
          v-else
          :registerId="registerId"
          :rowId="row.id"
          :columns="columns"
        />
      </template>

      <!-- Add row row -->
      <gdRowAddRow
        v-if="addRowVisible"
        :columns="columns"
        :registerId="registerId"
      />
    </gdTableBody>
    <gdTableFooter
      :standalone="false"
    >
      <gdButton
        theme="ghost"
        @click.native="showAddRow"
      >
        Dodaj
      </gdButton>
      <gdHorizontalScrollBar
        v-if="scrollBarVisile"
        :scrollableElements="scrollableElements"
      />
    </gdTableFooter>
  </gdTable>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdpsComponents from 'gdps-components'

  import gdTable from '@/components/tables/elements/gd-table'
  import gdTableBody from '@/components/tables/elements/gd-table-body'
  import gdTableCell from '@/components/tables/elements/gd-table-cell'
  import gdTableFooter from '@/components/tables/elements/gd-table-footer'
  import gdTableHeader from '@/components/tables/elements/gd-table-header'
  import gdTableRow from '@/components/tables/elements/gd-table-row'

  import gdRowEditRow from '@/components/tables/gd-row-edit-row'
  import gdRowAddRow from '@/components/tables/gd-row-add-row'

  export default {
    data () {
      return {
        scrollBarVisile: false
      }
    },

    props: {
      title: String,
      columns: Array,
      rows: Array,
      registerId: [String, Number],
    },

    computed: {
      ...mapGetters('registerSingle', ['addRowVisible', 'editRowsVisible']),

      rootId(){
        return `gd-table-register-single-${this._uid}`
      },

      scrollableElements(){
        return [`#${this.rootId} .cells-overflow`]
      }
    },
    methods: {
      async showEditRow({ row }){
        try {
          await this.$store.dispatch('registerSingle/GET_ROW_TO_EDIT', { registryId: this.registerId, rowId: row.id })
          this.$store.commit('registerSingle/SET_EDIT_ROW_VISIBLE', { rowId: row.id, value: true })
          setTimeout(() => {
            gdpsComponents.helpers.EventBus.$emit(gdpsComponents.helpers.EventsList.UPDATE_HORIZONTAL_SCROLLBAR_POSITION)
          })
        } catch (error) {
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },

      async showAddRow(){
        try{
          await this.$store.dispatch('registerSingle/GET_REGISTRY_ROW_TO_ADD', { registerId: this.registerId })
          this.$store.commit('registerSingle/SET_ADD_ROW_VISIBLE', { value: true })
          setTimeout(() => {
            gdpsComponents.helpers.EventBus.$emit(gdpsComponents.helpers.EventsList.UPDATE_HORIZONTAL_SCROLLBAR_POSITION)
          })
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },

      cellLabel(value){

        const listItems = value.map(item => `<li style="margin-bottom: 10px;">${item.value}</li>`)
        return `<ul style="${listItems.length > 1 ? 'list-style: circle; padding-left: 10px;' : ''}">${listItems.join('')}</ul>`
      },

      cellInfo(value){
        if(value && value.length && value[0].info){
          return value.map(item => `<span class="text--bold">${item.value}</span> - ${item.info}`).join('<br>')
        }

        return false
      },

      showScrollBar () {
        const columns = this.$refs.headerCells
        const table = this.$refs.gdTable.$el
        if (columns && table) {
          this.scrollBarVisile = (columns.clientWidth >= table.clientWidth)
        }
      }
    },

    mounted() {
      this.showScrollBar()
      window.addEventListener('resize', this.showScrollBar)
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.showScrollBar)
    },

    components: {
      gdTable,
      gdTableBody,
      gdTableCell,
      gdTableFooter,
      gdTableHeader,
      gdTableRow,
      gdRowEditRow,
      gdRowAddRow
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-table.gd-table-register-single {
    .gd-table__wrapper {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .gd-table-header {
      border: 1px solid $color-gray-semi-light;
      border-right: 0;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      margin-left: -1px;

      .gd-table-cell {
        width: 225px;

        &:first-of-type {
          border-top-left-radius: 3px;
        }

        &:last-of-type {
          border-top-right-radius: 3px;
        }
      }
    }

    .cells-overflow {
      padding-left: 0;
    }

    .gd-table-row--actions {
      padding: 20px;
      // border-bottom: 1px solid $color-gray-semi-light;

      .gd-button-wrapper {
        &:first-child {
          margin-right: auto;
        }

        &:last-child {
          margin-left: 50px;
        }
      }
    }

    .gd-table-body {
      border-right: 1px solid $color-gray-semi-light;
      border-left: 1px solid $color-gray-semi-light;
      margin-left: -1px;

      &__border-right {
        right: -1px;
      }

      .gd-table-cell {
        width: 225px;

        &:first-of-type {
          .gd-tooltip {
            display: none;
          }
        }
      }
    }

    .gd-table-row--edit {
      height: 80px;
      background-color: $color-blue-light;

      .gd-table-cell {
        align-items: center;
        height: 100%;
        background-color: $color-blue-light;

        .gd-input {
          margin: 0;
        }

        label {
          display: none;
        }
      }
    }
  }
</style>
