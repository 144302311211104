<template>
  <gdBrick :class="rodoStepClass">
    <gdRodoStepHeader
      title="Uzupełnij Rejestr Czynności Przetwarzania"
      completedTitle="Rejestr Czynności Przetwarzania Uzupełniony"
      iconName="table"
      :active="step2.active"
      :completed="step2.completed"
      :resettable="step2.active && !step2.completed"
      :rollbackable="step2.active && !step2.completed"
    />
    <gdGrowTransition>
      <gdGrowTransitionContent v-if="step2.active">
        <div
          class="gd-rodo-step__content"
        >
          <p>{{ step2.description }}</p>
          <div class="question__buttons">
            <gdButton :link="{ name: 'app.activity-register' }">Przejdź do rejestru czynności</gdButton>
            <gdButton
              @click.native="completeStep"
              v-if="user.rcpdGenerated"
            >
              Kontynuuj
            </gdButton>
          </div>
        </div>
      </gdGrowTransitionContent>
    </gdGrowTransition>
  </gdBrick>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdBrick from '@/components/bricks/gd-brick'
  import gdRodoStepHeader from '@/components/rodo-steps/gd-rodo-step-header'

  export default {

    data() {
      return {
        stepId: 2
      }
    },

    computed: {
      ...mapGetters('rodoSteps', ['step2']),
      ...mapGetters('user', ['user']),

      rodoStepClass () {
        return {
          'gd-rodo-step gd-rodo-step--2': true,
          'gd-rodo-step--active': this.step2.active,
          'gd-rodo-step--completed': this.step2.completed,
        }
      }
    },

    methods: {
      completeStep(){
        this.$store.dispatch('rodoSteps/COMPLETE_STEP', { stepId: this.stepId })
      }
    },

    components: {
      gdBrick,
      gdRodoStepHeader
    }
  }
</script>
