<template>
  <form
    class="gd-form gd-form-password-set"
    @submit.prevent="handleSubmit"
    novalidate
  >
    <gdInput
      v-model="form.password"
      name="password"
      type="password"
      ref="password"
      data-vv-as="hasło"
      v-validate="'required|passRt'"
    >
      hasło
    </gdInput>
    <gdInput
      v-model="form.passwordRepeat"
      name="passwordRepeat"
      type="password"
      data-vv-as="powtórz hasło"
      v-validate="'required|matchPasswords:password'"
    >
      Powtórz hasło
    </gdInput>
    <gdButton
      type="submit"
      :loading="loading"
    >
      Załóż konto w Risk Tracker
    </gdButton>
  </form>
</template>

<script>
  import objectPath from 'object-path'

  export default {
    data () {
      return {
        form: {
          password: null,
          passwordRepeat: null
        },
        loading: false
      }
    },

    methods: {
      async handleSubmit(){
        try {
          const isValid = await this.$validator.validateAll()

          if(!isValid){
            return
          }

          this.loading = true
          const result = await this.$store.dispatch('riskTracker/REGISTER_USER', { password: this.form.password, passwordRepeat: this.form.passwordRepeat })
          await this.$store.dispatch('user/GET_USER')
          this.clearForm()
          this.loading = false
        } catch (error){
          console.error('gd-form-rt-password-set', {error})
          this.handleError(error)
          this.loading = false
        }
        this.loading = false
      },

      handleError(error) {
        let message = 'Ups... coś poszło nie tak'
        const errorCode = objectPath.get(error, 'response.data.error.code')
        switch (errorCode) {
        case 'client_exists':
          message = 'Twój adres email jest już zarejestrowany!'
          break;
        case 'client_NIP_exists':
          message = 'Twój numer NIP jest już zarejestrowany!'
          break;
        case 'data_validation_error':
          let _message = this.getMessageFromValidationError(error);
          if ( _message ) {
            message = _message
          }
          break;
        }
        this.$store.dispatch('toasts/ADD_TOAST', { toast: { message: `${message}`, type: 'error' }})
      },

      getMessageFromValidationError(error) {
        const errorCode = objectPath.get(error, 'response.data.error.message')
        switch(errorCode) {
        case 'nip':
          return 'Nip Twojej firmy jest nieprawidłowy.'
        case 'email':
          return 'Twój email jest nieprawidłowy.'
        case 'password':
          return 'Hasło jest nieprawidłowe.'
        case 'company_name':
          return 'Nazwa firmy jest wymagana!'
        case 'company_name':
          return 'Nazwa firmy jest wymagana!'
        case 'company_address':
          return 'Adres firmy jest wymagany!'
        case 'first_name':
        case 'last_name':
          return 'Twoje imię i nazwisko jest wymagane!'
        }

        return null
      },

      clearForm () {
        Object.keys(this.form).forEach((key) => {
          this.form[key] = null
        })
      }
    },
  }
</script>

<style lang="scss">
  .gd-form-password-set {
    width: 100%;

    .gd-input {
      max-width: 100%;
      margin-right: 0 !important;
    }

    .gd-btn--active {
      margin: 0 auto;
    }
  }
</style>
