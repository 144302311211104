<template>
  <section class="gd-accordeon">
    <div
      :class="accordeonItemClass(index)"
      v-for="(item, index) in items"
      :key="index"
    >
      <div
        class="item-left"
        @click.prevent="toggleExpand({ index })"
      >
        <gdIcon name="arrow-small" />
      </div>
      <div class="item-center">
        <div
          class="item-center__visible-content"
          @click.prevent="toggleExpand({ index })"
        >
          <template v-if="visibleField">
            {{ item[visibleField] }}
          </template>
          <slot
            v-else
            name="visible-content"
            :item="item"
          />
        </div>
        <div
          class="item-center__expandable-content"
        >
          <template v-if="expandableField">
            <div v-html="item[expandableField]"/>
            <div
              v-html="item[commentField]"
              class="content-comment"
            />
          </template>
          <slot
            v-else
            name="expandable-content"
            :item="item"
          />
        </div>
      </div>
      <div class="item-right">
        <slot
          name="right"
          :item="item"
        />
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        expandedItems: this.items.map(item => item.expanded || false)
      }
    },

    props: {
      items: Array,
      visibleField: String,
      expandableField: String,
      commentField: String
    },

    methods: {
      toggleExpand({ index }){
        const expandedItems = [...this.expandedItems]
        expandedItems[index] = !expandedItems[index]
        this.expandedItems = expandedItems
        this.expanded = !this.expanded
      },

      accordeonItemClass (itemIndex) {
        return {
          'accordeon__item': true,
          'accordeon__item--expanded': this.expandedItems[itemIndex]
        }
      }
    },

    watch: {
      items(){
        this.expandedItems = this.items.map(item => item.expanded || false)
      }
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-accordeon {
    .accordeon__item {
      display: flex;
      flex-flow: row;
      overflow: hidden;
      min-height: 70px;
      border: 1px solid $color-brick-border;
      border-radius: 3px;
      margin: 10px 0 0;
      font-size: 20px;
      background-color: $color-white;
      box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
      max-height: 0;
      transition: max-height .5s;

      .item {
        &-left,
        &-center,
        &-right {
          display: flex;
          padding: 24px 0;
        }

        &-left {
          width: 70px;
          justify-content: center;
          cursor: pointer;

          .icon {
            width: 8px;
            height: 20px;
            color: darken($color-mithrill, 35);
          }
        }

        &-center {
          max-width: 80%;
          flex-flow: column nowrap;
          padding: 0;
          text-align: left;

          &__visible-content {
            display: flex;
            align-items: center;
            min-height: 68px;
            cursor: pointer;
            user-select: none;
          }

          &__expandable-content {
            opacity: 0;
            padding-bottom: 30px;
            margin-top: 10px;
            font-size: 16px;
            line-height: 20px;
            transition: opacity .4s;
          }

          .content-comment {
            margin-top: 25px;
            line-height: 20px;
            color: $color-gray-light;
          }
        }

        &-right {
          position: relative;
          width: 70px;
          margin-left: auto;

          .gd-checkbox {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &__fake {
              height: 24px;
              width: 24px;
              border-width: 2px;

              &::before {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }

      .icon-arrow-small {
        transition: .3s;
      }

      &.accordeon__item--expanded {
        max-height: 1000px;

        .icon-arrow-small {
          transform: rotate(180deg);
        }

        .item-center__expandable-content {
          opacity: 1;
        }
      }
    }
  }
</style>
