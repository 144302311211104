const initialState = () => ({
  paymentsLanding: {
    payments: {
      title: 'Sposoby płatności na stronie www.gdpsystem.pl',
      list: [
        {
          listHeading: 'W przypadku zakupu produktu oferowanego drogą cyfrową jedynym sposobem płatności jest przedpłata:',
          nestedList: [
            {
              text: 'Przelew elektroniczny. Płatność online/elektroniczna poprzez banki, których spis jest widoczny podczas wyboru metody płatności w trakcie składania zamówienia.'
            },
            {
              text: 'Płatność przelewem tradycyjnym. Po wyborze tej formy płatności można pobrać druk służący do zapłaty należności. '
            },
            {
              text: 'Płatność kartą. Płatność w internecie odbywa się tą samą kartą (debetową, kredytową), co w zwykłym sklepie. Akceptowane karty: Visa, MasterCard.'
            },
            {
              text: 'Opcja Płacę później. Innowacja na rynku metoda płatności, w której kupujący nabywa towar lub usługę online i bez dodatkowych kosztów przesuwa termin zapłaty do 30 dni. '
            },
            {
              text: 'PayU Raty. System płatności ratalnych, całkowicie online, bez konieczności wychodzenia z domu i bez kuriera.'
            }
          ]
        }
      ]
    }
  },
  userPaymentId: window.localStorage.getItem('gdps-user-payment-id') || null,
  userPaymentStatus: {
    waiting: null,
    done: null
  },
  userPlan: {
    id: null,
    key: null
  },
  paymentPlans: {
    plans: null,
    benefits: null
  },
  chosenPlanId: null
})

const state = () => (initialState())

const actions = {

  async USER_PAYMENT({ commit, dispatch }, id){
    try {
      const result = await dispatch('api/REQUEST', {
        endpoint: 'user/payment',
        method: 'POST',
        data: {
          paymentPlanId: id
        }
      }, { root: true })

      commit('SET_USER_PAYMENT_ID', { id: result.data.id })
      console.log('user/USER_PAYMENT', { result })
      return result.data.redirectUrl
    } catch (error) {
      console.log('user/USER_PAYMENT', { error })
      throw error
    }
  },

  async GET_PAYMENT_STATUS({ state, commit, dispatch }) {
    try {
      commit('SET_PAYMENT_STATUS', { done: null, waiting: true })
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/payment/${state.userPaymentId}/notify`,
        timeout: 60000
      }, { root: true })
      const { ok, userPlan } = data

      commit('SET_USER_PLAN', { id: userPlan.id, key: userPlan.userPlanKey })
      commit('SET_PAYMENT_STATUS', { done: ok, waiting: false })
      if(ok){
        commit('SET_USER_PAYMENT_ID', { id: null })
      }
      console.log('user/GET_PAYMENT_STATUS', { data })
      return data
    } catch (error) {
      commit('SET_PAYMENT_STATUS', { done: false, waiting: false })
      console.log('user/GET_PAYMENT_STATUS', { error })
      throw error
    }
  },

  async GET_PAYMENT_PLANS ({ commit, getters, dispatch }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'paymentplan/view',
        useToken: false
      }, { root: true })
      const { plans, benefits } = data

      commit('SET_PAYMENT_PLANS', { plans, benefits })
      console.log('paymentPlans/GET_PAYMENT_PLANS', { data })
    } catch(error){
      console.error('paymentPlans/GET_PAYMENT_PLANS', { error })
      throw error
    }
  }
}

const mutations = {
  SET_USER_PAYMENT_ID(state, { id }) {
    state.userPaymentId = id
    if(id){
      window.localStorage.setItem('gdps-user-payment-id', id)
    } else {
      window.localStorage.removeItem('gdps-user-payment-id')
    }
  },

  SET_PAYMENT_STATUS(state, { done, waiting }) {
    state.userPaymentStatus.done = done
    state.userPaymentStatus.waiting = waiting
  },

  SET_PAYMENT_PLANS (state, { plans, benefits }) {
    state.paymentPlans.plans = plans
    state.paymentPlans.benefits = benefits
  },

  SET_USER_PLAN (state, { id, key }) {
    state.userPlan.id = id
    state.userPlan.key = key
  },

  SET_CHOSEN_PLAN_ID (state, { id }) {
    state.chosenPlanId = id
  },

  RESET_STATE(state){
    Object.assign(state, initialState())
  }
}

const getters = {
  paymentStatus: state => state.userPaymentStatus,
  paymentsLanding: state => state.paymentsLanding,
  chosenPlanId: state => state.chosenPlanId,
  userPlan: state => state.userPlan,
  userPaymentId: state => state.userPaymentId,
  paymentBenefits: state => {
    if ((!state.paymentPlans || {}).benefits) {
      return null
    }

    return state.paymentPlans.benefits ? state.paymentPlans.benefits.sort((a, b) => a.sortOrder - b.sortOrder) : null
  },
  paymentPlans: state => {
    if((!state.paymentPlans || {}).plans){
      return null
    }

    return (state.paymentPlans.plans || []).map(plan => {
      const { id, name, days, description, price, benefitValues: benefits } = plan

      if (benefits) {
        const benefitValues = Object.keys(benefits)
          .map(function(key) {
            const benefit = benefits[key];
            let { info, sortOrder } = benefit
            if (info.toLowerCase() === 'tak' || info.toLowerCase() === 'tka') {
              return {
                isIcon: true,
                iconName: 'check',
                sortOrder
              }
            } else if (info.toLowerCase() === 'nie' || info.toLowerCase() === 'nei') {
              return {
                isIcon: true,
                iconName: 'close',
                sortOrder
              }
            } else {
              return {
                isIcon: false,
                info,
                sortOrder
              }
            }
          })
          .sort((a, b) => a.sortOrder - b.sortOrder)

        return {
          id,
          name,
          benefitValues,
          days,
          description,
          url: `/rejestracja?planId=${id}`,
          price: price !== 0 ? `${price}` / 100 + ' zł' : '-'
        }
      }

      return {
        id,
        name,
        benefitValues: null,
        benefits,
        days,
        description,
        url: `/rejestracja?planId=${id}`,
        price: price !== 0 ? `${price}` / 100 + ' zł' : '-'
      }

    })
  }
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
