var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gd-page-header" }, [
    _c("div", { staticClass: "gd-page-header__info" }, [
      _c("h1", { staticClass: "info__heading" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _vm.description
        ? _c("p", { staticClass: "info__sub-heading" }, [
            _vm._v("\n      " + _vm._s(_vm.description) + "\n    ")
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gd-page-header__actions" },
      [_vm._t("actions")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }