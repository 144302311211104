<template>
  <gdBrick :class="rodoStepClass">
    <gdRodoStepHeader
      title="Pobierz Certyfikat"
      completedTitle="Certyfikat pobrany"
      iconName="certificate"
      :active="step6.active"
      :completed="step6.completed"
      :resettable="false"
      :rollbackable="false"
    />
    <gdGrowTransition>
      <gdGrowTransitionContent v-if="step6.active">
        <div
          class="gd-rodo-step__content"
        >
          <p>{{ step6.description }}</p>
          <gdImg
            srcset="/assets/images/certificate-top.png 1x, /assets/images/certificate-top@2x.png 2x"
          />
          <gdButton
            @click.native="completeStep"
            :link="{ name: 'app.certificate' }"
          >
            Pobierz certyfikat
          </gdButton>
        </div>
      </gdGrowTransitionContent>
    </gdGrowTransition>
  </gdBrick>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdBrick from '@/components/bricks/gd-brick'
  import gdRodoStepHeader from '@/components/rodo-steps/gd-rodo-step-header'

  export default {

    data() {
      return {
        stepId: 6
      }
    },

    computed: {
      ...mapGetters('rodoSteps', ['step6']),
      rodoStepClass () {
        return {
          'gd-rodo-step gd-rodo-step--6': true,
          'gd-rodo-step--active': this.step6.active,
          'gd-rodo-step--completed': this.step6.completed,
        }
      }
    },

    methods: {
      async completeStep(){
        try {
          await this.$store.dispatch('rodoSteps/COMPLETE_STEP', { stepId: this.stepId })
        } catch(error) {
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      }
    },

    components: {
      gdBrick,
      gdRodoStepHeader
    }
  }
</script>
<style lang="scss">

</style>
