<template>
  <section class="gd-services">
    <gdPageHeader
      title="Usługi"
      description="Zapewniamy kompleksowe usługi wspierające Twoją firmę w ochronie danych osobowych."
    />
    <div class="gd-services__grid">
      <gdBrickDashboard
        v-for="(item, index) in services"
        :key="index"
        :disabled="false"
        :title="item.title"
        :iconName="item.icon"
        :content="item.about"
      >
        <template slot="buttons">
          <gdButton
            theme="secondary"
            @click.native="showModal(item)"
          >
            {{ item.buttonLabel }}
          </gdButton>
        </template>
      </gdBrickDashboard>
      <gdModalForm
        :iconName="activeModalData.icon"
        :title="activeModalData.modalTitle"
        :content="activeModalData.modalContent"
      >
        <gdFormServiceContact
          slot="form"
          :serviceTitle="activeModalData.modalTitle"
        />
      </gdModalForm>
    </div>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import gdPageHeader from '@/components/page/gd-page-header'
  import gdBrickDashboard from '@/components/bricks/gd-brick-dashboard'
  import gdFormServiceContact from '@/components/forms/gd-form-service-contact'

  export default {
    data () {
      return {
        activeModalData: {}
      }
    },

    computed: {
      ...mapGetters('services', ['services'])
    },

    methods: {
      showModal(modalData){
        this.activeModalData = modalData
        this.$store.commit('modals/SHOW_MODAL', { modalName: 'form' })
      }
    },

    components: {
      gdFormServiceContact,
      gdPageHeader,
      gdBrickDashboard
    }
  }
</script>

<style lang="scss">
  .gd-services {
    &__grid {
      display: flex;
      flex-flow: row wrap;
      margin: -10px -5px 0;

      .gd-brick-dashboard {
        min-height: 395px;
        width: calc(100% / 3);
        padding: 0 5px;

        .gd-btn {
          width: 190px;
        }

        .gd-brick__inner {
          position: relative;
        }

        .brick__title {
          margin-top: 70px;
        }

        .brick__content {
          margin-bottom: 30px;
        }

        .brick__buttons {
          position: absolute;
          left: 50%;
          bottom: 30px;
          transform: translateX(-50%);
        }
      }
    }
  }
</style>
