var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gd-continue-implementation" },
    [
      _c("p", { staticClass: "gd-continue-implementation__heading" }, [
        _vm._v("Kontynuuj")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "gd-continue-implementation__text" }, [
        _vm._v(
          "Jeśli uzupełniłeś już " +
            _vm._s(_vm.title) +
            " możesz wrócić i kontynuować wdrożenie."
        )
      ]),
      _vm._v(" "),
      _c("gdButton", { attrs: { link: { name: "app" } } }, [
        _vm._v("\n    Wróć do wdrożenia\n  ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }