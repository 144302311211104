<template>
  <section class="gd-edit-areas section--wide">
    <gdPageHeader
      title="Rejestr Czynności Przetwarzania Danych (RCPD)"
    >
      <template slot="actions">
        <gdButton
          class="btn-activity-register-edit"
          @click.native="cancel"
        >
          Anuluj
        </gdButton>
        <gdButton
          class="btn-activity-register-edit"
          @click.native="saveAreas"
          :loading="loading"
        >
          Zapisz obszary
        </gdButton>
      </template>
    </gdPageHeader>
    <gdEditAreaRow
      v-for="(area, index) in areas"
      :key="index"
      :title="area.title"
      :id="area.id"
      :mode="area.mode"
    />
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import gdEditAreaRow from '@/components/areas/gd-edit-area-row'
  import gdPageHeader from '@/components/page/gd-page-header'

  export default {

    data() {
      return {
        loading: false
      }
    },

    methods: {

      async saveAreas(){
        try {
          this.loading = true
          await this.$store.dispatch('editAreas/SAVE_AREAS')
          this.$router.push({ name: 'app.activity-register' })
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        } finally {
          this.loading = false
        }
      },

      cancel() {
        this.$router.push({ name: 'app.activity-register' })
      }
    },

    computed: {
      ...mapGetters('editAreas', ['areas'])
    },

    components: {
      gdPageHeader,
      gdEditAreaRow
    }
  }
</script>
