<template>
  <gdPasswordReset/>
</template>

<script>
  import gdPasswordReset from '@/pages/gd-password-reset'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    components: {
      gdPasswordReset
    }
  }
</script>

<style>
</style>
