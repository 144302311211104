const initialState = () => ({
  dataProcessing: {
    info: {
      list: [
        {
          text: '<span class="bold">My</span> czyli Good Data Protection Standard Sp. z o.o. z siedzibą Warszawie („<span class="bold">GDPS</span>”) jesteśmy Administratorem Twoich danych osobowych.'
        },
        {
          text: 'W sprawie ochrony swoich danych osobowych zapraszamy do kontaktu pod adresem e-mail <a href="mailto:kontakt@gdpsystem.pl">kontakt@gdpsystem.pl</a> lub pisemnie na adres: Al. Jana Pawła II 12, 00-124 Warszawa.'
        },
        {
          listHeading: '<span class="bold">Cele i podstawy przetwarzania.</span> Będziemy przetwarzać Twoje dane:',
          nestedList: [
            {
              text: 'w celu rejestracji w Serwisie i założenia konta oraz świadczenia usług (wykonywania umowy),'
            },
            {
              text: 'w celu przesyłania Ci marketingowych informacji handlowych GDPS lub partnerów GDPS uczestniczących w świadczeniu usług przez GDPS w oparciu o nasz prawnie uzasadniony interes. Stosujemy politykę zrównoważonego marketingu w oparciu o analizę uzasadnionego interesu,'
            },
            {
              text: 'w celach archiwalnych (dowodowych) będących realizacją naszego prawnie uzasadnionego interesu zabezpieczenia informacji na wypadek prawnej potrzeby wykazania faktów,'
            },
            {
              text: 'w celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami, będącego realizacją naszego prawnie uzasadnionego w tym interesu,'
            },
            {
              text: 'w celach analitycznych (lepszego doboru usług do potrzeb naszych Klientów, ogólnej optymalizacji naszych produktów, optymalizacji procesów obsługi, budowania wiedzy o naszych Klientach, analizy finansowej naszej spółki itp.) będących realizacją naszego prawnie uzasadnionego w tym interesu'
            }
          ]
        },
        {
          listHeading: '<span class="bold">Okres przechowywania danych:</span>',
          nestedList: [
            {
              text: 'Twoje dane osobowe przetwarzane w roku kalendarzowym dla potrzeb świadczenia przez nas usług planujemy przechowywać nie dużej niż przez 6 lat od końca takiego roku kalendarzowego (5 lat na przedawnienie podatkowe plus 1 rok na zapas na wszelki wypadek taki jak niedoręczona korespondencja),'
            },
            {
              text: 'Twoje dane przetwarzane w celu przesyłania Ci marketingowych informacji handlowych będziemy przechowywać aż do momentu wniesienia przez Ciebie sprzeciwu'
            }
          ]
        },
        {
          listHeading: '<span class="bold">Odbiorcy danych:</span>',
          nestedList: [
            {
              text: 'Twoje dane osobowe możemy przekazywać do takich kategorii odbiorców jak: nasi podwykonawcy (np. firma IT, księgowi, agencja marketingowa), oraz naszym partnerom, czyli firmom, z którymi współpracujemy świadcząc lub łącząc usługi z obszaru ochrony danych osobowych.'
            }
          ]
        },
        {
          listHeading: '<span class="bold">Twoje prawa:</span>',
          nestedList: [
            {
              text: 'Przysługują Ci następujące prawa: dostępu do swoich danych oraz otrzymania ich kopii, sprostowania swoich danych, żądania usunięcia danych, żądania ograniczenia przetwarzania danych, sprzeciwu względem przetwarzania danych, przenoszenia danych, skargi do organu nadzorczego.'
            }
          ]
        },
        {
          listHeading: '<span class="bold">Informacja o dobrowolności podania danych:</span>',
          nestedList: [
            {
              text: 'Podanie danych jest dobrowolne, ale bez nich nie możemy świadczyć ci Usług.'
            }
          ]
        }
      ]
    }
  }
})

const state = () => (initialState())

const getters = {
  dataProcessing: state => state.dataProcessing
}

export default {
  namespaced: true,
  state, getters
}
