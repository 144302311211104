import { formatActivities } from '@/helpers'

const initialState = () => ({
  userAreas: null,
  availableActivities: {},
  activitiesToEdit: {},
  activitiesToAdd: {},
  editActivityRowVisible: [], // Array with ids of activities
  addActivityRowVisible: [] // Array with ids of areas
})

const state = () => (initialState())

const actions = {
  async GET_USER_AREAS ({ commit, getters, dispatch }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'user/rcpd/view'
      }, { root: true })

      commit('SET_USER_AREAS', { areas: data })
      console.log('activityRegister/GET_USER_AREAS', { data })
    } catch(error){
      console.error('activityRegister/GET_USER_AREAS', { error })
      throw error
    }
  },

  async GET_ACTIVITY_TO_EDIT({ commit, dispatch }, { id }){
    try {
      const { data: activity } = await dispatch('api/REQUEST', {
        endpoint: `user/rcpd/activity/${id}`
      }, { root: true })

      console.log('activityRegister/GET_ACTIVITY_TO_EDIT', {activity})
      commit('SET_ACTIVITY_TO_EDIT', { activity })
    } catch(error) {
      console.error('activityRegister/GET_ACTIVITY_TO_EDIT')
      throw error
    }
  },

  async GET_ACTIVITY_TO_ADD({ commit, dispatch }, { id }){
    try {
      const { data:activity } = await dispatch('api/REQUEST', {
        endpoint: `rcpd/activity/${id}`
      }, { root: true })
      commit('SET_ACTIVITY_TO_ADD', { activity })
    } catch(error) {
      console.error('activityRegister/GET_EDITED_ACTIVITY')
      throw error
    }
  },

  async ADD_ACTIVITY ({ commit, dispatch }, { activityId, activityAreaId, selectedColumnValues }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/rcpd/activity`,
        method: 'POST',
        data: { activityId, activityAreaId, selectedColumnValues }
      }, { root: true })

      console.log('activityRegister/ADD_ACTIVITY', data)
      return data
    } catch(error) {
      console.error('activityRegister/ADD_ACTIVITY', { error })
      throw error
    }
  },

  async UPDATE_ACTIVITY ({ commit, dispatch }, { id, selectedColumnValues }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/rcpd/activity/${id}`,
        method: 'PUT',
        data: { selectedColumnValues }
      }, { root: true })

      console.log('activityRegister/UPDATE_ACTIVITY', data)
      return data
    } catch(error) {
      console.error('activityRegister/UPDATE_ACTIVITY', { error })
      throw error
    }
  },

  async REMOVE_ACTIVITY ({ commit, dispatch }, { id }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/rcpd/activity/${id}`,
        method: 'DELETE'
      }, { root: true })

      console.log('activityRegister/REMOVE_ACTIVITY', data)
      return data
    } catch(error) {
      console.error('activityRegister/REMOVE_ACTIVITY', { error })
      throw error
    }
  },

  async GET_AREA_AVAILABLE_ACTIVITIES ({ commit, dispatch }, { areaId }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/rcpd/activityarea/${areaId}/activity/available`
      }, { root: true })

      console.log('activityRegister/GET_AREA_AVAILABLE_ACTIVITIES', data)
      commit('SET_AVAILABLE_ACTIVITIES', { activities: data, areaId })
    } catch(error) {
      console.error('activityRegister/GET_AREA_AVAILABLE_ACTIVITIES', { error })
      throw error
    }
  },

  // CUSTOMS

  async GET_CUSTOM_ACTIVITY_TO_EDIT({ commit, dispatch }, { id }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/rcpd/activity/custom/${id}`,
        method: 'GET'
      }, { root: true })

      const activity = Object.assign({}, data)
      activity.isCustom = true
      activity.columnValues = Object.keys(activity.columnValues)
        .map(key => {
          const singleColumn = activity.columnValues[key]
            .map(item => {
              item.type = 'input'
              return item
            })

          return singleColumn
        })
        .reduce((res, item) => {
          res[item[0].columnId] = item
          return res
        }, {})

      commit('SET_ACTIVITY_TO_EDIT', { activity })

      console.log('activityRegister/GET_CUSTOM_ACTIVITY', activity)
      return activity
    } catch(error) {
      console.error('activityRegister/GET_CUSTOM_ACTIVITY', { error })
      throw error
    }
  },

  async ADD_CUSTOM_ACTIVITY({ commit, dispatch }, { activityAreaId, name, columnValues }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/rcpd/activity/custom`,
        method: 'POST',
        data: { activityAreaId, name, columnValues }
      }, { root: true })

      console.log('activityRegister/ADD_CUSTOM_ACTIVITY', data)
      return data
    } catch(error) {
      console.error('activityRegister/ADD_CUSTOM_ACTIVITY', { error })
      throw error
    }
  },

  async UPDATE_CUSTOM_ACTIVITY({ commit, dispatch }, { id, name, columnValues }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/rcpd/activity/custom/${id}`,
        method: 'PUT',
        data: { name, columnValues }
      }, { root: true })

      console.log('activityRegister/EDIT_CUSTOM_ACTIVITY', data)
      return data
    } catch(error) {
      console.error('activityRegister/EDIT_CUSTOM_ACTIVITY', { error })
      throw error
    }
  },

  async REMOVE_CUSTOM_ACTIVITY({ commit, dispatch }, { id }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/rcpd/activity/custom/${id}`,
        method: 'DELETE'
      }, { root: true })

      console.log('activityRegister/DELETE_CUSTOM_ACTIVITY', data)
      return data
    } catch(error) {
      console.error('activityRegister/DELETE_CUSTOM_ACTIVITY', { error })
      throw error
    }
  }
}

const mutations = {
  SET_USER_AREAS (state, { areas }) {
    state.userAreas = areas
  },

  SET_ACTIVITY_TO_EDIT (state, { activity }){
    state.activitiesToEdit = Object.assign({}, state.activitiesToEdit, { [activity.id] : activity })
  },

  SET_ACTIVITY_TO_ADD (state, { activity }){
    state.activitiesToAdd = Object.assign({}, state.activitiesToAdd, { [activity.id] : activity })
  },

  SET_AVAILABLE_ACTIVITIES (state, { activities, areaId }) {
    console.log('activityRegister/SET_AVAILABLE_ACTIVITIES', {activities, areaId})
    state.availableActivities = Object.assign({}, state.availableActivities, { [areaId] : activities })
  },

  SET_EDIT_ACTIVITY_ROW_VISIBLE (state, { activityId, value }) {
    console.log('activityRegister/SET_EDIT_ACTIVITY_ROW_VISIBLE', { activityId, value })
    if(value){
      state.editActivityRowVisible.push(activityId)
    } else {
      const isVisible = state.editActivityRowVisible.indexOf(activityId) > -1
      if(isVisible){
        state.editActivityRowVisible = state.editActivityRowVisible.filter(id => id !== activityId)
      }
    }
  },

  SET_ADD_ACTIVITY_ROW_VISIBLE (state, { areaId, value }) {
    console.log('activityRegister/SET_ADD_ACTIVITY_ROW_VISIBLE', { areaId, value })

    if(value){
      state.addActivityRowVisible.push(areaId)
    } else {
      const isVisible = state.addActivityRowVisible.indexOf(areaId) > -1
      if(isVisible){
        state.addActivityRowVisible = state.addActivityRowVisible.filter(id => id !== areaId)
      }
    }
  },

  RESET_STATE (state) {
    Object.assign(state, initialState())
  }
}

const getters = {
  userAreas: state => {
    if(!state.userAreas){
      return
    }

    return state.userAreas.map(space => {

      const { columnHeaders, name, table, id } = space
      const { activities: _activities, columns, customActivities: _customActivities } = table

      const headers = columns
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(item => {
          const { id, info, name, type } = item
          return { id, info, name, type }
        })

      const customActivities = (_customActivities || []).map(item => {
        item.isCustom = true
        return item
      })
      const activities = ([]).concat(_activities, customActivities).filter(item => item)

      return { name, headers, activities, id }
    })
  },

  availableActivities: state => {

    return Object.keys((state.availableActivities || {}))
      .reduce((res, areaId) => {

        const availableActivities = state.availableActivities[areaId]

        res[areaId] = (availableActivities || [])
          .sort((a,b) => a.sortOrder - b.sortOrder)
          .map(item => {
            const { id, name } = item
            return {
              value: id,
              label: name
            }
          })
        return res
      }, {})
  },

  editActivityRowVisible: state => state.editActivityRowVisible,

  addActivityRowVisible: state => state.addActivityRowVisible,

  activitiesToAdd: state => {
    return formatActivities({ activities: state.activitiesToAdd })
  },

  activitiesToEdit: state => {
    return formatActivities({ activities: state.activitiesToEdit })
  }
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
