import { formatColumnValues } from '@/helpers'

const initialState = () => ({
  register: null,
  addRowVisible: false,
  rowOptions: null,
  rowsToEdit: {},
  editRowsVisible: [], // Array with ids of activities
})

const state = () => (initialState())

const actions = {
  async GET_SINGLE_REGISTER ({ commit, dispatch }, { registerId }) {
    try {
      commit('RESET_STATE')
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/registry/${registerId}/view`
      }, { root: true })

      console.log('registers/GET_SINGLE_REGISTER', { data })
      commit('SET_SINGLE_REGISTER', { register: null })
      commit('SET_SINGLE_REGISTER', { register: data })
    } catch(error){
      console.error('registers/GET_SINGLE_REGISTER', { error })
      throw error
    }
  },

  async GET_ROW_TO_EDIT({ commit, dispatch }, { registryId, rowId }){
    try {
      const { data: row } = await dispatch('api/REQUEST', {
        endpoint: `user/registry/${registryId}/row/${rowId}`
      }, { root: true })

      console.log('registerSingle/GET_ACTIVITY_TO_EDIT', { row })
      commit('SET_ROW_TO_EDIT', { row })
    } catch(error) {
      console.error('registerSingle/GET_EDITED_ACTIVITY')
      throw error
    }
  },

  async GET_REGISTRY_ROW_TO_ADD ({ commit, dispatch }, { registerId }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `registry/${registerId}`
      }, { root: true })

      console.log('registerSingle/GET_REGISTRY_ROW_OPTIONS', data)
      commit('SET_ROW_OPTIONS', { rowOptions: data })
    } catch(error) {
      console.error('registerSingle/GET_REGISTRY_ROW_OPTIONS', { error, registerId })
      throw error
    }
  },

  async ADD_ROW ({ commit, dispatch }, { registerId, selectedColumnValues }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/registry/${registerId}/row`,
        method: 'POST',
        data: { selectedColumnValues }
      }, { root: true })

      console.log('registerSingle/ADD_ROW', data)
      return data
    } catch(error) {
      console.error('registerSingle/ADD_ROW', { error })
      throw error
    }
  },

  async UPDATE_ROW ({ commit, dispatch }, { registerId, rowId, selectedColumnValues }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/registry/${registerId}/row/${rowId}`,
        method: 'PUT',
        data: { selectedColumnValues }
      }, { root: true })

      console.log('registerSingle/UPDATE_ROW', data)
      return data
    } catch(error) {
      console.error('registerSingle/UPDATE_ROW', { error })
      throw error
    }
  },

  async REMOVE_ROW ({ commit, dispatch }, { registerId, rowId }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/registry/${registerId}/row/${rowId}`,
        method: 'DELETE'
      }, { root: true })

      console.log('registerSingle/REMOVE_ROW', data)
      return data
    } catch(error) {
      console.error('registerSingle/REMOVE_ROW', { error })
      throw error
    }
  }
}

const mutations = {
  SET_SINGLE_REGISTER(state, { register }) {
    state.register = register
  },

  SET_ROW_OPTIONS (state, { rowOptions }) {
    console.log('registerSingle/SET_ROW_OPTIONS', { rowOptions })
    state.rowOptions = rowOptions
  },

  SET_ROW_TO_EDIT (state, { row }){
    state.rowsToEdit = Object.assign({}, state.rowsToEdit, { [row.id] : row })
  },

  SET_ADD_ROW_VISIBLE (state, { value }) {
    console.log('registerSingle/SET_ADD_ROW_VISIBLE', { value })
    state.addRowVisible = value
  },

  SET_EDIT_ROW_VISIBLE (state, { rowId, value }) {
    console.log('registerSingle/SET_EDIT_ROW_VISIBLE', { rowId, value })
    if(value){
      state.editRowsVisible.push(rowId)
    } else {
      const isVisible = state.editRowsVisible.indexOf(rowId) > -1
      if(isVisible){
        state.editRowsVisible = state.editRowsVisible.filter(id => id !== rowId)
      }
    }
  },

  RESET_STATE(state){
    Object.assign(state, initialState())
  }
}

const getters = {
  register: state => {
    if(!state.register){
      return
    }

    const { id, info, name, table: _table } = state.register
    const { columns, rows } = (_table || {})

    const table = {
      columns: (columns || []).sort((a,b) => a.sortOrder - b.sortOrder),
      rows
    }

    return {
      id,
      info,
      name,
      table
    }
  },
  rowOptions: state => {
    if(!state.rowOptions){
      return
    }

    const { columnValues, id } = state.rowOptions

    return {
      columnValues: formatColumnValues({ columnValues }),
      id
    }
  },
  addRowVisible: state => state.addRowVisible,
  editRowsVisible: state => state.editRowsVisible,
  rowsToEdit: state => {

    return Object.keys(state.rowsToEdit).reduce((res, rowKey) => {

      const { id, columnValues } = state.rowsToEdit[rowKey]

      res[rowKey] = {
        id,
        columnValues: formatColumnValues({ columnValues })
      }

      return res
    }, {})
  }
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
