var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gdBrick",
    { class: _vm.uarStepClass },
    [
      _c("gdRodoStepHeader", {
        attrs: {
          title: _vm.groupTitle,
          completedTitle: _vm.group.name,
          active: _vm.group.active,
          completed: _vm.group.completed,
          sideTitle: _vm.sideTitle
        }
      }),
      _vm._v(" "),
      _c(
        "gdGrowTransition",
        [
          _vm.group.active
            ? _c("gdGrowTransitionContent", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return _vm.group.active
                        ? [
                            _c(
                              "section",
                              { staticClass: "gd-uar-step__content" },
                              [
                                _vm.group.questions && _vm.group.active
                                  ? _c("gdUarStepQuestions", {
                                      attrs: {
                                        items: _vm.group.questions,
                                        group: _vm.group,
                                        groupIndex: _vm.groupIndex
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        : undefined
                    }
                  }
                ])
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }