var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { ref: "rodoStepsHolder", staticClass: "gd-rodo-steps" },
    [
      _c("span", {
        ref: "rodoStepsProgressBg",
        staticClass: "gd-rodo-steps__progress-bg"
      }),
      _vm._v(" "),
      _c("gdRodoStep1"),
      _vm._v(" "),
      _c("gdRodoStep2"),
      _vm._v(" "),
      _c("gdRodoStep3"),
      _vm._v(" "),
      _c("gdRodoStep4"),
      _vm._v(" "),
      _c("gdRodoStep5"),
      _vm._v(" "),
      _c("gdRodoStep6")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }