import VueRouter from 'vue-router'
import Store from './stores'

import LogIn from './routing/log-in'
import SignUp from './routing/sign-up'
import DataProcessing from './routing/data-processing'
import PaymentAwait from './routing/payment-await'
import PasswordReset from './routing/password-reset'

import gdViewRouterHelper from './components/helpers/gd-view-router-helper'

import App from './routing/app/index'
import AppActivityRegister from './routing/app/activity-register'
import AppCertificate from './routing/app/certificate'
import AppCourses from './routing/app/courses'
import AppDashboard from './routing/app/dashboard'
import AppDocuments from './routing/app/documents'
import AppEditAreas from './routing/app/activity-register/edit-areas'
import AppHelp from './routing/app/help'
import AppInsurances from './routing/app/insurances'
import AppRegisterSingle from './routing/app/register-single'
import AppRecommendationsReport from './routing/app/recommendations-report'
import AppReportOfCompatibility from './routing/app/report-of-compatibility'
import AppServices from './routing/app/services'
import AppAccount from './routing/app/account'
import AppError from './routing/app/error'
import AppSimplifiedRiskAnalysis from './routing/app/simplified-risk-analysis'
import AppRiskTracker from './routing/app/risk-tracker'

const isRouteAllowedForUser = ({ route }) => {

  const disabledRoutes = Store.getters['user/disabledRoutes']

  if(typeof disabledRoutes[route.name] === 'function' && typeof route.params.id !== 'undefined'){
    return !disabledRoutes[route.name]({ id: parseInt(route.params.id) })
  } else {
    const isRouteDisabled = (disabledRoutes[route.name] === true)
    return !isRouteDisabled
  }
}

const routes = [
  {
    name: 'login',
    path: '/logowanie',
    component: LogIn
  },
  {
    name: 'signup',
    path: '/rejestracja',
    component: SignUp
  },
  {
    name: 'password-reset',
    path: '/reset-hasla',
    component: PasswordReset
  },
  {
    name: 'data-processing',
    path: '/przetwarzanie-danych',
    component: DataProcessing
  },
  {
    name: 'payment-await',
    path: '/przetwarzanie-platnosci',
    component: PaymentAwait,
    meta: {
      customRouteValidation: () => {
        const isLoggedIn = Store.getters['user/isLoggedIn']
        const userPaymentId = Store.getters['payments/userPaymentId']

        console.log({isLoggedIn, userPaymentId})
        return {
          success: !!(isLoggedIn && userPaymentId),
          errorRoute: isLoggedIn ? { name: 'app.dashboard' } : { name: 'login' }
        }
      }
    }
  },
  // App
  {
    name: 'app',
    path: '/',
    component: App,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'rejestr-czynnosci',
        component: gdViewRouterHelper,
        meta: {
          isRouteAllowedForUser
        },
        children: [
          {
            name: 'app.activity-register',
            path: '',
            component: AppActivityRegister,
          },
          {
            name: 'app.activity-register.edit-areas',
            path: 'edycja-obszarow',
            component: AppEditAreas
          }
        ]
      },
      {
        name: 'app.certificate',
        path: 'certyfikat',
        component: AppCertificate,
        meta: {
          isRouteAllowedForUser
        }
      },
      {
        name: 'app.register-single',
        path: 'rejestr/:id',
        component: AppRegisterSingle,
        meta: {
          isRouteAllowedForUser
        }
      },
      {
        name: 'app.courses',
        path: 'szkolenia',
        component: AppCourses,
        meta: {
          isRouteAllowedForUser
        }
      },
      {
        name: 'app.dashboard',
        path: 'kokpit',
        component: AppDashboard
      },
      {
        name: 'app.documents',
        path: 'dokumenty',
        component: AppDocuments,
        meta: {
          isRouteAllowedForUser
        }
      },
      {
        name: 'app.help',
        path: 'pomoc',
        component: AppHelp
      },
      {
        name: 'app.insurances',
        path: 'ubezpieczenia',
        component: AppInsurances,
        meta: {
          isRouteAllowedForUser
        }
      },
      {
        name: 'app.recommendations-report',
        path: 'raport-i-zalecenia',
        component: AppRecommendationsReport,
        meta: {
          isRouteAllowedForUser
        }
      },
      {
        name: 'app.report-of-compatibility',
        path: 'raport-zgodnosci',
        component: AppReportOfCompatibility,
        meta: {
          isRouteAllowedForUser
        }
      },
      {
        name: 'app.services',
        path: 'uslugi',
        component: AppServices,
        meta: {
          isRouteAllowedForUser
        }
      },
      {
        name: 'app.account',
        path: 'konto',
        component: AppAccount
      },
      {
        name: 'app.error',
        path: 'error',
        component: AppError
      },
      {
        name: 'app.simplified-risk-analysis',
        path: 'uproszczona-analiza-ryzyka',
        component: AppSimplifiedRiskAnalysis
      },
      {
        name: 'app.risk-tracker',
        path: 'risk-tracker',
        component: AppRiskTracker
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {

  // Redirect app => app.dashboard
  if(to.name === 'app'){
    return next({ name: 'app.dashboard' })
  }

  const isLoggedIn = Store.getters['user/isLoggedIn']
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth)
  const routeWithCustomRouteValidation = to.matched.find(route => route.meta.customRouteValidation)

  if(routeWithCustomRouteValidation){
    const { customRouteValidation } = routeWithCustomRouteValidation.meta

    if(typeof customRouteValidation === 'function'){
      const result = customRouteValidation()
      return next(result.success ? undefined : result.errorRoute)
    }
  }

  // Requires auth
  if(requiresAuth && isLoggedIn){

    // Is route unlocked
    if(to.meta.isRouteAllowedForUser){
      const isAllowed = to.meta.isRouteAllowedForUser({ route: to })
      return next(isAllowed ? undefined : { name: 'app.error', query: { type: 'NOT_ALLOWED' } })
    }

    return next()
  } else if(requiresAuth){
    return next({ name: 'login' })
  }

  return next()
})

export { router, routes }
