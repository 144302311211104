var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.brickClass },
    [
      _c("gdBrick", { attrs: { thin: _vm.thin, disabled: _vm.disabled } }, [
        _c("div", { staticClass: "brick__image" }, [_vm._t("image")], 2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "brick__content" },
          [
            _vm._t("icon"),
            _vm._v(" "),
            _c("h2", { staticClass: "content-title" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "content-about" }, [
              _vm._v(_vm._s(_vm.about))
            ]),
            _vm._v(" "),
            _vm._t("buttons")
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }