<template>
  <section class="gd-help">
    <gdPageHeader
      title="Pomoc"
      description="Maecenas sed diam eget risus varius blandit sit amet non magna."
    />
    <gdAccordeon
      v-if="questions"
      :items="questions"
      visibleField="title"
      expandableField="answer"
      commentField="comment"
    />
  </section>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdPageHeader from '@/components/page/gd-page-header'
  import gdAccordeon from '@/components/accordeons/gd-accordeon'

  export default {

    computed: {
      ...mapGetters('help', ['questions'])
    },

    components: {
      gdPageHeader,
      gdAccordeon
    }
  }
</script>
<style lang="scss">

</style>
