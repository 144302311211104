import 'babel-polyfill'
import Vue from 'vue'
import './plugins'
import { router } from './router'
import store from './stores'
import Meta from 'vue-meta'
import { metaInfo as gdMetaInfo } from '@/helpers'
import Root from './root.vue'
import Maintenance from '@/components/helpers/gd-maintenance-mode.vue'
import '@/assets/styles/main.scss'
import gdpsComponents from 'gdps-components'

Vue.use(Meta)
Vue.prototype.$gdMetaInfo = gdMetaInfo

async function initApp() {

  /*
   *
   *          F E A T U R E S
   *
   */

  function _registerExternalStores(){
    Object.keys(gdpsComponents.stores).forEach(storeName => {
      const storeModule = gdpsComponents.stores[storeName]
      store.registerModule(storeName, storeModule, { preserveState: false })
    })
  }

  function _setUIvalues(){

    const isMobile = () => (window.innerWidth <= 1045)
    store.commit('ui/SET_IS_MOBILE', { value: isMobile() })

    var resizeTimer;
    window.addEventListener('resize', (ev) => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        store.commit('ui/SET_IS_MOBILE', { value: isMobile() })
      }, 100)
    })
  }

  async function _getUser(){

    const token = store.getters['user/token'];
    const isRegisterPage = window.location.pathname.includes('rejestracja')

    if(!token || isRegisterPage){
      return
    }

    try {
      await store.dispatch('user/GET_USER')
      await store.dispatch('registers/GET_REGISTERS')
    } catch (error) {
      // Handled in router.js if route has requiresAuth
    }
  }

  function _getElementToRender(){
    const MAINTENANCE_MODE = process.env.MAINTENANCE_MODE;
    const disableMaintenance = window.location.search.includes('disableMaintenanceMode=true')
    const elementToRender = ((MAINTENANCE_MODE === true || MAINTENANCE_MODE === 'true') && !disableMaintenance) ? Maintenance : Root

    return elementToRender
  }

  function _renderApp(){

    const appInstance = new Vue({
        store,
        router,
        render: createElement => createElement(_getElementToRender())
      })
      .$mount('#app')
  }

  /*
   *
   *          I N I T
   *
   */

  _registerExternalStores()
  _setUIvalues()
  await _getUser()

  /*
   *
   *          R E N D E R
   *
   */

  _renderApp()
}


initApp()
