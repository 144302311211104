class Value {
  constructor (obj) {
    if (!obj.hasOwnProperty('label') || !obj.hasOwnProperty('value') || !obj.hasOwnProperty('isCustom')) {
      throw 'Error while creating instance of Value in format-column-values.js'
    }

    this.label = obj.label
    this.value = obj.value
    this.isCustom = obj.isCustom
    this.isDeprecate = obj.isDeprecate
  }
}

export default function formatColumnValues({ columnValues }) {

  return Object.keys((columnValues || {})).reduce((res, valueKey) => {

    const valuesCollection = columnValues[valueKey]
    const type = valuesCollection[0].type
    let availableValues
    let currentValue
    let isMultiselect

    if (!type) {
      console.log({ valuesCollection })
    }
    switch (type) {
    case 'list':

      isMultiselect = valuesCollection[0].isMultiselect
      availableValues = valuesCollection.map(value => {
        return new Value({
          label: value.value,
          value: value.id,
          isCustom: value.isCustom,
          isDeprecate: value.isDeprecate
        })
      })

      // CUSTOM_CLICK_TRIGGER
      availableValues.push({
        label: 'Własna wartość',
        type: 'CUSTOM_CLICK_TRIGGER'
      })

      const selectedValue = isMultiselect ? valuesCollection.filter(value => value.isSelected) : valuesCollection.find(value => value.isSelected)
      const hasSelectedValue = isMultiselect ? (selectedValue && selectedValue.length) : !!selectedValue

      if (hasSelectedValue) {
        if(isMultiselect){
          currentValue = selectedValue.map(item => new Value({ value: item.id , label: item.value, isCustom: item.isCustom, isDeprecate: item.isDeprecate }))
        } else {
          currentValue = new Value({ label: selectedValue.value, value: selectedValue.id, isCustom: selectedValue.isCustom, isDeprecate: selectedValue.isDeprecate })
        }
      } else {
        if(isMultiselect){
          let defaultValue = valuesCollection.filter(value => value.isDefault)
          currentValue = defaultValue.map(item => new Value({ value: item.id , label: item.value, isCustom: item.isCustom, isDeprecate: item.isDeprecate }))
        } else {
          let defaultValue = valuesCollection.find(value => value.isDefault)
          currentValue = new Value({ label: defaultValue.value, value: defaultValue.id, isCustom: defaultValue.isCustom, isDeprecate: defaultValue.isDeprecate })
        }
      }
      break
    case 'input':
      currentValue = valuesCollection[0]
      break
    }

    res[valueKey] = {
      isMultiselect,
      availableValues,
      currentValue,
      type
    }

    return res
  }, {})
}
