var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "gd-form gd-form-user-account",
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c(
        "section",
        { staticClass: "gd-form__section gd-form__section--space-bottom" },
        [
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:3",
                  expression: "'required|min:3'"
                }
              ],
              attrs: { name: "firstName", "data-vv-as": "imię" },
              model: {
                value: _vm.form.firstName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "firstName", $$v)
                },
                expression: "form.firstName"
              }
            },
            [_vm._v("\n      Imię\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:3",
                  expression: "'required|min:3'"
                }
              ],
              attrs: { name: "lastName", "data-vv-as": "nazwisko" },
              model: {
                value: _vm.form.lastName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "lastName", $$v)
                },
                expression: "form.lastName"
              }
            },
            [_vm._v("\n      Nazwisko\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'"
                }
              ],
              attrs: { name: "email", "data-vv-as": "adres email" },
              model: {
                value: _vm.form.email,
                callback: function($$v) {
                  _vm.$set(_vm.form, "email", $$v)
                },
                expression: "form.email"
              }
            },
            [_vm._v("\n      Adres Email\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|phoneNumber",
                  expression: "'required|phoneNumber'"
                }
              ],
              attrs: { name: "phone", "data-vv-as": "numer telefonu" },
              model: {
                value: _vm.form.phone,
                callback: function($$v) {
                  _vm.$set(_vm.form, "phone", $$v)
                },
                expression: "form.phone"
              }
            },
            [_vm._v("\n      Numer telefonu\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "gd-form__section gd-form__section--column" },
        [
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: { name: "companyName", "data-vv-as": "pełna nazwa firmy" },
              model: {
                value: _vm.form.company.name,
                callback: function($$v) {
                  _vm.$set(_vm.form.company, "name", $$v)
                },
                expression: "form.company.name"
              }
            },
            [_vm._v("\n      Pełna nazwa firmy\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    required: true,
                    regex: /^\d+$/
                  },
                  expression:
                    "{\n        required: true,\n        regex: /^\\d+$/\n      }"
                }
              ],
              attrs: {
                name: "companyNip",
                placeholder: "Dokładnie 10 cyfr",
                type: "number",
                maxLengthValue: 11,
                "data-vv-as": "NIP"
              },
              model: {
                value: _vm.form.company.nip,
                callback: function($$v) {
                  _vm.$set(_vm.form.company, "nip", $$v)
                },
                expression: "form.company.nip"
              }
            },
            [_vm._v("\n      NIP\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "gd-form__section" },
        [
          _c(
            "div",
            { staticClass: "gd-input" },
            [
              _c("span", { staticClass: "gd-input__label" }, [
                _vm._v("Województwo")
              ]),
              _vm._v(" "),
              _c("gdSelect", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                attrs: {
                  placeholder: "Wybierz z listy...",
                  name: "companyProvince",
                  "data-vv-as": "województwo",
                  items: _vm.regions
                },
                model: {
                  value: _vm.form.company.province,
                  callback: function($$v) {
                    _vm.$set(_vm.form.company, "province", $$v)
                  },
                  expression: "form.company.province"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|onlyLetters",
                  expression: "'required|onlyLetters'"
                }
              ],
              attrs: { name: "companyCity", "data-vv-as": "miejscowość" },
              model: {
                value: _vm.form.company.city,
                callback: function($$v) {
                  _vm.$set(_vm.form.company, "city", $$v)
                },
                expression: "form.company.city"
              }
            },
            [_vm._v("\n      Miejscowość\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|atLeastOneNumber|atLeastOneLetter",
                  expression: "'required|atLeastOneNumber|atLeastOneLetter'"
                }
              ],
              attrs: { name: "companyAddress", "data-vv-as": "adres" },
              model: {
                value: _vm.form.company.address,
                callback: function($$v) {
                  _vm.$set(_vm.form.company, "address", $$v)
                },
                expression: "form.company.address"
              }
            },
            [_vm._v("\n      Adres\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    required: true,
                    regex: /[0-9]/
                  },
                  expression:
                    "{\n        required: true,\n        regex: /[0-9]/\n      }"
                }
              ],
              attrs: {
                name: "companyZipCode",
                maxLength: true,
                maxLengthValue: 7,
                "data-vv-as": "z kodem pocztowym"
              },
              nativeOn: {
                input: function($event) {
                  _vm.zipCode(_vm.form.company.zipcode)
                }
              },
              model: {
                value: _vm.form.company.zipcode,
                callback: function($$v) {
                  _vm.$set(_vm.form.company, "zipcode", $$v)
                },
                expression: "form.company.zipcode"
              }
            },
            [_vm._v("\n      Kod pocztowy\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gd-form__submit" },
        [
          _c(
            "gdButton",
            {
              attrs: { loading: _vm.loading },
              nativeOn: {
                click: function($event) {
                  return _vm.handleSubmit($event)
                }
              }
            },
            [_vm._v("\n      Zapisz\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }