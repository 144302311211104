<template>
  <nav class="gd-nav-app-main">
    <ul class="gd-nav-app-main__list">
      <!-- TODO: Disabled & Tooltip (in item already)  -->
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="{ 'route--disabled' : item.disabled }"
      >
        <gdLink :to="item.to">
          <span class="item-icon">
            <gdIcon :name="item.iconName" />
          </span>
          <span class="item-label">
            {{ item.label }}
          </span>
        </gdLink>
        <gdTooltip
          placement="right-center"
          content="Ta strona będzie dostępna po uzupełnieniu niezbędnych danych"
        />
      </li>
    </ul>
  </nav>
</template>

<script>
  export default {
    props: {
      items: Array
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .gd-nav-app-main__list {
    li {
      position: relative;

      .gd-tooltip {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: not-allowed;
      }

      &.route--disabled {
        .gd-tooltip {
          display: block;
        }

        a {
          pointer-events: none;
        }
      }
    }

    li a {
      position: relative;
      display: flex;
      align-items: center;
      padding: 18px 22px;
      border-bottom: 1px solid $color-gray-dark;
      font-size: 14px;
      font-family: $font-medium;
      color: $color-mithrill;

      &.router-link-active {
        border-bottom: 1px solid $color-blue;
        color: $color-white;
        background-color: $color-blue;

        &::before {
          content: '';
          position: absolute;
          top: -1px;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          background-color: $color-blue;
        }

        .item-label {
          left: 90px;
        }

        .item-icon {
          svg {
            color: $color-orange;
            transition: .15s .05s;
          }

          &::before {
            width: 68px;
            height: 62px;
            border-radius: 0;
          }
        }

        &:hover {
          border-bottom: 1px solid $color-blue;
          background-color: $color-blue;
        }
      }

      &:hover {
        color: $color-white;
        background-color: $color-aside-hover;
      }
    }

    .item-icon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin-right: 45px;

      &::before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 0;
        width: 0;
        border-radius: 50%;
        background-color: rgba($color-black, .1);
        transition: .15s;
      }
    }

    .item-label {
      position: absolute;
      left: 70px;
      transition: .15s;
    }
  }
</style>
