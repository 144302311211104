var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-payment-await" },
    [
      _c(
        "transition",
        {
          attrs: { slot: "wide", name: "global-slide", mode: "out-in" },
          slot: "wide"
        },
        [
          _vm.paymentStatus.waiting && !_vm.paymentStatus.done
            ? _c(
                "div",
                { staticClass: "payment-awaits" },
                [
                  _c("gdImg", {
                    staticClass: "gd-payment-await__logo",
                    attrs: { src: "/assets/images/logo.svg" }
                  }),
                  _vm._v(" "),
                  _c("h1", { staticClass: "gd-payment-await__title" }, [
                    _vm._v("Twoja płatność jest przetwarzana")
                  ]),
                  _vm._v(" "),
                  _c("gdImg", { attrs: { src: "/assets/images/loader.svg" } }),
                  _vm._v(" "),
                  _c("p", { staticClass: "gd-payment-await__info" }, [
                    _vm._v(
                      "Nie zamykaj tej karty i oczekuj na potwierdzenie płatności"
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { slot: "wide", name: "global-slide", mode: "out-in" },
          slot: "wide"
        },
        [
          !_vm.paymentStatus.waiting && _vm.paymentStatus.done
            ? _c(
                "div",
                { staticClass: "payment-done" },
                [
                  _c("gdImg", {
                    staticClass: "gd-payment-await__logo",
                    attrs: { src: "/assets/images/logo.svg" }
                  }),
                  _vm._v(" "),
                  _c("h1", { staticClass: "gd-payment-await__title" }, [
                    _vm._v("Twoja płatność przebiegła poprawnie")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "payment-done__icon" },
                    [_c("gdIcon", { attrs: { name: "check" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "gdButton",
                    { attrs: { link: { name: "app.dashboard" } } },
                    [_vm._v("Przejdź do Panelu Startowego")]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { slot: "wide", name: "global-slide", mode: "out-in" },
          slot: "wide"
        },
        [
          !_vm.paymentStatus.waiting && !_vm.paymentStatus.done
            ? _c(
                "div",
                { staticClass: "payment-canceled" },
                [
                  _c("gdImg", {
                    staticClass: "gd-payment-await__logo",
                    attrs: { src: "/assets/images/logo.svg" }
                  }),
                  _vm._v(" "),
                  _c("h1", { staticClass: "gd-payment-await__title" }, [
                    _vm._v("Twoja płatność "),
                    _c("span", [_vm._v("nie powiodła się")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "payment-canceled__icon" },
                    [_c("gdIcon", { attrs: { name: "close" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "gd-payment-await__info" }, [
                    _vm._v("Skontaktuj się z administratorem serwisu")
                  ]),
                  _vm._v(" "),
                  _vm.userPlan.key || _vm.userPlan.id
                    ? _c("ul", { staticClass: "gd-payment-await__details" }, [
                        _c("li", [
                          _vm._v("ID planu: " + _vm._s(_vm.userPlan.id))
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v("Klucz planu: " + _vm._s(_vm.userPlan.key))
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }