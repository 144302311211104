<template>
  <gdInsurances />
</template>
<script>
  import gdInsurances from '@/pages/app/gd-insurances'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    components: {
      gdInsurances
    }
  }
</script>
