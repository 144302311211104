<template>
  <form
    class="gd-form gd-form-user-account"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <section class="gd-form__section gd-form__section--space-bottom">
      <gdInput
        name="firstName"
        v-validate="'required|min:3'"
        data-vv-as="imię"
        v-model="form.firstName"
      >
        Imię
      </gdInput>
      <gdInput
        name="lastName"
        v-validate="'required|min:3'"
        data-vv-as="nazwisko"
        v-model="form.lastName"
      >
        Nazwisko
      </gdInput>
      <gdInput
        name="email"
        v-validate="'required|email'"
        data-vv-as="adres email"
        v-model="form.email"
      >
        Adres Email
      </gdInput>
      <gdInput
        name="phone"
        v-validate="'required|phoneNumber'"
        data-vv-as="numer telefonu"
        v-model="form.phone"
      >
        Numer telefonu
      </gdInput>
    </section>

    <section class="gd-form__section gd-form__section--column">
      <gdInput
        name="companyName"
        v-validate="'required'"
        data-vv-as="pełna nazwa firmy"
        v-model="form.company.name"
      >
        Pełna nazwa firmy
      </gdInput>
      <gdInput
        name="companyNip"
        placeholder="Dokładnie 10 cyfr"
        type="number"
        :maxLengthValue="11"
        data-vv-as="NIP"
        v-validate="{
          required: true,
          regex: /^\d+$/
        }"
        v-model="form.company.nip"
      >
        NIP
      </gdInput>
    </section>

    <section class="gd-form__section">
      <div class="gd-input">
        <span class="gd-input__label">Województwo</span>
        <gdSelect
          placeholder="Wybierz z listy..."
          name="companyProvince"
          v-validate="'required'"
          data-vv-as="województwo"
          v-model="form.company.province"
          :items="regions"
        />
      </div>
      <gdInput
        name="companyCity"
        data-vv-as="miejscowość"
        v-validate="'required|onlyLetters'"
        v-model="form.company.city"
      >
        Miejscowość
      </gdInput>

      <gdInput
        name="companyAddress"
        data-vv-as="adres"
        v-validate="'required|atLeastOneNumber|atLeastOneLetter'"
        v-model="form.company.address"
      >
        Adres
      </gdInput>
      <gdInput
        name="companyZipCode"
        :maxLength="true"
        :maxLengthValue="7"
        v-validate="{
          required: true,
          regex: /[0-9]/
        }"
        data-vv-as="z kodem pocztowym"
        v-model="form.company.zipcode"
        @input.native="zipCode(form.company.zipcode)"
      >
        Kod pocztowy
      </gdInput>
    </section>
    <div class="gd-form__submit">
      <gdButton
        @click.native="handleSubmit"
        :loading="loading"
      >
        Zapisz
      </gdButton>
    </div>
  </form>
</template>
<script>
  import { mapGetters } from "vuex";
  import objectPath from "object-path";
  import { formatZipCode } from "@/helpers";

  export default {
    data() {
      return {
        form: {
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
          company: {
            name: null,
            nip: null,
            province: null,
            city: null,
            address: null,
            zipcode: null
          }
        },
        loading: false
      };
    },

    created() {
      if (this.user) {
        this.form = this.user;
        if (this.form.company && this.form.company.province) {
          const province = this.regions.find(
            item => item.value === this.form.company.province
          );
          if (province) {
            this.form.company.province = province;
          }
        }
      }
    },

    computed: {
      ...mapGetters("user", ["user"]),
      ...mapGetters("staticData", ["regions"])
    },

    methods: {
      async handleSubmit() {
        try {
          const isValid = await this.$validator.validateAll();

          if (!isValid) {
            return;
          }

          this.loading = true;
          await this.$store.dispatch(
            "user/UPDATE_USER_DATA",
            Object.assign(
              {},
              { ...this.form },
              {
                company: Object.assign({}, this.form.company, {
                  province: this.form.company.province.value
                })
              }
            )
          );
          this.$store.dispatch("toasts/ADD_TOAST", {
            toast: { message: "Zaktualizowano dane użytkownika" }
          });
          this.loading = false;
        } catch (error) {
          console.error("gd-form-user-account", { error });
          let message = "Błąd aktualizacji danych użytkownika";
          const errorCode = objectPath.get(error, "response.data.error.code");
          if (errorCode === "email_exists") {
            message = "Podany adres email już istnieje";
          }
          this.$store.dispatch("toasts/ADD_TOAST", {
            toast: { message: `${message}`, type: "error" }
          });
          this.loading = false;
        }
      },

      zipCode(value) {
        this.form.company.zipcode = formatZipCode(value);
      }
    }
  };
</script>

<style lang="scss">
  .gd-form-user-account {
    .gd-form__submit {
      width: 180px;
    }
  }
</style>
