/*
 * File: /src/stores/risk-tracker.js
 * Project: gdps-app-frontend
 * -
 * File created: Tuesday 15th October 2019 2:45:25 pm
 * File modified: Tuesday 15th October 2019 2:45:25 pm
 * Last modified by: Bart
 * -
 * @author: Bart
 */
const rtUrl = process.env.RISK_TRACKER_URL || ''

const initialState = () => ({
  rtUrl
})

const state = () => (initialState())

const actions = {
  async REGISTER_USER({ commit, dispatch }, data){
    try {
      const result = await dispatch('api/REQUEST', {
        endpoint: 'rt/integration',
        method: 'POST',
        data
      }, { root: true })
    
      return result
      console.log('riskTracker/REGISTER_USER', { result })
    } catch (error) {
      console.error('user/REGISTER_USER', { error })
      throw error
    }
  },
}

const mutations = {}

const getters = {
  rtUrl: state => state.rtUrl
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}