var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-account" },
    [
      _c("gdPageHeader", { attrs: { title: "Twoje konto" } }),
      _vm._v(" "),
      _c(
        "gdBricksWrapper",
        [
          _c(
            "gdBrick",
            { attrs: { slot: "wide" }, slot: "wide" },
            [_c("gdFormUserAccount")],
            1
          ),
          _vm._v(" "),
          _c(
            "gdBrick",
            { attrs: { slot: "wide" }, slot: "wide" },
            [_c("gdFormUserPasswordChange")],
            1
          ),
          _vm._v(" "),
          _c("gdBrickAccountPlan", {
            attrs: {
              slot: "thin",
              planName: _vm.userPayments.name,
              oneTimePrice: _vm.userPayments.price,
              planPrice: _vm.userPayments.price,
              startDate: _vm.userPayments.startDate,
              endDate: _vm.userPayments.endDate,
              planInvoices: _vm.invoices
            },
            slot: "thin"
          }),
          _vm._v(" "),
          _c(
            "gdBrick",
            { attrs: { slot: "thin" }, slot: "thin" },
            [_c("gdFormUserCompanySwitch")],
            1
          ),
          _vm._v(" "),
          _c("gdBrickLogout", { attrs: { slot: "thin" }, slot: "thin" })
        ],
        1
      ),
      _vm._v(" "),
      _vm.user.isAdmin
        ? _c(
            "div",
            { staticClass: "gd-account__admin-area" },
            [
              _c("gdAccountDivider", { attrs: { title: "Użytkownicy" } }),
              _vm._v(" "),
              _c(
                "gdTable",
                { staticClass: "gd-account-table" },
                [
                  _c("gdAccountUsersList", {
                    attrs: { users: _vm.companysUsers }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("gdAccountDivider", { attrs: { title: "Dodaj użytkownika" } }),
              _vm._v(" "),
              _c(
                "gdBricksWrapper",
                [
                  _c(
                    "gdBrick",
                    { attrs: { slot: "wide" }, slot: "wide" },
                    [_c("gdFormUserInvite")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }