<template>
  <gdRecommendationsReport />
</template>
<script>
  import gdRecommendationsReport from '@/pages/app/gd-recommendations-report'
  import Store from '@/stores'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    async beforeRouteEnter(to, from, next){
      try {
        await Store.dispatch('recommendationsReport/GET_RECOMMENDATIONS')
        next()
      } catch(error) {
        console.error(`Couldn't resolve gd-recommendations-report beforeRouteEnter requests`, { error })
        next({
          name: 'app.error',
          query: {
            type: 'API'
          }
        })
      }
    },

    components: {
      gdRecommendationsReport
    }
  }
</script>
