var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gdBrick",
    { class: _vm.rodoStepClass },
    [
      _c("gdRodoStepHeader", {
        attrs: {
          title: "Zastosuj się do zaleceń",
          completedTitle: "Zalecenia zastosowane",
          iconName: "list",
          active: _vm.step5.active,
          completed: _vm.step5.completed,
          resettable: _vm.step5.active && !_vm.step5.completed,
          rollbackable: _vm.step5.active && !_vm.step5.completed
        }
      }),
      _vm._v(" "),
      _c(
        "gdGrowTransition",
        [
          _vm.step5.active
            ? _c("gdGrowTransitionContent", [
                _c("div", { staticClass: "gd-rodo-step__content" }, [
                  _c("p", [_vm._v(_vm._s(_vm.step5.description))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "question__buttons" },
                    [
                      _c(
                        "gdButton",
                        {
                          attrs: {
                            link: { name: "app.recommendations-report" }
                          }
                        },
                        [_vm._v("Zobacz zalecenia")]
                      ),
                      _vm._v(" "),
                      _vm.allowedResources.recommendation.allCompleted
                        ? _c(
                            "gdButton",
                            {
                              nativeOn: {
                                click: function($event) {
                                  return _vm.completeStep($event)
                                }
                              }
                            },
                            [_vm._v("\n            Kontynuuj\n          ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }