const initialState = () => ({})

const state = () => (initialState())

const actions = {
  async SEND_CONTACT_FORM({ commit, dispatch }, { type, data }){

    const allowedTypes = ['plan', 'service', '', null, undefined]

    if(!allowedTypes.includes(type)){
      dispatch('toasts/ADD_DEFAULT_WARNING_TOAST', undefined, { root: true })
      console.error('contact/SEND_CONTACT_FORM wrong mail type')
      return
    }

    try {
      const { result } = await dispatch('api/REQUEST', {
        endpoint: `contact${type ? `/${type}` : ''}`,
        method: 'POST',
        data: data
      }, { root: true })

      console.log('contact/SEND_CONTACT_FORM', {result, type, data})
    } catch(error){
      console.error('contact/SEND_CONTACT_FORM', { error })
      throw error
    }
  },
}

export default {
  namespaced: true,
  state, actions
}
