<template>
  <section class="gd-recommendations-report">
    <gdPageHeader
      title="Raport Zaleceń"
      description="W tym miejscu znajdziesz wytyczne i zalecenia do wykonania, które mają na celu dostosowanie Twojej działalności do zgodności z RODO. Po wykonaniu poniższych zaleceń będziesz mógł wygenerować Raport z Wdrożenia oraz przejść do pobrania Certyfikatu."
    />
    <gdAccordeonRecommendations
      v-if="recommendations"
      :items="recommendations"
      @fileDeleted="reloadRecommendations"
    />
    <gdBricksWrapper>
      <gdBrickDashboard
        v-for="(doc, docIndex) in documents"
        :key="docIndex"
        :slot="(docIndex + 1) % 3 ? 'wide' : 'thin'"
        :thin="!!((docIndex + 1) % 3)"
        iconName="list"
        :title="doc.name"
        :content="doc.shortDescription"
        :disabled="!doc.available"
      >
        <template slot="buttons">
          <gdButton
            @click.native="downloadDocument({ documentId: doc.id, specialType: doc.specialType })"
          >
            Pobierz
          </gdButton>
        </template>
      </gdBrickDashboard>
    </gdBricksWrapper>
    <gdContinueImplementation
      v-if="!user.metadata.steps[5].completed"
    />
  </section>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdPageHeader from '@/components/page/gd-page-header'
  import gdBricksWrapper from '@/components/bricks/gd-bricks-wrapper'
  import gdBrickDashboard from '@/components/bricks/gd-brick-dashboard'
  import gdContinueImplementation from '@/components/footers/gd-continue-implementation'
  import gdAccordeonRecommendations from '@/components/accordeons/gd-accordeon-recommendations'

  export default {
    computed: {
      ...mapGetters('user', ['user']),
      ...mapGetters('recommendationsReport', ['recommendations', 'documents'])
    },

    methods: {
      async reloadRecommendations(){
        try {
          await this.$store.dispatch('recommendationsReport/GET_RECOMMENDATIONS')
        } catch(error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })

        }
      },
      async downloadDocument({ documentId, specialType }){
        try {
          await this.$store.dispatch('documents/DOWNLOAD_SINGLE_DOCUMENT', { documentId, specialType })
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      }
    },

    components: {
      gdPageHeader,
      gdBricksWrapper,
      gdBrickDashboard,
      gdContinueImplementation,
      gdAccordeonRecommendations
    }
  }
</script>
<style lang="scss">
  .gd-recommendations-report {
    .gd-brick-dashboard .gd-brick__inner {
      min-height: 302px;
    }

    .gd-bricks-wrapper {
      margin-top: 0;
      margin-bottom: 50px;
    }
  }
</style>
