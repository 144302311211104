<template>
  <section class="gd-report-of-compatibility">
    <gdPageHeader
      title="Raport Zgodności"
      description="To dokument wykazujący wymagania RODO oraz przedstawiający sposób w jaki zostały one zrealizowane w Twojej firmie, przy wykorzystaniu systemu oraz poprzez realizację zaleceń."
    />
    <gdBrickHorizontal
      v-for="(doc, docIndex) in documents"
      :key="docIndex"
      :wide="true"
      :title="doc.name"
      :about="doc.shortDescription"
      :disabled="!doc.available"
    >
      <gdImg
        slot="image"
        srcset="/assets/images/compliance-report.jpg 1x, /assets/images/compliance-report@2x.jpg 2x"
      />
      <gdIcon
        slot="icon"
        name="docs"
        :large="true"
      />
      <gdButton
        slot="buttons"
        @click.native="downloadDocument({ document: doc })"
        :disabled="!doc.available"
      >
        Pobierz
      </gdButton>
    </gdBrickHorizontal>
    <gdTable
      title="Matryca zgodności"
      :tableStatic="true"
    >
      <gdTableHeader>
        <gdTableCell>
          Wymagania rodo
        </gdTableCell>
        <gdTableCell>
          Przepis rodo
        </gdTableCell>
        <gdTableCell class="cell--wide">
          Sposób zapewnienia zgodności z rodo
        </gdTableCell>
      </gdTableHeader>
      <gdTableBody>
        <gdTableRow
          v-for="(item, bodyIndex) in compatibilities"
          :key="bodyIndex"
        >
          <gdTableCell>
            <span v-html="item.requirement" />
          </gdTableCell>
          <gdTableCell>
            <span v-html="item.recipe" />
          </gdTableCell>
          <gdTableCell class="cell--wide">
            <span v-html="item.sollution" />
          </gdTableCell>
        </gdTableRow>
      </gdTableBody>
    </gdTable>
    <gdContinueImplementation
      v-if="!user.metadata.steps[5].completed"
    />
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'

  import gdTable from '@/components/tables/elements/gd-table'
  import gdTableHeader from '@/components/tables/elements/gd-table-header'
  import gdPageHeader from '@/components/page/gd-page-header'
  import gdTableBody from '@/components/tables/elements/gd-table-body'
  import gdTableCell from '@/components/tables/elements/gd-table-cell'
  import gdTableRow from '@/components/tables/elements/gd-table-row'
  import gdContinueImplementation from '@/components/footers/gd-continue-implementation'
  import gdBrickHorizontal from '@/components/bricks/gd-brick-horizontal'

  export default {

    computed: {
      ...mapGetters('user', ['user']),
      ...mapGetters('reportOfCompatibility', ['documents', 'compatibilities'])
    },

    methods: {
      async downloadDocument({ document }){
        try {
          const { id: documentId, specialType } = document
          this.$store.dispatch('documents/DOWNLOAD_SINGLE_DOCUMENT', { documentId, specialType })
        } catch (error) {
          console.error({error})
        }
      }
    },

    components: {
      gdTable,
      gdTableBody,
      gdTableCell,
      gdPageHeader,
      gdTableRow,
      gdTableHeader,
      gdBrickHorizontal,
      gdContinueImplementation
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/fonts.scss";

  .gd-report-of-compatibility {
    .gd-page-header:not(:first-child) {
      margin-top: 30px;
    }

    .gd-table-header .gd-table-cell {
      &:first-child {
        width: 340px;
      }
    }

    .gd-table__title {
      margin-top: 35px;

      font: {
        size: 28px;
        family: $font-medium;
      }
    }

    .gd-table-body .gd-table-cell {
      height: unset;

      &:first-child {
        width: 340px;
      }
    }
  }
</style>
