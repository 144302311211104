<template>
  <gdAccordeon
    class="gd-accordeon-recommendations"
    :items="items"
  >
    <!-- Center - visible content -->
    <template
      slot="visible-content"
      slot-scope="props"
    >
      {{ props.item.title }}
    </template>
    <!-- Center - expandable content -->
    <template
      slot="expandable-content"
      slot-scope="props"
    >
      <div
        class="recommendation-description recommendation-description--main"
        v-if="props.item.content"
      >
        <gdRichText>
          {{ props.item.content }}
        </gdRichText>
      </div>
      <div
        class="recommendation-description recommendation-description--detail"
        v-if="props.item.comment"
      >
        <gdRichText>
          {{ props.item.comment }}
        </gdRichText>
      </div>

      <ul class="files-list">
        <li
          v-for="(file, fileIndex) in props.item.files"
          :key="fileIndex"
        >
          <gdFileLink
            v-if="props.item.files"
            :file="file"
            :allowRemove="true"
            @fileDeleted="$emit('fileDeleted')"
          >
            {{ file.name }}
          </gdFileLink>
        </li>
      </ul>
      <gdButton
        v-if="props.item.requireAttachment"
        @click.native="uploadFile({ recommendation: props.item })"
        theme="secondary"
      >
        Wgraj plik
      </gdButton>
    </template>

    <!-- Right -->
    <template
      slot="right"
      slot-scope="props"
    >
      <gdCheckbox
        v-model="props.item.isCompleted"
        @input="setRecommendationStatus({ recommendation: props.item })"
      />
    </template>
  </gdAccordeon>
</template>
<script>
  import gdAccordeon from '@/components/accordeons/gd-accordeon'

  export default {

    props: {
      items: Array
    },

    methods: {
      async uploadFile({ recommendation }){
        try {
          const req = await this.$store.dispatch('filestack/UPLOAD_FILE')
          const file = (req.filesUploaded || [])[0]
          const { id: recommendationId } = recommendation

          if(file){
            const { filename: name, handle } = file
            await this.$store.dispatch('file/ADD_FILE', { name, handle, recommendationId })
            await this.$store.dispatch('recommendationsReport/SET_RECOMMENDATION_STATUS', { recommendationId, isCompleted: true })
          }
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },
      async setRecommendationStatus({ recommendation }){
        const { id: recommendationId, isCompleted } = recommendation
        const hasAttachedFiles = recommendation.files && recommendation.files.length

        if(recommendation.requireAttachment && !hasAttachedFiles){
          this.$store.dispatch('toasts/ADD_TOAST', {
            toast: {
              message: 'Wypełnienie zalecenia wymaga dodania pliku. Rozwiń zalecenie i dodaj plik.',
              type: 'warning'
            }
          })
          this.$store.commit('recommendationsReport/UPDATE_RECOMMENDATION', { recommendation: Object.assign({}, recommendation, { isCompleted: false, expanded: true }) })
          return
        }

        try {
          await this.$store.dispatch('recommendationsReport/SET_RECOMMENDATION_STATUS', { recommendationId, isCompleted: !!isCompleted })
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      }
    },

    components: {
      gdAccordeon
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-accordeon-recommendations {
    .files-list {
      display: block;
      margin-bottom: 10px;

      li {
        display: inline-block;
        margin-right: 10px;
      }
    }

    .item-right {
      border-left: 1px solid $color-brick-border;
      justify-content: center;

      .gd-checkbox {
        .gd-checkbox__fake {
          margin: 0;
        }
      }
    }

    .recommendation-description {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;

      &--main {
        color: $color-dark;
      }

      &--detail {
        color: $color-gray-light;
      }
    }
  }
</style>
