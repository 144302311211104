<template>
  <gdHelp />
</template>
<script>
  import gdHelp from '@/pages/app/gd-help'
  import Store from '@/stores'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    async beforeRouteEnter(to, from, next){
      try {
        await Store.dispatch('help/GET_FAQ')
        next()
      } catch(error) {
        console.error(`Couldn't resolve gd-help beforeRouteEnter requests`, { error })
        next({
          name: 'app.error',
          query: {
            type: 'API'
          }
        })
      }
    },

    components: {
      gdHelp
    }
  }
</script>
