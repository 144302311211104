<template>
  <form
    class="gd-form gd-form-password-reset"
    @submit.prevent="handleSubmit"
    novalidate
  >
    <gdInput
      v-model="form.email"
      name="email"
      data-vv-as="adres email"
      v-validate="'required|email'"
    >
      Adres Email
    </gdInput>
    <p
      v-if="formSent"
      class="gd-form-password-reset__confirm"
    >
      Link do resetu hasła został wysłany na podany adres e-mail.
    </p>
    <gdButton
      type="submit"
      :loading="loading"
    >
      Resetuj hasło
    </gdButton>
  </form>
</template>

<script>
  import objectPath from 'object-path'

  export default {
    data () {
      return {
        form: {
          email: null,
        },
        loading: false,
        formSent: false
      }
    },

    methods: {
      async handleSubmit(){
        try {
          const isValid = await this.$validator.validateAll()

          if(!isValid){
            return
          }

          this.loading = true
          await this.$store.dispatch('user/RESET_PASSWORD', { email: this.form.email })
          this.loading = false
          this.clearForm()
          this.formSent = true
        } catch (error){
          console.error('gd-form-password-reset', {error})
          let message = 'Użytkownik z tymi danymi nie istnieje'
          const errorCode = objectPath.get(error, 'response.data.error.code')
          if (errorCode === 'invalid_username') {
            message = 'Nieprawidłowa nazwa użytkownika'
          }
          this.$store.dispatch('toasts/ADD_TOAST', { toast: { message: `${message}`, type: 'error' }})
          this.loading = false
        }
        this.loading = false
      },

      clearForm () {
        Object.keys(this.form).forEach((key) => {
          this.form[key] = null
        })
      }
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-form-password-reset {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;

    div.gd-input {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
    }

    .gd-btn {
      width: 180px;
    }

    &__confirm {
      margin: -5px 0 10px;
      color: darken($color-green, 5);
    }
  }
</style>
