var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "gd-rodo-step__questions" }, [
    !_vm.questionsAnswered
      ? _c(
          "div",
          { staticClass: "questions" },
          _vm._l(_vm.items, function(question, questionIndex) {
            return question.active
              ? _c(
                  "ul",
                  { key: questionIndex, staticClass: "questions_list" },
                  [
                    _c("li", { class: _vm.questionClasses(question) }, [
                      _c("p", { staticClass: "question__title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(question.question) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      question.info
                        ? _c("p", { staticClass: "question__description" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(question.info) +
                                "\n        "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "question__answers" },
                        [
                          question.questionType === "boolean"
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "question__buttons" },
                                  [
                                    _vm._l(question.answers, function(
                                      answer,
                                      answerIndex
                                    ) {
                                      return _c(
                                        "gdButton",
                                        {
                                          key: answerIndex,
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.nextQuestion({
                                                answer: answer
                                              })
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(answer.label) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    !question.isRequired
                                      ? _c(
                                          "gdButton",
                                          {
                                            attrs: { theme: "secondary" },
                                            nativeOn: {
                                              click: function($event) {
                                                _vm.nextQuestion({
                                                  skipped: true
                                                })
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Pomiń\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          question.questionType === "select"
                            ? [
                                _c("gdSelect", {
                                  attrs: {
                                    name: "question" + questionIndex,
                                    placeholder: "Wybierz z listy...",
                                    items: question.answers
                                  },
                                  model: {
                                    value: _vm.answers[questionIndex],
                                    callback: function($$v) {
                                      _vm.$set(_vm.answers, questionIndex, $$v)
                                    },
                                    expression: "answers[questionIndex]"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "question__buttons" },
                                  [
                                    _c(
                                      "gdButton",
                                      {
                                        attrs: {
                                          disabled: !_vm.answers[questionIndex]
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            _vm.nextQuestion({})
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Dalej\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !question.isRequired
                                      ? _c(
                                          "gdButton",
                                          {
                                            attrs: { theme: "secondary" },
                                            nativeOn: {
                                              click: function($event) {
                                                _vm.nextQuestion({
                                                  skipped: true
                                                })
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Pomiń\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          question.questionType === "checkbox"
                            ? [
                                _c("gdCheckboxGroup", {
                                  attrs: { items: question.answers },
                                  model: {
                                    value: _vm.answers[questionIndex],
                                    callback: function($$v) {
                                      _vm.$set(_vm.answers, questionIndex, $$v)
                                    },
                                    expression: "answers[questionIndex]"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "question__buttons" },
                                  [
                                    _c(
                                      "gdButton",
                                      {
                                        attrs: {
                                          disabled: !(
                                            _vm.answers[questionIndex] || []
                                          ).length
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.nextQuestion($event)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Dalej\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !question.isRequired
                                      ? _c(
                                          "gdButton",
                                          {
                                            attrs: { theme: "secondary" },
                                            nativeOn: {
                                              click: function($event) {
                                                _vm.nextQuestion({
                                                  skipped: true
                                                })
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Pomiń\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  ]
                )
              : _vm._e()
          })
        )
      : _c(
          "div",
          [
            _c("p", {
              staticClass: "question-answered-message",
              domProps: { innerHTML: _vm._s(_vm.questionsAnsweredMessage) }
            }),
            _vm._v(" "),
            _c(
              "gdButton",
              {
                attrs: { theme: "secondary", loading: _vm.loading },
                nativeOn: {
                  click: function($event) {
                    _vm.completeStep()
                  }
                }
              },
              [_vm._v("\n      Kontynuuj\n    ")]
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }