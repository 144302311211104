import { formatRodoStep, formatQuestionType, interpretRiskResult } from '@/helpers'
import Vue from 'vue'

const initialStateAPI = () => ({
  introSlides: [],
  groups: [],
  results: [],
  documents: [],
  amountOfAllQuestions: 0,
  lastAnalysis: null
})

const initialStateInternal = () => ({
  initLoading: true,
  raportLoading: false,
  isReadyToSend: false,
  currentAnalysis: {
    resultId: null,
    groups: []
  },
  amountOfAnsweredQuestions: 0,
  currentAnalysisGroupIndexMap: { 0: 0, 1: 1, 3: 2 }
})

const initialState = () => {
  return Object.assign({}, initialStateAPI(), initialStateInternal())
}


const UAR_result_text = {}

function set_UarResultText(question = '', info = '') {
  Object.assign(UAR_result_text, { question, info, questionType: '$UAR-RESULT' })
}

set_UarResultText()


const state = () => initialState()

const actions = {
  async GET_PAGE({ commit, dispatch }) {
    try {
      state.initLoading = true

      const { data } = await dispatch('api/REQUEST', {endpoint: 'uar'}, { root: true })
      const { intro, groups, results } = (data || {})

      commit('SET_INTRO', { intro })
      commit('SET_GROUPS', { groups })
      commit('SET_RESULTS', { results })

      await dispatch('GET_DOCUMENTS') // dispatch after 'SET_RESULTS', because 'DISPLAY_LAST_ANALYSIS_RESULTS' needs 'results' Object

      console.log('simplifiedRiskAnalysis/GET_PAGE', data)
    } catch (error) {
      console.error('simplifiedRiskAnalysis/GET_PAGE', { error })
      throw error
    } finally {
      state.initLoading = false
    }
  },
  async GET_DOCUMENTS({ dispatch, commit }) {
    try {
      state.raportLoading = true

      const { data } = await dispatch('api/REQUEST', {endpoint: 'user/uar/view'}, { root: true })
      const { documents, userUar } = data

      if (documents && documents.length) {
        commit('SET_DOCUMENTS', documents)
      }

      if (userUar && userUar.length) {
        const sortedAnalyses = userUar.sort((a, b) => a.id - b.id)
        commit('SET_LAST_ANALYSIS', sortedAnalyses[sortedAnalyses.length - 1])
        commit('DISPLAY_LAST_ANALYSIS_RESULTS')
      }

      console.log('simplifiedRiskAnalysis/GET_DOCUMENTS', data)
    } catch (error) {
      console.error('simplifiedRiskAnalysis/GET_DOCUMENTS', { error })
      throw error
    } finally {
      state.raportLoading = false
    }
  },
  async SEND_ANALYSIS({ state, dispatch }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'user/uar',
        method: 'POST',
        data: state.currentAnalysis
      }, { root: true })

      console.log('simplifiedRiskAnalysis/SEND_ANALYSIS', data)
    } catch (error) {
      console.error('simplifiedRiskAnalysis/SEND_ANALYSIS', { error })
      throw error
    }
  }
}

const mutations = {
  RESET_STATE(state){
    console.log('simplifiedRiskAnalysis/RESET_STATE')
    Object.assign(state, initialState())
  },
  RESET_STATE_INTERNAL(state){
    console.log('simplifiedRiskAnalysis/RESET_STATE_INTERNAL')
    Object.assign(state, initialStateInternal())
  },
  SET_INTRO(state, { intro }) {
    state.introSlides = intro
  },
  SET_GROUPS(state, { groups }) {

    state.groups = groups.reduce((res, item, i, source) => {
      item.active = i === 0
      item.completed = false
      item.activeQuestion = 0
      state.amountOfAllQuestions += item.questions.length

      res.push(item)

      if (i === 1) {
        res.push({
          active: false,
          name: 'Działalność',
          questions: [
            {
              question: item.riskName,
              info: item.riskDescription,
              questionType: '$CONTINUE'
            }
          ],
          fakedQuestions: true,
          completed: false,
          activeQuestion: 0,
          id: 222
        })

        state.amountOfAllQuestions++
      } else if (i === 2) {
        res.push({
          active: false,
          name: 'Przetwarzanie danych',
          questions: [
            {
              question: item.riskName,
              info: item.riskDescription,
              questionType: '$CONTINUE'
            }
          ],
          fakedQuestions: true,
          completed: false,
          activeQuestion: 0,
          id: 444
        })

        state.amountOfAllQuestions++

        res.push({
          active: false,
          fakedQuestions: true,
          name: 'Wynik Uproszczonej Analizy Ryzyka',
          questions: [ UAR_result_text ],
          activeQuestion: 0,
          id: 555
        })
      }

      return res
    }, [])
  },
  SET_RESULTS(state, { results }) {
    state.results = results
  },
  SET_LAST_ANALYSIS(state, lastAnalysis) {
    state.lastAnalysis = lastAnalysis
  },
  SET_DOCUMENTS(state, documents) {
    state.documents = documents
  },
  SET_READY_TO_SENT(state, bool) {
    state.isReadyToSend = bool
  },
  CHANGE_ACTIVE_QUESTION(state, { forGroup, method }) {
    const group = state.groups.find(item => item.id === forGroup.id)
    group.activeQuestion = method === 'increase' ? group.activeQuestion + 1 : group.activeQuestion - 1
  },
  PREVIOUS_QUESTION(state) {
    state.amountOfAnsweredQuestions--
  },
  COMPLETE_QUESTION(state) {
    state.amountOfAnsweredQuestions++
  },
  PREVIOUS_GROUP(state, { groupIndex }) {
    const currentGroup = state.groups[groupIndex]
    const prevGroup = state.groups[groupIndex - 1]

    currentGroup.active = false
    currentGroup.completed = false

    prevGroup.active = true
    prevGroup.completed = false

    state.amountOfAnsweredQuestions--
  },
  COMPLETE_GROUP(state, { groupIndex, groupAnalysis }) {
    let previousGroupRisk = 1
    const currentGroup = state.groups[groupIndex]
    const nextGroup = state.groups[groupIndex + 1]

    currentGroup.active = false
    currentGroup.completed = true

    if (nextGroup) {
      nextGroup.active = true
    }

    if (Object.keys(groupAnalysis).length) {
      // COLLECT GROUP ANALYSIS

      Vue.set(state.currentAnalysis.groups, state.currentAnalysisGroupIndexMap[groupIndex], groupAnalysis)

      // CALCULATE AND FILL GROUP RISKS
      state.currentAnalysis.groups.forEach((gr, i) => {
        previousGroupRisk = i ? interpretRiskResult({ result: gr.answersRiskValue * previousGroupRisk }) : gr.answersRiskValue
        gr.riskValue = previousGroupRisk
      })
    }

    // IF USER ANSWERED LAST QUESTION LET'S DISPLAY RESULTS
    if (groupIndex === state.groups.length - 2) {
      const riskResultNum = state.currentAnalysis.groups[state.currentAnalysis.groups.length - 1].riskValue
      const resultObj = state.results.find(res => res.riskValue === riskResultNum)

      state.currentAnalysis.resultId = resultObj.id

      this.commit('simplifiedRiskAnalysis/SET_READY_TO_SENT', true)

      set_UarResultText(resultObj.title, resultObj.description)
    }
  },
  DISPLAY_LAST_ANALYSIS_RESULTS(state) {
    // COMPLETES ALL GROUPS TO DISPLAY RESULT
    state.groups.forEach((gr, i) => {
      if (i !== state.groups.length - 1) {
        gr.active = false
        gr.completed = true
      } else {
        gr.active = true
      }
    })

    state.amountOfAnsweredQuestions = state.amountOfAllQuestions

    const riskResultNum = state.lastAnalysis.result.riskValue
    const resultObj = state.results.find(item => item.riskValue === riskResultNum)

    set_UarResultText(resultObj.title, resultObj.description)
  },
  START_NEW_ANALYSIS(state) {
    state.groups.forEach((gr, i) => {
      gr.activeQuestion = 0

      if (i === 0) {
        gr.active = true
        gr.completed = false
      } else {
        gr.active = false
        gr.completed = false
      }
    })
  }
}

const getters = {
  introSlides: state => {
    const res = state.introSlides.sort((a, b) => a.sortOrder - b.sortOrder)

    return res.map(({description, title}) => ({
      title,
      content: description
    }))
  },
  groups: state => state.groups
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map(group => {
      if (group.questions && group.questions.length) {
        group.questions
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map(question => {
            if (question.answers && question.answers.length) {
              question.answers = question.answers
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map(answer => {
                  return { label: answer.answer, value: answer.riskValue, id: answer.id }
                })
            }

            question.questionType = formatQuestionType({ questionType: question.questionType })
            return question
          })
      }

      return group
    }),
  groupsRisksOfCurrentAnalysis: (state, getters) => {
    let arr = []
    const risks = [
      {
        text: 'Ryzyko niskie',
        color: '#01c401'
      },
      {
        text: 'Ryzyko średnie',
        color: '#ffb600'
      },
      {
        text: 'Ryzyko wysokie',
        color: '#ff0000'
      }
    ]

    if (getters.currentAnalysis.groups.length || !getters.allStepsCompleted) {
      arr = getters.currentAnalysis.groups
    } else if (state.lastAnalysis) {
      arr = state.lastAnalysis.groups
    }

    if (!arr.length) {
      return []
    }

    return arr.reduce((res, item, i) => {
      if (i === 0) {
        res.push(risks[item.answersRiskValue - 1]) // Branża
      } else if (i === 1) {
        res.push(risks[item.answersRiskValue - 1]) // Klientela
        res.push(risks[item.riskValue - 1]) // Działalność
      } else if (i === 2) {
        res.push(risks[item.answersRiskValue - 1]) // Skala
        res.push(risks[item.riskValue - 1]) // Przetwarzanie danych
      }

      return res
    }, [])
  },
  results: state => state.results,
  documents: state => state.documents,
  currentAnalysis: state => state.currentAnalysis,
  stepsProgress: state => {
    return state.amountOfAnsweredQuestions / state.amountOfAllQuestions * 100
  },
  allStepsCompleted: (state, getters) => getters.stepsProgress === 100,
  progressDescription: state => {
    const currentGroup = state.groups.findIndex(gr => gr.active)
    const messages = ['Ryzyko branży.', 'Ryzyko klienteli.', 'Ryzyko działalności.', 'Ryzyko Skali.', 'Ryzyko przetwarzania danych.', 'Wynik poziomu ryzyka.']

    return messages[currentGroup]
  },
  isReadyToSend: state => state.isReadyToSend,
  lastAnalysis: state => state.lastAnalysis,
  initLoading: state => state.initLoading,
  raportLoading: state => state.raportLoading,
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
