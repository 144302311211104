<template>
  <div class="gd-form-sign-up gd-brick">
    <div class="gd-brick__inner">
      <header class="gd-form-sign-up__plan-header">
        <div class="plan-header__icon">
          <gdIcon name="user"/>
        </div>
        <h1
          v-if="paymentPlans[planIndex]"
          class="plan-header__title"
        >
          Rejestracja Klient {{ paymentPlans[planIndex].name }}
        </h1>
        <p
          v-if="paymentPlans[planIndex]"
          class="plan-header__price"
        >
          {{ paymentPlans[planIndex].price }} <span>/ rok</span>
        </p>
      </header>
      <form
        class="gd-form"
        @submit.prevent="handleSubmit"
        novalidate
      >
        <gdInput
          v-model="form.firstName"
          name="firstName"
          data-vv-as="imię"
          v-validate="'required'"
        >
          Imię
        </gdInput>
        <gdInput
          v-model="form.lastName"
          name="lastName"
          data-vv-as="nazwisko"
          v-validate="'required'"
        >
          Nazwisko
        </gdInput>
        <gdInput
          v-model="form.email"
          name="email"
          data-vv-as="email"
          v-validate="'required|email'"
        >
          Email
        </gdInput>
        <gdInput
          v-model="form.phone"
          name="phone"
          data-vv-as="telefon"
          v-validate="'required|phoneNumber'"
        >
          Telefon
        </gdInput>
        <gdInput
          v-model="form.password"
          name="password"
          type="password"
          ref="password"
          data-vv-as="hasło"
          v-validate="'required|passEnoughLength'"
        >
          Hasło
        </gdInput>
        <gdInput
          v-model="form.passwordRepeat"
          name="passwordRepeat"
          type="password"
          data-vv-as="powtórz hasło"
          v-validate="'required|matchPasswords:password'"
        >
          Powtórz hasło
        </gdInput>
        <gdInput
          v-model="form.company.name"
          name="companyName"
          data-vv-as="z nazwą firmy"
          v-validate="'required'"
        >
          Pełna nazwa firmy
        </gdInput>
        <gdInput
          v-model="form.company.nip"
          name="companyNip"
          placeholder="Dokładnie 10 cyfr"
          data-vv-as="z NIPem"
          v-validate="{
            required: true,
            regex: /^\d+$/
          }"
        >
          NIP
        </gdInput>
        <div class="gd-input">
          <span class="gd-input__label">Województwo</span>
          <gdSelect
            placeholder="Wybierz z listy..."
            v-model="form.company.province"
            name="companyProvince"
            :items="regions"
            data-vv-as="województwo"
            v-validate="'required'"
          />
        </div>
        <gdInput
          v-model="form.company.city"
          name="companyCity"
          data-vv-as="miejscowość"
          v-validate="'required|onlyLetters'"
        >
          Miejscowość
        </gdInput>

        <gdInput
          v-model="form.company.address"
          name="companyAddress"
          data-vv-as="adres"
          v-validate="'required|atLeastOneNumber|atLeastOneLetter'"
        >
          Adres
        </gdInput>
        <gdInput
          name="companyZipCode"
          :maxLength="true"
          :maxLengthValue="6"
          v-validate="{
            required: true,
            regex: /[0-9]/
          }"
          data-vv-as="z kodem pocztowym"
          v-model="form.company.zipcode"
          @input.native="zipCode(form.company.zipcode)"
        >
          Kod pocztowy
        </gdInput>
        <gdCheckbox
          class="no-selection"
          v-model="form.legalAccept"
          name="legalAccept"
          data-vv-as="z akceptacją regulaminu"
          v-validate="'required'"
        >
          <p>Oświadczem, że zapoznałem się z treścią
            <a
              href="/assets/regulations/regulamin_korzystania_z_gdpsystem.pdf"
              target="_blank"
            >
              Regulaminu
            </a>,
            akceptuję jego treść oraz zobowiązuję się do jego przestrzegania.
          </p>
        </gdCheckbox>
        <gdCheckbox
          name="legalAcceptEmail"
          class="no-selection"
          v-model="form.legalAcceptEmail"
        >
          Wyrażam zgodę na otrzymywanie <span class="bold">emailem</span> od Good Data Protection Standard Sp. z o.o. z siedzibą w Warszawie marketingowych informacji handlowych.
        </gdCheckbox>
        <gdCheckbox
          name="legalAcceptSms"
          class="no-selection"
          v-model="form.legalAcceptSms"
        >
          Wyrażam zgodę na otrzymywanie <span class="bold">SMSem</span> od Good Data Protection Standard Sp. z o.o. z siedzibą w Warszawie marketingowych informacji handlowych.
        </gdCheckbox>
        <p class="gd-checkbox__label label--alone">
          Administratorem Twoich danych osobowych będzie Good Data Protection Standard Sp. z o.o. W
          <a
            href="/przetwarzanie-danych"
            target="_blank"
          >
            polityce prywatności
          </a>
          dowiesz się jak będziemy przetwarzać Twoje dane.
        </p>
        <p class="gd-checkbox__label label--alone">
          Klauzula informacyjna, czyli
          <a
            href="/przetwarzanie-danych"
            target="_blank"
          >
            jak przetwarzamy
          </a>
          Twoje dane.
        </p>
        <div class="gd-form-sign-up__submit">
          <gdLink
            :to="landingUrl"
          >
            <gdIcon
              name="arrow"
            />
          </gdLink>
          <gdButton
            type="submit"
            :loading="loading"
          >
            Zarejestruj i opłać
          </gdButton>
        </div>
      </form>
      <div class="gd-form-sign-up__pay-u">
        <p class="pay-u__info">Nad bezpieczeństwem płatności czuwa</p>
        <gdImg src="/assets/images/pay_u_logo.svg" />
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import gdpsComponents from "gdps-components";
  import objectPath from "object-path";

  import { formatZipCode } from "@/helpers";

  export default {
    data() {
      return {
        form: {
          firstName: null,
          lastName: null,
          phone: null,
          email: null,
          password: null,
          passwordRepeat: null,
          legalAccept: null,
          legalAcceptEmail: null,
          legalAcceptSms: null,
          company: {
            name: null,
            nip: null,
            address: null,
            city: null,
            province: null,
            zipcode: null
          }
        },
        loading: false,
        planIndex: null
      };
    },

    created() {
      if (this.token) {
        window.localStorage.removeItem("gdps-token");
      }
      if (this.userPaymentId) {
        window.localStorage.removeItem("gdps-user-payment-id");
      }
    },

    computed: {
      ...mapGetters("payments", [
        "paymentPlans",
        "chosenPlanId",
        "userPaymentId"
      ]),
      ...mapGetters("staticData", ["regions"]),
      ...mapGetters("user", ["token"]),

      landingUrl() {
        return gdpsComponents.helpers.gdpsUrls({
          environment: process.env.ENVIRONMENT
        }).LANDING;
      }
    },

    methods: {
      async handleSubmit() {
        try {
          const isValid = await this.$validator.validateAll();

          if (!isValid) {
            return;
          }

          this.loading = true;

          const company = Object.assign({}, this.form.company, {
            province: this.form.company.province.value
          });
          let { legalAcceptEmail, legalAcceptSms } = this.form;
          const acceptEmail = !!legalAcceptEmail;
          const acceptSms = !!legalAcceptSms;

          await this.$store.dispatch(
            "user/REGISTER_USER",
            Object.assign(
              {},
              { ...this.form },
              {
                company,
                legalAcceptEmail: acceptEmail,
                legalAcceptSms: acceptSms
              }
            )
          );

          if (this.chosenPlanId) {
            let redirectUrl = await this.$store.dispatch(
              "payments/USER_PAYMENT",
              this.chosenPlanId
            );
            if (redirectUrl) {
              window.location.href = redirectUrl;
            }
          }
        } catch (error) {
          let message = "Wystąpił błąd poczas próby rejestracji.";
          const errorCode = objectPath.get(error, "response.data.error.code");

          switch (errorCode) {
          case "company_exists":
            message = "Firma o podanym numerze NIP już istnieje";
            break;
          case "email_exists":
            message = "User o podanym emailu już istnieje";
            break;
          }

          console.error("gd-form-sign-up", { error });
          this.$store.dispatch("toasts/ADD_TOAST", {
            toast: { message: `${message}`, type: "error" }
          });
          this.loading = false;
        }
      },

      zipCode(value) {
        this.form.company.zipcode = formatZipCode(value);
      },

      activePlanIndex(plans) {
        const id = this.chosenPlanId;

        plans.forEach((plan, i) => {
          if (plan.id === id) {
            this.planIndex = i;
          }
        });
      }
    },

    mounted() {
      this.activePlanIndex(this.paymentPlans);
      window.scrollTo(0, 0);
    }
  };
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";
  @import "./src/assets/styles/mixins/breakpoints.scss";

  .gd-form-sign-up {
    width: 100%;
    max-width: 740px;
    margin: 0 auto !important;
    background-color: $color-white;

    .gd-brick {
      margin: 0 auto;

      &__inner {
        padding: 50px;

        @include media(tablet) {
          padding: 20px;
        }
      }
    }

    &__submit {
      position: relative;
      display: flex;
      align-items: center;
      margin: 50px auto 15px;

      .icon-arrow {
        position: absolute;
        top: 50%;
        left: -40px;
        transform: translateY(-50%);
        color: $color-blue;
        cursor: pointer;
      }
    }

    &__pay-u {
      text-align: center;

      .pay-u__info {
        margin-bottom: 8px;
        font-size: 14px;
        color: $color-gray-light;
      }
    }

    &__plan-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;
      padding: 24px 32px 24px 0;
      border-radius: 2px;
      margin-bottom: 40px;
      font-size: 14px;
      font-weight: 500;
      font-family: $font-medium;
      color: $color-white;
      background-color: $color-blue;

      @include media(tablet) {
        flex-direction: column;
        padding-right: 0;
      }
    }

    .plan-header {
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 100%;
        color: $color-orange;
        background-color: rgba(0, 0, 0, 0.1);

        @include media(tablet) {
          width: 100%;
          height: 40px;
        }
      }

      &__title {
        margin: 0 auto 0 80px;

        @include media(tablet) {
          margin: 40px 0 20px;
        }
      }

      &__price {
        display: flex;
        align-items: center;
        font-size: 40px;

        span {
          display: block;
          margin-left: 20px;
          font-size: 14px;
        }
      }
    }

    .gd-form {
      display: flex;
      flex-flow: row wrap;
      width: 100%;

      .gd-checkbox {
        width: 100%;

        &__error {
          font-size: 16px;
          text-align: left;
        }

        &__fake {
          position: relative;
          top: -1px;
          flex-shrink: 0;
          width: 20px;
          height: 20px;
          border-color: darken($color-mithrill, 35);
          margin-top: 0;
          margin-left: 0;
        }

        &__label {
          display: flex;
          align-items: flex-start;
          margin-bottom: 15px;
          line-height: 20px;
          color: $color-gray-light;

          a {
            color: $color-blue;
          }

          &.label--alone {
            display: block;
            padding-left: 32px;
            text-align: left;
            cursor: default;
          }
        }
      }

      .gd-input {
        max-width: 310px;

        &__label {
          text-align: left;
        }

        &__error {
          text-align: left;
        }

        &.input--wide {
          max-width: 100%;
        }

        &:nth-child(2n + 1) {
          margin-right: 18px;
        }

        @include media(tablet) {
          max-width: 340px;
        }

        @include media(740) {
          max-width: 100%;
        }
      }
    }

    .gd-btn {
      min-width: 260px;

      @include media(mobile) {
        min-width: 200px;
      }
    }
  }
</style>
