<template>
  <div class="gd-brick-certificate-info">
    <gdBrick
      :thin="thin"
      :disabled="false"
    >
      <h2 class="brick__title">{{ title }}</h2>
      <p class="brick__content brick__date">Data wydania: {{ startDate }}</p>
      <p class="brick__content brick__date">Data ważności: {{ endDate }}</p>
      <p class="brick__content">{{ content }}</p>
    </gdBrick>
  </div>
</template>

<script>
  import gdBrick from '@/components/bricks/gd-brick'

  export default {
    props: {
      title: String,
      content: String,
      thin: Boolean,
      startDate: [Number, String],
      endDate: [Number, String]
    },
    components: {
      gdBrick
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-brick-certificate-info {
    .gd-brick__inner {
      justify-content: center;
    }

    .brick {
      &__title {
        font-size: 20px;
        margin-bottom: 25px;
      }

      &__content {
        font-size: 16px;
        line-height: 20px;
        color: $color-gray-light;
        margin-top: 25px;
        // &:not(:last-child) {
        //   margin-top: 25px;
        // }
      }

      &__date {
        margin-top: 0px;
      }
    }
  }
</style>
