import * as moment from 'moment';

const initialState = () => ({
  startDate: null,
  endDate: null
})

const state = () => (initialState())

const actions = {
  async DOWNLOAD_CERTIFICATE({ commit, dispatch }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'user/certificate/download',
        fileDownload: true
      }, { root: true })
      const { certicate, documents } = (data || {})
      console.log('certicate/DOWNLOAD_CERTIFICATE', data)
    } catch(error){
      console.error('certicate/DOWNLOAD_CERTIFICATE', { error })
      throw error
    }
  },
  async GET_DATES({ commit, dispatch }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'user/certificate/view'
      }, { root: true })
      const { startDate, endDate } = (data || {})

      commit('SET_START_DATE', { startDate })
      commit('SET_END_DATE', { endDate })

      console.log('certicate/GET_DATES', data)
    } catch(error){
      console.error('certicate/GET_DATES', { error })
      throw error
    }
  },
}

const mutations = {
  SET_START_DATE(state, { startDate }){
    state.startDate = startDate
  },

  SET_END_DATE(state, { endDate }){
    state.endDate = endDate
  },
}

const getters = {
  startDate: state => state.startDate,
  endDate: state => state.endDate,
  startDateFormat: state => {
    if (!state.startDate) {
      return ''
    }

    return moment.unix(state.startDate).format("DD/MM/YYYY")
  },
  endDateFormat: state => {
    if (!state.endDate) {
      return ''
    }

    return moment.unix(state.endDate).format("DD/MM/YYYY")
  },
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
