<template>
  <section class="gd-activity-add-row">
    <gdTableRow class="gd-table-row--edit">
      <div class="cells-overflow">
        <gdTableCell
          v-for="(cell, cellIndex) in columns"
          :key="cellIndex"
        >
          <template v-if="rowOptions.columnValues && rowOptions.columnValues[cell.id]">
            <gdSelect
              v-if="cell.type === 'list' && rowOptions.columnValues[cell.id].availableValues"
              :name="`row-add-${cellIndex}`"
              :items="rowOptions.columnValues[cell.id].availableValues"
              :multiselect="rowOptions.columnValues[cell.id].isMultiselect"
              v-model="rowOptions.columnValues[cell.id].currentValue"
              tabindex="-1"
              ref="editRowGdSelect"
            />

            <gdInput
              v-if="rowOptions.columnValues[cell.id].type === 'input'"
              :name="`row-add-${cellIndex}`"
              v-model="rowOptions.columnValues[cell.id].currentValue.value"
              tabindex="-1"
            />
          </template>
        </gdTableCell>
      </div>
    </gdTableRow>
    <gdTableRow class="gd-table-row--actions">
      <gdButton
        @click.native="save"
        :loading="loading"
      >
        Zapisz
      </gdButton>
      <gdButton
        theme="warning"
        @click.native="close"
      >
        Usuń
      </gdButton>
    </gdTableRow>
  </section>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { formatSelectedColumnValues } from '@/helpers'

  import gdTableCell from '@/components/tables/elements/gd-table-cell'
  import gdTableRow from '@/components/tables/elements/gd-table-row'

  export default {

    props: {
      registerId: Number,
      columns: Array
    },

    data(){
      return {
        loading: false,
        lastEditTableCell: null
      }
    },

    mounted() {
      this.lastEditTableCell = this.$refs.editRowGdSelect[this.$refs.editRowGdSelect.length -1].$el.parentNode
      this.lastGdSelectClass()
    },

    computed: {
      ...mapGetters('registerSingle', ['rowOptions'])
    },

    methods: {
      async save(){

        try {
          this.loading = true
          const selectedColumnValues = formatSelectedColumnValues({ selectedColumnValues: this.rowOptions.columnValues})
          await this.$store.dispatch('registerSingle/ADD_ROW', {
            registerId: this.registerId,
            selectedColumnValues
          })
          await this.$store.dispatch('registerSingle/GET_SINGLE_REGISTER', {
            registerId: this.registerId
          })
          this.close()
        } catch(error) {
          console.error({error})
          if(error.message === 'ONLY_INPUTS_SHOULD_CONTAIN_AT_LEAST_ONE_VALUE'){
            this.$store.dispatch('toasts/ADD_TOAST', {
              toast: {
                message: 'Aby zapisać wypełnij przynajmniej jedno pole.',
                type: 'warning'
              }
            })
          } else {
            this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
            console.error({ error })
          }
        } finally {
          this.loading = false
        }
      },

      close(){
        this.$store.commit('registerSingle/SET_ADD_ROW_VISIBLE', { value: false })
      },

      lastGdSelectClass() {
        if (this.lastEditTableCell) {
          this.lastEditTableCell.classList.add('gd-select--last')
        }
      }
    },

    components: {
      gdTableCell,
      gdTableRow
    }
  }
</script>
<style lang="scss">
  .gd-table-row--edit {
    .gd-table-cell {
      width: 225px;
    }
  }

</style>
