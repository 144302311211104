var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "gd-nav-app-main" }, [
    _c(
      "ul",
      { staticClass: "gd-nav-app-main__list" },
      _vm._l(_vm.items, function(item, index) {
        return _c(
          "li",
          { key: index, class: { "route--disabled": item.disabled } },
          [
            _c("gdLink", { attrs: { to: item.to } }, [
              _c(
                "span",
                { staticClass: "item-icon" },
                [_c("gdIcon", { attrs: { name: item.iconName } })],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "item-label" }, [
                _vm._v("\n          " + _vm._s(item.label) + "\n        ")
              ])
            ]),
            _vm._v(" "),
            _c("gdTooltip", {
              attrs: {
                placement: "right-center",
                content:
                  "Ta strona będzie dostępna po uzupełnieniu niezbędnych danych"
              }
            })
          ],
          1
        )
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }