<template>
  <section class="gd-activity-edit-row">
    <gdTableRow
      class="gd-table-row--edit"
      v-if="activity"
    >
      <gdTableCell>
        {{ activity.name }}
      </gdTableCell>
      <div class="cells-overflow">
        <gdTableCell
          v-for="(cell, cellIndex) in headers"
          :key="cellIndex"
        >
          <pre hidden>{{ activity }}</pre>
          <template v-if="activity.columns[cell.id]">
            <gdSelect
              v-if="cell.type === 'list' && activity.columns[cell.id].availableValues"
              :name="`activity-edit-${cellIndex}`"
              :items="activity.columns[cell.id].availableValues"
              :multiselect="activity.columns[cell.id].isMultiselect"
              :required="activity.columns[cell.id].isMultiselect"
              v-model="activity.columns[cell.id].currentValue"
              tabindex="-1"
              @error-forbidden-close="lockSave"
              @onCustomClick="(() => handleCustomClick({
                value: activity.columns[cell.id].currentValue,
                items: activity.columns[cell.id].availableValues,
                columnId: cell.id
              }))"
            />

            <gdInput
              :name="`activity-edit-${cellIndex}`"
              v-if="activity.columns[cell.id].type === 'input'"
              v-model="activity.columns[cell.id].currentValue.value"
              tabindex="-1"
            />
          </template>
        </gdTableCell>
        <gdTableCell />
      </div>
    </gdTableRow>
    <gdTableRow class="gd-table-row--actions">
      <gdButton
        @click.native="updateActivity"
        :loading="isSaving"
        :disabled="!allowToSave"
      >
        Zapisz czynność
      </gdButton>
      <gdButton
        theme="ghost"
        @click.native="closeActivityEdit"
      >
        Anuluj zmiany
      </gdButton>
      <gdButton
        theme="warning"
        @click.native="removeActivity"
        :loading="isRemoving"
      >
        Usuń czynność
      </gdButton>
    </gdTableRow>
  </section>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdpsComponents from 'gdps-components'
  import { formatSelectedColumnValues } from '@/helpers'

  import gdTableCell from '@/components/tables/elements/gd-table-cell'
  import gdTableRow from '@/components/tables/elements/gd-table-row'

  export default {

    props: {
      activityId: Number,
      headers: Array
    },

    data(){
      return {
        allowToSave: true,
        isSaving: false,
        isRemoving: false
      }
    },

    computed: {
      ...mapGetters('activityRegister', ['activitiesToEdit']),

      activity(){
        return (this.activitiesToEdit || {})[this.activityId]
      }
    },

    methods: {
      lockSave(){
        this.allowToSave = false
      },

      unlockSave(){
        this.allowToSave = true
      },

      async updateActivity(){
        if(this.activity.isCustom){
          await this.saveCustomActivity()
        } else {
          await this.saveActivity()
        }
      },

      async saveActivity(){
        try {
          this.isSaving = true
          const selectedColumnValues = formatSelectedColumnValues({ selectedColumnValues: this.activity.columns })
          await this.$store.dispatch('activityRegister/UPDATE_ACTIVITY', { id: this.activity.id, selectedColumnValues })
          await this.$store.dispatch('activityRegister/GET_USER_AREAS')
          this.closeActivityEdit()
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        } finally {
          this.isSaving = false
        }
      },

      async saveCustomActivity(){
        try {
          this.isSaving = true
          const columnValues = formatSelectedColumnValues({ selectedColumnValues: this.activity.columns, isCustomActivity: true })
          await this.$store.dispatch('activityRegister/UPDATE_CUSTOM_ACTIVITY', { id: this.activity.id, columnValues, name: this.activity.name })
          await this.$store.dispatch('activityRegister/GET_USER_AREAS')
          this.closeActivityEdit()
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        } finally {
          this.isSaving = false
        }
      },

      async removeActivity(){
        if(this.activity.isCustom){
          await this.removeCustomActivity()
        } else {
          await this.removePredefinedActivity()
        }
      },

      async removePredefinedActivity(){
        try {
          this.isRemoving = true
          await this.$store.dispatch('activityRegister/REMOVE_ACTIVITY', { id: this.activity.id })
          await this.$store.dispatch('activityRegister/GET_USER_AREAS')
          this.closeActivityEdit()
        } catch (error) {
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        } finally {
          this.isRemoving = false
        }
      },

      async removeCustomActivity(){
        try {
          this.isRemoving = true
          await this.$store.dispatch('activityRegister/REMOVE_CUSTOM_ACTIVITY', { id: this.activity.id })
          await this.$store.dispatch('activityRegister/GET_USER_AREAS')
          this.closeActivityEdit()
        } catch (error) {
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        } finally {
          this.isRemoving = false
        }
      },

      closeActivityEdit(){
        setTimeout(() => {
          gdpsComponents.helpers.EventBus.$emit(gdpsComponents.helpers.EventsList.UPDATE_HORIZONTAL_SCROLLBAR_POSITION)
        })
        this.$store.commit('activityRegister/SET_EDIT_ACTIVITY_ROW_VISIBLE', { activityId: this.activity.id, value: false })
      },

      handleCustomClick ({ value, items, columnId }){

        this.unlockSave()

        const { id: activityId, originalActivityId } = this.activity
        const customValues = items.filter(item => item.isCustom)

        this.$store.commit('modals/SHOW_MODAL', {
          modalName: 'rcpd-custom-values',
          modalData: {
            activityId,
            originalActivityId,
            columnId,
            customValues,
            mode: 'EDIT'
          }
        })
      }
    },

    components: {
      gdTableCell,
      gdTableRow
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-activity-edit-row {
    position: relative;
    z-index: 1000;
  }

  .gd-table-body .gd-activity-edit-row:not(:last-of-type) {
    border-bottom: 1px solid $color-gray-semi-light;
  }
</style>
