<template>
  <section class="gd-app">
    <gdHeaderApp />
    <gdSidebarApp />
    <main class="gd-app__main">
      <transition
        name="global-slide"
        mode="out-in"
      >
        <router-view />
      </transition>
<!--      <gdFooterApp/> -->
    </main>
  </section>
</template>

<script>
  import gdHeaderApp from '@/components/headers/gd-header-app'
  import gdFooterApp from '@/components/footers/gd-footer-app'
  import gdSidebarApp from '@/components/sidebars/gd-sidebar-app'

  export default {
    async beforeRouteEnter(to, from, next){
      try {
        await Store.dispatch('user/GET_USER')
        next()
      } catch(error) {
        console.error(`Couldn't resolve gd-app.vue beforeRouteEnter requests`, { error })
        next({
          name: 'app.error',
          query: {
            type: 'API'
          }
        })
      }
    },

    components: {
      gdHeaderApp,
      gdFooterApp,
      gdSidebarApp
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/layout.scss";

  .gd-app {
    position: relative;
    z-index: 100;
    display: flex;
    flex-flow: row wrap;

    .gd-header-app {
      width: 100%;
    }

    &__main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(100% - #{$aside-app-width});
      padding: 38px 50px;
      overflow-x: hidden;
      min-height: calc(100vh - #{$header-height});

      > section:not(.section--wide) {
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
      }

      > section.section--wide {
        width: 100%;
      }
    }
  }
</style>
