var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gdTable",
    {
      ref: "gdTable",
      staticClass: "gd-table-register-single",
      attrs: { title: _vm.title, id: _vm.rootId, cellsOverflow: false }
    },
    [
      _c(
        "gdTableHeader",
        { attrs: { underlined: !_vm.columns || _vm.columns.length === 0 } },
        [
          _c("gdTableRow", [
            _c(
              "div",
              { ref: "headerCells", staticClass: "cells-overflow" },
              _vm._l(_vm.columns, function(cell, cellIndex) {
                return _c(
                  "gdTableCell",
                  { key: cellIndex },
                  [
                    cell.info
                      ? _c("gdTooltip", {
                          attrs: { content: cell.info, iconName: "question" }
                        })
                      : _vm._e(),
                    _vm._v("\n          " + _vm._s(cell.name) + "\n        ")
                  ],
                  1
                )
              })
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "gdTableBody",
        [
          _vm._l(_vm.rows, function(row, rowIndex) {
            return [
              !_vm.editRowsVisible.includes(row.id)
                ? _c("gdTableRow", [
                    _c(
                      "div",
                      { staticClass: "cells-overflow" },
                      _vm._l(_vm.columns, function(cell, cellIndex) {
                        return _c(
                          "gdTableCell",
                          { key: cellIndex },
                          [
                            row.selectedColumnValues &&
                            row.selectedColumnValues[cell.id]
                              ? [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        row.selectedColumnValues[cell.id]
                                          ? _vm.cellLabel(
                                              row.selectedColumnValues[cell.id]
                                            )
                                          : null
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.cellInfo(
                                    row.selectedColumnValues[cell.id]
                                  )
                                    ? _c("gdTooltip", {
                                        attrs: {
                                          iconName: "question",
                                          content: _vm.cellInfo(
                                            row.selectedColumnValues[cell.id]
                                          )
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  cellIndex === 0
                                    ? _c(
                                        "gdButton",
                                        {
                                          attrs: { theme: "ghost" },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.showEditRow({ row: row })
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Edytuj\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      })
                    )
                  ])
                : _c("gdRowEditRow", {
                    attrs: {
                      registerId: _vm.registerId,
                      rowId: row.id,
                      columns: _vm.columns
                    }
                  })
            ]
          }),
          _vm._v(" "),
          _vm.addRowVisible
            ? _c("gdRowAddRow", {
                attrs: { columns: _vm.columns, registerId: _vm.registerId }
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "gdTableFooter",
        { attrs: { standalone: false } },
        [
          _c(
            "gdButton",
            {
              attrs: { theme: "ghost" },
              nativeOn: {
                click: function($event) {
                  return _vm.showAddRow($event)
                }
              }
            },
            [_vm._v("\n      Dodaj\n    ")]
          ),
          _vm._v(" "),
          _vm.scrollBarVisile
            ? _c("gdHorizontalScrollBar", {
                attrs: { scrollableElements: _vm.scrollableElements }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }