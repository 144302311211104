<template>
  <section class="gd-password-reset">
    <gdLink :to="{ name: 'home' }">
      <gdImg src="/assets/images/logo.svg" />
    </gdLink>
    <gdFormPasswordSet
      v-if="token"
      :token="token"
    />
    <gdFormPasswordReset v-else/>
    <gdLink
      :to="{ name: 'login' }"
      class="gd-password-reset__link"
    >
      Powrót do logowania
    </gdLink>
  </section>
</template>

<script>
  import gdFormPasswordReset from '@/components/forms/gd-form-password-reset'
  import gdFormPasswordSet from '@/components/forms/gd-form-password-set'

  export default {
    data () {
      return {
        token: null
      }
    },

    mounted() {
      if (this.$route.query.token) {
        this.token = this.$route.query.token
      }
    },

    components: {
      gdFormPasswordReset,
      gdFormPasswordSet
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-password-reset {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 490px;
    padding: 40px 40px 45px;
    border: 1px solid $color-brick-border;
    border-radius: 3px;
    margin: 160px auto 0;
    background-color: $color-white;
    box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);

    &__link {
      margin-top: 40px;
      text-align: center;

      a {
        color: $color-blue;
      }
    }

    .gd-image {
      margin-bottom: 30px;
    }
  }
</style>
