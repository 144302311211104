<template>
  <section class="gd-courses">
    <gdPageHeader
      title="Szkolenia"
      description="Maecenas sed diam eget risus varius blandit sit amet non magna."
    />
  </section>
</template>
<script>
  import gdPageHeader from '@/components/page/gd-page-header'

  export default {
    components: {
      gdPageHeader
    }
  }
</script>
<style lang="scss">

</style>
