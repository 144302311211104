<template>
  <section class="gd-activity-edit-row">
    <gdTableRow class="gd-table-row--edit">
      <div class="cells-overflow">
        <gdTableCell
          v-for="(cell, cellIndex) in columns"
          :key="cellIndex"
        >
          <template v-if="row.columnValues && row.columnValues[cell.id]">
            <gdSelect
              v-if="cell.type === 'list' && row.columnValues[cell.id].availableValues"
              :name="`row-edit-${cellIndex}`"
              :items="row.columnValues[cell.id].availableValues"
              :multiselect="row.columnValues[cell.id].isMultiselect"
              v-model="row.columnValues[cell.id].currentValue"
              tabindex="-1"
              ref="editRowGdSelect"
            />

            <gdInput
              v-if="row.columnValues[cell.id].type === 'input'"
              :name="`row-edit-${cellIndex}`"
              v-model="row.columnValues[cell.id].currentValue.value"
              tabindex="-1"
            />
          </template>
        </gdTableCell>
      </div>
    </gdTableRow>
    <gdTableRow class="gd-table-row--actions">
      <gdButton
        @click.native="updateRow"
        :loading="isSaving"
      >
        Zapisz
      </gdButton>
      <gdButton
        theme="ghost"
        @click.native="closeRowEdit"
      >
        Anuluj zmiany
      </gdButton>
      <gdButton
        theme="warning"
        @click.native="removeRow"
        :loading="isRemoving"
      >
        Usuń
      </gdButton>
    </gdTableRow>
  </section>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { formatSelectedColumnValues } from '@/helpers'

  import gdTableCell from '@/components/tables/elements/gd-table-cell'
  import gdTableRow from '@/components/tables/elements/gd-table-row'

  export default {
    props: {
      registerId: Number,
      rowId: Number,
      columns: Array
    },

    data(){
      return {
        isSaving: false,
        isRemoving: false,
        lastEditTableCell: null
      }
    },

    mounted() {
      this.lastEditTableCell = this.$refs.editRowGdSelect[this.$refs.editRowGdSelect.length -1].$el.parentNode
      this.lastGdSelectClass()
    },

    computed: {
      ...mapGetters('registerSingle', ['rowsToEdit']),

      row(){
        return (this.rowsToEdit || {})[this.rowId]
      }
    },

    methods: {
      async updateRow(){

        try {
          this.isSaving = true
          const selectedColumnValues = formatSelectedColumnValues({ selectedColumnValues: this.row.columnValues})
          await this.$store.dispatch('registerSingle/UPDATE_ROW', { registerId: this.registerId, rowId: this.rowId, selectedColumnValues })
          await this.$store.dispatch('registerSingle/GET_SINGLE_REGISTER', {
            registerId: this.registerId
          })
          this.closeRowEdit()
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        } finally {
          this.isSaving = false
        }
      },

      async removeRow(){
        try {
          this.isRemoving = true
          await this.$store.dispatch('registerSingle/REMOVE_ROW', { rowId: this.rowId, registerId: this.registerId })
          await this.$store.dispatch('registerSingle/GET_SINGLE_REGISTER', {
            registerId: this.registerId
          })
          this.closeRowEdit()
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        } finally {
          this.isRemoving = false
        }
      },

      closeRowEdit(){
        this.$store.commit('registerSingle/SET_EDIT_ROW_VISIBLE', { rowId: this.rowId, value: false })
        this.lastGdSelectClass()
      },

      lastGdSelectClass() {
        if (this.lastEditTableCell) {
          this.lastEditTableCell.classList.add('gd-select--last')
        }
      }
    },

    components: {
      gdTableCell,
      gdTableRow
    }
  }
</script>
<style lang="scss">
  .gd-activity-edit-row,
  .gd-activity-add-row {
    .gd-select--last {
      .gd-select {
        transition: .15s ease-out;
      }

      .gd-select--opened {
        transform: translateX(-150px);
      }
    }
  }
</style>
