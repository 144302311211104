<template>
  <form
    class="gd-form gd-form-password-set"
    @submit.prevent="handleSubmit"
    novalidate
  >
    <gdInput
      v-model="form.password"
      name="password"
      type="password"
      ref="password"
      data-vv-as="nowe hasło"
      v-validate="'required|passEnoughLength'"
    >
      Nowe hasło
    </gdInput>
    <gdInput
      v-model="form.passwordRepeat"
      name="passwordRepeat"
      type="password"
      data-vv-as="powtórz nowe hasło"
      v-validate="'required|matchPasswords:password'"
    >
      Powtórz nowe hasło
    </gdInput>
    <gdButton
      type="submit"
      :loading="loading"
    >
      Zapisz nowe hasło
    </gdButton>
  </form>
</template>

<script>
  import objectPath from 'object-path'

  export default {
    data () {
      return {
        form: {
          password: null,
          passwordRepeat: null
        },
        loading: false
      }
    },

    props: {
      token: String
    },

    methods: {
      async handleSubmit(){
        try {
          const isValid = await this.$validator.validateAll()

          if(!isValid){
            return
          }

          this.loading = true
          if (this.token) {
            await this.$store.dispatch('user/SET_NEW_PASSWORD', { token: this.token, password: this.form.password, passwordRepeat: this.form.passwordRepeat })
          }
          this.loading = false
          this.clearForm()
          this.$router.push({ name: 'login' })
        } catch (error){
          console.error('gd-form-password-set', {error})
          let message = 'Ups... coś poszło nie tak'
          const errorCode = objectPath.get(error, 'response.data.error.code')
          if (errorCode === 'invalid_token') {
            message = 'Twój link do resetu hasła wygasł.'
            setTimeout(() => {
              this.$router.push({ name: 'password-reset' })
            }, 2500)
          }
          this.$store.dispatch('toasts/ADD_TOAST', { toast: { message: `${message}`, type: 'error' }})
          this.loading = false
        }
        this.loading = false
      },

      clearForm () {
        Object.keys(this.form).forEach((key) => {
          this.form[key] = null
        })
      }
    }
  }
</script>

<style lang="scss">
  .gd-form-password-set {
    width: 100%;

    .gd-input {
      max-width: 100%;
      margin-right: 0 !important;
    }

    .gd-btn--active {
      margin: 0 auto;
    }
  }
</style>
