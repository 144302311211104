var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-courses" },
    [
      _c("gdPageHeader", {
        attrs: {
          title: "Szkolenia",
          description:
            "Maecenas sed diam eget risus varius blandit sit amet non magna."
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }