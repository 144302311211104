var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-activity-edit-row" },
    [
      _c("gdTableRow", { staticClass: "gd-table-row--edit" }, [
        _c(
          "div",
          { staticClass: "cells-overflow" },
          _vm._l(_vm.columns, function(cell, cellIndex) {
            return _c(
              "gdTableCell",
              { key: cellIndex },
              [
                _vm.row.columnValues && _vm.row.columnValues[cell.id]
                  ? [
                      cell.type === "list" &&
                      _vm.row.columnValues[cell.id].availableValues
                        ? _c("gdSelect", {
                            ref: "editRowGdSelect",
                            refInFor: true,
                            attrs: {
                              name: "row-edit-" + cellIndex,
                              items:
                                _vm.row.columnValues[cell.id].availableValues,
                              multiselect:
                                _vm.row.columnValues[cell.id].isMultiselect,
                              tabindex: "-1"
                            },
                            model: {
                              value: _vm.row.columnValues[cell.id].currentValue,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.row.columnValues[cell.id],
                                  "currentValue",
                                  $$v
                                )
                              },
                              expression:
                                "row.columnValues[cell.id].currentValue"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.row.columnValues[cell.id].type === "input"
                        ? _c("gdInput", {
                            attrs: {
                              name: "row-edit-" + cellIndex,
                              tabindex: "-1"
                            },
                            model: {
                              value:
                                _vm.row.columnValues[cell.id].currentValue
                                  .value,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.row.columnValues[cell.id].currentValue,
                                  "value",
                                  $$v
                                )
                              },
                              expression:
                                "row.columnValues[cell.id].currentValue.value"
                            }
                          })
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            )
          })
        )
      ]),
      _vm._v(" "),
      _c(
        "gdTableRow",
        { staticClass: "gd-table-row--actions" },
        [
          _c(
            "gdButton",
            {
              attrs: { loading: _vm.isSaving },
              nativeOn: {
                click: function($event) {
                  return _vm.updateRow($event)
                }
              }
            },
            [_vm._v("\n      Zapisz\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdButton",
            {
              attrs: { theme: "ghost" },
              nativeOn: {
                click: function($event) {
                  return _vm.closeRowEdit($event)
                }
              }
            },
            [_vm._v("\n      Anuluj zmiany\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdButton",
            {
              attrs: { theme: "warning", loading: _vm.isRemoving },
              nativeOn: {
                click: function($event) {
                  return _vm.removeRow($event)
                }
              }
            },
            [_vm._v("\n      Usuń\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }