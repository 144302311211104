<template>
  <section
    class="gd-rodo-steps"
    ref="rodoStepsHolder"
  >
    <span
      class="gd-rodo-steps__progress-bg"
      ref="rodoStepsProgressBg"
    />
    <gdRodoStep1 />
    <gdRodoStep2 />
    <gdRodoStep3 />
    <gdRodoStep4 />
    <gdRodoStep5 />
    <gdRodoStep6 />
  </section>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdRodoStep1 from '@/components/rodo-steps/gd-rodo-step-1'
  import gdRodoStep2 from '@/components/rodo-steps/gd-rodo-step-2'
  import gdRodoStep3 from '@/components/rodo-steps/gd-rodo-step-3'
  import gdRodoStep4 from '@/components/rodo-steps/gd-rodo-step-4'
  import gdRodoStep5 from '@/components/rodo-steps/gd-rodo-step-5'
  import gdRodoStep6 from '@/components/rodo-steps/gd-rodo-step-6'

  export default {
    computed: {
      ...mapGetters('rodoSteps', ['activeStepId', 'succeedSteps'])
    },

    mounted () {
      this.rodoStepsProgressBar()
    },

    beforeDestroy () {
      this.$store.commit('rodoSteps/RESET_STATE')
    },

    methods: {
      rodoStepsProgressBar () {
        const lastStepId = this.$refs.rodoStepsHolder.children.length - 1
        const progressBar = this.$refs.rodoStepsProgressBg
        console.log(progressBar, 'progressBar')

        if (this.activeStepId === lastStepId) {
          progressBar.style.height = `calc(${100}% - ${0}px)`
        }
      }
    },

    watch: {
      activeStepId() {
        this.rodoStepsProgressBar()
      }
    },

    components: {
      gdRodoStep1,
      gdRodoStep2,
      gdRodoStep3,
      gdRodoStep4,
      gdRodoStep5,
      gdRodoStep6
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/mixins/breakpoints.scss";

  .gd-rodo-steps {
    position: relative;
    width: 100%;

    .gd-brick__inner {
      padding: 22px 40px 18px 140px;
      min-height: unset;

      .gd-rodo-step-header + div {
        width: 110%;
        padding-left: 30px;
        margin-left: -5px;
        text-align: left;
      }
    }

    &__progress-bg {
      position: absolute;
      top: 43px;
      left: 51px;
      width: 4px;
      height: calc(100% - 76px);
      background-color: $color-blue-light;
    }

    .gd-image {
      height: unset;
      margin-bottom: auto;
    }
  }

  .gd-rodo-step {
    min-height: unset !important;

    &__content {
      width: 100%;
      height: 0;
      margin-top: 20px;
      font-size: 16px;
      line-height: 20px;
      color: $color-gray-light;
      transition: height .15s;
      position: relative;

      p {
        max-width: 500px;
      }
    }

    &--active {
      .gd-brick__inner {
        padding-bottom: 38px;
      }

      .gd-rodo-step__content {
        display: flex;
        flex-flow: column nowrap;
        height: 260px;
      }

      .gd-rodo-step__content-extra {
        position: absolute;
        top: 0;
        left: -60px;
        bottom: 0;
        height: 100%;
        width: 60px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }

      .gd-rodo-step__questions,
      .questions_list,
      .question {
        height: 100%;
      }

      .gd-rodo-step__questions > div {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .question-answered-message {
        margin-bottom: auto;
      }
    }

    &--completed {
      .gd-rodo-step__content {
        overflow: hidden;
      }
    }

    .question {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;

      &s {
        height: 100%;
      }

      &s_list {
        position: relative;

        .gd-btn--ghost {
          position: absolute;
          left: -55px;
          bottom: 0;
          min-width: unset;
        }
      }

      .question__buttons {
        display: flex;
      }

      .gd-button-wrapper {
        justify-content: flex-start;
      }
    }

    .question__buttons {
      display: flex;
      margin-top: auto;

      .gd-btn {
        margin: 0 5px;

        @include media(1300px) {
          font-size: 10px;
        }
      }
    }
  }
</style>
