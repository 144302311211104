export default function formatQuestionType({ questionType }){

  switch(questionType){
  case 0:
    return 'select'
    break
  case 1:
    return 'boolean'
    break
  case 2:
    return 'boolean'
    break
  case 3:
    return 'checkbox'
    break
  default:
    return questionType
  }
}
