<template>
  <section :class="rootCssClasses">
    <h3
      v-if="title"
      class="gd-table__title"
    >
      {{ title }}
    </h3>
    <div class="gd-table__wrapper">
      <slot />
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      title: String,
      cellsOverflow: Boolean,
      tableStatic: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      rootCssClasses(){
        const base = 'gd-table'
        const classes = [
          base,
          this.cellsOverflow ? 'gd-table--cells-overflow' : null,
          this.tableStatic ? 'gd-table--static' : null
        ]

        return classes.filter(item => !!item).join(' ')
      }
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .gd-table {
    position: relative;
    padding: 5px 0 0;
    border-radius: 3px;
    margin-bottom: 20px;
    box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);

    &--static {
      margin-left: 1px;

      .gd-table-header {
        border: 1px solid $color-gray-semi-light;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        margin-left: -1px;

        .gd-table-cell:first-of-type {
          border-top-left-radius: 3px;
        }

        &__border-right {
          display: none;
        }
      }

      .gd-table-body {
        border: 1px solid $color-gray-semi-light;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        margin-left: -1px;

        &__border-right {
          display: none;
        }

        .gd-table-row:last-of-type {
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;

          .gd-table-cell {
            &:first-of-type {
              border-bottom-left-radius: 3px;
            }

            &:last-of-type {
              border-bottom-right-radius: 3px;
            }
          }
        }
      }
    }

    &--cells-overflow {
      .gd-table-header {
        border-top: 1px solid $color-gray-semi-light;
        border-bottom: 2px solid $color-gray-semi-light;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;

        .gd-table-row {
          background-color: $color-gray-semi-dark;

          > .gd-table-cell {
            position: absolute;
            top: -1px;
            left: -1px;
            z-index: 1000;
            width: 100%;
            max-width: 226px;
            height: calc(100% + 3px);
            border-top: 1px solid $color-gray-semi-light;
            border-bottom: 2px solid $color-gray-semi-light;
            border-left: 1px solid $color-gray-semi-light;
            border-top-left-radius: 3px;
          }
        }
      }

      .gd-table-body {
        .gd-table-row {
          > .gd-table-cell {
            position: absolute;
            top: 0;
            left: -1px;
            z-index: 1000;
            height: calc(100% + 1px);
            width: 100%;
            max-width: 226px;
            border-bottom: 1px solid $color-gray-semi-light;
            border-left: 1px solid $color-gray-semi-light;
            color: $color-blue;
          }
        }
      }
    }

    .cell--wide {
      max-width: 100% !important;
      border-right: 0 !important;
      border-top-right-radius: 3px;
    }

    .cells-overflow {
      display: flex;
      padding-left: 225px;
      z-index: 999;
    }

    .gd-table-cell {
      position: relative;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      width: 225px;
      max-width: 225px;
      min-height: 120px;
      padding: 0 10px 0 20px;
      font-size: 16px;
      line-height: 20px;
      cursor: default;
      flex-grow: 1;
      flex-basis: 0;

      &:not(:last-child) {
        border-right: 1px solid $color-gray-semi-light;
      }

      &:hover {
        .gd-tooltip {
          visibility: visible;
          opacity: 1;
        }
      }

      .gd-button-wrapper {
        position: absolute;
        bottom: 0px;
        left: 20px;
        width: 70%;
        opacity: 0;
        transition: opacity .15s;
      }

      .gd-btn {
        width: 100%;
        justify-content: flex-start;
      }

      .gd-tooltip {
        position: absolute;
        bottom: 5px;
        right: 5px;
        transition: 200ms all ease-out;
        visibility: hidden;
        opacity: 0;
      }

      &:hover .gd-button-wrapper {
        opacity: 1;
      }
    }

    &__title {
      margin-bottom: 15px;
      font-size: 20px;
    }

    &__wrapper {
      border-radius: 3px;
      margin-left: 1px;
      background-color: $color-gray-semi-dark;

      &::before,
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $color-bg;
        z-index: 9999;
        top: 0;
      }

      &::before {
        right: calc(100%);
      }

      &::after {
        left: calc(100%);
      }
    }

    &-row {
      position: relative;
      display: flex;
      flex: 1;
      width: 100%;
      background-color: $color-white;
      flex-flow: row wrap;

      &--edit {
        z-index: 1001;

        .gd-table-cell {
          height: unset;
          min-height: unset;
        }

        > .gd-table-cell {
          position: absolute;
          top: 0;
          left: -1px;
          z-index: 100001;
          height: 100%;
          min-height: unset;
          width: 100%;
          max-width: 226px;
          border-left: 1px solid #D3D7DF;
        }
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $color-gray-semi-light;
      }
    }

    &-header {
      display: flex;
      flex-flow: row nowrap;

      .gd-table-cell {
        height: auto;
        min-height: 60px;
        padding: 10px 10px 10px 20px;
        font-size: 12px;
        font-family: $font-bold;
        letter-spacing: 1.7px;
        line-height: 15px;
        text-transform: uppercase;
        background-color: $color-gray-semi-dark;
      }
    }

    &-body {
      display: flex;
      flex-flow: column nowrap;
      background-color: $color-white;

      .gd-table-cell {
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px 20px 35px;
        border-bottom: $color-gray-semi-light;
        color: $color-gray-light;
        background-color: $color-white;
      }

      .gd-table-row--edit .gd-table-cell {
        padding: 0 20px;
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      bottom: 0;
      left: 0;
      width: calc(100% + 1px);
      // height: 41px;
      padding: 0 40px 0 20px;
      border: 1px solid $color-gray-semi-light;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      margin-left: -1px;
      background-color: $color-gray-semi-dark;

      &--standalone {
        border: 1px solid $color-gray-semi-light;
        border-radius: 3px;
      }
    }

    .gd-table__wrapper {
      border-radius: 3px;
    }

    .gd-select {
      &--opened {
        .gd-select__button,
        .gd-select__list {
          width: 330px;
          max-width: 330px;
        }
      }

      &__button {
        max-width: 184px;
      }
    }
  }
</style>
