var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-maintenance-mode" },
    [
      _c("gdImg", {
        staticClass: "gd-maintenance-mode__logo",
        attrs: { src: "/assets/images/logo.svg" }
      }),
      _vm._v(" "),
      _c("h1", { staticClass: "gd-maintenance-mode__title" }, [
        _vm._v("\n    :(\n  ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "gd-maintenance-mode__description" }, [
        _vm._v("Serwis w trakcie prac konserwacyjnych.")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }