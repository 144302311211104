<template>
  <!-- eslint-disable -->
  <div class="chart-wrapper">
    <svg width="115px" height="115px" viewBox="0 0 42 42" class="chart">
      <circle
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="#ffffff"
        class="chart__hole"
      />
      <circle
        class="chart__ring"
        cx="21"
        cy="21"
        r="15.91549430918954"
        :stroke="colors.background"
      />

      <circle
        class="chart__segment"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="3"
        stroke-dashoffset="0"
        stroke-linecap="round"
        :stroke="colors.circle"
        :stroke-dasharray="chartCircle"
      />
    </svg>
    <p class="chart__progress">{{ parseInt(value) }}%</p>
    <h2 class="chart__title" v-html="title"></h2>
    <p class="chart__content" v-html="content"></p>
  </div>
</template>

<script>
  export default {

    props: {
      title: String,
      content: String,
      value: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        colors: {
          circle: '#4B4DE0',
          background: '#FC9380'
        }
      }
    },

    computed: {
      chartCircle () {
        return `${this.value} 100`
      }
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .chart {
    &-wrapper {
      position: relative;
    }

    &__hole {
      cx: 21;
      cy: 21;
      r: 15.91549430918954;
      fill: $color-white;
    }

    &__ring {
      cx: 21;
      cy: 21;
      r: 15.91549430918954;
      fill: transparent;
      stroke-width: 1.5;
    }

    &__progress {
      position: absolute;
      top: 47px;
      left: 0;
      width: 100%;
      font-size: 24px;
      text-align: center;
    }

    &__title {
      margin: 30px 0 9px;
      font-size: 20px;
    }

    &__content {
      margin-bottom: auto;
      font-size: 16px;
      line-height: 20px;
      color: $color-gray-light;
    }

    &__segment {
      cx: 21;
      cy: 21;
      r: 15.91549430918954;
      fill: transparent;
      stroke-width: 3;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      transition: 1s;
      animation: gd-chart-fill 1s reverse;
      transform: rotate(-90deg);
      transform-origin: center;
    }
  }

  @keyframes gd-chart-fill {
    to { stroke-dasharray: 0 100; }
  }
</style>
