var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.register
    ? _c(
        "section",
        { staticClass: "gd-register-of-single section--wide" },
        [
          _c(
            "gdPageHeader",
            {
              attrs: {
                title: _vm.register.name,
                description: _vm.register.info
              }
            },
            [
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "gdButton",
                    {
                      nativeOn: {
                        click: function($event) {
                          _vm.downloadRegister(_vm.register.id)
                        }
                      }
                    },
                    [_vm._v("\n        Pobierz\n      ")]
                  )
                ],
                1
              )
            ],
            2
          ),
          _vm._v(" "),
          _c("gdTableRegisterSingle", {
            attrs: {
              registerId: _vm.register.id,
              columns: _vm.register.table.columns,
              rows: _vm.register.table.rows
            }
          }),
          _vm._v(" "),
          !_vm.step5.completed ? _c("gdContinueImplementation") : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }