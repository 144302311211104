// HELPERS
const getThumbnailUrl = (media) => (`/assets/images/thumb-${media}.png`)

// SEO
const SEO = {}

SEO.HOME = {
  TITLE: 'Program do RODO: instrukcje, zalecenia, raporty, dokumenty i szkolenia',
  DESCRIPTION: 'Wdrożenie RODO może być proste i kosztować od 109 zł/mies. Rozwiązanie dla każdej Firmy oraz Inspektorów Ochrony Danych Osobowych IODO. Certyfikat RODO.',
  KEYWORDS: 'RODO w praktyce, Zgodność z RODO, RODO wzory dokumentów PDF, Pomoc RODO, ochrona danych osobowych, Program dla IOD, Oprogramowanie RODO, Aplikacja do RODO, Kancelaria rodo, certyfikat RODO, Wysokość kary rodo i odpowiedzialność karna, inspektor ochrony danych osobowych',
  FB: {
    THUMB: () => getThumbnailUrl('facebook')
  },
  TT: {
    THUMB: () => getThumbnailUrl('twitter')
  },
  LI: {
    THUMB: () => getThumbnailUrl('linkedin')
  }
}

module.exports = SEO
