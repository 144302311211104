<template>
  <gdBrick :class="rodoStepClass">
    <gdRodoStepHeader
      title="Rejestr Kategorii Przetwarzań"
      completedTitle="Rejestr Kategorii Przetwarzań"
      iconName="sheet"
      :active="step4.active"
      :completed="step4.completed"
      :resettable="step4.active && !step4.completed"
      :rollbackable="step4.active && !step4.completed"
    />
    <gdGrowTransition>
      <gdGrowTransitionContent v-if="step4.active">
        <div
          class="gd-rodo-step__content"
        >
          <p>{{ step4.prevStepAffect ? step4.affectedDescription : step4.description }}</p>
          <div
            class="question__buttons"
            v-if="step4.prevStepAffect"
          >
            <gdButton @click.native="completeStep">
              Pomiń
            </gdButton>
          </div>
          <div
            class="question__buttons"
            v-else
          >
            <gdButton :link="{ name: 'app.register-single', params: { id: processorRegisterId } }">Uzupełnij Rejestr Kategorii Przetwarzań</gdButton>
            <gdButton @click.native="completeStep">Kontynuuj</gdButton>
          </div>
        </div>
      </gdGrowTransitionContent>
    </gdGrowTransition>
  </gdBrick>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdBrick from '@/components/bricks/gd-brick'
  import gdRodoStepHeader from '@/components/rodo-steps/gd-rodo-step-header'

  export default {

    data() {
      return {
        stepId: 4
      }
    },

    computed: {
      ...mapGetters('rodoSteps', ['step4']),
      ...mapGetters('registers', ['registersCollection']),

      rodoStepClass () {
        return {
          'gd-rodo-step gd-rodo-step--4': true,
          'gd-rodo-step--active': this.step4.active,
          'gd-rodo-step--completed': this.step4.completed,
        }
      },

      processorRegisterId(){
        const processorRegister = this.registersCollection.find(item => item.showOnDashboardStep) || {}
        return processorRegister.id
      }
    },

    methods: {
      completeStep(){
        this.$store.dispatch('rodoSteps/COMPLETE_STEP', { stepId: this.stepId })
      }
    },

    components: {
      gdBrick,
      gdRodoStepHeader
    }
  }
</script>
<style lang="scss">

</style>
