<template>
  <section class="gd-data-processing container">
    <gdLink :to="{ name: 'home' }">
      <gdImg src="/assets/images/logo.svg" />
    </gdLink>
    <h1 class="gd-data-processing__heading">Jak przetwarzamy dane</h1>
    <gdInfoTextList
      v-for="(el, index) in dataProcessing"
      :key="index"
      :element="el"
    />
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import gdInfoTextList from '@/components/lists/gd-info-text-list'

  export default {
    computed: {
      ...mapGetters('dataProcessing', ['dataProcessing'])
    },

    mounted() {
      window.scrollTo(0,0)
    },

    components: {
      gdInfoTextList
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .gd-data-processing {
    padding: 80px 0;

    &__heading {
      margin-bottom: 60px;
      text: {
        align: center;
        transform: uppercase;
      }
      font: {
        size: 28px;
        family: $font-bold;
      }
      line-height: 37px;
      color: $color-blue;
    }

    span.bold {
      font-family: $font-bold;
    }

    .gd-image {
      margin-bottom: 20px;
      text-align: center;
    }
  }
</style>
