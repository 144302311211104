<template>
  <div :class="brickClass">
    <div class="gd-brick__inner">
      <slot/>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      large: {
        type: Boolean,
        default: false
      },
      thin: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      brickClass () {
        return {
          'gd-brick': true,
          'gd-brick--disabled': this.disabled,
          'gd-brick--large': this.large
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-brick {
    width: 100%;
    min-height: 290px;
    border-radius: 3px;
    margin: 10px 0 0;
    box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);

    &--disabled {
      .gd-brick__inner {
        background-color: transparent;
      }
    }

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      min-height: 290px;
      width: 100%;
      padding: 38px 35px 30px;
      border-radius: 3px;
      border: 1px solid $color-brick-border;
      text-align: center;
      background-color: $color-white;
    }

    &--large {
      min-height: 590px;

      .gd-brick__inner {
        padding: 20px;
      }
    }

    &--thin {
      width: calc((100% / 2) - 5px);

      &:nth-child(2n) {
        margin-right: 10px;
      }
    }
  }
</style>
