const initialState = () => ({
  recommendations: [],
  documents: []
})

const state = () => (initialState())

const actions = {
  async GET_RECOMMENDATIONS({ commit, dispatch }) {
    try {
      const { data } = await dispatch('api/REQUEST', { endpoint: 'user/recommendation/view' }, { root: true })
      const { documents, recommendations } = data
      console.log('recommendationsReport/GET_PRESCRIPTIONS', { data })
      commit('SET_RECOMMENDATIONS', { recommendations })
      commit('SET_DOCUMENTS', { documents })
    } catch (error) {
      console.error('recommendationsReport/GET_PRESCRIPTIONS', { error })
      throw error
    }
  },

  async SET_RECOMMENDATION_STATUS({ commit, dispatch }, { recommendationId, isCompleted }) {
    try {
      const { data: recommendation } = await dispatch('api/REQUEST', {
        method: 'PUT',
        endpoint: `user/recommendation/${recommendationId}`,
        data: {
          isCompleted
        }
      }, { root: true })

      commit('UPDATE_RECOMMENDATION', { recommendation })
      console.log('recommendationsReport/SET_RECOMMENDATIONS_STATUS', { recommendation })
    } catch (error) {
      console.error('recommendationsReport/SET_RECOMMENDATIONS_STATUS', { error })
      throw error
    }
  }
}

const mutations = {
  SET_RECOMMENDATIONS(state, { recommendations }) {

    state.recommendations = recommendations
  },

  SET_DOCUMENTS(state, { documents }) {

    state.documents = documents
  },

  UPDATE_RECOMMENDATION(state, { recommendation }){
    console.log('recommendationsReport/UPDATE_RECOMMENDATION_STATUS', { recommendation })
    state.recommendations = [...state.recommendations].map(item => {

      if(item.id === recommendation.id){
        item = recommendation
      }

      return item
    })
  },

  RESET_STATE(state) {
    Object.assign(state, initialState())
  }
}

const getters = {
  documents: state => {
    if (!state.documents || !state.documents.length) {
      return
    }

    return state.documents
  },

  recommendations: state => {
    if (!state.recommendations || !state.recommendations.length) {
      return
    }

    return state.recommendations.sort((a, b) => a.sortOrder - b.sortOrder)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
