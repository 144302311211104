const getParsedMeta = ({ meta, index }) => {

  const DEFAULTS = [
    {
      charset: 'utf-8'
    },
    {
      vmid: 'robots',
      name: 'robots',
      content: index ? 'index' : 'noindex'
    }
  ]

  let result = [
    ...DEFAULTS
  ]

  if (Array.isArray(meta)) {
    result = result.concat(meta)
  }
  if (typeof meta === 'object') {
    const parsed = Object.keys(meta).map(name => ({ name, content: meta[name], vmid: name }))
    result = result.concat(parsed)
  }

  return result
}

export default function metaInfo({ index, meta, title } = {}) {

  // DEFAULTS
  index = (process.env.IS_BUILD && !(process.env.ENVIRONEMENT === 'production')) ? false : index;
  title = title || 'Program do RODO: instrukcje, zalecenia, raporty, dokumenty i szkolenia'

  const config = {
    title: title || title,
    // link: [
    //   { rel: 'icon', type: 'image/png', href: '/assets/images/logo_new.png' }
    // ],
    script: [
      { src: 'https://cdn.polyfill.io/v2/polyfill.min.js' },
      {
        async: true,
        src: 'https://www.googletagmanager.com/gtag/js?id=UA-120735615-1',
        type: 'text/javascript'
      },
      {
        innerHTML: '{ window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag("js", new Date()); gtag("config", "UA-120735615-1"); }',
        type: 'text/javascript'
      }
    ],
    meta: [
      ...getParsedMeta({ meta, index })
    ]
  }

  return config
}
