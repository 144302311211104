<template>
  <section class="section--wide">
    <gdPageHeader
      title="Wybierz plan płatności"
    />
    <div
      class="gd-payment-plans"
    >
      <gdBrick
        class="gd-payment-plans__block block-info gd-brick"
      >
        <ul class="gd-payment-plans__benefits">
          <li
            v-for="(benefit, index) in benefits"
            :key="index"
          >
            {{ benefit.name }}
          </li>
        </ul>
      </gdBrick>
      <gdBrick
        v-for="(plan, index) in plans"
        :key="index"
        class="gd-payment-plans__block block-plan"
        v-match-heights="{
          el: ['.gd-payment-plans__benefits li', '.block-plan__title']
        }"
      >
        <p class="block-plan__title">{{ plan.name }}</p>
        <ul class="gd-payment-plans__benefits">
          <li
            v-for="(item, index) in plan.benefitValues"
            :key="index"
          >{{ item.info }}</li>
        </ul>
        <p class="block-plan__price">{{ plan.price }}</p>
        <gdButton @click.native="manageSignUpForm(plan.id, index)">Wybierz</gdButton>
      </gdBrick>
    </div>
  </section>
</template>

<script>
  import gdPageHeader from '@/components/page/gd-page-header'
  import gdBrick from '@/components/bricks/gd-brick'

  export default {
    props: {
      plans: {
        type: Array,
        default: () => []
      },
      benefits: {
        type: Array,
        default: () => []
      }
    },

    methods: {
      manageSignUpForm (id, index) {
        this.$store.commit('payments/SET_CHOSEN_PLAN_ID', { id })
        this.$store.commit('payments/SET_CHOSEN_PLAN_INDEX', { index })
        this.$store.commit('payments/SET_SIGN_IN_FORM_VISIBLE', { bool: true })
      }
    },

    components: {
      gdPageHeader,
      gdBrick
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .gd-payment-plans {
    display: flex;
    justify-content: space-between;
    max-width: 1110px;
    width: 100%;
    min-height: 550px;
    padding: 40px 0;
    margin: 0 auto;
    line-height: 20px;

    .gd-brick__inner {
      line-height: 30px;
    }

    .block {
      &-info {
        max-width: 450px;

        .gd-payment-plans__benefits {
          width: 100%;
        }

        .gd-brick__inner {
          padding-top: 100px;
          text-align: left;
          color: $color-gray-light;
        }
      }

      &-plan {
        max-width: 200px;

        .gd-brick__inner {
          padding: 38px 10px 30px;
          color: $color-gray-light;
        }

        &__title {
          margin-bottom: 32px;
          font-size: 20px;
          color: $color-dark;
        }

        &__price {
          margin: auto 0 10px;
          font-size: 24px;
          color: $color-dark;
        }
      }
    }
  }
</style>
