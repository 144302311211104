var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-dashboard" },
    [
      _c("gdPageHeader", {
        attrs: {
          title: "Good Data Protection Standard",
          description: "Porządek w ochronie danych osobowych"
        }
      }),
      _vm._v(" "),
      _c(
        "gdBricksWrapper",
        [
          _c(
            "transition",
            {
              attrs: { slot: "wide", name: "global-slide", mode: "out-in" },
              slot: "wide"
            },
            [
              !_vm.userMeta.sawIntroSlider
                ? _c("gdSliderAppIntro", {
                    staticClass: "gd-brick--large",
                    attrs: { items: _vm.introSlides }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: { slot: "wide", name: "global-slide", mode: "out-in" },
              slot: "wide"
            },
            [
              !_vm.allStepsCompleted && _vm.userMeta.sawIntroSlider
                ? _c("gdRodoSteps")
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.allStepsCompleted && _vm.userMeta.sawIntroSlider
            ? _c("gdBrickCertificateDownload", {
                attrs: { slot: "wide" },
                slot: "wide"
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "gdBrick",
            { attrs: { slot: "thin" }, slot: "thin" },
            [
              _c("gdChartProgress", {
                attrs: {
                  title: "Postęp wdrożenia",
                  content: _vm.progressDescription
                },
                model: {
                  value: _vm.stepsProgress,
                  callback: function($$v) {
                    _vm.stepsProgress = $$v
                  },
                  expression: "stepsProgress"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.userMeta.sawIntroSlider
            ? _c(
                "gdBrickDashboard",
                {
                  attrs: {
                    slot: "thin",
                    iconName: "table",
                    title: "Rejestr Czynności Przetwarzania",
                    content: "Rejestr Czynności Przetwarzania Danch Osobowych.",
                    disabledTooltipMessage:
                      "Moduł będzie dostepny po uzupełnieniu niezbednych danych.",
                    disabled: !_vm.step1.completed
                  },
                  slot: "thin"
                },
                [
                  _c(
                    "template",
                    { slot: "buttons" },
                    [
                      _c(
                        "gdButton",
                        {
                          attrs: {
                            link: { name: "app.activity-register" },
                            theme: "secondary"
                          }
                        },
                        [_vm._v("\n          Zobacz\n        ")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.allStepsCompleted
            ? _c(
                "gdBrickDashboard",
                {
                  attrs: {
                    slot: "thin",
                    iconName: "certificate",
                    title: "Certyfikat",
                    content:
                      "Potwierdzenie, że przetwarzasz dane zgodnie z standardem Good Data Protection Standard.",
                    disabledTooltipMessage:
                      "Moduł będzie dostepny po uzupełnieniu niezbednych danych",
                    disabled: !_vm.step5.completed
                  },
                  slot: "thin"
                },
                [
                  _c(
                    "template",
                    { slot: "buttons" },
                    [
                      _c(
                        "gdButton",
                        {
                          attrs: {
                            link: { name: "app.certificate" },
                            theme: "secondary"
                          }
                        },
                        [_vm._v("\n          Pobierz\n        ")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.documents, function(doc, docIndex) {
            return _c(
              "gdBrickDashboard",
              {
                key: docIndex,
                attrs: {
                  slot: _vm.getDocumentBrickSlot({ docIndex: docIndex }),
                  thin: _vm.getDocumentBrickThin({ docIndex: docIndex }),
                  iconName: "table",
                  title: doc.name,
                  content: doc.shortDescription,
                  disabledTooltipMessage:
                    "Moduł będzie dostepny po uzupełnieniu niezbednych danych",
                  disabled: !doc.available
                },
                slot: _vm.getDocumentBrickSlot({ docIndex: docIndex })
              },
              [
                _c(
                  "template",
                  { slot: "buttons" },
                  [
                    _c(
                      "gdButton",
                      {
                        attrs: { theme: "secondary" },
                        nativeOn: {
                          click: function($event) {
                            _vm.downloadDocument({
                              documentId: doc.id,
                              specialType: doc.specialType
                            })
                          }
                        }
                      },
                      [_vm._v("\n          Pobierz\n        ")]
                    )
                  ],
                  1
                )
              ],
              2
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("gdModalInfo", {
        attrs: {
          iconName: "dashboard",
          title: "Rozpoczynasz wdrożenie RODO",
          content:
            "Przed tobą pięć kroków prosimy o staranne odpowiadanie na zadane pytania do niektórych nie będziesz miał możliwości powrotu.",
          buttonLabel: "Rozpocznij"
        },
        on: { beforeClose: _vm.setIntroModalAsSeen, buttonClick: _vm.hideModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }