var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "gd-form gd-form-user-invite",
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "gd-form__section" },
        [
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:3",
                  expression: "'required|min:3'"
                }
              ],
              ref: "name",
              attrs: { name: "user-name", "data-vv-as": "imię", type: "text" },
              model: {
                value: _vm.form.firstName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "firstName", $$v)
                },
                expression: "form.firstName"
              }
            },
            [_vm._v("\n      Imię\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min:3",
                  expression: "'required|min:3'"
                }
              ],
              ref: "surname",
              attrs: {
                name: "user-surname",
                "data-vv-as": "nazwisko",
                type: "text"
              },
              model: {
                value: _vm.form.lastName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "lastName", $$v)
                },
                expression: "form.lastName"
              }
            },
            [_vm._v("\n      Nazwisko\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gd-form__section" },
        [
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'"
                }
              ],
              attrs: {
                name: "user-email",
                type: "email",
                "data-vv-as": "email"
              },
              model: {
                value: _vm.form.email,
                callback: function($$v) {
                  _vm.$set(_vm.form, "email", $$v)
                },
                expression: "form.email"
              }
            },
            [_vm._v("\n      Email\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                name: "user-password",
                type: "password",
                "data-vv-as": "password"
              },
              model: {
                value: _vm.form.password,
                callback: function($$v) {
                  _vm.$set(_vm.form, "password", $$v)
                },
                expression: "form.password"
              }
            },
            [_vm._v("\n      Hasło\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gd-form__submit" },
        [
          _c(
            "gdButton",
            {
              attrs: { loading: _vm.loading },
              nativeOn: {
                click: function($event) {
                  return _vm.handleSubmit($event)
                }
              }
            },
            [_vm._v("\n      Dodaj użytkownika\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }