<template>
  <section class="gd-dashboard">
    <gdPageHeader
      title="Good Data Protection Standard"
      description="Porządek w ochronie danych osobowych"
    />
    <gdBricksWrapper>

      <!-- Intro sider -->
      <transition
        name="global-slide"
        mode="out-in"
        slot="wide"
      >
        <gdSliderAppIntro
          class="gd-brick--large"
          :items="introSlides"
          v-if="!userMeta.sawIntroSlider"
        />
      </transition>

      <!-- RODO Steps -->
      <transition
        name="global-slide"
        mode="out-in"
        slot="wide"
      >
        <gdRodoSteps
          v-if="!allStepsCompleted && userMeta.sawIntroSlider"
        />
      </transition>

      <!-- Certicate download -->
      <gdBrickCertificateDownload
        slot="wide"
        v-if="allStepsCompleted && userMeta.sawIntroSlider"
      />

      <gdBrick slot="thin">
        <gdChartProgress
          title="Postęp wdrożenia"
          :content="progressDescription"
          v-model="stepsProgress"
        />
      </gdBrick>

      <!-- Activity Register -->

      <gdBrickDashboard
        v-if="userMeta.sawIntroSlider"
        slot="thin"
        iconName="table"
        title="Rejestr Czynności Przetwarzania"
        content="Rejestr Czynności Przetwarzania Danch Osobowych."
        disabledTooltipMessage="Moduł będzie dostepny po uzupełnieniu niezbednych danych."
        :disabled="!step1.completed"
      >
        <template slot="buttons">
          <gdButton
            :link="{ name: 'app.activity-register' }"
            :theme="'secondary'"
          >
            Zobacz
          </gdButton>
        </template>
      </gdBrickDashboard>

      <!-- Certificate -->
      <gdBrickDashboard
        slot="thin"
        iconName="certificate"
        title="Certyfikat"
        content="Potwierdzenie, że przetwarzasz dane zgodnie z standardem Good Data Protection Standard."
        disabledTooltipMessage="Moduł będzie dostepny po uzupełnieniu niezbednych danych"
        v-if="!allStepsCompleted"
        :disabled="!step5.completed"
      >
        <template slot="buttons">
          <gdButton
            :link="{ name: 'app.certificate' }"
            :theme="'secondary'"
          >
            Pobierz
          </gdButton>
        </template>
      </gdBrickDashboard>

      <!-- Documents -->
      <gdBrickDashboard
        v-for="(doc, docIndex) in documents"
        :key="docIndex"
        :slot="getDocumentBrickSlot({ docIndex })"
        :thin="getDocumentBrickThin({ docIndex })"
        iconName="table"
        :title="doc.name"
        :content="doc.shortDescription"
        disabledTooltipMessage="Moduł będzie dostepny po uzupełnieniu niezbednych danych"
        :disabled="!doc.available"
      >
        <template slot="buttons">
          <gdButton
            @click.native="downloadDocument({ documentId: doc.id, specialType: doc.specialType })"
            :theme="'secondary'"
          >
            Pobierz
          </gdButton>
        </template>
      </gdBrickDashboard>
    </gdBricksWrapper>

    <!-- Modal -->
    <gdModalInfo
      iconName="dashboard"
      title="Rozpoczynasz wdrożenie RODO"
      content="Przed tobą pięć kroków prosimy o staranne odpowiadanie na zadane pytania do niektórych nie będziesz miał możliwości powrotu."
      buttonLabel="Rozpocznij"
      @beforeClose="setIntroModalAsSeen"
      @buttonClick="hideModal"
    />
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import gdSliderAppIntro from '@/components/sliders/gd-slider-app-intro'
  import gdRodoSteps from '@/components/rodo-steps/gd-rodo-steps'
  import gdPageHeader from '@/components/page/gd-page-header'
  import gdBrick from '@/components/bricks/gd-brick'
  import gdBrickDashboard from '@/components/bricks/gd-brick-dashboard'
  import gdBricksWrapper from '@/components/bricks/gd-bricks-wrapper'
  import gdBrickCertificateDownload from '@/components/bricks/gd-brick-certificate-download'
  import gdChartProgress from '@/components/charts/gd-chart-progress'

  export default {

    mounted() {
      if (this.step6.completed) {
        this.getDates()
      }
    },

    computed: {
      ...mapGetters('rodoSteps', ['allStepsCompleted', 'stepsProgress', 'activeStep', 'step1', 'step5', 'step6']),
      ...mapGetters('dashboard', ['introSlides', 'documents']),
      ...mapGetters('user', ['disabledRoutes', 'userMeta']),

      progressDescription(){
        if(this.allStepsCompleted){
          return 'Gratulujemy! Z sukcesem wdrożyłeś RODO w swojej firmie.'
        }

        if(!this.userMeta.sawIntroSlider){
          return 'Witamy w systemie, pierwszy krok za Tobą. Rozpocznij wdrożenie.'
        }

        return this.activeStep.descriptionExtra
      }
    },

    methods: {
      getDocumentBrickSlot({ docIndex }){
        return (docIndex + 1) % 3 ? 'wide' : 'thin'
      },

      getDocumentBrickThin({ docIndex }){
        const isThin = this.getDocumentBrickSlot({ docIndex })

        return (isThin !== 'thin')
      },

      async downloadDocument({ documentId, specialType }){
        try {
          await this.$store.dispatch('documents/DOWNLOAD_SINGLE_DOCUMENT', { documentId, specialType })
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },

      hideModal(){
        this.$store.commit('modals/HIDE_MODAL')
      },

      async setIntroModalAsSeen(){
        await this.$store.dispatch('user/SET_META', {
          sawIntroModal: true
        })
      },

      getDates(){
        this.$store.dispatch('certificate/GET_DATES')
      }
    },

    components: {
      gdChartProgress,
      gdSliderAppIntro,
      gdRodoSteps,
      gdPageHeader,
      gdBrickCertificateDownload,
      gdBrickDashboard,
      gdBricksWrapper,
      gdBrick
    }
  }
</script>

<style lang="scss">
  .gd-dashboard {
    width: 100%;
  }
</style>
