const initialState = () => ({
  documentsCategories: [],
  filesCategories: []
})

const state = () => (initialState())

const actions = {
  async GET_DOCUMENTS({ commit, dispatch }, fileType){
    // function create for future use with different types of files
    const createEndpoint = (type) => {
      switch (type) {
      default:
        return 'user/document/view'
      }
    }
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: createEndpoint(fileType)
      }, { root: true })
      console.log('documents/GET_DOCUMENTS', data)
      const { documentsCategories, filesCategories } = (data || {})
      commit('SET_DOCUMENTS_CATEGORIES', { documentsCategories })
      commit('SET_FILES_CATEGORIES', { filesCategories })
    } catch(error){
      console.error('documents/GET_DOCUMENTS', { error })
      throw error
    }
  },

  async DOWNLOAD_SINGLE_DOCUMENT({ commit, dispatch }, { documentId, specialType }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: specialType ? `user/document/${documentId}/specialtype/${specialType}/download` : `user/document/${documentId}/download`,
        fileDownload: true
      }, { root: true })

      console.log('documents/DOWNLOAD_SINGLE_DOCUMENT', { data })
    } catch(error){
      console.error('documents/DOWNLOAD_SINGLE_DOCUMENT', { error })
      throw error
    }
  }
}

const mutations = {
  SET_DOCUMENTS_CATEGORIES(state, { documentsCategories }){
    state.documentsCategories = documentsCategories
  },

  SET_FILES_CATEGORIES(state, { filesCategories }){
    state.filesCategories = filesCategories
  }
}

const getters = {
  documentsCategories: state => {
    if(!state.documentsCategories || !state.documentsCategories.length){
      return
    }

    return state.documentsCategories.sort((a, b) => a.sortOrder - b.sortOrder)
  },

  filesCategories: state => {
    if(!state.filesCategories || !state.filesCategories.length){
      return
    }

    return state.filesCategories.sort((a, b) => a.sortOrder - b.sortOrder)
  },

  featuredDocuments: state => {
    if(!state.documentsCategories || !state.documentsCategories.length){
      return
    }

    const allDocuments = state.documentsCategories.reduce((res, category) => {

      if(category.documents && category.documents.length){
        res = res.concat(category.documents)
      }
      return res
    }, [])

    const featuredDocuments = allDocuments.filter(doc => doc.isFeatured)

    return featuredDocuments
  }
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
