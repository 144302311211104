var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.rootCssClasses }, [
    _c("p", { staticClass: "row__title" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row__content" },
      [
        _vm.innerMode === "REMOVABLE"
          ? _c(
              "gdButton",
              {
                attrs: { theme: "ghost" },
                nativeOn: {
                  click: function($event) {
                    return _vm.deleteArea($event)
                  }
                }
              },
              [_vm._v("\n      Usuń obszar\n    ")]
            )
          : _vm.innerMode === "AVAILABLE"
            ? _c(
                "gdButton",
                {
                  attrs: { theme: "ghost" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.addArea($event)
                    }
                  }
                },
                [_vm._v("\n      Dodaj obszar\n    ")]
              )
            : _c("p", [
                _vm._v(
                  "Nie możesz usunąć tego Obszaru, ponieważ przetwarzasz czynności z jego zakresu."
                )
              ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }