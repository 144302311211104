<template>
  <section class="gd-activity-add-row">
    <gdTableRow class="gd-table-row--edit">
      <gdTableCell>
        <template v-if="isCustomActivity">
          <gdInput
            name="custom-activity-name"
            v-model="customActivityName"
            tabindex="-1"
          />
          <gdIcon
            name="close"
            @click.native="clearCurrentActivity"
          />
        </template>
        <gdSelect
          v-else
          placeholder="Wybierz z listy"
          name="'available-activities'"
          :items="rowAvailableActivities"
          v-model="currentActivity"
          tabindex="-1"
        />
      </gdTableCell>
      <div class="cells-overflow">
        <gdTableCell
          v-for="(cell, cellIndex) in headers"
          :key="cellIndex"
        >
          <template v-if="activity && activity.columns[cell.id]">
            <gdSelect
              v-if="cell.type === 'list' && activity.columns[cell.id].availableValues"
              :name="`activity-edit-${cellIndex}`"
              :items="activity.columns[cell.id].availableValues"
              :multiselect="activity.columns[cell.id].isMultiselect"
              v-model="activity.columns[cell.id].currentValue"
              @onCustomClick="(() => handleCustomClick({
                value: activity.columns[cell.id].currentValue,
                items: activity.columns[cell.id].availableValues,
                columnId: cell.id
              }))"
              tabindex="-1"
            />

            <gdInput
              v-if="activity.columns[cell.id].type === 'input'"
              :name="`activity-edit-${cellIndex}`"
              v-model="activity.columns[cell.id].currentValue.value"
              tabindex="-1"
            />
          </template>
        </gdTableCell>
        <gdTableCell />
      </div>
    </gdTableRow>
    <gdTableRow class="gd-table-row--actions">
      <gdButton
        @click.native="saveActivity"
        :loading="loading"
      >
        Zapisz czynność
      </gdButton>
      <gdButton
        theme="warning"
        @click.native="closeActivityAdd"
      >
        Usuń czynność
      </gdButton>
    </gdTableRow>
  </section>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { formatSelectedColumnValues} from '@/helpers'
  import gdpsComponents from 'gdps-components'

  import gdTableCell from '@/components/tables/elements/gd-table-cell'
  import gdTableRow from '@/components/tables/elements/gd-table-row'

  export default {

    props: {
      areaId: Number,
      headers: Array
    },

    data(){
      return {
        loading: false,
        currentActivity: null,
        customActivityName: ''
      }
    },

    computed: {
      ...mapGetters('activityRegister', ['availableActivities', 'activitiesToAdd']),

      rowAvailableActivities(){

        if(!this.availableActivities || typeof this.areaId !== 'number'){
          return
        }

        return ([]).concat([], this.availableActivities[this.areaId], [ { label: '► Własna czynność', value: 'CUSTOM' } ])
      },

      isCustomActivity(){
        return !!((this.currentActivity || {}).value === 'CUSTOM')
      },

      activity(){

        const activity = (this.activitiesToAdd || {})[(this.currentActivity || {}).value]

        if(activity){
          return activity
        } else {

          if(this.isCustomActivity && Array.isArray(this.headers)){
            return this.headers
              .map(item => {
                const { id: columnId } = item
                return {
                  columnId,
                  isCustom: true,
                  type: 'input',
                  currentValue: {
                    value: null
                  }
                }
              })
              .reduce((res, item) => {
                res.columns[item.columnId] = item
                return res
              }, { columns: {} })
          }
        }
      }
    },

    methods: {
      async saveActivity(){
        if(this.isCustomActivity){
          await this.saveCustomActivity()
        } else {
          await this.savePredefinedActivity()
        }
      },

      async savePredefinedActivity(){
        try {
          this.loading = true
          const selectedColumnValues = formatSelectedColumnValues({ selectedColumnValues: this.activity.columns })
          await this.$store.dispatch('activityRegister/ADD_ACTIVITY', {
            activityId: this.currentActivity.value,
            activityAreaId: this.areaId,
            selectedColumnValues
          })
          await this.$store.dispatch('activityRegister/GET_USER_AREAS')
          this.closeActivityAdd()
        } catch(error) {
          console.error({ error })
          if(error.message === 'ONLY_INPUTS_SHOULD_CONTAIN_AT_LEAST_ONE_VALUE'){
            this.$store.dispatch('toasts/ADD_TOAST', {
              toast: {
                message: 'Aby zapisać wypełnij przynajmniej jedno pole.',
                type: 'warning'
              }
            })
          } else {
            this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
            console.error({ error })
          }
        } finally {
          this.loading = false
        }
      },

      async saveCustomActivity(){
        if(!this.customActivityName){
          this.$store.dispatch('toasts/ADD_TOAST', {
            toast: {
              message: 'Wpisz nazwę czynności.',
              type: 'warning'
            }
          })
          return
        }

        try {
          this.loading = true
          const columnValues = formatSelectedColumnValues({ selectedColumnValues: this.activity.columns, isCustomActivity: true })
          await this.$store.dispatch('activityRegister/ADD_CUSTOM_ACTIVITY', {
            activityAreaId: this.areaId,
            columnValues,
            name: this.customActivityName
          })
          await this.$store.dispatch('activityRegister/GET_USER_AREAS')
          this.closeActivityAdd()

        } catch (error) {
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        } finally {
          this.loading = false
        }
      },

      clearCurrentActivity(){
        this.currentActivity = null
      },

      closeActivityAdd(){
        setTimeout(() => {
          gdpsComponents.helpers.EventBus.$emit(gdpsComponents.helpers.EventsList.UPDATE_HORIZONTAL_SCROLLBAR_POSITION)
        })
        this.$store.commit('activityRegister/SET_ADD_ACTIVITY_ROW_VISIBLE', { areaId: this.areaId, value: false })
      },

      handleCustomClick({ value, items, columnId }){
        const activityId = this.currentActivity.value
        const customValues = items.filter(item => item.isCustom)

        this.$store.commit('modals/SHOW_MODAL', {
          modalName: 'rcpd-custom-values',
          modalData: {
            activityId,
            columnId,
            customValues,
            mode: 'ADD'
          }
        })
      }
    },

    watch: {
      async currentActivity(value){

        if(!value || value && value.value === 'CUSTOM'){
          return
        }

        try {
          this.loading = true
          await this.$store.dispatch('activityRegister/GET_ACTIVITY_TO_ADD', { id: value.value })
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        } finally {
          this.loading = false
        }
      }
    },

    components: {
      gdTableCell,
      gdTableRow
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-table-cell {
    .icon.icon-close {
      position: absolute;
      width: 10px;
      height: 10px;
      top: 5px;
      right: 5px;
      color: $color-red;
    }
  }
</style>
