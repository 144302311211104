var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "gd-form gd-form-password-set",
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c(
        "gdInput",
        {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|passEnoughLength",
              expression: "'required|passEnoughLength'"
            }
          ],
          ref: "password",
          attrs: {
            name: "password",
            type: "password",
            "data-vv-as": "nowe hasło"
          },
          model: {
            value: _vm.form.password,
            callback: function($$v) {
              _vm.$set(_vm.form, "password", $$v)
            },
            expression: "form.password"
          }
        },
        [_vm._v("\n    Nowe hasło\n  ")]
      ),
      _vm._v(" "),
      _c(
        "gdInput",
        {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|matchPasswords:password",
              expression: "'required|matchPasswords:password'"
            }
          ],
          attrs: {
            name: "passwordRepeat",
            type: "password",
            "data-vv-as": "powtórz nowe hasło"
          },
          model: {
            value: _vm.form.passwordRepeat,
            callback: function($$v) {
              _vm.$set(_vm.form, "passwordRepeat", $$v)
            },
            expression: "form.passwordRepeat"
          }
        },
        [_vm._v("\n    Powtórz nowe hasło\n  ")]
      ),
      _vm._v(" "),
      _c("gdButton", { attrs: { type: "submit", loading: _vm.loading } }, [
        _vm._v("\n    Zapisz nowe hasło\n  ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }