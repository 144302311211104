<template>
  <section class="gd-documents">
    <gdPageHeader
      title="Twoje dokumenty"
    />
    <section class="gd-documents__base">
      <gdBrickFilesUpload
        v-if="filesCategories"
        v-for="(fileCategory, index) in filesCategories"
        :key="index"
        :title="fileCategory.name"
        :fileList="fileCategory.files"
        :categoryId="fileCategory.id"
        @fileUploaded="reloadDocuments"
        @fileDeleted="reloadDocuments"
      />
    </section>
    <gdBrickFilesList
      v-if="userUARDocs"
      title="Raporty z Uproszczonej Analizy Ryzyka"
      :filesList="userUARDocs"
      @fileDeleted="reloadDocuments"
      fileType="uar"
    />
    <gdPageHeader
      title="Wzory dokumentów"
    />
    <section class="gd-documents__examples">
      <gdBricksWrapper>
        <gdBrickDashboard
          v-if="featuredDocuments"
          v-for="(doc, docIndex) in featuredDocuments"
          :key="docIndex"
          :slot="(docIndex + 1) % 3 ? 'wide' : 'thin'"
          :disabled="false"
          :thin="!!((docIndex + 1) % 3)"
          iconName="docs"
          :title="doc.name"
          :content="doc.shortDescritpion"
        >
          <template slot="buttons">
            <gdButton
              @click.native="downloadDocument({ documentId: doc.id })"
              :theme="'secondary'"
            >
              Pobierz
            </gdButton>
          </template>
        </gdBrickDashboard>


      </gdBricksWrapper>
      <gdBrickFilesList
        v-if="documentsCategories"
        v-for="(list, index) in documentsCategories"
        :key="index"
        :title="list.name"
        :filesList="list.documents"
        fileType="user-docs"
      />
    </section>
  </section>
</template>
<script>
  import { mapGetters } from 'vuex'

  import gdPageHeader from '@/components/page/gd-page-header'
  import gdBricksWrapper from '@/components/bricks/gd-bricks-wrapper'
  import gdBrickDashboard from '@/components/bricks/gd-brick-dashboard'
  import gdBrickFilesUpload from '@/components/bricks/gd-brick-files-upload'
  import gdBrickFilesList from '@/components/bricks/gd-brick-files-list'

  export default {
    computed: {
      ...mapGetters('documents', ['documentsCategories', 'featuredDocuments', 'filesCategories']),
      ...mapGetters('user', ['userUARDocs'])
    },

    methods: {
      async reloadDocuments(type){
        try {
          switch (type) {
          case 'uar':
            await this.$store.dispatch('user/GET_UAR_DOCS')
            break
          default:
            await this.$store.dispatch('documents/GET_DOCUMENTS')
            break
          }
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },

      async downloadDocument({ documentId }){
        try {
          await this.$store.dispatch('documents/DOWNLOAD_SINGLE_DOCUMENT', { documentId })
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      }
    },

    components: {
      gdPageHeader,
      gdBricksWrapper,
      gdBrickDashboard,
      gdBrickFilesList,
      gdBrickFilesUpload
    }
  }
</script>
<style lang="scss">
  .gd-documents {
    &__base + .gd-brick-files-list {
      margin-bottom: 25px;

      .gd-brick__inner {
        padding: 20px;
      }
    }
  }
</style>
