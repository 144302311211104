<template>
  <section class="gd-payment-await">
    <transition
      name="global-slide"
      mode="out-in"
      slot="wide"
    >
      <div
        class="payment-awaits"
        v-if="paymentStatus.waiting && !paymentStatus.done"
      >
        <gdImg
          src="/assets/images/logo.svg"
          class="gd-payment-await__logo"
        />
        <h1 class="gd-payment-await__title">Twoja płatność jest przetwarzana</h1>
        <gdImg src="/assets/images/loader.svg" />
        <p class="gd-payment-await__info">Nie zamykaj tej karty i oczekuj na potwierdzenie płatności</p>
      </div>
    </transition>
    <transition
      name="global-slide"
      mode="out-in"
      slot="wide"
    >
      <div
        class="payment-done"
        v-if="!paymentStatus.waiting && paymentStatus.done"
      >
        <gdImg
          src="/assets/images/logo.svg"
          class="gd-payment-await__logo"
        />
        <h1 class="gd-payment-await__title">Twoja płatność przebiegła poprawnie</h1>
        <div class="payment-done__icon">
          <gdIcon name="check" />
        </div>
        <gdButton :link="{ name: 'app.dashboard' }">Przejdź do Panelu Startowego</gdButton>
      </div>
    </transition>
    <transition
      name="global-slide"
      mode="out-in"
      slot="wide"
    >
      <div
        class="payment-canceled"
        v-if="!paymentStatus.waiting && !paymentStatus.done"
      >
        <gdImg
          src="/assets/images/logo.svg"
          class="gd-payment-await__logo"
        />
        <h1 class="gd-payment-await__title">Twoja płatność <span>nie powiodła się</span></h1>
        <div class="payment-canceled__icon">
          <gdIcon name="close" />
        </div>
        <p class="gd-payment-await__info">Skontaktuj się z administratorem serwisu</p>
        <ul
          v-if="userPlan.key || userPlan.id"
          class="gd-payment-await__details"
        >
          <li>ID planu: {{ userPlan.id }}</li>
          <li>Klucz planu: {{ userPlan.key }}</li>
        </ul>
      </div>
    </transition>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters('payments', ['paymentStatus', 'userPlan'])
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .gd-payment-await {
    display: flex;
    flex-direction: column;
    background-color: $color-white;

    &__title {
      margin-bottom: 40px;
      font-size: 40px;
      font-family: $font-medium;

      span {
        text-decoration: underline;
      }
    }

    &__info {
      margin-top: 40px;
      font-size: 18px;
      color: $color-gray-light;
    }

    &__details {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      li {
        margin-bottom: 10px;
        font-size: 20px;
      }
    }

    .gd-image {
      height: 60px;
      width: 60px;

      &.gd-payment-await__logo {
        width: unset;
        height: unset;
      }
    }

    .payment-awaits,
    .payment-done,
    .payment-canceled {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      padding-top: 20vh;
      min-height: 100vh;
    }

    .payment-done {
      &__icon {
        position: relative;
        height: 90px;
        width: 90px;
        border-radius: 50%;
        margin-bottom: 40px;
        background-color: rgba($color-green, .25);

        .icon-check {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 50px;
          width: 50px;
          color: $color-green;
        }
      }
    }

    .payment-canceled {
      &__icon {
        position: relative;
        height: 90px;
        width: 90px;
        border-radius: 50%;
        margin-bottom: 40px;
        background-color: rgba($color-red, .25);

        .icon-close {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 40px;
          width: 40px;
          color: $color-red;
        }
      }
    }
  }
</style>
