<template>
  <gdRiskTracker />
</template>
<script>
  import gdRiskTracker from '@/pages/app/gd-risk-tracker'
  import Store from '@/stores'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    async beforeRouteEnter(to, from, next){
      try {
        await Store.dispatch('user/GET_COMPANY')
        next()
      } catch(error) {
        console.error(`Couldn't resolve gd-risk-tracker beforeRouteEnter requests`, { error })
        next({
          name: 'app.error',
          query: {
            type: 'API'
          }
        })
      }
      next();
    },

    components: {
      gdRiskTracker
    }
  }
</script>
