<template>
  <gdTable
    class="gd-table-activity-area"
    :id="rootId"
    :title="title"
    :cellsOverflow="true"
  >
    <gdTableHeader
      v-if="activities || isAddActivityRowVisible"
      :bordered="!!(this.isAddActivityRowVisible || this.activities)"
      :underlined="!headers || headers.length === 0"
      :stickyMode="stickyMode"
    >
      <gdTableRow>
        <gdTableCell ref="gdTableCellHeaderHeroCell">
          Czynność
        </gdTableCell>
        <div
          class="cells-overflow"
          ref="gdTableCellHeaderWrapper"
        >
          <gdTableCell
            v-for="(cell, cellIndex) in headers"
            :key="cellIndex"
          >
            <gdTooltip
              v-if="cell.info"
              :content="cell.info"
              iconName="question"
            />
            {{ cell.name }}
          </gdTableCell>
          <gdTableCell>
            Data aktualizacji
          </gdTableCell>
        </div>
      </gdTableRow>
    </gdTableHeader>
    <gdTableBody>
      <!-- eslint-disable -->
      <template
        v-if="activities"
        v-for="(activity, activityIndex) in activities"
      >
        <pre hidden v-if="activity">{{ activity.id }}</pre>
        <!-- Show activity row -->
        <gdTableRow
          v-if="activity && !editActivityRowVisible.includes(activity.id)"
          :key="activityIndex"
          ref="gdTableRow"
        >
          <gdTableCell>
            <span ref="gdTableHeroCell">{{ activity.name }}</span>
            <gdTooltip
              v-if="activity.info"
              :content="activity.info"
              iconName="question"
            />
            <gdButton
              v-if="!editActivityRowVisible.length && !isAddActivityRowVisible"
              theme="ghost"
              @click.native="showEditActivityRow({ activity })"
            >
              Edytuj
            </gdButton>
          </gdTableCell>
          <div class="cells-overflow">
            <gdTableCell
              v-for="(cell, cellIndex) in headers"
              :key="cellIndex"
            >
                <pre hidden>{{ activity }}</pre>
              <template v-if="activity[_activityColumnsKey(activity)] && activity[_activityColumnsKey(activity)][cell.id]">
                <span v-html="activity[_activityColumnsKey(activity)][cell.id] ? cellLabel(activity[_activityColumnsKey(activity)][cell.id]) : null"></span>
                <gdTooltip
                  v-if="cellInfo(activity[_activityColumnsKey(activity)][cell.id])"
                  :content="cellInfo(activity[_activityColumnsKey(activity)][cell.id])"
                  iconName="question"
                />
              </template>
            </gdTableCell>
            <gdTableCell>
              <pre>{{ activity.updatedAt | moment("DD/MM/YYYY hh:mm") }}</pre>
            </gdTableCell>
          </div>
        </gdTableRow>

        <!-- Edit activity row -->
        <gdActivityEditRow
          v-else
          :activityId="activity.id"
          :headers="headers"
        />
      </template>
    </gdTableBody>
    <gdTableFooter
      :class="{ 'gd-table-footer--no-padding' : isAddActivityRowVisible }"
      :standalone="!this.isAddActivityRowVisible && !this.activities"
      :stickyMode="stickyMode"
    >

      <!-- Add activity row -->
      <gdActivityAddRow
        v-if="isAddActivityRowVisible"
        :headers="headers"
        :areaId="areaId"
      />
      <gdButton
        theme="ghost"
        :style="{ visibility: editActivityRowVisible.length ? 'hidden' : '' }"
        @click.native="showAddActivityRow"
        v-if="!isAddActivityRowVisible"
      >
        Dodaj czynność
      </gdButton>
      <gdHorizontalScrollBar
        :scrollableElements="scrollableElements"
        v-if="activities || isAddActivityRowVisible"
      />
    </gdTableFooter>
  </gdTable>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdpsComponents from 'gdps-components'

  import gdTable from '@/components/tables/elements/gd-table'
  import gdActivityEditRow from '@/components/tables/gd-activity-edit-row'
  import gdActivityAddRow from '@/components/tables/gd-activity-add-row'
  import gdTableBody from '@/components/tables/elements/gd-table-body'
  import gdTableCell from '@/components/tables/elements/gd-table-cell'
  import gdTableFooter from '@/components/tables/elements/gd-table-footer'
  import gdTableHeader from '@/components/tables/elements/gd-table-header'
  import gdTableRow from '@/components/tables/elements/gd-table-row'

  export default {
    data() {
      return {
        heroCellsHeightSet: false,
        stickyMode: false
      }
    },

    props: {
      title: String,
      headers: Array,
      activities: Array,
      areaId: Number
    },

    computed: {
      ...mapGetters('activityRegister', ['editActivityRowVisible', 'addActivityRowVisible']),

      rootId(){
        return `gd-table-activity-area-${this._uid}`
      },

      scrollableElements(){
        return [`#${this.rootId} .cells-overflow`]
      },

      isAddActivityRowVisible(){
        return (this.addActivityRowVisible.indexOf(this.areaId) > -1)
      }
    },

    mounted(){
      this.stickyMode = (this.$el.clientHeight > window.innerHeight)

      console.log('gd-table-activity-area', this.stickyMode, this.$el.clientHeight, window.innerHeight)
    },

    methods: {

      _activityColumnsKey(activity){

        if(activity.columnValues){
          return 'columnValues'
        } else {
          return 'selectedColumnValues'
        }
      },

      cellLabel(value){

        const listItems = value.map(item => `<li style="margin-bottom: 10px;">${item.value}</li>`)
        return `<ul style="${listItems.length > 1 ? 'list-style: circle; padding-left: 10px;' : ''}">${listItems.join('')}</ul>`
      },

      cellInfo(value){
        if(value && value.length && value[0].info){
          return value.map(item => `<span class="text--bold">${item.value}</span> - ${item.info}`).join('<br>')
        }

        return false
      },

      async showEditActivityRow({ activity }){
        try {
          const { id } = activity
          await this.$store.dispatch('activityRegister/' + (activity.isCustom ? 'GET_CUSTOM_ACTIVITY_TO_EDIT' : 'GET_ACTIVITY_TO_EDIT'), { id })
          this.$store.commit('activityRegister/SET_EDIT_ACTIVITY_ROW_VISIBLE', { activityId: activity.id, value: true })
          setTimeout(() => {
            gdpsComponents.helpers.EventBus.$emit(gdpsComponents.helpers.EventsList.UPDATE_HORIZONTAL_SCROLLBAR_POSITION)
          })
        } catch (error) {
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },

      async showAddActivityRow(){
        try{
          await this.$store.dispatch('activityRegister/GET_AREA_AVAILABLE_ACTIVITIES', { areaId: this.areaId })
          this.$store.commit('activityRegister/SET_ADD_ACTIVITY_ROW_VISIBLE', { areaId: this.areaId, value: true })
          setTimeout(() => {
            gdpsComponents.helpers.EventBus.$emit(gdpsComponents.helpers.EventsList.UPDATE_HORIZONTAL_SCROLLBAR_POSITION)
          })
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      }
    },

    components: {
      gdTable,
      gdTableBody,
      gdTableCell,
      gdTableFooter,
      gdTableHeader,
      gdTableRow,
      gdActivityEditRow,
      gdActivityAddRow
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-table-activity-area {
    .gd-table-row--edit {
      position: relative;
      z-index: 1000;
      height: 80px;
      background-color: $color-blue-light;

      .gd-table-cell {
        align-items: center;
        height: 100%;
        background-color: $color-blue-light;

        .gd-input {
          margin: 0;
        }

        label {
          display: none;
        }
      }
    }

    .gd-table-row--actions {
      padding: 20px;
      border-left: 1px solid $color-gray-semi-light;
      margin-left: -1px;

      .gd-button-wrapper {
        &:first-child {
          margin-right: auto;
        }

        &:last-child {
          margin-left: 50px;
        }
      }
    }
  }
</style>
