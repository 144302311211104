var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-services" },
    [
      _c("gdPageHeader", {
        attrs: {
          title: "Usługi",
          description:
            "Zapewniamy kompleksowe usługi wspierające Twoją firmę w ochronie danych osobowych."
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gd-services__grid" },
        [
          _vm._l(_vm.services, function(item, index) {
            return _c(
              "gdBrickDashboard",
              {
                key: index,
                attrs: {
                  disabled: false,
                  title: item.title,
                  iconName: item.icon,
                  content: item.about
                }
              },
              [
                _c(
                  "template",
                  { slot: "buttons" },
                  [
                    _c(
                      "gdButton",
                      {
                        attrs: { theme: "secondary" },
                        nativeOn: {
                          click: function($event) {
                            _vm.showModal(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.buttonLabel) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              2
            )
          }),
          _vm._v(" "),
          _c(
            "gdModalForm",
            {
              attrs: {
                iconName: _vm.activeModalData.icon,
                title: _vm.activeModalData.modalTitle,
                content: _vm.activeModalData.modalContent
              }
            },
            [
              _c("gdFormServiceContact", {
                attrs: {
                  slot: "form",
                  serviceTitle: _vm.activeModalData.modalTitle
                },
                slot: "form"
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }