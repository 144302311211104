<template>
  <gdModal
    class="gd-modal-rcpd-custom-values"
    :name="modalName"
    @show="showModal"
    :beforeClose="beforeClose"
    :width="710"
    :updateQuery="false"
  >
    <gdIcon
      class="gd-modal-rcpd-custom-values__icon"
      name="table"
      :large="true"
    />
    <h1 class="gd-modal-rcpd-custom-values__title">
      Własne wartości
    </h1>
    <div class="gd-modal-rcpd-custom-values__content">
      <ul
        class="gd-modal-rcpd-custom-values__values-list"
        v-if="modalData"
      >
        <li
          v-for="(value, index) in customValues"
          :key="index"
          v-if="!removedValues.includes(value.value)"
        >
          {{ value.label }}
          <gdIcon
            name="close"
            @click.native="removeValue({ value })"
          />
        </li>
      </ul>

      <gdFormAddRcpdCustomValue
        v-if="modalData"
        :activityId="modalData.mode === 'EDIT' ? modalData.originalActivityId : modalData.activityId"
        :mode="modalData.mode"
        :columnId="modalData.columnId"
        @submitted="handleFormSubmit"
      />
    </div>
  </gdModal>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdFormAddRcpdCustomValue from '@/components/forms/gd-form-add-rcpd-custom-value'

  export default {
    data () {
      return {
        modalName: 'rcpd-custom-values',
        addedValues: [],
        removedValues: []
      }
    },

    computed: {
      ...mapGetters('modals', ['modalData']),

      customValues(){

        let result = []

        if(this.modalData && this.modalData.customValues){

          result = result.concat(this.modalData.customValues)
        }

        if(this.addedValues && this.addedValues.length){

          result = result.concat(this.addedValues)
        }

        return result
      }
    },

    methods: {
      showModal () {
        this.$modal.show(this.modalName)
      },
      closeModal () {
        this.$modal.hide(this.modalName)
      },
      handleButtonClick(){
        this.$emit('buttonClick')
      },
      async beforeClose(){

        const actionName = this.modalData.mode === 'ADD' ? 'GET_ACTIVITY_TO_ADD' : 'GET_ACTIVITY_TO_EDIT'
        await this.$store.dispatch(`activityRegister/${actionName}`, { id: this.modalData.activityId })

        this.addedValues = []
        this.removedValues = []
        this.$emit('beforeClose')
      },
      handleFormSubmit({ customValue }){
        this.addedValues.push({
          value: customValue.id,
          label: customValue.value
        })
      },
      async removeValue({ value }){

        try {

          const id = value.value

          await this.$store.dispatch('user/DELETE_CUSTOM_COLUMN_VALUE', { id })

          this.$store.dispatch('toasts/ADD_TOAST', {
            toast: {
              message: 'Wartość została usunięta'
            }
          })

          this.removedValues.push(id)
        } catch(error) {

          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
        }
      }
    },

    components: {
      gdFormAddRcpdCustomValue
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";
  @import "./src/assets/styles/mixins/breakpoints.scss";

  .gd-modal-rcpd-custom-values {
    .v--modal {
      width: 100% !important;
      max-width: 725px !important;
      max-height: 90vh;
      padding: 58px 50px 50px !important;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      @include media(tablet) {
        top: 0 !important;
        height: 100vh;
        max-height: 100vh;
        padding: 30px 30px 45px !important;
      }
    }

    &__icon {
      display: block;
      margin: 60px auto;
      color: $color-orange;
    }

    &__title {
      margin-bottom: 20px;
      font-size: 28px;
      font-family: $font-medium;
      text-align: center;
      color: $color-dark;
    }

    &__content {
      max-width: 470px;
      margin: 0 auto 40px;
      font-size: 16px;
      font-family: $font-regular;
      line-height: 20px;
      text-align: center;
      color: $color-gray-light;

      @include media(tablet) {
        margin-bottom: 15px;
      }

      .gd-form-add-rcpd-custom-value {
        margin-top: 50px;

        .gd-input {
          width: 100%;
          max-width: 100%;
        }

        .gd-button-wrapper {
          justify-content: flex-end;
        }
      }
    }

    &__values-list {
      li {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $color-gray-semi-light;
        padding-bottom: 5px;
        margin-bottom: 20px;

        .icon {
          color: $color-red;
          height: 15px;
          width: 15px;
          cursor: pointer;
        }
      }
    }

    .gd-btn.gd-btn--active {
      @include media(tablet) {
        width: 100%;
      }
    }
  }
</style>
