<template>
  <section class="gd-table-body">
    <slot />
    <span class="gd-table-body__border-right"/>
  </section>
</template>
<script>
  export default {
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-table-body {
    position: relative;

    &__border-right {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1000;
      display: block;
      height: 100%;
      border-right: 1px solid $color-gray-semi-light;
    }
  }
</style>
