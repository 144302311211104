<template>
  <form
    class="gd-form gd-form-add-rcpd-custom-value"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <p style="font-weight: 600">Dodaj własną wartość</p>
    <gdInput
      name="value"
      v-validate="'required'"
      data-vv-as="wartość"
      v-model="form.valueName"
    />
    <gdButton
      @click.native="handleSubmit"
      :loading="loading"
    >
      Zapisz
    </gdButton>
  </form>
</template>
<script>
  export default {

    props: {
      activityId: Number,
      columnId: Number
    },

    data(){
      return {
        form: {
          valueName: null
        },
        loading: false
      }
    },

    methods: {
      async handleSubmit() {
        try {
          const isValid = await this.$validator.validateAll()

          if(!isValid){
            return
          }

          this.loading = true

          const response = await this.$store.dispatch('user/ADD_CUSTOM_COLUMN_VALUE', {
            activityId: this.activityId,
            columnId: this.columnId,
            value: this.form.valueName
          })

          this.$emit('submitted', { customValue: response })

          this.$store.dispatch('toasts/ADD_TOAST', {
            toast: {
              message: 'Własna wartość została dodana'
            }
          })

          this.form.valueName = null
        } catch (error){
          console.error('gd-form-add-rcpd-custom-value', { error })
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
<style lang="scss">
</style>
