<template>
  <gdRegisterSingle />
</template>
<script>
  import gdRegisterSingle from '@/pages/app/gd-register-single'
  import Store from '@/stores'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    async beforeRouteEnter(to, from, next){
      try {
        await Store.dispatch('registerSingle/GET_SINGLE_REGISTER', { registerId: to.params.id })
        next()
      } catch(error) {
        console.error(`Couldn't resolve gd-register-single beforeRouteEnter requests`, { error })
        next({
          name: 'app.error',
          query: {
            type: 'API'
          }
        })
      }
    },

    async beforeRouteUpdate(to, from, next){
      try {
        await Store.dispatch('registerSingle/GET_SINGLE_REGISTER', { registerId: to.params.id })
        next()
      } catch(error) {
        console.error(`Couldn't resolve gd-register-single beforeRouteEnter requests`, { error })
        next({
          name: 'app.error',
          query: {
            type: 'API'
          }
        })
      }
    },

    components: {
      gdRegisterSingle
    }
  }
</script>
