var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gd-brick-logout" },
    [
      _c(
        "gdBrick",
        { attrs: { thin: _vm.thin, disabled: false } },
        [
          _c(
            "gdButton",
            {
              nativeOn: {
                click: function($event) {
                  return _vm.logOut($event)
                }
              }
            },
            [_vm._v("\n      Wyloguj\n    ")]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "gd-brick-logout__delete-account",
              on: { click: _vm.handleWarningModal }
            },
            [_vm._v("\n      Usuń konto\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("gdModalWarning", {
        attrs: {
          title: "Uwaga!",
          content:
            'Kliknięcie "usuń konto" jako administrator usuwa <b>wszystkie sub konta firmy, firmę</b> i konto admina.',
          buttonLabel: "Usuń konto"
        },
        on: { buttonClick: _vm.deleteAccount }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }