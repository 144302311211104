<template>
  <form
    class="gd-form gd-form-user-company-switch"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <section class="gd-form__section">
      <div class="gd-input">
        <span class="gd-input__label">Aktywna firma</span>
        <gdSelect
          placeholder="Wybierz z listy..."
          name="currentCompanyId"
          v-model="form.currentCompany"
          :items="userCompanies"
        />
      </div>
    </section>
    <div class="gd-form__submit">
      <gdButton
        @click.native="handleSubmit"
        :loading="loading"
      >
        Przełącz
      </gdButton>
    </div>
  </form>
</template>
<script>
  import { mapGetters } from "vuex";
  import objectPath from "object-path";

  export default {
    data() {
      return {
        form: {
          currentCompany: null
        },
        loading: false
      };
    },

    created() {
      if (this.user) {
        this.form.currentCompany = {value: this.user.currentCompanyId};
      }
    },

    computed: {
      ...mapGetters("user", ["user"]),
      ...mapGetters("company", ["userCompanies"])
    },

    methods: {
      async handleSubmit() {
        try {
          if ( ! this.form.currentCompany.value || this.user.currentCompanyId === this.form.currentCompany.value ) {
            return;
          }

          this.loading = true;
          await this.$store.dispatch(
            "company/SET_CURRENT_COMPANY",
            this.form.currentCompany.value
          );
          this.loading = false;
          this.$router.go();
        } catch (error) {
          console.error("gd-form-user-company-switch", { error });
          let message = "Błąd przełączania firmy użytkownika";
          const errorCode = objectPath.get(error, "response.data.error.code");
          if (errorCode === "user_company_not_found") {
            message = "Podany adres email już istnieje";
          }
          else if (errorCode === "company_locked") {
            message = "W tym momencie zalogowany jest inny użytkownik";
          }
          this.$store.dispatch("toasts/ADD_TOAST", {
            toast: { message: `${message}`, type: "error" }
          });
          this.loading = false;
        }
      }
    }
  };
</script>

<style lang="scss">
  .gd-form-user-account {
    .gd-form__submit {
      width: 180px;
    }
  }
</style>
