var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-report-of-compatibility" },
    [
      _c("gdPageHeader", {
        attrs: {
          title: "Raport Zgodności",
          description:
            "To dokument wykazujący wymagania RODO oraz przedstawiający sposób w jaki zostały one zrealizowane w Twojej firmie, przy wykorzystaniu systemu oraz poprzez realizację zaleceń."
        }
      }),
      _vm._v(" "),
      _vm._l(_vm.documents, function(doc, docIndex) {
        return _c(
          "gdBrickHorizontal",
          {
            key: docIndex,
            attrs: {
              wide: true,
              title: doc.name,
              about: doc.shortDescription,
              disabled: !doc.available
            }
          },
          [
            _c("gdImg", {
              attrs: {
                slot: "image",
                srcset:
                  "/assets/images/compliance-report.jpg 1x, /assets/images/compliance-report@2x.jpg 2x"
              },
              slot: "image"
            }),
            _vm._v(" "),
            _c("gdIcon", {
              attrs: { slot: "icon", name: "docs", large: true },
              slot: "icon"
            }),
            _vm._v(" "),
            _c(
              "gdButton",
              {
                attrs: { slot: "buttons", disabled: !doc.available },
                nativeOn: {
                  click: function($event) {
                    _vm.downloadDocument({ document: doc })
                  }
                },
                slot: "buttons"
              },
              [_vm._v("\n      Pobierz\n    ")]
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "gdTable",
        { attrs: { title: "Matryca zgodności", tableStatic: true } },
        [
          _c(
            "gdTableHeader",
            [
              _c("gdTableCell", [_vm._v("\n        Wymagania rodo\n      ")]),
              _vm._v(" "),
              _c("gdTableCell", [_vm._v("\n        Przepis rodo\n      ")]),
              _vm._v(" "),
              _c("gdTableCell", { staticClass: "cell--wide" }, [
                _vm._v("\n        Sposób zapewnienia zgodności z rodo\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "gdTableBody",
            _vm._l(_vm.compatibilities, function(item, bodyIndex) {
              return _c(
                "gdTableRow",
                { key: bodyIndex },
                [
                  _c("gdTableCell", [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(item.requirement) }
                    })
                  ]),
                  _vm._v(" "),
                  _c("gdTableCell", [
                    _c("span", { domProps: { innerHTML: _vm._s(item.recipe) } })
                  ]),
                  _vm._v(" "),
                  _c("gdTableCell", { staticClass: "cell--wide" }, [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(item.sollution) }
                    })
                  ])
                ],
                1
              )
            })
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.user.metadata.steps[5].completed
        ? _c("gdContinueImplementation")
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }