var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gd-accordeon" },
    _vm._l(_vm.items, function(item, index) {
      return _c("div", { key: index, class: _vm.accordeonItemClass(index) }, [
        _c(
          "div",
          {
            staticClass: "item-left",
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.toggleExpand({ index: index })
              }
            }
          },
          [_c("gdIcon", { attrs: { name: "arrow-small" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "item-center" }, [
          _c(
            "div",
            {
              staticClass: "item-center__visible-content",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.toggleExpand({ index: index })
                }
              }
            },
            [
              _vm.visibleField
                ? [
                    _vm._v(
                      "\n          " +
                        _vm._s(item[_vm.visibleField]) +
                        "\n        "
                    )
                  ]
                : _vm._t("visible-content", null, { item: item })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-center__expandable-content" },
            [
              _vm.expandableField
                ? [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(item[_vm.expandableField]) }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "content-comment",
                      domProps: { innerHTML: _vm._s(item[_vm.commentField]) }
                    })
                  ]
                : _vm._t("expandable-content", null, { item: item })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-right" },
          [_vm._t("right", null, { item: item })],
          2
        )
      ])
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }