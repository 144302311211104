var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gd-brick-account-plan" },
    [
      _c("gdBrick", { attrs: { thin: _vm.thin, disabled: false } }, [
        _c("section", { staticClass: "gd-brick-account-plan__section" }, [
          _c("p", { staticClass: "brick__title" }, [_vm._v("Twój plan to")]),
          _vm._v(" "),
          _c("h3", { staticClass: "brick__plan-name" }, [
            _vm._v('"' + _vm._s(_vm.planName) + '"')
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "brick__content" }, [
            _vm._v("Płatność jednorazowa")
          ]),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "brick__content brick__content--space-bottom" },
            [_vm._v(_vm._s(_vm.oneTimePrice) + " netto rocznie")]
          ),
          _vm._v(" "),
          _vm.startDate
            ? _c("p", { staticClass: "brick__content brick__date" }, [
                _vm._v(
                  "\n        Rozpoczęcie usługi: " +
                    _vm._s(_vm.startDate) +
                    "\n      "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.endDate
            ? _c(
                "p",
                {
                  staticClass:
                    "brick__content brick__content--space-bottom brick__date"
                },
                [
                  _vm._v(
                    "\n        Koniec usługi: " +
                      _vm._s(_vm.endDate) +
                      "\n      "
                  )
                ]
              )
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }