<template>
  <section class="gd-activity-register section--wide">
    <gdPageHeader
      title="Rejestr Czynności Przetwarzania Danych (RCPD)"
      description="To mapa czynności w Twojej firmie, w których wykorzystujesz dane osobowe. Dzięki niej wiesz co robisz z danymi w firmie, np. wykonywanie przelewów, prowadzenie listy płac, rekrutacja."
    >
      <template
        slot="actions"
      >
        <gdButton
          @click.native="downloadRegister"
        >
          Pobierz
        </gdButton>
        <!-- <gdButton
          @click.native="printRegister"
        >
          Drukuj
        </gdButton> -->
        <gdButton
          :link="{ name: 'app.activity-register.edit-areas' }"
        >
          Edytuj obszary
        </gdButton>
      </template>
    </gdPageHeader>
    <!-- <router-view /> -->

    <gdTableActivityArea
      v-for="area in userAreas"
      :key="area.id"
      :areaId="area.id"
      :title="area.name"
      :headers="area.headers"
      :activities="area.activities"
    />
    <gdContinueImplementation
      v-if="!user.company.metadata.steps[5].completed"
    />
    <!-- Modal -->
    <gdModalInfo
      iconName="table"
      title="Rejestr Czynności Przetwarzania wegenerowany!"
      content="Rejestr, który wygenerowaliśmy jest dokładnie dopasowany do profilu Twojej działalności, nadal masz możliwość edycji, usuwania bądź dodawania czynności"
      buttonLabel="Zobacz rejestr"
      @buttonClick="hideModal"
    />
    <!-- Modal  -->
    <gdModalRcpdCustomValues
      title="TITLE"
      content="CONTENT"
      iconName="table"
    />
  </section>
</template>
<script>
  import { mapGetters, mapState } from 'vuex'
  import gdPageHeader from '@/components/page/gd-page-header'
  import gdTableActivityArea from '@/components/tables/gd-table-activity-area'
  import gdContinueImplementation from '@/components/footers/gd-continue-implementation'
  import gdModalRcpdCustomValues from '@/components/modals/gd-modal-rcpd-custom-values'

  export default {
    mounted() {
      if (!this.user.company.rcpdGenerated) {
        this.$store.commit("modals/SHOW_MODAL", { modalName: "info" });
        this.$store.commit("user/SET_USER", {
          user: {
            ...this.user,
            company: Object.assign({}, this.user.company, { rcpdGenerated: true })
          }
        });
      }
    },

    computed: {
      ...mapGetters("activityRegister", ["userAreas"]),
      ...mapState("user", ["user"])
    },

    methods: {
      hideModal() {
        this.$store.commit("modals/HIDE_MODAL");
      },

      downloadRegister() {
        this.$store.dispatch("documents/DOWNLOAD_SINGLE_DOCUMENT", {
          documentId: 0,
          specialType: 2
        });
      }
    },

    components: {
      gdPageHeader,
      gdTableActivityArea,
      gdContinueImplementation,
      gdModalRcpdCustomValues
    }
  };
</script>

<style lang="scss">
  .btn-activity-register-edit {
    justify-content: flex-end;
  }
</style>
