var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gdBrick",
    { class: _vm.rodoStepClass },
    [
      _c("gdRodoStepHeader", {
        attrs: {
          title: "Pobierz Certyfikat",
          completedTitle: "Certyfikat pobrany",
          iconName: "certificate",
          active: _vm.step6.active,
          completed: _vm.step6.completed,
          resettable: false,
          rollbackable: false
        }
      }),
      _vm._v(" "),
      _c(
        "gdGrowTransition",
        [
          _vm.step6.active
            ? _c("gdGrowTransitionContent", [
                _c(
                  "div",
                  { staticClass: "gd-rodo-step__content" },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.step6.description))]),
                    _vm._v(" "),
                    _c("gdImg", {
                      attrs: {
                        srcset:
                          "/assets/images/certificate-top.png 1x, /assets/images/certificate-top@2x.png 2x"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "gdButton",
                      {
                        attrs: { link: { name: "app.certificate" } },
                        nativeOn: {
                          click: function($event) {
                            return _vm.completeStep($event)
                          }
                        }
                      },
                      [_vm._v("\n          Pobierz certyfikat\n        ")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }