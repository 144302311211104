<template>
  <gdBrick :class="uarStepClass">
    <gdRodoStepHeader
      :title="groupTitle"
      :completedTitle="group.name"
      :active="group.active"
      :completed="group.completed"
      :sideTitle="sideTitle"
    />
    <gdGrowTransition>
      <gdGrowTransitionContent v-if="group.active">
        <template
          slot-scope="props"
          v-if="group.active"
        >
          <section
            class="gd-uar-step__content"
          >
            <gdUarStepQuestions
              v-if="group.questions && group.active"
              :items="group.questions"
              :group="group"
              :groupIndex="groupIndex"
            />
          </section>
        </template>
      </gdGrowTransitionContent>
    </gdGrowTransition>
  </gdBrick>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdBrick from '@/components/bricks/gd-brick'
  import gdRodoStepHeader from '@/components/rodo-steps/gd-rodo-step-header'
  import gdUarStepQuestions from '@/components/uar-steps/gd-uar-step-questions'

  export default {
    components: {
      gdBrick,
      gdRodoStepHeader,
      gdUarStepQuestions
    },

    props: {
      group: Object,
      groupIndex: Number
    },

    computed: {
      ...mapGetters('simplifiedRiskAnalysis', [
        'groupsRisksOfCurrentAnalysis'
      ]),
      uarStepClass () {
        return {
          'gd-uar-step': true,
          'gd-uar-step--active': this.group.active,
          'gd-uar-step--completed': this.group.completed,
        }
      },
      groupTitle () {
        return this.group.active && !this.group.fakedQuestions ? `${this.group.name} - pytanie ${this.group.activeQuestion + 1} z ${this.group.questions.length}` : this.group.name
      },
      sideTitle () {
        return this.groupsRisksOfCurrentAnalysis[this.groupIndex]
      }
    }
  }
</script>
