<template>
  <div class="gd-brick-files-list">
    <gdBrick>
      <h5 class="gd-brick-files-list__title">{{ title }}</h5>
      <ul class="gd-brick-files-list__list">
        <li
          v-for="(file, index) in filesList"
          :key="index"
        >
          <gdIcon
            v-if="isUAR()"
            name="close"
            @click.native="deleteFile({ file })"
          />
          <span
            :class="labelClasses()"
            @click="downloadDocument({ documentId: file.id, specialType: file.specialType ? file.specialType : null })"
          >
            {{ file.name }}
          </span>
        </li>
      </ul>
    </gdBrick>
  </div>
</template>

<script>
  import gdBrick from '@/components/bricks/gd-brick'

  export default {
    props: {
      title: String,
      filesList: {
        type: Array,
        default: () => []
      },
      fileType: String,
    },

    methods: {
      async downloadDocument({ documentId, specialType }){
        try {
          await this.$store.dispatch('documents/DOWNLOAD_SINGLE_DOCUMENT', { documentId, specialType })
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },
      async deleteFile({ file }) {
        try {
          await this.$store.dispatch('file/DELETE_FILE', { fileId: file.id, fileType: this.fileType || null })
          this.$emit('fileDeleted', 'uar')
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },
      isUAR () {
        return this.fileType.toLowerCase() === 'uar' ? true : false
      },
      labelClasses () {
        return {
          'list-el__label': true,
          'list-el__label--no-offset': !this.isUAR()
        }
      }
    },

    components: {
      gdBrick
    }
  }
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-brick-files-list {
    &__title {
      margin-bottom: 10px;
      font-size: 20px;
    }

    &__list {
      display: flex;
      flex-flow: column nowrap;

      li {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .gd-brick {
      min-height: unset;
    }

    .gd-brick__inner {
      align-items: flex-start;
      min-height: unset;
      padding: 25px 30px;
    }

    .icon {
      position: relative;
      top: -1px;
      width: 8px;
      height: 8px;
      transform: rotate(180deg);
      color: $color-red;
    }

    .list-el__label {
      padding-left: 8px;
      font-size: 16px;
      line-height: 30px;
      color: $color-blue;

      &--no-offset {
        padding-left: 0;
      }
    }
  }
</style>
