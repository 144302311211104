<template>
  <section class="gd-sign-up">
    <transition
      name="global-slide"
      mode="out-in"
    >
      <gdFormSignUp/>
    </transition>
  </section>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdFormSignUp from '@/components/forms/gd-form-sign-up'
  import gdPaymentPlans from '@/components/payment-plans/gd-payment-plans'

  export default {
    created () {
      const id = parseInt(this.$route.query.planId)
      if (id) {
        this.$store.commit('payments/SET_CHOSEN_PLAN_ID', { id })
      } else {
        this.$router.push('/')
      }
    },

    components: {
      gdFormSignUp,
      gdPaymentPlans
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/mixins/breakpoints.scss";
  
  .gd-sign-up {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    padding: 60px 0;

    @include media(tablet) {
      padding-top: 0;
    }
  }
</style>
