const initialState = () => ({})

const state = () => (initialState())

const actions = {}

const mutations = {}

const getters = {}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
