<template>
  <section
    class="gd-register-of-single section--wide"
    v-if="register"
  >
    <gdPageHeader
      :title="register.name"
      :description="register.info"
    >
      <template
        slot="actions"
      >
        <gdButton
          @click.native="downloadRegister(register.id)"
        >
          Pobierz
        </gdButton>
      </template>
    </gdPageHeader>
    <gdTableRegisterSingle
      :registerId="register.id"
      :columns="register.table.columns"
      :rows="register.table.rows"
    />
    <gdContinueImplementation
      v-if="!step5.completed"
    />
  </section>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdPageHeader from '@/components/page/gd-page-header'
  import gdContinueImplementation from '@/components/footers/gd-continue-implementation'
  import gdTableRegisterSingle from '@/components/tables/gd-table-register-single'

  export default {
    computed: {
      ...mapGetters('user', ['user']),
      ...mapGetters('rodoSteps', ['step5']),
      ...mapGetters('registerSingle', ['register'])
    },

    methods: {
      downloadRegister(id) {
        this.$store.dispatch("documents/DOWNLOAD_SINGLE_DOCUMENT", {
          documentId: id,
          specialType: 4
        });
      }
    },

    components: {
      gdPageHeader,
      gdTableRegisterSingle,
      gdContinueImplementation
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-register-of-single {
    display: flex;
    flex-direction: column;
    height: 100%;

    .gd-activity-edit-row {
      .gd-table-row--actions {
        border-bottom: 1px solid $color-gray-semi-light;
      }

      &:last-of-type .gd-table-row--actions {
        border-bottom: none;
      }
    }
  }
</style>
