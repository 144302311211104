<template>
  <gdLogIn/>
</template>
<script>
  import gdLogIn from '@/pages/gd-log-in'

  export default {
    metaInfo(){
      return this.$gdMetaInfo()
    },

    components: {
      gdLogIn
    }
  }
</script>
<style lang="scss">

</style>
