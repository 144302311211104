var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "gd-form gd-form-user-password-change",
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "gd-form__section" },
        [
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                name: "user-password",
                type: "password",
                "data-vv-as": "obecne hasło"
              },
              model: {
                value: _vm.password.old,
                callback: function($$v) {
                  _vm.$set(_vm.password, "old", $$v)
                },
                expression: "password.old"
              }
            },
            [_vm._v("\n      Obecne hasło\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gd-form__section" },
        [
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|passEnoughLength",
                  expression: "'required|passEnoughLength'"
                }
              ],
              ref: "password",
              attrs: {
                name: "user-new-password",
                "data-vv-as": "nowe hasło",
                type: "password"
              },
              model: {
                value: _vm.password.new,
                callback: function($$v) {
                  _vm.$set(_vm.password, "new", $$v)
                },
                expression: "password.new"
              }
            },
            [_vm._v("\n      Nowe hasło\n    ")]
          ),
          _vm._v(" "),
          _c(
            "gdInput",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|matchPasswords:password|passEnoughLength",
                  expression:
                    "'required|matchPasswords:password|passEnoughLength'"
                }
              ],
              ref: "password_confirm",
              attrs: {
                name: "user-new-password-repeat",
                "data-vv-as": "potwierdź nowe hasło",
                type: "password"
              },
              model: {
                value: _vm.password.newRepeat,
                callback: function($$v) {
                  _vm.$set(_vm.password, "newRepeat", $$v)
                },
                expression: "password.newRepeat"
              }
            },
            [_vm._v("\n      Potwierdź nowe hasło\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gd-form__submit" },
        [
          _c(
            "gdButton",
            {
              attrs: { loading: _vm.loading },
              nativeOn: {
                click: function($event) {
                  return _vm.handleSubmit($event)
                }
              }
            },
            [_vm._v("\n      Zmień hasło\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }