import { render, staticRenderFns } from "./index.vue?vue&type=template&id=47de0ada&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!R:\\.apache\\www\\public\\sp\\gdps\\sp\\frontend\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("R:\\.apache\\www\\public\\sp\\gdps\\sp\\frontend\\node_modules\\vue-loader\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('47de0ada', component.options)
    } else {
      api.reload('47de0ada', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=47de0ada&", function () {
      api.rerender('47de0ada', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/routing/app/certificate/index.vue"
export default component.exports