export default function formatSelectedColumnValues({ selectedColumnValues, isCustomActivity }) {

  const selectedColumnValuesArray = Object.keys(selectedColumnValues)
    .reduce((res, _columnId) => {
      const columnId = parseInt(_columnId)
      const column = selectedColumnValues[columnId]
      const { type, currentValue } = column
      res.push({ type, currentValue, columnId, column })
      return res
    }, [])

  if(!selectedColumnValuesArray.length){
    return []
  }

  const onlyInputs = selectedColumnValuesArray.every(item => 'input' === item.type)

  if(onlyInputs){
    const isInvalid = selectedColumnValuesArray.every(item => (item.currentValue && !item.currentValue.value))
    if(isInvalid){
      throw new Error('ONLY_INPUTS_SHOULD_CONTAIN_AT_LEAST_ONE_VALUE')
    }
  }

  const result = selectedColumnValuesArray
    .reduce((res, item) => {
      const { columnId, column, type, currentValue } = item

      let value

      switch (type) {
      case 'input':

        if(!isCustomActivity) {
          value = currentValue.value ? {
            columnId,
            input: currentValue.value
          } : null
        } else {
          value = {
            columnId,
            input: currentValue.value || ''
          }
        }


        if(!isCustomActivity && value){
          value.valueId = currentValue.id
        }
        break
      case 'list':

        if (Array.isArray(currentValue)) {
          value = currentValue
            .filter(item => Object.keys(item).length)
            .map(item => ({ columnId, valueId: item.value , isCustom: item.isCustom }))
        } else {
          if (Object.keys(currentValue).length) {
            value = {
              columnId,
              isCustom: currentValue.isCustom,
              valueId: (currentValue.value || currentValue.id)
            }
          }
        }
        break
      }

      if (Array.isArray(value)) {
        value.forEach(item => item && res.push(item))
      } else {
        if(value){
          res.push(value)
        }
      }

      return res
    }, [])
    .filter(item => item.valueId !== 'CUSTOM')

  return result
}
