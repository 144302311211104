var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chart-wrapper" }, [
    _c(
      "svg",
      {
        staticClass: "chart",
        attrs: { width: "115px", height: "115px", viewBox: "0 0 42 42" }
      },
      [
        _c("circle", {
          staticClass: "chart__hole",
          attrs: { cx: "21", cy: "21", r: "15.91549430918954", fill: "#ffffff" }
        }),
        _vm._v(" "),
        _c("circle", {
          staticClass: "chart__ring",
          attrs: {
            cx: "21",
            cy: "21",
            r: "15.91549430918954",
            stroke: _vm.colors.background
          }
        }),
        _vm._v(" "),
        _c("circle", {
          staticClass: "chart__segment",
          attrs: {
            cx: "21",
            cy: "21",
            r: "15.91549430918954",
            fill: "transparent",
            "stroke-width": "3",
            "stroke-dashoffset": "0",
            "stroke-linecap": "round",
            stroke: _vm.colors.circle,
            "stroke-dasharray": _vm.chartCircle
          }
        })
      ]
    ),
    _vm._v(" "),
    _c("p", { staticClass: "chart__progress" }, [
      _vm._v(_vm._s(parseInt(_vm.value)) + "%")
    ]),
    _vm._v(" "),
    _c("h2", {
      staticClass: "chart__title",
      domProps: { innerHTML: _vm._s(_vm.title) }
    }),
    _vm._v(" "),
    _c("p", {
      staticClass: "chart__content",
      domProps: { innerHTML: _vm._s(_vm.content) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }