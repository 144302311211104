var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gd-brick-files-upload" },
    [
      _c("gdBrick", [
        _c(
          "div",
          { staticClass: "brick-content" },
          [
            _c("p", { staticClass: "content__title" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _vm._v(" "),
            _vm.fileList && _vm.fileList.length
              ? [
                  _c(
                    "ul",
                    _vm._l(_vm.fileList, function(file, index) {
                      return _c(
                        "li",
                        { key: index },
                        [
                          _c("gdIcon", {
                            attrs: { name: "close" },
                            nativeOn: {
                              click: function($event) {
                                _vm.deleteFile({ file: file })
                              }
                            }
                          }),
                          _vm._v(" "),
                          file.name
                            ? _c(
                                "span",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.downloadFile({ file: file })
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(file.name) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    })
                  )
                ]
              : [
                  _c("span", { staticClass: "no-files" }, [
                    _vm._v("Brak plików.")
                  ])
                ]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "brick-actions" },
          [
            _c(
              "gdButton",
              {
                attrs: { theme: "secondary" },
                nativeOn: {
                  click: function($event) {
                    return _vm.uploadFile($event)
                  }
                }
              },
              [_vm._v("\n        Wgraj plik\n      ")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }