var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "gd-uar-step__questions" }, [
    _c(
      "div",
      { staticClass: "questions" },
      _vm._l(_vm.items, function(question, questionIndex) {
        return questionIndex === _vm.group.activeQuestion
          ? _c("ul", { key: questionIndex, staticClass: "questions_list" }, [
              _c("li", { class: _vm.questionClasses(question) }, [
                _c("p", { staticClass: "question__title" }, [
                  _vm._v(
                    "\n          " + _vm._s(question.question) + "\n        "
                  )
                ]),
                _vm._v(" "),
                question.info
                  ? _c("p", {
                      staticClass: "question__description",
                      domProps: { innerHTML: _vm._s(question.info) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "question__answers" },
                  [
                    _c(
                      "section",
                      { staticClass: "gd-rodo-step__content-extra" },
                      [
                        question.questionType !== "$UAR-RESULT" &&
                        _vm.groupIndex !== 0
                          ? _c(
                              "gdButton",
                              {
                                attrs: { theme: "ghost" },
                                nativeOn: {
                                  click: function($event) {
                                    _vm.group.activeQuestion > 0
                                      ? _vm.previousQuestion()
                                      : _vm.previousGroup()
                                  }
                                }
                              },
                              [
                                _c("gdImg", {
                                  attrs: {
                                    src: "/assets/icons/icon-arrow-purple.svg"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    question.requireUserComment
                      ? [
                          _c(
                            "gdTextArea",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              attrs: {
                                name: "userComment",
                                "data-vv-as": "uzasadnienie"
                              },
                              model: {
                                value: _vm.data[questionIndex].userComment,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.data[questionIndex],
                                    "userComment",
                                    $$v
                                  )
                                },
                                expression: "data[questionIndex].userComment"
                              }
                            },
                            [
                              _vm._v(
                                "\n              Uzasadnienie\n            "
                              )
                            ]
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    question.questionType === "boolean"
                      ? [
                          _c(
                            "div",
                            { staticClass: "question__buttons" },
                            _vm._l(question.answers, function(
                              answer,
                              answerIndex
                            ) {
                              return _c(
                                "gdButton",
                                {
                                  key: answerIndex,
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.setAnswer({
                                        answer: answer,
                                        questionId: question.id,
                                        questionIndex: questionIndex
                                      }) + _vm.nextQuestion()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(answer.label) +
                                      "\n              "
                                  )
                                ]
                              )
                            })
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    question.questionType === "select"
                      ? [
                          _c("gdSelect", {
                            attrs: {
                              value: _vm.data[questionIndex].answer,
                              name: "question" + questionIndex,
                              placeholder: "Wybierz z listy...",
                              items: question.answers
                            },
                            on: {
                              input: function($event) {
                                _vm.setAnswer({
                                  answer: $event,
                                  questionId: question.id,
                                  questionIndex: questionIndex
                                })
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "question__buttons" },
                            [
                              _c(
                                "gdButton",
                                {
                                  attrs: {
                                    disabled: !_vm.data[questionIndex].answer
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.nextQuestion()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Dalej\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    question.questionType === "checkbox"
                      ? [
                          _c("p", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              "MUTLI CHECKBOX FOR UAR HAVE NO SENSE. CHANGE IT IN CMS."
                            )
                          ])
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    question.questionType === "$UAR-RESULT"
                      ? [
                          _c(
                            "gdButton",
                            {
                              staticClass: "question__buttons",
                              attrs: { theme: "secondary" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.startNewAnalysis($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Przeprowadź ponowną analizę\n            "
                              )
                            ]
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    question.questionType === "$CONTINUE"
                      ? [
                          _c(
                            "gdButton",
                            {
                              staticClass: "question__buttons",
                              nativeOn: {
                                click: function($event) {
                                  _vm.nextQuestion(true)
                                }
                              }
                            },
                            [_vm._v("\n              Kontynuuj\n            ")]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ])
            ])
          : _vm._e()
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }